<script lang="tsx">
import { defineComponent, useCssModule } from '@vue/composition-api';
import moment from 'moment';
import { useState } from '../../../hooks/show/state';
import { useTab } from '../../../hooks/show/tab';
import TabContent from '../TabContent.vue';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'CompetitionShowScheduleTab',
  setup() {
    const I18n = useI18n();
    const style = useCssModule();
    const { tabContents } = useTab();
    const { state } = useState();

    return () => {
      if (!tabContents.schedules) return;

      const { showRegistrationGroup } = state;
      const {
        scheduleDescription,
        testSchedulesByRegistrationGroup,
      } = tabContents.schedules;

      return (
        <div>
          {
            testSchedulesByRegistrationGroup.map(({ registrationGroup, tests }) => (
              <div class="schedule-item">
                {
                  showRegistrationGroup &&
                  <div class="registration-group">
                    <h5 class="title">
                      { I18n.t('competition.show.tabs.schedules.registration_group.title', { title: registrationGroup.title }) }
                    </h5>
                    <h6>
                      { registrationGroup.description }
                    </h6>
                  </div>
                }
                <div class="table-wrap">
                  <table class="table-schedule">
                    <tbody>
                      {
                        tests.map(({ id, title, startAt, endAt, submissionStatus }) => (
                          <tr key={id}>
                            <td class="t-date">
                              <h6 class="month">
                                { I18n.t('competition.show.tabs.schedules.month', { month: moment(startAt).month() + 1 }) }
                              </h6>
                              <h3 class="day">
                                { I18n.l('time.formats.day', startAt) }
                              </h3>
                            </td>
                            <td class="t-event">
                              <h5 class="event-title">
                                { title }
                              </h5>
                              <h6 class="period">
                                { I18n.l('time.formats.competition_start', startAt) }&nbsp;
                                ~ { I18n.l('time.formats.competition_end', endAt) }
                              </h6>
                            </td>
                            { submissionStatus &&
                              <td class="t-submission">
                                <h6>
                                  { I18n.t(`competitions.schedules.submission.${submissionStatus}`) }
                                </h6>
                              </td>
                            }
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            ))
          }
          { scheduleDescription &&
            <div class={style.markdownContainer}>
              <TabContent markdown={scheduleDescription} />
            </div>
          }
        </div>
      );
    };
  },
});
</script>

<style lang="scss" module>
@use "~/stylesheets/functions";
@use "~/stylesheets/variables";

.markdownContainer {
  margin-top: functions.rem-calc(32);
}

@media (variables.$lg-down) {
  .markdownContainer {
    margin-top: functions.rem-calc(24);
  }
}
</style>
