import { Validation } from '@vuelidate/core';
import formatConstants, {
  Language,
  Test,
} from '@/constant/format';
import { Token, Tryout } from '@/exportables/models/career/tokens.model';
import { DefaultDate } from '@/exportables/types/general.d';
import { useI18n } from '@/helpers/i18n';

const I18n = useI18n();

export const inputValidationClass = (proxy: Validation, useDirty = true) => (
  (useDirty ? proxy?.$dirty : true) &&
  (proxy?.$error || proxy?.$invalid) && 'has-error' ||
  ''
);

export const fileSize = (bytes: number) => {
  const UNITS = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const THRESH = 1024;
  let newBytes = bytes;
  let index = -1;

  if (newBytes < THRESH) {
    return newBytes + 'B';
  }

  do {
    newBytes /= THRESH;
    index++;
  } while (newBytes >= THRESH && index < UNITS.length - 1);

  return newBytes.toFixed(1) + UNITS[index];
};

export const skillCheckLevelImg = (level: SkillCheckLevel) => require(`~/images/img-badge-skill-${level}.png`);

export const percentileRank = (
  {
    rank,
    testeesCount = 1,
  }: Tryout,
) => rank ? Math.round(rank! / testeesCount! * 100 || 0) : '-';

export const tooltipContent = (skillCheck: SkillCheck) => {
  const min = I18n.t('datetime.prompts.min');
  const language = formatConstants.LANGUAGE?.[skillCheck.language]?.name || '';
  const testTime = (skillCheck.testTime / 60).toFixed(1);
  const finishedAt = skillCheck.finishedAt && I18n.l('time.formats.without_time', skillCheck.finishedAt) || '';

  return `
    <ul>
      <li>${I18n.t('resume.test_results.language')}: ${language}</li>
      <li>${testTime}${min} (${skillCheck.timeout}${min})</li>
      <li>${I18n.t('resume.test_results.finished_at')}: ${finishedAt}</li>
    <ul>
  `;
};

export const testLanguages = (token: Token) => Array.from(new Set(token.languages), (language) => formatConstants.LANGUAGE?.[language]?.name || '').join(', ');

export const testFrameworks = (token: Token) => token.frameworks?.join(', ') || '';

export const testIcon = (token: Token, type: keyof Test) => {
  const { challengeableType = '' } = token;
  const testTypes = Object.entries(formatConstants.TEST[type]);
  const challengeableTypeName = (
    testTypes.filter(([, value]: [string, string[]]) => value.includes(challengeableType)).map(([key]) => key) ||
    testTypes[0][0]
  );

  return `ic-${challengeableTypeName}`;
};

export const testInfo = (token: Token, type: keyof Test) => {
  const { status, isMailed } = token;
  const languages = {};

  type === 'tryout' && Object.assign(languages, { languages: testLanguages(token) });
  type === 'assignment' && Object.assign(languages, { frameworks: testFrameworks(token) });

  return Object.assign(
    {
      badgeStatus: (isMailed && status !== 'finished') ? I18n.t('resume.test_results.status.emailed') : '',
      status: status === 'finished' ? '' : I18n.t(`resume.test_results.status.${status}`),
      icon: testIcon(token, type),
    },
    languages,
  );
};

type SkillCheckLevel = 1 | 2 | 3 | 4 | 5;

type SkillCheck = {
  id: number,
  codeUrl: string,
  finishedAt: DefaultDate,
  language: Language,
  level: number,
  timeout: number,
  testTime: number,
};

export const pluralType = {
  activity: 'activities',
  award: 'awards',
  education: 'educations',
  experience: 'experiences',
  foreignLanguage: 'foreignLanguages',
  project: 'projects',
  publication: 'publications',
  attachment: 'attachment',
} as const;

export type Type = keyof typeof pluralType;
