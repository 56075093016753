<template>
  <div class="resume-card-item">
    <div class="resume-card-left">
      <h5 v-if="experience.startAt" class="resume-card-item-period">
        {{ I18n.l('date.formats.year_month', experience.startAt) }} ~ {{ endAt }}
        <span class="period">
          ({{ experienceDuration }})
        </span>
      </h5>
    </div>

    <div ref="hoverRef" class="resume-card-right">
      <h4 class="resume-card-item-label" :class="style.header">
        <a
          v-if="company.url"
          :href="company.url"
          :class="company.url"
          target="_blank"
        >
          {{ company.name }}
        </a>
        <template v-else>
          {{ company.name }}
        </template>

        <span
          v-if="experience.developmentUnrelated"
          class="label"
          :class="style.label"
        >
          {{ I18n.t('section.experience.non_dev_related.abstract_label') }}
        </span>
      </h4>

      <h5 class="resume-card-item-text">
        {{ experience.role }}
      </h5>

      <h5 v-if="experience.link" class="resume-card-item-text">
        <a
          :href="experience.link"
          target="_blank"
          rel="noopener"
        >
          {{ experience.link }}
        </a>
      </h5>

      <ul v-if="experience.tags && experience.tags.length" class="tag-list">
        <li
          v-for="tag in experience.tags"
          :key="tag.id"
          :class="isHighlightableTag(tag.name) && 'highlight'"
        >
          {{ tag.name }}
        </li>
      </ul>

      <MarkdownViewer
        class="markdown-viewer"
        :value="experience.description"
      />

      <ul v-if="experience.parts && experience.parts.length" class="list-contributions">
        <PartPreview
          v-for="part in experience.parts"
          :key="part.title"
          :part="part"
        />
      </ul>

      <HoverButtonWrapper
        v-if="isHovered"
        type="experience"
        :delete-item="() => handleDeleteItem(index)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  PropType,
  computed,
  defineComponent,
  inject,
  onBeforeUnmount,
  ref,
  toRef,
  unref,
  useCssModule,
} from '@vue/composition-api';
import HoverButtonWrapper from '../HoverButtonWrapper.vue';
import PartPreview from '../Part/Preview.vue';
import MarkdownViewer from '@/components/shared/MarkdownViewer.vue';
import { ResumeExperience } from '@/exportables/models/career/resume.model';
import { durationYearAndMonth } from '@/helpers/datetime';
import { useHover } from '@/helpers/event';
import { useI18n } from '@/helpers/i18n';
import { useStore } from '@/store/career';

export default defineComponent({
  name: 'ResumeExperiencePreview',
  components: {
    HoverButtonWrapper,
    PartPreview,
    MarkdownViewer,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    experience: {
      type: Object as PropType<ResumeExperience>,
      required: true,
    },
  },
  setup(props) {
    const { handleDeleteItem } = inject('form')!;
    const hoverRef = ref(null);
    const experience = toRef(props, 'experience');
    const I18n = useI18n();
    const store = useStore();
    const style = useCssModule();
    const clickedTagName = computed(() => store.state.resume.clickedTagName);
    const mouseOveredTagName = computed(() => store.state.resume.mouseOveredTagName);
    const company = computed(() => {
      const unrefedExperience = unref(experience);
      return {
        name: unrefedExperience?.indexedCompany?.name ||
          unrefedExperience?.company?.name ||
          unrefedExperience.name,
        url: unrefedExperience?.company?.companyUrl || '',
      };
    });
    const endAt = computed(() => {
      const unrefedEndAt = unref(experience).endAt;
      return unrefedEndAt ? I18n.l('date.formats.year_month', unrefedEndAt) : I18n.t('section.experience.in_employeed');
    });
    const experienceDuration = computed(() => durationYearAndMonth(unref(experience)?.duration!) || 0);
    const { isHovered, unwatch } = useHover(hoverRef);

    const isHighlightableTag = (tagName: string) => {
      const unrefedMouseOveredTagName = unref(mouseOveredTagName);
      return (
        unrefedMouseOveredTagName === tagName ||
        unrefedMouseOveredTagName === '' && unref(clickedTagName) === tagName ||
        false
      );
    };

    onBeforeUnmount(unwatch);

    return {
      I18n,
      clickedTagName,
      company,
      endAt,
      experienceDuration,
      handleDeleteItem,
      hoverRef,
      isHighlightableTag,
      isHovered,
      mouseOveredTagName,
      style,
    };
  },
});
</script>

<style lang="scss" module>
@use "~/stylesheets/variables";
@use "~/stylesheets/functions";

.label {
  background: variables.$blue-grey-900;
  margin-left: functions.rem-calc(8);
  padding: functions.rem-calc(3 6) !important;
  border-radius: functions.rem-calc(4);
}

.header {
  > a {
    color: variables.$color-text--primary;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
}
</style>
