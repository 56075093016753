<template>
  <div class="test-item">
    <div class="test-item-header">
      <div class="thumb-icon">
        <SvgIcon :name="icon" html-class="ic-24" />
      </div>

      <div class="test-item-info">
        <h4 class="resume-card-item-label">
          {{ token.title }}
        </h4>

        <p v-if="token.finishedAt" class="resume-card-item-text">
          {{ I18n.l('time.formats.period', token.finishedAt) }}
        </p>
        <a
          v-if="!isSharable"
          :href="token.codeUrl"
          class="btn btn-code-preview skill-check-assignment"
          target="_blank"
          rel="noopener"
        >
          {{ I18n.t('resume.test_results.view_code') }}
          <span v-show="token.isReviewed">({{ I18n.t('resume.test_results.status.reviewed') }})</span>
          <SvgIcon name="ic-launch-14" html-class="ic-14" />
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  PropType,
  computed,
  defineComponent,
} from '@vue/composition-api';
import { testIcon } from '../../resume.util';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { SkillCheckAssignment } from '@/exportables/models/career/tokens.model';
import { useI18n } from '@/helpers/i18n';
import { useStore } from '@/store/career';

export default defineComponent({
  name: 'ResumeSkillCheckAssignmentResult',
  components: {
    SvgIcon,
  },
  props: {
    token: {
      type: Object as PropType<SkillCheckAssignment>,
      required: true,
    },
  },
  setup(props) {
    const I18n = useI18n();
    const store = useStore();
    const isSharable = computed(() => store.state.resume.isSharable);

    return {
      icon: testIcon(props.token, 'assignment'),
      I18n,
      isSharable,
    };
  },
});
</script>
