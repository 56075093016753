<template>
  <div>
    <section v-if="!isEmpty" class="banner-custom-html swiper-slider">
      <div class="swiper-container">
        <Swiper ref="swiper" :options="swiperOption" class="swiper">
          <SwiperSlide
            v-for="(banner, index) in banners"
            :key="index"
            :class="$style.swiper"
            :data-hackle-value="hackleValue"
          >
            <a
              v-if="banner.bannerType === 'html'"
              :href="banner.linkUrl"
              :data-banner-id="banner.id"
              target="_blank"
              class="gtm-banner-link"
              rel="noopener"
              v-html="banner.contents"
            ></a>
            <a
              v-else-if="banner.bannerType === 'image'"
              class="slider-item gtm-banner-link"
              :data-banner-id="banner.id"
              :class="$style.slideritem"
              :href="banner.linkUrl"
              :style="{
                background: `url('${banner.imageUrl}') no-repeat center center`,
                backgroundSize: 'cover',
              }"
              target="_blank"
              rel="noopener"
            ></a>
            <ImpressionTracker
              :trackable-id="banner.id"
              :tracking-infos="{ index: index + 1 }"
              trackable-type="Banner"
            />
          </SwiperSlide>

          <div
            v-if="banners.length > 1"
            slot="pagination"
            class="swiper-pagination"
          ></div>
        </Swiper>
        <div class="swiper-button swiper-button-prev">
          <SvgIcon name="ic-keyboard-arrow-left" html-class="ic-24 ic-white" />
        </div>
        <div class="swiper-button swiper-button-next">
          <SvgIcon name="ic-keyboard-arrow-right" html-class="ic-24 ic-white" />
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import 'swiper/swiper-bundle.css';
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  unref,
} from '@vue/composition-api';
import {
  Autoplay,
  Navigation,
  Pagination,
  Swiper as SwiperClass,
} from 'swiper/core';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';
import ImpressionTracker from '@/components/shared/ImpressionTracker.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { useBannerApiClient } from '@/exportables/apis/banner.api';
import { Banner } from '@/exportables/models/banner.model';

SwiperClass.use([Navigation, Pagination, Autoplay]);
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);

export default defineComponent({
  name: 'BannerSwiper',
  components: {
    ImpressionTracker,
    SvgIcon,
    Swiper,
    SwiperSlide,
  },
  props: {
    hackleValue: {
      type: String,
      default: '',
    },
  },
  setup() {
    const banners = ref<Banner[]>([]);
    const bannerApiClient = useBannerApiClient();
    const swiper = ref(null);
    const isEmpty = computed(() => unref(banners).length === 0);
    const swiperOption = computed(() => {
      return {
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        slidesPerView: 1,
        loop: unref(banners).length > 1,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        initialSlide: unref(banners)[0].ordered
          ? 0
          : Math.floor(Math.random() * unref(banners).length),
      };
    });

    onMounted(async () => {
      banners.value = await bannerApiClient.getBanners({ ordered: true });
      if (unref(banners)[0]?.swiperDelay) {
        swiperOption.value.autoplay.delay = unref(banners)[0]?.swiperDelay;
      }
    });

    return {
      banners,
      swiper,
      isEmpty,
      swiperOption,
    };
  },
});
</script>

<style lang="scss" module>
@use '~/stylesheets/functions';

.slideritem {
  border-radius: 0 !important;
  min-height: functions.rem-calc(242);
}

.swiper {
  height: auto;
}
</style>
