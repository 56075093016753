<template>
  <section id="job-categories" class="job-categories">
    <h5 class="resume-section-title">
      <span>{{ I18n.t('job_profile.job_categories.title') }}</span>

      <SectionEditButton
        :is-editable="isEditable"
        :tooltip="I18n.t('job_profile.job_categories.tooltip')"
        type="JobCategories"
      />
    </h5>

    <ul class="list-stack">
      <li
        v-for="category in jobCategories"
        :key="category.id"
        class="col-item"
      >
        <button class="stack-item">
          {{ category.name }}
        </button>
      </li>
    </ul>

    <div class="section-alert">
      <p v-for="idx in 2" :key="idx">
        {{ I18n.t(`job_profile.job_categories.alert_${idx}`) }}
      </p>
    </div>
  </section>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
} from '@vue/composition-api';
import { useState } from '../hooks/show/state';
import { useI18n } from '@/helpers/i18n';
import SectionEditButton from '@/pages/career/resumes/components/SectionEditButton.vue';
import { useStore } from '@/store/career';

export default defineComponent({
  name: 'JobProfileJobCategories',
  components: {
    SectionEditButton,
  },
  setup() {
    const I18n = useI18n();
    const { state } = useState();
    const store = useStore();
    const jobCategories = computed(() => state.jobProfile.jobCategories);
    const isEditable = computed(() => store.state.resume.isEditable);

    return {
      I18n: { t: I18n.t },
      isEditable,
      jobCategories,
    };
  },
});
</script>
