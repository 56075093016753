import { ActionTree } from 'vuex';
import { SET_JOB_CATEGORIES, SET_JOB_THEMES } from './mutation-types';
import { JobPositionState } from './state';
import { fetchJobCategories } from '@/exportables/apis/career/job-position.api';
import { fetchJobThemes } from '@/exportables/apis/career/job-theme.api';
import { JobTheme } from '@/exportables/models/career/job-theme.model';

const actions: ActionTree<JobPositionState, any> = {
  async setJobCategories({ commit }) {
    commit(SET_JOB_CATEGORIES, await fetchJobCategories());
  },
  async setJobThemes({ commit }) {
    const jobThemes = await fetchJobThemes();
    if (!jobThemes.length) return;
    commit(
      SET_JOB_THEMES,
      jobThemes.sort((a, b) => a.displayOrder - b.displayOrder).map(JobTheme.new),
    );
  },
};
export default actions;
