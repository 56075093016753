<template>
  <Autocomplete
    class="form-tag"
    icon-name="ic-testcast-box"
    url="/api/tags/auto_complete"
    ul-custom-class="modal-autocomplete"
    :empty-form-shake="true"
    :no-result-label="I18n.t('job_position.list.search_filter.no_result')"
    :is-restricted-unicode="true"
    :placeholder="I18n.t('job_position.list.search_filter.tag.placeholder')"
    @selected="addTagFilter"
  />
</template>

<script lang="ts">
import { defineComponent, inject } from '@vue/composition-api';
import { FilterState } from '../../hooks/filter';
import Autocomplete from '@/components/shared/Autocomplete.vue';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'JobPositionMinEmployeesFilter',
  components: {
    Autocomplete,
  },
  setup() {
    const { tags, addTagFilter } = inject<FilterState>('filter')!;
    const I18n = useI18n();

    return {
      I18n,
      addTagFilter,
      tags,
    };
  },
});
</script>
