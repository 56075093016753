<template>
  <a
    :class="style.container"
    :href="`/companies/${info.id}?featured_company=true`"
    data-testid="featured company"
  >
    <div
      :class="style.background"
      :style="{ backgroundImage: `url(${info.imageUrl})` }"
      role="img"
      :aria-label="I18n.t('company.background_image', { name: info.name })"
    ></div>

    <img :class="style.logo" :src="info.logoUrl" :alt="I18n.t('company.logo', { name: info.name })">

    <div :class="style.infoWrap">
      <h5>{{ info.name }}</h5>
      <h6>{{ info.serviceName }}</h6>
    </div>
  </a>
</template>

<script lang="ts">
import { PropType, defineComponent, useCssModule } from '@vue/composition-api';
import { FeaturedCompany } from '@/exportables/models/career/company.model';
import { useI18n } from '@/helpers/i18n';
import { moveTo } from '@/helpers/url';

export default defineComponent({
  name: 'FeaturedCompany',
  props: {
    info: {
      type: Object as PropType<FeaturedCompany>,
      required: true,
    },
  },
  setup() {
    const I18n = useI18n();
    const style = useCssModule();

    return {
      I18n,
      moveTo,
      style,
    };
  },
});
</script>

<style lang="scss" module>
@use '~/stylesheets/functions';
@use '~/stylesheets/variables';

$height: 216;
$bgHeight: 120;

.container {
  height: functions.rem-calc($height);
  border-radius: functions.rem-calc(10);
  border: 1px solid variables.$color-border;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    background-color: variables.$blue-grey-10;
    border-color: variables.$blue-100;

    .infoWrap {
      background-color: variables.$blue-grey-10;
    }
  }
}

.background {
  width: 100%;
  min-height: functions.rem-calc($bgHeight);
  height: 70%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: absolute;
  top: 0;
}

.logo {
  width: functions.rem-calc(64);
  height: functions.rem-calc(64);
  background-color: white;
  border: 1px solid variables.$color-border;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  bottom: functions.rem-calc(60);
  transform: translateX(-50%);
  object-fit: contain;
  z-index: 2;
}

.infoWrap {
  width: 100%;
  height: functions.rem-calc($height - $bgHeight);
  border-top: 1px solid variables.$color-border;
  background-color: white;
  padding: functions.rem-calc(16);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1;

  h5 {
    color: variables.$color-text--primary;
    font-weight: 700;
  }

  h6 {
    color: variables.$color-text--secondary;
  }

  h5,
  h6 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media (variables.$sm-down) {
  .logo {
    width: functions.rem-calc(48);
    height: functions.rem-calc(48);
  }

  .infoWrap {
    padding: functions.rem-calc(12);
  }
}
</style>
