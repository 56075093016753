<template>
  <TagPosts
    :tag="tag"
    :current-page="currentPage"
  />
</template>

<script lang="ts">
import { defineComponent, ref, watch } from '@vue/composition-api';
import TagPosts from '../components/TagPosts.vue';
import { useRoute } from '@/router/career';

const DEFAULT_PAGE = 1;
const DEFAULT_TAG = '';

export default defineComponent({
  name: 'TaggedPostsIndex',
  components: {
    TagPosts,
  },
  setup() {
    const route = useRoute();

    const tag = ref<string>(DEFAULT_TAG);
    const currentPage = ref<number>(DEFAULT_PAGE);

    watch(
      () => [route.query, route.params],
      ([query, params]) => {
        currentPage.value = Number(query.page) || DEFAULT_PAGE;
        tag.value = params.tag as string || DEFAULT_TAG;
      },
      { immediate: true },
    );

    return {
      tag,
      currentPage,
    };
  },
});
</script>
