<template>
  <div class="resume-card-item form">
    <div class="form-group-wrap">
      <label class="form-group-title">
        {{ I18n.t('resume.project') }} {{ index + 1 }}
      </label>

      <div class="form-row">
        <div class="col-8">
          <div class="form-group form-required">
            <label>
              {{ I18n.t('resume.project') }}
            </label>

            <input
              v-model="newProject.name"
              class="form-control"
              :class="inputValidationClass($v.name)"
              type="text"
              autocomplete="off"
              :placeholder="I18n.t('terms.input')"
              @blur="$v.name.$touch"
            >
          </div>
        </div>

        <div class="col-4">
          <div class="form-group form-required">
            <label>
              {{ I18n.t('section.project.start_at') }}
            </label>

            <select
              v-model="newProject.startAt"
              class="form-control"
              :class="inputValidationClass($v.startAt)"
            >
              <option
                value=""
                disabled
                selected
              >
                {{ I18n.t('section.project.start_at') }}
              </option>
              <option
                v-for="year in validYears"
                :key="year"
                :value="year"
              >
                {{ year }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label>
              {{ I18n.t('section.project.description') }}
            </label>

            <input
              v-model="newProject.description"
              class="form-control"
              type="text"
              autocomplete="off"
              :placeholder="I18n.t('terms.input')"
            >
          </div>
        </div>

        <div class="col-4">
          <div class="form-group">
            <label>
              {{ I18n.t('section.project.team_type') }}
            </label>

            <select v-model="teamType" class="form-control">
              <option :value="TEAM" selected>
                {{ I18n.t('section.project.team') }}
              </option>
              <option :value="PERSON">
                {{ I18n.t('section.project.person') }}
              </option>
            </select>
          </div>
        </div>

        <div v-if="isTeam" class="col-8">
          <div class="form-group">
            <label>
              {{ I18n.t('section.project.team_description') }}
            </label>

            <input
              v-model="newProject.teamDescription"
              class="form-control"
              type="text"
              autocomplete="off"
              :placeholder="I18n.t('terms.input')"
            >
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label>
              {{ I18n.t('section.project.stack') }}
            </label>

            <MultiSelect
              :search="tagSearch"
              :select="tagSelect"
              :placeholder="I18n.t('section.project.placeholder.stack')"
            >
              <template #no-item>
                <div class="tag">
                  {{ I18n.t('resume.messages.tag.no_item') }}
                </div>
              </template>
            </MultiSelect>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label>
              {{ I18n.t('section.project.role_description') }}
            </label>

            <textarea
              v-model="newProject.roleDescription"
              type="text"
              class="form-control"
              rows="3"
              autocomplete="off"
              :placeholder="I18n.t('terms.input')"
            ></textarea>
          </div>
        </div>

        <div class="col-12 col-published">
          <div class="form-group">
            <label>
              {{ I18n.t('section.project.published') }}
            </label>

            <div class="form-control flex-between">
              <span>
                {{ I18n.t('section.project.is_published') }}
              </span>

              <Toggle :is-active="isPublished" @toggle="handleToggle" />
            </div>
          </div>
        </div>

        <template v-if="isPublished">
          <div class="col-4">
            <div class="form-group">
              <label>Website</label>

              <input
                v-model="newProject.productUrl.websiteUrl"
                class="form-control"
                :class="inputValidationClass($v.productUrl.websiteUrl)"
                type="text"
                autocomplete="off"
                placeholder="https://"
                @blur="$v.productUrl.websiteUrl.$touch"
              >
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label>Android</label>

              <input
                v-model="newProject.productUrl.androidUrl"
                class="form-control"
                :class="inputValidationClass($v.productUrl.androidUrl)"
                type="text"
                autocomplete="off"
                placeholder="https://"
                @blur="$v.productUrl.androidUrl.$touch"
              >
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label>iOS</label>

              <input
                v-model="newProject.productUrl.iosUrl"
                class="form-control"
                :class="inputValidationClass($v.productUrl.iosUrl)"
                type="text"
                autocomplete="off"
                placeholder="https://"
                @blur="$v.productUrl.iosUrl.$touch"
              >
            </div>
          </div>
        </template>

        <div class="col-12">
          <div class="form-group">
            <label>
              {{ I18n.t('section.project.link') }}
            </label>

            <input
              v-model="newProject.repository"
              class="form-control"
              :class="inputValidationClass($v.repository)"
              type="text"
              placeholder="https://github.com/project"
              @blur="$v.repository.$touch"
            >
          </div>
        </div>
      </div>
    </div>

    <div class="form-btn-wrap-lg">
      <button class="btn btn-md btn-light" @click="handleCancelEdit">
        {{ I18n.t('actions.cancel') }}
      </button>
      <button class="btn btn-md btn-primary" @click="handleSubmit">
        {{ I18n.t('actions.save') }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import {
  PropType,
  computed,
  defineComponent,
  inject,
  reactive,
  ref,
  unref,
  watch,
} from '@vue/composition-api';
import { useVuelidate } from '@vuelidate/core';
import { required, url } from '@vuelidate/validators';
import { handleUpdateForm } from '../../hooks/form';
import { ResumeShowStates, getResume } from '../../hooks/state';
import constants from '../../resume.constant';
import { inputValidationClass } from '../../resume.util';
import Toggle from '../Toggle.vue';
import MultiSelect from '@/components/shared/MultiSelect.vue';
import { ResumeProject } from '@/exportables/models/career/resume.model';
import { Tag } from '@/exportables/models/career/tag.model';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';
import { useSearch, useSelect } from '@/helpers/search';
import { cloneArray } from '@/helpers/utils';

const currentYear = new Date().getFullYear();

export default defineComponent({
  name: 'ResumeProjectForm',
  components: {
    MultiSelect,
    Toggle,
  },
  props: {
    isAdded: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
    project: {
      type: Object as PropType<ResumeProject>,
      required: true,
    },
  },
  setup(props) {
    const changeEditState = inject<Function>('changeEditState')!;
    const { newItem } = inject('form')!;
    const state = inject<ResumeShowStates>('state')!;
    const { TEAM, PERSON } = constants;
    const validYears = Array.from({ length: 20 }, (_, index) => (currentYear - index).toString());
    const { t } = useI18n();
    const tag = reactive({
      id: -1,
      name: '',
    });
    const project = new ResumeProject(props.project);
    const newProject = reactive({
      ...project,
      startAt: project.startAt?.toString().split('-')[0] || '',
    });
    const rules = {
      name: { required },
      startAt: { required },
      productUrl: {
        websiteUrl: { url },
        androidUrl: { url },
        iosUrl: { url },
      },
      repository: { url },
    };
    const teamType = ref(unref(newProject.teamDescription) === PERSON ? PERSON : TEAM);
    const isPublished = ref(Boolean(newProject.productUrl.websiteUrl || newProject.productUrl.androidUrl || newProject.productUrl.iosUrl));
    const isTeam = computed(() => unref(teamType) === TEAM);
    const $v = useVuelidate(rules, newProject);
    const tagSelect = useSelect(newProject.tags);
    const tagSearch = useSearch(tag, 'tag', {
      click(t?: Tag) {
        if (!t) return;

        tagSelect.handleSelect(t);
        Object.assign(tag, {
          id: -1,
          name: '',
        });
      },
    });

    const handleToggle = () => {
      Object.assign(
        isPublished,
        { value: !isPublished.value },
      );

      unref(isPublished) && Object.assign(
        newProject.productUrl,
        {
          websiteUrl: '',
          androidUrl: '',
          iosUrl: '',
        },
      );
    };

    const handleCancelEdit = () => {
      props.isAdded && Object.assign(newItem, { value: null });
      changeEditState(false);
    };

    const handleSubmit = () => {
      const unreffed$v = unref($v);
      const resume = getResume(state);
      const projects = cloneArray(resume.projects);

      unreffed$v.$touch();

      if (unreffed$v.$error || unreffed$v.$invalid) {
        appendToast(t('resume.error.form'));
        return;
      }

      props.isAdded ? projects.push(newProject) : projects.splice(props.index, 1, newProject);

      handleUpdateForm(state, { projects });
      changeEditState(false);

      Object.assign(newItem, { value: null });
    };

    watch(
      isTeam,
      (isTeam) => newProject.teamDescription = isTeam ? '' : PERSON,
    );

    return {
      $v,
      I18n: { t },
      PERSON,
      TEAM,
      handleCancelEdit,
      handleToggle,
      handleSubmit,
      inputValidationClass,
      isPublished,
      isTeam,
      newProject,
      tagSearch,
      tagSelect,
      teamType,
      validYears,
    };
  },
});
</script>
