import { useHttpClient } from '../../httpClient';
import { BusinessJobOffer } from '@/exportables/models/career/business/job-offer.model';
import { BusinessJobPosition } from '@/exportables/models/career/business/job-position.model';
import { BASE_API_URL } from '@/helpers/http';
import { OfferingJobPosition } from '@/pages/career/business/job-offers/hooks/state';

export const BUSINESS_JOB_OFFER_URL = '/business/job_profile_offers';

const httpClient = useHttpClient();

export type FetchNewJobOfferPayload = {
  jobPositions: BusinessJobPosition[],
  jobProfileId?: number,
  lastOfferMessage: string,
};

type CreateJobOfferPayload = {
  jobPositionId: number,
  jobProfileId: number,
  offerMessage: string,
};

type UpdateJobOfferPayload = {
  jobProfileOfferId: number,
  offerMessage: string,
};

type DeleteJobOfferPayload = {
  jobProfileOfferId: number,
  cancelMessage: string,
};

type GetJobOfferXlsxUriPayload = {
  status?: BusinessJobOffer['status'] | null,
  jobPositionId?: OfferingJobPosition['id'] | null,
};

export const fetchNewJobOffer = (id: number) => httpClient.get<FetchNewJobOfferPayload>(`${BUSINESS_JOB_OFFER_URL}/new?job_profile_id=${id}`);

export const createJobOffer = (params: CreateJobOfferPayload) => httpClient.post<BusinessJobOffer>(BUSINESS_JOB_OFFER_URL, params);

export const updateJobOffer = (id: number, params: UpdateJobOfferPayload) => httpClient.patch(`${BUSINESS_JOB_OFFER_URL}/${id}`, params);

export const deleteJobOffer = (id: number, params: DeleteJobOfferPayload) => httpClient.delete(`${BUSINESS_JOB_OFFER_URL}/${id}`, { params });

export const getJobOfferXlsxUri = ({
  status,
  jobPositionId,
}: GetJobOfferXlsxUriPayload) => BASE_API_URL + httpClient.getUri({
  method: 'get',
  url: `${BUSINESS_JOB_OFFER_URL}/export_excel`,
  params: {
    status,
    job_position_id: jobPositionId,
  },
});
