<template>
  <div v-if="currentUser.isLoggedIn" class="checkbox-toggle-wrap position-alert-wrap">
    <h6
      data-container="body"
      data-toggle="popover"
      data-trigger="hover"
      data-placement="top"
      :data-content="I18n.t('job_position.list.filter_notice.tooltip_html')"
      data-html="true"
    >
      {{ I18n.t('job_position.list.filter_notice.title') }}
    </h6>
    <div class="checkbox-toggle">
      <input
        id="job-position-filter-toggle"
        type="checkbox"
        class="tgl tgl-light"
        :checked="hasJobPositionFilter"
        @change="jobPositionFilterToggleChangeHandler($event.target.checked)"
      >
      <label for="job-position-filter-toggle" class="tgl-btn"></label>
    </div>
    <a href="/job_position_filters" class="link-saved-alerts">
      {{ I18n.t('job_position.list.filter_notice.link') }}
      <SvgIcon name="ic-link-arrow-right-16" html-class="ic-16" />
    </a>
  </div>
</template>

<script lang="ts">
import {
  Ref,
  computed,
  defineComponent,
  inject,
  unref,
} from '@vue/composition-api';
import { FilterState } from '../../hooks/filter';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { destroyJobPositionFilter, updateJobPositionFilter } from '@/exportables/apis/career/job-position.api';
import { JobPositionFilterQuery } from '@/exportables/models/career/job-position.model';
import { User } from '@/exportables/models/user.model';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'JobPositionFilterNotice',
  components: {
    SvgIcon,
  },
  setup() {
    const currentUser = inject<User>('currentUser');
    const jobPositionFilter = inject<Ref<JobPositionFilterQuery>>('jobPositionFilter')!;
    const filter = inject<FilterState>('filter')!;
    const { filterQuery } = filter;
    const I18n = useI18n();

    const hasJobPositionFilter = computed(() => Boolean(unref(jobPositionFilter)?.id));

    const jobPositionFilterToggleChangeHandler = (checked: boolean) => (
      checked
        ? newJobPositionFilters(filterQuery, jobPositionFilter)
        : destoryJobPositionFilters(jobPositionFilter)
    );

    return {
      I18n,
      currentUser,
      hasJobPositionFilter,
      jobPositionFilter,
      jobPositionFilterToggleChangeHandler,
    };
  },
});

const newJobPositionFilters = async (filterQuery: JobPositionFilterQuery, filter: Ref) => {
  const { jobPositionFilter } = await updateJobPositionFilter({
    company_ids: filterQuery.companyIds || [],
    job_category_ids: filterQuery.jobCategoryIds || [],
    job_theme_ids: filterQuery.jobThemeIds || [],
    locations: filterQuery.locations || [],
    min_career: filterQuery.minCareer || null,
    min_employees: filterQuery.minEmployees || null,
    min_salary: filterQuery.minSalary || null,
    tags: filterQuery.tags?.map((tag) => tag.name) || [],
  });

  Object.assign(filter, { value: jobPositionFilter });
};

const destoryJobPositionFilters = async (jobPositionFilter: Ref) => {
  await destroyJobPositionFilter(jobPositionFilter.value.id);
  jobPositionFilter.value = null;
};
</script>
