<template>
  <Card
    v-if="isEditable || awards.length"
    :is-editable="isEditable"
    :title="I18n.t('resume.award')"
    @add-item="handleAddItem"
  >
    <template #body>
      <Award
        v-if="newItem"
        ref="newFormRef"
        :index="awards.length"
        :info="newItem"
        :is-added="true"
        type="award"
      />
      <Award
        v-for="(award, index) in awards"
        :key="award.index"
        :index="index"
        :info="award"
        type="award"
      />
    </template>
  </Card>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  provide,
} from '@vue/composition-api';
import { useFocusInputOnMount, useForm } from '../hooks/form';
import { ResumeShowStates, getResume } from '../hooks/state';
import Card from './Card.vue';
import Award from './Index.vue';
import { ResumeAward } from '@/exportables/models/career/resume.model';
import { useI18n } from '@/helpers/i18n';
import { useStore } from '@/store/career';

export default defineComponent({
  name: 'ResumeAwards',
  components: {
    Award,
    Card,
  },
  setup() {
    const state = inject<ResumeShowStates>('state')!;
    const I18n = useI18n();
    const store = useStore();
    const awards = computed(() => getResume(state).awards);
    const isEditable = computed(() => store.state.resume.isEditable);
    const form = useForm(state, 'awards', ResumeAward);
    const { ref: newFormRef } = useFocusInputOnMount();

    provide('form', form);

    return {
      ...form,
      I18n: { t: I18n.t },
      awards,
      isEditable,
      newFormRef,
    };
  },
});
</script>
