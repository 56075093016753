import VueCompositionAPI, {
  Ref,
  computed,
  reactive,
  toRefs,
} from '@vue/composition-api';
import Vue from 'vue';
import { BiteCompany } from '@/exportables/models/career/company.model';
import { JobPositionFilterQuery } from '@/exportables/models/career/job-position.model';
import { Tag } from '@/exportables/models/career/tag.model';

Vue.use(VueCompositionAPI);

const defaultFilterQuery = {
  companies: [],
  companyIds: [],
  jobCategoryIds: [],
  jobThemeIds: [],
  locations: [],
  minCareer: '',
  minEmployees: '',
  minSalary: '',
  order: 'recent',
  tags: [],
};

const filterQuery = reactive<JobPositionFilterQuery>(defaultFilterQuery);

const resetFilter = () => Object.assign(
  filterQuery,
  JobPositionFilterQuery.new(),
  { order: filterQuery.order },
);

const addTagFilter = (selectedTag: Tag) => {
  if (filterQuery.tags.every((tag) => tag.id !== selectedTag.id)) {
    filterQuery.tags.push(selectedTag);
  }
};

const addCompanyFilter = (selectedCompany: BiteCompany) => {
  if (filterQuery.companies.every((company) => company.id !== selectedCompany.id)) {
    filterQuery.companies.push(selectedCompany);
  }
  if (filterQuery.companyIds.every((id) => id !== selectedCompany.id)) {
    filterQuery.companyIds.push(selectedCompany.id);
  }
};

const addJobCategoryFilter = (selectedJobCategoryId: number) => {
  if (filterQuery.jobCategoryIds.every((id) => id !== selectedJobCategoryId)) {
    filterQuery.jobCategoryIds.push(selectedJobCategoryId);
  }
};

const addJobThemeFilter = (selectedJobThemeId: number) => {
  if (filterQuery.jobThemeIds.every((id) => id !== selectedJobThemeId)) {
    filterQuery.jobThemeIds.push(selectedJobThemeId);
  }
};

const removeTagFilter = (tagId: number) => {
  filterQuery.tags.splice(filterQuery.tags.findIndex((tag) => tag.id === tagId), 1);
};

const removeCompanyFilter = (companyId: number) => {
  filterQuery.companies.splice(filterQuery.companies.findIndex((company) => company.id === companyId), 1);
  filterQuery.companyIds.splice(filterQuery.companyIds.findIndex((id) => id === companyId), 1);
};

const removeJobCategoryFilter = (jobCategoryId: number) => {
  filterQuery.jobCategoryIds.splice(filterQuery.jobCategoryIds.findIndex((id) => id === jobCategoryId), 1);
};

const removeJobThemeFilter = (jobThemeId: number) => {
  filterQuery.jobThemeIds.splice(filterQuery.jobThemeIds.findIndex((id) => id === jobThemeId), 1);
};

const removeMinSalaryFilter = () => {
  filterQuery.minSalary = '';
};

const removeMinCareerFilter = () => {
  filterQuery.minCareer = '';
};

const removeMinEmployeesFilter = () => {
  filterQuery.minEmployees = '';
};

const removeLocationFilter = (selectedLocation: string) => {
  filterQuery.locations.splice(filterQuery.locations.findIndex((location) => location === selectedLocation), 1);
};

const hasFilters = computed(() => {
  const {
    companies,
    jobCategoryIds,
    jobThemeIds,
    locations,
    minCareer,
    minEmployees,
    minSalary,
    tags,
  } = filterQuery;

  return (
    [companies, jobCategoryIds, jobThemeIds, locations, tags].some((key) => key?.length) ||
    [minSalary, minCareer, minEmployees].some((key) => key)
  );
});

export const useFilter = () => ({
  ...toRefs(filterQuery),
  addCompanyFilter,
  addJobCategoryFilter,
  addJobThemeFilter,
  addTagFilter,
  filterQuery,
  hasFilters,
  removeCompanyFilter,
  removeTagFilter,
  removeJobCategoryFilter,
  removeJobThemeFilter,
  removeMinCareerFilter,
  removeMinEmployeesFilter,
  removeMinSalaryFilter,
  removeLocationFilter,
  resetFilter,
} as FilterState
);

type MappedRefFilterQuery = {
  [P in keyof JobPositionFilterQuery]: Ref<JobPositionFilterQuery[P]>
};

export interface FilterState extends MappedRefFilterQuery {
  addCompanyFilter: typeof addCompanyFilter;
  addJobCategoryFilter: typeof addJobCategoryFilter;
  addJobThemeFilter: typeof addJobThemeFilter;
  addTagFilter: typeof addTagFilter;
  filterQuery: JobPositionFilterQuery;
  hasFilters: Ref<boolean>;
  removeCompanyFilter: typeof removeCompanyFilter;
  removeTagFilter: typeof removeTagFilter;
  removeJobCategoryFilter: typeof removeJobCategoryFilter;
  removeJobThemeFilter: typeof removeJobThemeFilter;
  removeMinCareerFilter: typeof removeMinCareerFilter;
  removeMinEmployeesFilter: typeof removeMinEmployeesFilter;
  removeMinSalaryFilter: typeof removeMinSalaryFilter;
  removeLocationFilter: typeof removeLocationFilter;
  resetFilter: typeof resetFilter;
}
