<template>
  <header class="header">
    <ol class="list-steps">
      <li
        v-for="(step, index) in steps"
        :key="index"
        class="step-item"
        :class="{ active: isActive(index), current: isCurrent(index) }"
      >
        <div class="icon-wrap">
          <SvgIcon
            v-if="isCurrent(index)"
            class="arrow"
            name="ic-keyboard-arrow-down"
            html-class="icon ic-24"
          />
          <SvgIcon
            v-if="isActive(index)"
            name="ic-check"
            html-class="icon ic-14"
          />
          <template v-else>
            {{ Number(index) + 1 }}
          </template>
        </div>
        <h6 class="step-title">
          {{ translateStepName(step) }}
        </h6>
      </li>

      <li class="step-item">
        <div class="icon-wrap">
          {{ Object.keys(steps).length + 1 }}
        </div>
        <h6 class="step-title">
          {{ translateStepName('complete') }}
        </h6>
      </li>
    </ol>

    <h3 class="title">
      {{ translateStepName(steps[currentStepIndex]) }}
    </h3>
    <h5 class="sub-title">
      {{ title }}
    </h5>
  </header>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'JobApplicationFormHeader',
  components: {
    SvgIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    steps: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const I18n = useI18n();

    const translateStepName = (stepName: string) => I18n.t(`application.wizard_form.sections.${stepName}`);
    const isActive = (index: string) => props.currentStepIndex > Number(index);
    const isCurrent = (index: string) => props.currentStepIndex === Number(index);

    return {
      isActive,
      isCurrent,
      translateStepName,
    };
  },
});
</script>
