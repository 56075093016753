import { FeaturedCompany } from '../../models/career/company.model';
import { User } from '../../models/user.model';
import { useHttpClient } from '../httpClient';
import {
  JobPosition,
  JobPositionCompany,
  JobPositionFilterQuery,
  JobPositionRecommendation,
  WantedJobPosition,
  Query,
} from '@/exportables/models/career/job-position.model';
import { Tag } from '@/exportables/models/career/tag.model';
import { CamelToSnake } from '@/exportables/types/string.d';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';

export type JobPositionIndexState = {
  companies: JobPositionCompany[],
  jobPositionFilter: Omit<JobPositionFilterQuery, 'companies'> | null,
  jobPositionRecommendations: JobPositionRecommendation[],
  jobPositions: JobPosition[],
  wantedJobPositions: WantedJobPosition[],
  jobThemeIds?: number[],
  tags: Tag[],
  totalEntries: number,
  totalPages: number,
};

export type JobPositionShowState = {
  company: JobPositionCompany,
  jobPosition: JobPosition,
  user: User,
};

export const JOB_POSITION_URL = '/job_positions';
const JOB_POSITION_FILTER_URL = '/job_position_filters/';

const httpClient = useHttpClient();
const I18n = useI18n();

export const fetchJobCategories = async () => {
  const { data, status } = await httpClient.get(`${JOB_POSITION_URL}/job_categories`);
  if (status !== 200) throw new Error(data?.message || I18n.t('error.internal'));
  return data;
};

export const fetchJobPositions = async (params: Partial<JobPositionFilterQuery>) => {
  try {
    const { data, status } = await httpClient.get(JOB_POSITION_URL, { params });
    if (status !== 200) throw new Error(data?.message || I18n.t('error.internal'));
    return data;
  } catch (error) {
    error instanceof Error && appendToast(error.message);
  }
};

export const fetchJobPosition = async (id: number, params?: Query) => {
  try {
    const { data, status } = await httpClient.get(`${JOB_POSITION_URL}/${id}`, { params });
    if (status !== 200) throw new Error(data?.message || I18n.t('error.internal'));
    return data;
  } catch (error) {
    error instanceof Error && appendToast(error.message);
    throw error;
  }
};

export const fetchRecommendedPositions = async (id: number) => {
  try {
    const { data, status } = await httpClient.get(`${JOB_POSITION_URL}/${id}/recommended_positions`);
    if (status !== 200) throw new Error(data?.message || I18n.t('error.internal'));
    return data;
  } catch (error) {
    error instanceof Error && appendToast(error.message);
    throw error;
  }
};

type SnakeizeFilterParams = {
  [key in CamelToSnake<keyof JobPositionFilterQuery>]: JobPositionFilterQuery[keyof JobPositionFilterQuery];
};
export const updateJobPositionFilter = async (params: SnakeizeFilterParams) => {
  let toastMessage = '';
  try {
    const { data, status } = await httpClient.post<Partial<JobPositionFilterQuery>>(
      JOB_POSITION_FILTER_URL,
      params,
    );
    if (status !== 200) throw new Error();
    toastMessage = I18n.t('job_position.list.filter_notice.save.success');
    return data;
  } catch (error) {
    toastMessage = I18n.t('job_position.list.filter_notice.save.fail');
  } finally {
    appendToast(toastMessage);
  }
};

export const destroyJobPositionFilter = async (id: number) => {
  let toastMessage = '';
  try {
    const { data, status } = await httpClient.delete(
      `${JOB_POSITION_FILTER_URL}/${id}`,
    );
    if (status !== 200) throw new Error();
    toastMessage = I18n.t('job_position.list.filter_notice.remove.success');
    return data;
  } catch (error) {
    toastMessage = I18n.t('job_position.list.filter_notice.remove.fail');
  } finally {
    appendToast(toastMessage);
  }
};

export const addBookmark = async (positionId: number) => {
  const { data } = await httpClient.put(`/v1/career${JOB_POSITION_URL}/${positionId}/bookmark`);

  return data;
};

export const removeBookmark = async (positionId: number) => {
  const { data } = await httpClient.delete(`/v1/career${JOB_POSITION_URL}/${positionId}/bookmark`);

  return data;
};

type FetchFeaturedCompaniesRequestPayload = { jobPositionCategoryIds: JobPositionFilterQuery['jobCategoryIds'] };
export const fetchFeaturedCompanies = async (params: FetchFeaturedCompaniesRequestPayload) => {
  const { data } = await httpClient.get<FeaturedCompany[]>('/v1/career/featured_companies', { params });
  return data;
};
