<template>
  <header v-if="featuredPosts.length > 0" class="featured-item-wrapper">
    <h5 class="container-xl font-weight-700">
      추천 콘텐츠
    </h5>

    <ul class="list-featured">
      <li
        v-for="featuredPost in featuredPosts"
        :key="featuredPost.id"
        class="featured-item"
      >
        <div class="item-body">
          <div class="item-body__left">
            <h4 class="item-body__title">
              <a
                :href="featuredPost.url"
                :data-post-id="featuredPost.id"
                class="gtm-post-link"
                target="_blank"
                rel="noopener noreferrer"
              >{{ featuredPost.title }}</a>
            </h4>
            <div class="item-bottom">
              <a
                v-for="(tag, index) in getAllTags(featuredPost)"
                :key="`${tag}_${index}`"
                :href="tagLink(tag)"
                class="item-tag"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ tag }}
              </a>
            </div>
          </div>
          <div class="item-body__right">
            <a
              :href="featuredPost.url"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                v-if="featuredPost.imageUrl"
                :src="featuredPost.imageUrl"
                :alt="featuredPost.title"
                class="image"
              >
            </a>
          </div>
        </div>
      </li>
    </ul>
  </header>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import useFeaturedPosts from '../hooks/useFeaturedPosts';
import { getAllTagsFromFeaturedPost } from '../utils';

export default defineComponent({
  name: 'FeaturedPosts',
  setup() {
    const { featuredPosts } = useFeaturedPosts();

    const tagLink = (tag: string) =>
      `/posts/tag/${encodeURIComponent(tag.replace(/\./g, '%2E'))}`;

    return {
      featuredPosts,
      tagLink,
      getAllTags: getAllTagsFromFeaturedPost,
    };
  },
});
</script>
