<template>
  <Modal ref="modalRef" @hide="closeModal">
    <template #header>
      <button
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        @click="closeModal"
      >
        <SvgIcon name="ic-close" html-class="ic-24" />
      </button>

      <h3 class="modal-title">
        {{ I18n.t('resume.modal.skill_check_guide.title') }}
      </h3>
    </template>

    <template #body>
      <h5 class="section-title">
        {{ I18n.t('resume.modal.skill_check_guide.subtitle_1') }}
      </h5>

      <ul class="list-skill-level">
        <li v-for="introduction in INTRODUCTIONS" :key="introduction.level" class="list-item">
          <div class="col-label">
            <span class="label-level">
              {{ I18n.t('business.introduce.recruit.skill_check_level.level', { level: introduction.level }) }}</span>
            <span class="label-position">
              {{ I18n.t('business.introduce.recruit.skill_check_level.position', { percentage: introduction.percentage }) }}</span>
          </div>

          <div class="col-progress">
            <div class="progress progress-striped">
              <div class="progress-bar progress-bar-info" :style="{ width: `${introduction.width}%` }"></div>
            </div>
          </div>

          <div class="col-description">
            <p>
              {{ I18n.t(`business.introduce.recruit.skill_check_level.level_${introduction.level}.description`) }}
            </p>
          </div>
        </li>
      </ul>

      <h5 class="section-title">
        {{ I18n.t('resume.modal.skill_check_guide.subtitle_2') }}
      </h5>

      <table class="table table-use-case">
        <thead>
          <tr>
            <th class="t-label"></th>
            <th v-for="level in skillCheckLevels" :key="level">
              <span>
                {{ I18n.t('terms.level') }}</span>
              {{ level }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="[category, difficulty] in Object.entries(JOB_CATEGORY_DIFFICULTIES)" :key="category">
            <td class="t-label">
              <SvgIcon name="ic-applicant" html-class="ic-24 ic-applicant" />
              {{ I18n.t(`business.introduce.recruit.skill_check_level.use_case.${category}`) }}
            </td>

            <td class="t-stage" colspan="5">
              <div class="grid-stage">
                <div :class="difficulty" class="bar"></div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </Modal>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  ref,
  unref,
} from '@vue/composition-api';
import constants from '../../resume.constant';
import Modal from '@/components/shared/Modal2.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { useI18n } from '@/helpers/i18n';

const {
  SKILL_CHECK: {
    introductions: INTRODUCTIONS,
    job_category_difficulties: JOB_CATEGORY_DIFFICULTIES,
  },
} = constants;

export default defineComponent({
  name: 'ResumeSkillCheckGuideModal',
  components: {
    Modal,
    SvgIcon,
  },
  emits: ['hide'],
  setup(_, { emit }) {
    const skillCheckLevels = INTRODUCTIONS.map((introduction) => introduction.level).reverse();
    const modalRef = ref<InstanceType<typeof Modal> | null>(null);
    const { t } = useI18n();

    const closeModal = () => emit('hide');

    onMounted(() => {
      const unrefedModalRef = unref(modalRef);
      if (!unrefedModalRef) return;
      const [modalDialog] = unrefedModalRef?.$el?.childNodes || [];
      modalDialog && modalDialog.classList.add('modal-lg', 'modal-skill-check-level');
    });

    return {
      I18n: { t },
      INTRODUCTIONS,
      JOB_CATEGORY_DIFFICULTIES,
      closeModal,
      modalRef,
      skillCheckLevels,
    };
  },
});
</script>
