<template>
  <div class="resume-card-item">
    <div class="resume-card-left">
      <h5 v-if="award.awardedAt" class="resume-card-item-period">
        {{ I18n.l('date.formats.year_month', award.awardedAt) }} {{ award.endAt && `~ ${I18n.l('date.formats.year_month', award.endAt)}` }}
      </h5>
    </div>

    <div ref="hoverRef" class="resume-card-right">
      <h4 class="resume-card-item-label">
        {{ award.title }}
      </h4>

      <h5 v-if="award.publisher" class="resume-card-item-text">
        {{ award.publisher }}
      </h5>

      <h6 v-if="award.number" class="resume-card-item-text">
        등록번호: {{ award.number }}
      </h6>

      <h5 v-if="award.link" class="resume-card-item-text">
        <a
          :href="award.link"
          target="_blank"
          rel="noopener"
        >{{ award.link }}</a>
      </h5>

      <MarkdownViewer
        class="markdown-viewer"
        :value="award.description"
      />

      <HoverButtonWrapper
        v-if="isHovered"
        type="award"
        :delete-item="() => handleDeleteItem(index)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  onBeforeUnmount,
  ref,
  unref,
} from '@vue/composition-api';
import HoverButtonWrapper from '../HoverButtonWrapper.vue';
import MarkdownViewer from '@/components/shared/MarkdownViewer.vue';
import { useHover } from '@/helpers/event';
import { useI18n } from '@/helpers/i18n';
import { useStore } from '@/store/career';

export default defineComponent({
  name: 'ResumeAwardPreview',
  components: {
    HoverButtonWrapper,
    MarkdownViewer,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    award: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { handleDeleteItem } = inject('form')!;
    const hoverRef = ref(null);
    const I18n = useI18n();
    const store = useStore();
    const isEditable = computed(() => store.state.resume.isEditable);
    const { isHovered, unwatch } = unref(isEditable) ? useHover(hoverRef) : { isHovered: false, unwatch: () => {} };

    onBeforeUnmount(unwatch);

    return {
      I18n,
      handleDeleteItem,
      hoverRef,
      isEditable,
      isHovered,
    };
  },
});
</script>
