<script lang="tsx">
import {
  computed,
  defineComponent,
  onMounted,
  useCssModule,
} from '@vue/composition-api';
import ShowInfo from '../components/show/Info.vue';
import ShowNotification from '../components/show/Notification.vue';
import ShowOverview from '../components/show/Overview.vue';
import ShowTabControl from '../components/show/TabControl.vue';
import { useState } from '../hooks/show/state';
import { skeleton } from '@/directives';
import { dispatchHackleViewEvent } from '@/exportables/services/useHackle';
import { useCurrentUser } from '@/hooks/useCurrentUser';

export default defineComponent({
  name: 'CompetitionShow',
  directives: {
    skeleton,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const style = useCssModule();
    const { isLoading, state, initialize } = useState();
    const { initialize: initializeUser } = useCurrentUser();
    const coverStyle = computed(
      () => `background-image: url(${state.largeImageUrl});`,
    );

    onMounted(async () => {
      await initialize(props.id);
      await initializeUser();

      dispatchHackleViewEvent('career_competition_detail_viewed');
    });

    return () => {
      return (
        <div>
          {(isLoading.value || state.largeImageUrl) && (
            <div
              v-skeleton={isLoading.value}
              style={coverStyle.value}
              class={['competition-cover', style.coverImg]}
            />
          )}
          <div class={style.wrapper}>
            <ShowNotification />
            <ShowOverview />
            <ShowInfo />
            <ShowTabControl />
          </div>
        </div>
      );
    };
  },
});
</script>

<style lang="scss" module>
@use '~/stylesheets/variables';

.wrapper {
  padding-top: 45px;
}

.coverImg {
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media (variables.$md-down) {
  .wrapper {
    padding-top: 32px;
  }
}
</style>
