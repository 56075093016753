import VueCompositionAPI, {
  ComponentInstance,
  Ref,
  ref,
  watch,
} from '@vue/composition-api';
import Vue from 'vue';

Vue.use(VueCompositionAPI);

export const useHover = (
  element: Ref<ComponentInstance | HTMLElement | null>,
  handler: {
    start: Function,
    end: Function,
  } = {
    start: () => {},
    end: () => {},
  },
) => {
  const isHovered = ref(false);

  const unwatch = watch(
    element,
    (element) => {
      if (!element) return;

      const { $el: newElement = element as Element } =
        element as ComponentInstance;

      const handlePointerEnter = () => {
        isHovered.value = true;
        newElement.classList.add('hovered');
        handler?.start();
      };

      const handlePointerLeave = () => {
        isHovered.value = false;
        newElement.classList.remove('hovered');
        handler?.end();
      };

      newElement.addEventListener('pointerenter', handlePointerEnter);
      newElement.addEventListener('pointerleave', handlePointerLeave);
    },
    { immediate: true },
  );

  return {
    isHovered,
    unwatch,
  };
};
