<template>
  <div class="preference-step">
    <div class="alert alert-light">
      {{ I18n.t('application.message.hire_process_consider_preference') }}
    </div>
    <div class="form-group">
      <template v-for="(jobPosition, index) in response.jobPositions">
        <label :key="jobPosition" class="control-label required">
          {{ I18n.t('application.title.preference_position', { number: index + 1 }) }}
        </label>
        <select
          :key="index"
          v-model="selectedPreferences[index]"
          class="form-control"
          @change="changeSelectedPreference($event, index)"
        >
          <option selected :value="EMPTY_VALUE">
            {{ I18n.t('actions.select') }}
          </option>
          <template v-for="jobPositionId in response.jobPositions">
            <option
              :key="jobPositionId"
              :value="jobPositionId"
              :disabled="isSelectedPreference(jobPositionId) && selectedPreferences[index] !== jobPositionId"
            >
              {{ `${findJobPositionById(jobPositionId).company.name} - ${findJobPositionById(jobPositionId).title}` }}
              <template v-if="isSelectedPreference(jobPositionId)">
                ({{ I18n.t('job_application.preference_step.selected_number_preference', {
                  number: selectedPreferences.indexOf(jobPositionId) + 1,
                }) }})
              </template>
            </option>
          </template>
        </select>
      </template>
    </div>

    <h6 v-if="errors($v.selectedPreferences) && $v.$dirty" class="error-feedback">
      <SvgIcon html-class="ic-14" name="ic-care-center-14" />
      <span>{{ errors($v.selectedPreferences) }}</span>
    </h6>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, PropType } from '@vue/composition-api';
import { useVuelidate, Validation } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { Response } from '@/exportables/models/career/job-application.model';
import { RegistrationJobPosition } from '@/exportables/models/career/job-position.model';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  props: {
    response: {
      type: Object as PropType<Response>,
      required: true,
    },
    jobPositions: {
      type: Array as PropType<RegistrationJobPosition[]>,
      default: () => [],
    },
    preferences: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
  },
  emits: ['update:updateSelectedPreference'],
  setup(props, { emit }) {
    const I18n = useI18n();

    const EMPTY_VALUE = -1;
    const selectedPreferences = ref([...props.preferences]);
    if (selectedPreferences.value.length !== props.response.jobPositions.length) {
      selectedPreferences.value = props.response.jobPositions.map((_: number, index: number) => selectedPreferences.value[index] || EMPTY_VALUE);
    }
    const allSelected = (val: number[]) => !val.includes(EMPTY_VALUE);
    const changeSelectedPreference = (event: Event & { target: HTMLInputElement }, index: number) => {
      selectedPreferences.value[index] = Number(event.target.value);
    };
    const errors = (field: Validation) => field.$errors.length ? field.$errors[0].$message : '';
    const findJobPositionById = (id: number) => props.jobPositions.find((jobPosition) => jobPosition.id === id);
    const isSelectedPreference = (jobPositionId: number) => selectedPreferences.value.find((selectedJobPositionId) => selectedJobPositionId === jobPositionId);

    const rules = {
      selectedPreferences: {
        allSelected: helpers.withMessage(I18n.t('job_application.preference_step.select_all_preference'), allSelected),
      },
    };
    const $v = useVuelidate(rules, { selectedPreferences });
    const isValidated = () => {
      $v.value.$touch();
      return !$v.value.$invalid;
    };

    watch(selectedPreferences, (newVal) => {
      emit('update:updateSelectedPreference', newVal);
    });

    return {
      EMPTY_VALUE,
      I18n,
      $v,
      allSelected,
      changeSelectedPreference,
      errors,
      findJobPositionById,
      isSelectedPreference,
      isValidated,
      selectedPreferences,
    };
  },
});
</script>
