<template>
  <div class="form-group form-income">
    <button
      type="button"
      class="btn btn-block btn-outline-light btn-filter dropdown-toggle"
      data-toggle="dropdown"
      aria-expanded="false"
    >
      {{ I18n.t('job_position.list.search_filter.min_salary.title') }}
    </button>

    <div class="dropdown-menu dropdown-filter dropdown-menu-right">
      <div class="row justify-content-center">
        <div class="dropdown-item-wrapper">
          <div class="dropdown-item">
            <label class="min_salary_label">
              <input v-model="minSalary" type="radio" value="" name="minSalary">
              {{ I18n.t('job_position.list.search_filter.total') }}
            </label>
          </div>

          <div v-for="salary in salaryOptions" :key="salary" class="dropdown-item">
            <label class="min_salary_label" @click.stop>
              <input v-model="minSalary" type="radio" :value="salary" name="minSalary">
              {{ I18n.t('job_position.list.search_filter.min_salary.label', { min_salary: thousandSeparator(salary) }) }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  inject,
} from '@vue/composition-api';
import { FilterState } from '../../hooks/filter';
import { thousandSeparator } from '@/exportables/utils/number.util';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'JobPositionMinSalaryFilter',
  setup() {
    const { minSalary } = inject<FilterState>('filter')!;
    const I18n = useI18n();
    const salaryOptions = getSalaryOptions();

    return {
      I18n,
      minSalary,
      salaryOptions,
      thousandSeparator,
    };
  },
});

const getSalaryOptions = () => {
  const MIN_SALARY = 3000;
  const MAX_SALARY = 8000;
  const SALARY_SETP = 500;
  const salaryOptions = [];

  for (let salary = MIN_SALARY; salary <= MAX_SALARY; salary += SALARY_SETP) {
    salaryOptions.push(salary);
  }
  return salaryOptions;
};
</script>

<style lang="scss" scoped>
  @import "~stylesheets/variables";

  .dropdown-menu {
    padding: 0.5rem 1.375rem;
    min-width: 100%;
    .dropdown-item {
      text-align: left;
    }
    .dropdown-item-wrapper {
      column-count: 2;
      @media ($sm-down) {
        column-count: 1;
      }
      .dropdown-item {
        padding: 0.125rem 1rem;
        @media ($lg-down) {
          padding: 0.125rem 0.5rem;
        }
      }
    }
  }
</style>
