import { JobPosition } from './job-position.model';
import { Tag } from './tag.model';

export class Company {
  id: number = -1;
  address: string = '';
  averageResponseTime?: string;
  benefitTags: string[] = [];
  countryCode: string = 'KR';
  description: string = '';
  developers: CompanyDeveloper[] = [];
  employees: Employee[] = [];
  employeesCount: number = 0;
  funding: number;
  hideFunding: boolean = false;
  hideRevenue: boolean = false;
  homeUrl: string = '';
  images?: string[] = [];
  imageUrl: string = ''; // deprecated 예정
  jobPositions?: Partial<JobPosition>[] = [];
  latitude: number;
  longitude: number;
  logoUrl: string | HTMLImageElement = require('~/images/img-profile-default@2x.png');
  name: string = '';
  registrationUrl?: string = '';
  revenue: number;
  serviceName: string = '';
  serviceUrl: string = '';
  technicalTags: Tag[];
  url: string = ''; // jobPositionUrl
  updatePolicy?: boolean = true;
  blog: string;

  constructor(company?: Partial<Company>) {
    Object.assign(this, company);
  }

  static new(company?: Partial<Company>) {
    return new Company(company);
  }
}

export class CompanyDeveloper {
  description: string = '';
  icon: string = '';
  name: string = '';
  url: string = '';

  constructor(developer: CompanyDeveloper) {
    Object.assign(this, developer);
  }

  static new(developer: CompanyDeveloper) {
    return new CompanyDeveloper(developer);
  }
}

export type BiteCompany = Pick<Company, 'id' | 'name'>;

type Employee = {
  employeesCount: number,
  registeredAt: Date | string | null,
};

export type FeaturedCompany = Pick<Company,
| 'id'
| 'name'
| 'serviceName'
| 'logoUrl'
> & {
  imageUrl: Required<Company>['images'][number],
};
