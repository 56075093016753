<template>
  <div>
    <UserFeedSearchAllTabPane
      v-if="currentTab === 'user_feed'"
      :all-category-tag-map="allCategoryTagMap"
      @select="toggleTagFromAutoComplete"
    />
    <UserFeedSearchCategoryTabPane
      v-else
      :category="currentTab"
      :has-all-job-category-default-tags="hasAllJobCategoryDefaultTags"
      :tags="getAllTagsByTab(currentTab)"
      @select="toggleTagFromAutoComplete"
      @toggleAllJobCategoryTags="toggleAllJobCategoryTags"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from '@vue/composition-api';
import UserFeedSearchAllTabPane from '../components/UserFeedSearchAllTabPane.vue';
import UserFeedSearchCategoryTabPane from '../components/UserFeedSearchCategoryTabPane.vue';
import { FeedCategory, FEED_CATEGORIES } from '../constants';
import { getAllTagsByUserFeedTab } from '../utils';
import {
  SearchedTag, UserFeedTabExceptUserFeed,
} from '@/exportables/models/career/user-feed.model';
import camelCase from '@/plugins/camelCase';
import { useStore } from '@/store/career';

export default defineComponent({
  name: 'UserFeedTabPaneContainer',
  components: {
    UserFeedSearchAllTabPane,
    UserFeedSearchCategoryTabPane,
  },
  setup() {
    const store = useStore();

    const currentTab = computed(() => store.state.post.feedSettingTab);
    const hasAllJobCategoryDefaultTags = computed(
      () =>
        store.state.post.userFeed.jobCategory.length ===
        (store.state.post.userFeedDefault.jobCategory || []).length,
    );

    const getAllTagsByTab = (tab: FeedCategory) =>
      getAllTagsByUserFeedTab({
        tab,
        userFeedTags: store.state.post.userFeed,
        topTaggingCountTags: store.state.post.userFeedDefault,
      });

    const getUserFeedTagsByTab = (tab: FeedCategory) =>
      store.state.post.userFeed[camelCase(tab) as UserFeedTabExceptUserFeed];

    const allCategoryTagMap = computed(() => {
      return FEED_CATEGORIES.reduce((acc, category) => {
        if (category === 'user_feed') return acc;

        return {
          ...acc,
          [category]: getAllTagsByTab(category),
        };
      }, {});
    });

    const toggleTagFromAutoComplete = (selectedTag: SearchedTag) => {
      const { id, name, data: { category, counts } } = selectedTag;

      store.dispatch('post/toggleUserFeedTag', {
        key: camelCase(category),
        tag: { id, name, count: counts.post },
      });
    };

    const toggleAllJobCategoryTags = () => {
      store.dispatch('post/toggleAllJobCategoryTags');
    };

    return {
      categories: FEED_CATEGORIES,
      currentTab,
      hasAllJobCategoryDefaultTags,
      allCategoryTagMap,
      getAllTagsByTab,
      getUserFeedTagsByTab,
      toggleTagFromAutoComplete,
      toggleAllJobCategoryTags,
    };
  },
});
</script>
