<template>
  <div class="checkbox-toggle">
    <label v-if="labels[0]" :style="style.label">{{ labels[0] }}</label>
    <input
      class="tgl tgl-light"
      :class="{ active: isActive }"
      type="button"
    >
    <span
      class="tgl-btn"
      :class="style.btn"
      role="switch"
      @click="handleToggle"
    ></span>
    <label v-if="labels[1]" :style="style.label">{{ labels[1] }}</label>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  useCssModule,
} from '@vue/composition-api';

export default defineComponent({
  name: 'ResumeToggle',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    labels: {
      type: Array,
      default: () => ['', ''],
    },
  },
  emits: ['toggle'],
  setup(_, { emit }) {
    const style = useCssModule();

    const handleToggle = () => emit('toggle');

    return {
      handleToggle,
      style,
    };
  },
});
</script>

<style lang="scss" module>
.btn {
  flex-shrink: 0;
  margin: 0 8px;
}
</style>
