<template>
  <div class="tab-pane fade active show" role="tabpanel" aria-labelledby="tag">
    <Autocomplete
      label="아래에 없는 태그는 검색해주세요"
      placeholder="이곳에 검색할 태그를 입력"
      url="/api/tags/search"
      no-result-label="검색결과가 없습니다."
      ul-custom-class="modal-autocomplete"
      visible-count="post"
      @selected="handleSelect"
    />
    <template v-for="[category, tags] of Object.entries(allCategoryTagMap)">
      <FeedTagButton
        v-for="(tag, index) in tags"
        :key="`${category}_${index}`"
        :category="category"
        :item="tag"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from '@vue/composition-api';
import { FeedCategory } from '../constants';
import FeedTagButton from './FeedTagButton.vue';
import Autocomplete from '@/components/shared/Autocomplete.vue';
import {
  SearchedTag,
  UserFeedTag,
} from '@/exportables/models/career/user-feed.model';

export default defineComponent({
  name: 'UserFeedSearchAllTabPane',
  components: {
    FeedTagButton,
    Autocomplete,
  },
  props: {
    allCategoryTagMap: {
      type: Object as PropType<
      Record<Exclude<FeedCategory, 'user_feed'>, UserFeedTag[]>
      >,
      required: true,
    },
  },
  emits: ['select'],
  setup(_, { emit }) {
    const handleSelect = (tag: SearchedTag) => emit('select', tag);

    return {
      handleSelect,
    };
  },
});
</script>
