var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isEditable || (_vm.isGitable && !_vm.hideGitStats))?_c('div',{staticClass:"git-stat",class:_vm.style.container},[_c('h5',{staticClass:"resume-section-title"},[_vm._v("\n    "+_vm._s(_vm.I18n.t('section.repository.language'))+"\n    "),(_vm.isEditable)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip:top",value:({ message: _vm.I18n.t('resume.git_stat.tooltip'), width: '210px' }),expression:"{ message: I18n.t('resume.git_stat.tooltip'), width: '210px' }",arg:"top"}],staticClass:"btn-help dark"},[_c('SvgIcon',{attrs:{"name":"ic-help-full","html-class":"ic-20"}})],1):_vm._e(),_vm._v(" "),(_vm.isEditable)?_c('div',{class:_vm.style.absolute},[(_vm.analyzedAt && _vm.commits)?_c('Toggle',{class:_vm.style.label,attrs:{"is-active":!_vm.hideGitStats,"labels":[_vm.I18n.t('section.repository.attach')]},on:{"toggle":_vm.handleAttachToggle}}):_vm._e(),_vm._v(" "),(_vm.inProgress)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip:top",value:({
          message: _vm.I18n.t('section.repository.tooltip.in_progress'),
          style: { wordBreak: 'keep-all' },
          width: '250px',
        }),expression:"{\n          message: I18n.t('section.repository.tooltip.in_progress'),\n          style: { wordBreak: 'keep-all' },\n          width: '250px',\n        }",arg:"top"}],staticClass:"btn btn-sm btn-light",class:_vm.style.refresh},[_c('SvgIcon',{attrs:{"name":"ic-refresh","html-class":("ic-16 " + (_vm.style.loadingAnimation))}}),_vm._v(" "),_c('span',{staticClass:"ml-1"},[_vm._v("\n          "+_vm._s(_vm.I18n.t('section.repository.in_progress'))+"\n        ")])],1):[(_vm.isGitable)?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip:top",value:(_vm.I18n.t('section.repository.tooltip.description')),expression:"I18n.t('section.repository.tooltip.description')",arg:"top"}],class:_vm.style.refresh,on:{"click":_vm.handleRefreshGitStat}},[_c('SvgIcon',{attrs:{"name":"ic-refresh","html-class":"ic-16"}})],1):_c('button',{staticClass:"btn btn-sm btn-primary",on:{"click":_vm.handleRefreshGitStat}},[_vm._v("\n          "+_vm._s(_vm.I18n.t('resume.repository.refresh.action'))+"\n        ")])]],2):_vm._e()]),_vm._v(" "),(_vm.isGitable && (_vm.isEditable ? true : !_vm.hideGitStats))?_c('div',{class:_vm.hideGitStats && _vm.style.translucent},[_c('p',{staticClass:"update mt-2 mb-0"},[_c('span',[_vm._v(_vm._s(_vm.I18n.t('section.repository.commit', { count: _vm.commits })))]),_vm._v(" "),(_vm.analyzedAt)?_c('span',[_vm._v(" ― ("+_vm._s(_vm.I18n.t('section.repository.updated', { date: _vm.I18n.l('time.formats.detail_2', _vm.analyzedAt) }))+")")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"stat-chart-wrap"},[_c('div',{ref:"donutRef",staticClass:"stat-chart"}),_vm._v(" "),_c('ul',{staticClass:"stat-chart-legend"},_vm._l((_vm.linesCountByLanguage),function(ref){
        var name = ref.name;
        var counts = ref.counts;
return _c('li',{key:name},[_vm._v("\n          "+_vm._s(name)+" ― "),_c('span',[_vm._v(_vm._s(counts)+" lines")])])}),0)])]):(_vm.isEditable)?_c('div',{staticClass:"section-alert"},[(_vm.inProgress)?_vm._l((2),function(idx){return _c('p',{key:idx},[_vm._v("\n        "+_vm._s(_vm.I18n.t(("section.repository.message.in_progress_" + idx)))+"\n      ")])}):[_c('p',[_vm._v(_vm._s(_vm.I18n.t('section.repository.message.no_result')))])]],2):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }