<template>
  <select v-model="order" class="btn btn-md btn-outline-light">
    <option
      v-for="option in orderSelectOptions"
      :key="option"
      :value="option"
    >
      {{ I18n.t(`job_position.order.${option}`) }}
    </option>
  </select>
</template>

<script lang="ts">
import {
  defineComponent,
  inject,
} from '@vue/composition-api';
import { FilterState } from '../../hooks/filter';
import { useI18n } from '@/helpers/i18n';

const orderSelectOptions = ['recent', 'popular', 'response'];

export default defineComponent({
  name: 'JobPositionOrderFilter',
  setup() {
    const { order } = inject<FilterState>('filter')!;
    const I18n = useI18n();

    return {
      I18n,
      order,
      orderSelectOptions,
    };
  },
});
</script>
