<template>
  <div v-lazy-container="{ selector: 'img' }">
    <img :data-src="impressionExposeUrl">
  </div>
</template>

<script>

export default {
  name: 'ImpressionTracker',
  props: {
    trackableType: {
      type: String,
      required: true,
    },
    trackableId: {
      type: Number,
      required: true,
    },
    trackingInfos: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    currentUrl() {
      const {
        host,
        pathname,
        search,
      } = window.location;
      const path_with_params = this.$router?.currentRoute?.fullPath || (pathname + search);

      return host + path_with_params;
    },
    impressionExposeUrl() {
      const urlParams = new URLSearchParams({
        trackable_type: this.trackableType,
        trackable_id: this.trackableId.toString(),
        current_url: encodeURIComponent(this.currentUrl),
      });

      for (const [key, value] of Object.entries(this.trackingInfos)) {
        urlParams.append(key, value);
      }

      return `/api/expose?${urlParams.toString()}`;
    },
  },
};
</script>
