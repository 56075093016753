import { Viewer } from '@toast-ui/vue-editor';
import VueCompositionAPI, {
  Ref,
  watch,
} from '@vue/composition-api';
import Vue from 'vue';

Vue.use(VueCompositionAPI);

type Primitive = string | number | boolean | Date | Map<any, any> | Set<any> | Symbol;

export const viewerSet: Promise<void>[] = [];

export const useViewer = (
  viewerRef: Ref<Viewer | null>,
  watcher: Ref | (() => Primitive),
  usingOriginalStyle: boolean = false,
  key?: string,
) => {
  const refUnwatch = watch(
    viewerRef,
    (viewerRef) => {
      if (!viewerRef) return;
      if (usingOriginalStyle) return;

      viewerSet.push(
        removeTuiStyle(viewerRef.getRootElement()),
      );
    },
  );

  const watcherUnwatch = watch(
    watcher,
    (watcher) => {
      const value = key ? watcher?.[key] : watcher;
      viewerRef?.value?.invoke('setMarkdown', value || '');
    },
  );

  return function unwatch() {
    refUnwatch();
    watcherUnwatch();
  };
};

export function removeTuiStyle(target: HTMLElement | Document = document) {
  return new Promise<void>(
    (resolve) => {
      const TUI_CLASS = 'tui-editor-contents';
      const targetDivs = target.querySelectorAll(`.${TUI_CLASS}`);
      Array.from(targetDivs).forEach((div) => div.classList.remove(TUI_CLASS));
      resolve();
    },
  );
}
