import {
  camelToSnake,
  isCamelCase,
  isSnakeCase,
  snakeToCamel,
} from './string';
import constants from '@/constant/format';
import { Primitive, Query } from '@/exportables/types/general.d';
import { ObjectKeysToCamel, ObjetKeysToSnake } from '@/exportables/types/string.d';

const { ALPHA, BETA, PRODUCTION } = constants;
const BUSINESS = 'business';

type Part = {
  id: number,
  type: string,
};

const parse = (url: string = '') => {
  let info;

  try {
    info = new URL(url);
  } catch (e) {
    const path = url.startsWith('/') ? url : `/${url}`;
    info = new URL(`${document.location.origin}${path}`);
  }

  const { host, pathname: path, protocol, search } = info;
  const a = isAlpha(host);
  const b = isBeta(host);
  const mode = (
    a && ALPHA ||
    b && BETA ||
    PRODUCTION
  );

  return {
    host,
    isAlpha: a,
    isBeta: b,
    isBusiness: isBusinessPage(host),
    mode,
    path,
    protocol,
    search,
  };
};

export const originalUrl = (url: string = '', usePath = true) => {
  const {
    host,
    isAlpha,
    isBeta,
    path,
    protocol,
    search,
  } = parse(url);
  const isB = isBusinessPage(host);
  const [first, ...rest] = host.split('.');
  const h = [
    isB ? (
      isAlpha && ALPHA ||
      isBeta && BETA ||
      ''
    ) : first,
  ].concat(rest).filter(Boolean);
  return `${protocol}//${h.join('.')}${usePath ? `${path}${search}` : ''}`;
};

export const businessUrl = (url: string = '') => {
  const {
    host,
    isAlpha,
    isBeta,
    path,
    protocol,
  } = parse(url);
  const h = host.replace(/-(career|campus|school)|(career|campus|school)\./, '');
  const splitted = h.split('.');
  let origin = '';

  if ((isAlpha && splitted[0] !== ALPHA) || isBeta) {
    const spliced = splitted.splice(0, 1, `-${BUSINESS}`);
    origin = spliced + splitted.join('.');
  } else {
    origin = [BUSINESS, h].join('.');
  }

  return `${protocol}//${origin}${path}`;
};

export const isAlpha = (host: string) => host.includes(ALPHA);

export const isBeta = (host: string) => host.includes(BETA);

export const isDevelopment = (url: string) => {
  const { isAlpha, isBeta } = parse(url);
  return isAlpha || isBeta;
};

export const isBusinessPage = (url: string = window.location.host) => url.includes(BUSINESS);

export const moveTo = (path: string, isNewPage = false) => {
  if (!path) return;
  isNewPage ? window.open(path, '_target', 'noopener noreferrer') : window.location.assign(path);
};

type KeyWrapper<T> = {
  keys: (keyof T | string)[],
  defaultValue?: [] | Primitive,
};

export const mapBoolean = (value: string | boolean): string | boolean => {
  if (value === 'true') return true;
  if (value === 'false') return false;
  return value;
};

const mapNumber = <T>(value: T): T | number => {
  if (typeof value === 'boolean') return value;
  const parsed = Number(value);
  return Number.isSafeInteger(parsed) ? parsed : value;
};

export const mapArray = <T>(value: T | T[]) => Array.isArray(value) ? value : [value];

const mapKey = <T extends Query>(
  obj: T,
  keyWrapper: KeyWrapper<T>,
  isArray = false,
): Partial<ObjectKeysToCamel<T>> | Partial<ObjetKeysToSnake<T>> => {
  const { defaultValue, keys } = keyWrapper;
  if (!keys.length) return {};

  return keys.reduce(
    (acc, key) => {
      const value = obj?.[key] ?? defaultValue;
      const isExist = Boolean(Array.isArray(value) ? value.length : value);
      const newKey = (
        isCamelCase(key as string) && camelToSnake(key as string) ||
        isSnakeCase(key as string) && snakeToCamel(key as string) ||
        key
      );

      isExist && Object.assign(
        acc,
        {
          [newKey]: isArray
            ? Array.isArray(value)
              ? value.map(mapNumber)
              : [mapNumber(value)]
            : mapNumber(value),
        },
      );
      return acc;
    },
    {},
  );
};

export const queryWrapper = <T extends Query>(
  query: T,
  primitiveKeyWrapper: KeyWrapper<T>,
  arrKeyWrapper?: KeyWrapper<T>,
): Query => {
  const wrapper: Query = {};

  return query
    ? Object.assign(
      wrapper,
      primitiveKeyWrapper && mapKey<T>(query, primitiveKeyWrapper),
      arrKeyWrapper && mapKey<T>(query, arrKeyWrapper, true),
    )
    : wrapper;
};
