<script lang="tsx">
import { computed, defineComponent, useCssModule } from '@vue/composition-api';
import { useState } from '../../hooks/show/state';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { tooltip, skeleton } from '@/directives';
import { useI18n } from '@/helpers/i18n';
import format from '@/constant/format';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

if (!('duration' in dayjs)) {
  dayjs.extend(duration);
}

const getLanguageName = (language: string) => format.LANGUAGE[language].name;

export default defineComponent({
  name: 'CompetitionShowInfo',
  directives: {
    tooltip,
    skeleton,
  },
  setup() {
    const style = useCssModule();
    const { isLoading, state } = useState();
    const I18n = useI18n();
    const remainTimes = computed(() => {
      const receiptEndAt = dayjs(state.receiptEndAt);
      const tryoutEndAt = dayjs(state.endAt);
      const now = dayjs();
      return {
        receiptEnd: dayjs.duration(receiptEndAt.diff(now)),
        tryoutEnd: dayjs.duration(tryoutEndAt.diff(now)),
      };
    });

    return () => {
      const { isRegistrable, receiptStartAt, receiptEndAt, hasTest, testStartAt, testEndAt, testLanguages } = state;
      const { receiptEnd, tryoutEnd } = remainTimes.value;

      return (
        <div class={style.container}>
          <div v-skeleton={isLoading.value} class="competition-info">
            { isRegistrable &&
              <div class={[style.item, style.remainTime]}>
                <div class={style.label}>
                { I18n.t('competition.label.registration_end_at') }
                </div>
                {
                  receiptEnd.asMilliseconds() > 0 &&
                  <div class={style.timeWrap}>
                    <div class={style.time}>
                      <span class={style.number}>
                        { Math.floor(receiptEnd.asDays()) }
                      </span>
                      <span class={style.unit}>
                        { I18n.t('datetime.prompts.day') }
                      </span>
                    </div>
                    <div class={style.time}>
                      <span class={style.number}>
                        { receiptEnd.hours() }
                      </span>
                      <span class={style.unit}>
                        { I18n.t('datetime.prompts.hour') }
                      </span>
                    </div>
                    <div class={style.time}>
                      <span class={style.number}>
                        { receiptEnd.minutes() }
                      </span>
                      <span class={style.unit}>
                        { I18n.t('datetime.prompts.min') }
                      </span>
                    </div>
                  </div>
                }
                {
                  receiptEnd.asMilliseconds() <= 0 &&
                  tryoutEnd.asMilliseconds() <= 0 &&
                  <div class={style.timeWrap}>
                    <div class={style.time}>
                      <span class={style.number}>--</span>
                      <span class={style.unit}>
                        { I18n.t('datetime.prompts.day') }
                      </span>
                    </div>
                    <div class={style.time}>
                      <span class={style.number}>--</span>
                      <span class={style.unit}>
                        { I18n.t('datetime.prompts.hour') }
                      </span>
                    </div>
                    <div class={style.time}>
                      <span class={style.number}>--</span>
                      <span class={style.unit}>
                        { I18n.t('datetime.prompts.min') }
                      </span>
                    </div>
                  </div>
                }
              </div>
            }
            <div class={style.border} />
            <div class={style.item}>
              <div class={style.schedules}>
                <div>
                  { I18n.t('terms.receipt') }
                </div>
                <div>
                  <span>
                    {I18n.l('time.formats.competition_start', receiptStartAt)}
                  </span>
                  <span>~ {I18n.l('time.formats.competition_end', receiptEndAt)}</span>
                </div>
                {
                  hasTest &&
                  [
                    <div>
                      { I18n.t('terms.test') }
                    </div>,
                    <div>
                      <span>
                        { I18n.l('time.formats.competition_start', testStartAt)}
                      </span>
                      <span>~ {I18n.l('time.formats.competition_end', testEndAt) }</span>
                    </div>
                  ]
                }
              </div>
            </div>
            {
              testLanguages?.length ?
              [
                <div class={style.border} />,
                <div class={[style.item, style.languages]}>
                  {
                    testLanguages.map((language) => (
                      <div vTooltip:top={{
                        message: getLanguageName(language),
                      }}>
                        <SvgIcon name={`ic-${language}-30`} htmlClass="ic-30 ic-blue-grey-900" />
                      </div>
                    ))
                  }
                </div>
              ] : null
            }
          </div>
        </div>
      );
    }
  },
});
</script>

<style lang="scss" module>
@use "~/stylesheets/variables";
@use "~/stylesheets/functions";

.container {
  width: 100%;
  margin: 0 auto;
  padding: functions.rem-calc(0 16);
  max-width: functions.rem-calc(992);

  > div {
    justify-content: space-evenly;
  }
}

.item {
  display: flex;
  align-items: center;
  min-height: functions.rem-calc(60);
  height: 100%;
}

.remainTime {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .label {
    font-size: functions.rem-calc(12);
  }

  .timeWrap {
    display: flex;
    gap: functions.rem-calc(8);
    font-weight: 500;

    .time {

      .number {
        font-size: functions.rem-calc(26);
      }

      .unit {
        margin-left: functions.rem-calc(2);
        font-size: functions.rem-calc(12);
      }
    }
  }
}

.schedules {
  display: grid;
  grid-template-columns: 1fr auto;
  font-size: functions.rem-calc(14);
  gap: functions.rem-calc(2 8);

  span {
    display: inline-block;
  }

  span + span {
    &::before {
      content: '\00a0';
    }
  }
}

.languages {
  display: grid;
  max-width: functions.rem-calc(227);
  grid-template-columns: repeat(auto-fit, functions.rem-calc(30));
  gap: functions.rem-calc(0 8);
}

.border {
  min-height: functions.rem-calc(60);
  height: 100%;
  border-right: 1px solid variables.$color-border;

  &:first-child {
    display: none;
  }
}

@media (variables.$lg-down) {
  .remainTime {
    display: none;
  }

  .container {
    > div {
      .border:nth-child(2) {
        display: none;
      }
    }
  }

  .languages {
    max-width: functions.rem-calc(287);
  }
}

@media (variables.$md-down) {
  .item {
    min-height: 0;
  }

  .container {
    > div {
      margin-top: functions.rem-calc(16) !important;
    }
  }

  .border {
    min-height: 0;
    width: 100%;
    border-bottom: 1px solid variables.$color-border;
    margin: functions.rem-calc(8 0);
  }

  .languages {
    max-width: none;
  }
}

@media (variables.$sm-down) {
  .schedules {
    div:nth-child(2), div:nth-child(4) {
      text-align: right;
    }
  }
}
</style>
