import { ref, onMounted, unref, onUnmounted } from '@vue/composition-api';
import { isElementOverflow } from '@/exportables/utils/dom.util';
import { debounce } from '@/helpers/lazy';

export const useIsOverflow = () => {
  const refElement = ref<HTMLElement>(null!);
  const isOverflow = ref(false);

  const handleRefOverflow = () => {
    isOverflow.value = isElementOverflow(unref(refElement));
  };

  const debouncedHandleRefOverflow = debounce(handleRefOverflow, 500);

  window.addEventListener('resize', debouncedHandleRefOverflow);

  onMounted(() => {
    handleRefOverflow();
  });

  onUnmounted(() => {
    window.removeEventListener('resize', debouncedHandleRefOverflow);
  });

  return { refElement, isOverflow };
};
