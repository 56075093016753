import { DefaultDate, DefaultNumber } from '../../types/general.d';

export type TokenType = keyof Tokens;
export class Tokens {
  assignments: Assignment[] = [];
  skillCheckAssignments: SkillCheckAssignment[] = [];
  tryouts: Tryout[] = [];

  constructor(tokens: Partial<Tokens> = {}) {
    const {
      assignments,
      skillCheckAssignments,
      tryouts,
    } = tokens;

    Object.assign(
      this,
      assignments && { assignments: assignments.map(Assignment.new) },
      skillCheckAssignments && { skillCheckAssignments: skillCheckAssignments.map(SkillCheckAssignment.new) },
      tryouts && { tryouts: tryouts.map(Tryout.new) },
    );
  }

  static new(tokens?: Partial<Tokens>) {
    return new Tokens(tokens);
  }
}

export class Assignment {
  id: number = -1;
  challengeableType?: string = '';
  finishedAt: DefaultDate = null;
  frameworks: string[] = [];
  isMailed?: boolean = false;
  perfectScore: DefaultNumber = null;
  rank: DefaultNumber = null;
  resultUrl: string = '';
  score: DefaultNumber = null;
  shareResultPolicy: boolean = false;
  startedAt: DefaultDate = null;
  status: string = '';
  testeesCount: DefaultNumber = null;
  testDuration: string | number = '';
  title: string = '';

  constructor(assignment: Partial<Assignment> = {}) {
    Object.assign(this, assignment);
  }

  static new(assignment?: Partial<Assignment>) {
    return new Assignment(assignment);
  }
}

export class SkillCheckAssignment {
  id: number = -1;
  challengeableType?: string = '';
  codeUrl: string = '';
  codeViewPolicy: boolean = false;
  finishedAt: DefaultDate = null;
  frameworks: string[] = [];
  isReviewed: boolean = false;
  resultUrl?: string = '';
  startedAt: DefaultDate = null;
  status: string = '';
  title: string = '';

  constructor(skillCheckAssignment: Partial<SkillCheckAssignment> = {}) {
    Object.assign(this, skillCheckAssignment);
  }

  static new(skillCheckAssignment?: Partial<SkillCheckAssignment>) {
    return new SkillCheckAssignment(skillCheckAssignment);
  }
}

export class Tryout {
  id: number = -1;
  challengeableType?: string = '';
  finishedAt: DefaultDate = null;
  isMailed: boolean = false;
  languages: string[] = [];
  rank: DefaultNumber = null;
  resultUrl: string = '';
  perfectScore: DefaultNumber = null;
  score: DefaultNumber = null;
  shareResultPolicy: boolean = false;
  startedAt: DefaultDate = null;
  status: string = '';
  testeesCount: DefaultNumber = null;
  testDuration: string | number = '';
  title: string = '';

  constructor(tryout: Partial<Tryout> = {}) {
    Object.assign(this, tryout);
  }

  static new(tryout?: Partial<Tryout>) {
    return new Tryout(tryout);
  }
}

export type SkillCheck = {
  codeUrl: string,
  finishedAt: DefaultDate,
  languages: string[],
  level: number,
  testTime: number,
  timeout: number,
};

export type Token = {
  id: number,
  challengeableType?: string,
  finishedAt?: DefaultDate,
  frameworks?: string[],
  isMailed?: boolean,
  languages?: string[],
  startedAt: DefaultDate,
  status: string,
  title: string,
};
