<script lang="tsx">
import { computed, defineComponent, reactive, useCssModule, watch } from '@vue/composition-api';
import { useState } from '../../../hooks/show/state';
import { useTab } from '../../../hooks/show/tab';
import Autocomplete from '@/components/shared/Autocomplete.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { fetchCompetitionJobPositions } from '@/exportables/apis/career/competition.api';
import { CompetitionJobPositionsTab } from '@/exportables/models/career/competition.model';
import { useI18n } from '@/helpers/i18n';

type AutocompleteItem = {
  id: number,
  name: string,
};

export default defineComponent({
  name: 'CompetitionShowJobPositionTab',
  setup() {
    const careerRange = [...Array(11).keys()];
    const I18n = useI18n();
    const style = useCssModule();
    const { state } = useState();
    const { tabContents, setState: setTabState } = useTab();
    const companiesApiUrl = computed(() => `/api/competitions/${state.id}/companies`);
    const technicalTagsApiUrl = computed(() => `/api/competitions/${state.id}/technical_tags`);
    const filterParams = reactive<Required<CompetitionJobPositionsTab['filterParams']>>({
      career: null,
      companyIds: [],
      technicalTagIds: [],
      jobPositionCategoryIds: [],
    });

    const handleChangeJobPostionCategories = (e: Event) => {
      e.stopPropagation();
      const { value, checked: isChecked, tagName } = e.target as HTMLInputElement;
      if (tagName !== 'INPUT') return;
      const parsed = parseInt(value);
      if (isChecked) {
        if (filterParams.jobPositionCategoryIds.includes(parsed)) return;
        filterParams.jobPositionCategoryIds.push(parsed);
      } else {
        const targetIndex = filterParams.jobPositionCategoryIds.findIndex((id) => id === parsed);
        if (targetIndex !== -1) filterParams.jobPositionCategoryIds.splice(targetIndex, 1);
      }
    };

    const handleChangeCareer = (e: Event) => {
      e.stopPropagation();
      const { value, tagName } = e.target as HTMLInputElement;
      if (tagName !== 'INPUT') return;
      const parsed = parseInt(value);
      filterParams.career = parsed === -1 ? null : parsed;
    };

    const handleFilterReset = () => {
      Object.assign(filterParams, {
        career: null,
        companyIds: [],
        technicalTagIds: [],
        jobPositionCategoryIds: [],
      });
    };

    const handleRemoveFilter = (type: keyof typeof filterParams, id?: number) => {
      if (type === 'career') {
        return filterParams.career = null;
      } else {
        return filterParams[type] = filterParams[type].filter((_id) => _id !== id);
      }
    };

    const handleSelectAutocomplete = (
      type: 'company' | 'tech',
      item: AutocompleteItem,
    ) => {
      if (type === 'company') {
        if (!filterParams.companyIds.includes(item.id)) filterParams.companyIds.push(item.id);
      } else {
        if (!filterParams.technicalTagIds.includes(item.id)) filterParams.technicalTagIds.push(item.id);
      }
    };

    const filteredCompanies = computed(() => filterParams.companyIds.map((id) => tabContents.jobPositions?.companies.find((tag) => tag.id === id)!));
    const filteredJobPositionCategories = computed(() => filterParams.jobPositionCategoryIds.map((id) => tabContents.jobPositions?.jobPositionCategories.find((tag) => tag.id === id)!));
    const filteredTechs = computed(() => filterParams.technicalTagIds.map((id) => tabContents.jobPositions?.technicalTags.find((tag) => tag.id === id)!));

    watch(
      filterParams,
      async (filterParams) => {
        const jobPositions = await fetchCompetitionJobPositions(state.id, filterParams);
        setTabState({
          jobPositions,
        });
      },
    );

    return () => {
      if (!tabContents.jobPositions) return;
      const { jobPositionCategories, jobPositionGroups } = tabContents.jobPositions;

      return (
        <div>
          <h5 class={style.title}>
            {I18n.t('competition.show.tabs.job_positions.search')}
          </h5>
          <div>
            <div class={style.filterList}>
              <Autocomplete
                iconName="ic-domain"
                onSelected={(item: AutocompleteItem) => handleSelectAutocomplete('company', item)}
                class={style.autocomplete}
                url={companiesApiUrl.value}
                emptyFormShake={true}
                ulCustomClass={style.autocompleteItem}
                isRestructedUnicode={true}
                placeholder={I18n.t('competition.show.tabs.job_positions.filter.company.placeholder')}
              />
              <Autocomplete
                icon-name="ic-testcast-box"
                onSelected={(item: AutocompleteItem) => handleSelectAutocomplete('tech', item)}
                class={style.autocomplete}
                url={technicalTagsApiUrl.value}
                emptyFormShake={true}
                ulCustomClass={style.autocompleteItem}
                isRestructedUnicode={true}
                placeholder={I18n.t('competition.show.tabs.job_positions.filter.technical_tag.placeholder')}
              />
              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-block btn-outline-light btn-filter dropdown-toggle"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  {I18n.t('competition.show.tabs.job_positions.filter.job_category_tag.title')}
                </button>
                <div class="dropdown-menu dropdown-menu-right dropdown-filter" >
                  <div class="select-wrap" onClick={handleChangeJobPostionCategories}>
                    {
                      jobPositionCategories.map(({ id, name }) => (
                        <div key={id} class="dropdown-item">
                          <label>
                            <input
                              type="checkbox"
                              name="jobPositionCategories"
                              value={id}
                              checked={filterParams.jobPositionCategoryIds.includes(id)}
                            />
                            <span>
                              {name}
                            </span>
                          </label>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-block btn-outline-light btn-filter dropdown-toggle"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  {I18n.t('competition.show.tabs.job_positions.filter.career.title')}
                </button>
                <div class="dropdown-menu dropdown-menu-right" onClick={handleChangeCareer} >
                  <div class="dropdown-item">
                    <label>
                      <input
                        name="career"
                        type="radio"
                        value={-1}
                        checked={filterParams.career === null}
                      />
                      <span>
                        {I18n.t('competition.show.tabs.job_positions.filter.career.all')}
                      </span>
                    </label>
                  </div>
                  {
                    careerRange.map((n) => (
                      <div key={n} class="dropdown-item">
                        <label>
                          <input
                            name="career"
                            type="radio"
                            value={n}
                            checked={filterParams.career === n}
                          />
                          <span>
                            {
                              n === 0 ?
                                I18n.t('competition.show.tabs.job_positions.filter.career.new') :
                                I18n.t('competition.show.tabs.job_positions.filter.career.label', { career: n })
                            }
                          </span>
                        </label>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
            <div class={style.chipList}>
              {
                filteredCompanies.value.length ?
                  <ul class={style.chipWrap}>
                    {
                      filteredCompanies.value.map(({ id, name }) => (
                        <li class={[style.chip, style.company]} key={`company-${id}`}>
                          <span>{name}</span>
                          <button onClick={() => handleRemoveFilter('companyIds', id)}>
                            <SvgIcon name="ic-cancel-plain-14" html-class="ic-14" />
                          </button>
                        </li>
                      ))
                    }
                  </ul> : null
              }
              {
                filteredJobPositionCategories.value.length ?
                  <ul class={style.chipWrap}>
                    {
                      filteredJobPositionCategories.value.map(({ id, name }) => (
                        <li class={[style.chip, style.jobCategory]} key={`job-category-${id}`}>
                          <span>{name}</span>
                          <button onClick={() => handleRemoveFilter('jobPositionCategoryIds', id)}>
                            <SvgIcon name="ic-cancel-plain-14" html-class="ic-14" />
                          </button>
                        </li>
                      ))
                    }
                  </ul> : null
              }
              {
                filteredTechs.value.length ?
                  <ul class={style.chipWrap}>
                    {
                      filteredTechs.value.map(({ id, name }) => (
                        <li class={[style.chip, style.techStack]} key={`tech-${id}`}>
                          <span>{name}</span>
                          <button onClick={() => handleRemoveFilter('technicalTagIds', id)}>
                            <SvgIcon name="ic-cancel-plain-14" html-class="ic-14" />
                          </button>
                        </li>
                      ))
                    }
                  </ul> : null
              }
              {
                filterParams.career !== null &&
                <ul class={style.chipWrap}>
                  {
                    filterParams.career === 0 ?
                      <li class={[style.chip, style.employmentDuration]} key="career">
                        <span>
                          {I18n.t('competition.show.tabs.job_positions.filter.career.new')}
                        </span>
                        <button onClick={() => handleRemoveFilter('career')}>
                          <SvgIcon name="ic-cancel-plain-14" html-class="ic-14" />
                        </button>
                      </li> :
                      <li class={[style.chip, style.employmentDuration]} key="career">
                        <span>
                          {I18n.t('competition.show.tabs.job_positions.filter.career.label', { career: filterParams.career })}
                        </span>
                        <button onClick={() => handleRemoveFilter('career')}>
                          <SvgIcon name="ic-cancel-plain-14" html-class="ic-14" />
                        </button>
                      </li>
                  }
                </ul>
              }
              {
                (filteredCompanies.value.length ||
                  filteredJobPositionCategories.value.length ||
                  filteredTechs.value.length ||
                  filterParams.career !== null) &&
                <button
                  class={['btn btn-md', style.resetBtn]}
                  onClick={handleFilterReset}
                >
                  {I18n.t('job_position.list.search_filter.reset_filter')}
                </button>
              }
            </div>
          </div>
          <div>
            {
              jobPositionGroups.length ?
                jobPositionGroups.map(({
                  jobPositions,
                  registrationGroup,
                }, index) => (
                  <div key={index}>
                    {
                      registrationGroup &&
                      <div class="registration-group">
                        <h5 class={style.title}>
                          {I18n.t('competition.show.tabs.job_positions.registration_group')}
                          - {registrationGroup.title}
                        </h5>
                        <h6>
                          {registrationGroup.description}
                        </h6>
                      </div>
                    }
                    <div class={['table-responsive-wrap', style.positionTable]}>
                      <table class="table table-sm table-participating-companies">
                        <thead>
                          <tr>
                            <th class="t-position">
                              {I18n.t('competition.show.tabs.job_positions.position')}
                            </th>
                            <th class="t-career">
                              {I18n.t('competition.show.tabs.job_positions.career')}
                            </th>
                            {
                              state.showCompetitiveRate ?
                                <th class="t-competitive-rate">
                                  {I18n.t('competition.show.tabs.job_positions.competitive_rate')}
                                </th> : null
                            }
                            <th class="t-skills">
                              {I18n.t('competition.show.tabs.job_positions.stack')}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            jobPositions.map(({ id, company, title, career, technicalTags, competitiveRate }) => (
                              <tr key={id}>
                                <td class="t-position">
                                  <div>
                                    <div>
                                      <a
                                        href={`/companies/${company.id}`}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                      >
                                        <div class={style.avatar}>
                                          <img src={company.logoUrl} alt={`${company.name} 로고`} />
                                        </div>
                                      </a>
                                    </div>
                                    <div class={style.positionInfo}>
                                      <div>
                                        <span class={[style.competitiveRateTag, style[competitiveRate]]}>
                                          {competitiveRate === 'high' && '지원율 높음'}
                                          {competitiveRate === 'middle' && '지원율 보통'}
                                          {competitiveRate === 'low' && '지원율 낮음'}
                                        </span>
                                      </div>
                                      <div>
                                        <a href={`/job_positions/${id}`}
                                          target="_blank"
                                          rel="noreferrer noopener"
                                        >
                                          {title}
                                        </a>
                                      </div>
                                      <div>
                                        <a href={`/companies/${company.id}`}
                                          target="_blank"
                                          rel="noreferrer noopener">
                                          {company.name}
                                        </a>
                                      </div>
                                      <div>
                                        <SvgIcon name="ic-laptop-14" html-class="ic-14" />
                                        <span>{career}</span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td class="t-career">
                                  {career}
                                </td>
                                {
                                  state.showCompetitiveRate ?
                                    <td class="t-competitive-rate">
                                      <span class={[style.competitiveRateTag, style[competitiveRate]]}>
                                        {competitiveRate === 'high' && '지원율 높음'}
                                        {competitiveRate === 'middle' && '지원율 보통'}
                                        {competitiveRate === 'low' && '지원율 낮음'}
                                      </span>
                                    </td> : null
                                }
                                <td class="t-skills">
                                  {technicalTags.map(({ name }) => name).join(', ')}
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))
                :
                <div class="empty">
                  <h2>(ノ&gt;ノ)</h2>
                  <h6 domPropsInnerHTML={I18n.t('competition.show.tabs.job_positions.empty_html')}></h6>
                </div>
            }
          </div>
        </div>
      );
    };
  },
});
</script>

<style lang="scss" module>
@use '~/stylesheets/variables';
@use '~/stylesheets/functions';
@use '~/stylesheets/mixins';

.title {
  font-weight: 500;
}

.autocomplete {
  display: flex;
  align-items: center;
  position: relative;

  > input {
    padding-left: functions.rem-calc(40);
  }

  > svg {
    position: absolute;
    left: functions.rem-calc(8);
    fill: variables.$blue-grey-200;
  }
}

.filterList {
  display: flex;
  gap: functions.rem-calc(8);
  margin: functions.rem-calc(8 0);

  > div {
    margin: 0;
  }

  > div:nth-child(3) {
    min-width: functions.rem-calc(100);
  }

  > div:nth-child(4) {
    min-width: functions.rem-calc(136);
  }
}

.chipList {
  display: block;
  margin-bottom: functions.rem-calc(4);

  > ul + ul {
    &::before {
      content: '&&';
      font-size: functions.rem-calc(14);
      color: variables.$blue-grey-200;
      margin: functions.rem-calc(0 3 0 -2);
      display: inline-flex;
      align-items: center;
    }
  }
}

.chipWrap {
  display: inline;
  padding: 0;

  li {
    margin-right: functions.rem-calc(4);
    margin-bottom: functions.rem-calc(4);
  }
}

.chip {
  width: max-content;
  display: inline-flex;
  height: functions.rem-calc(29);
  padding: functions.rem-calc(4 7.5 4 12);
  font-size: functions.rem-calc(14);
  font-weight: 700;
  align-items: center;
  border-radius: functions.rem-calc(4);
  column-gap: functions.rem-calc(6);
  overflow: hidden;

  span {
    max-width: calc(100% - functions.rem-calc(18));
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  button {
    background: none;
    border: none;
    display: inline-flex;
    padding: 0;

    > svg {
      opacity: 0.24;
    }
  }
}

.resetBtn {
  display: inline-flex;
  align-items: center;
  font-size: functions.rem-calc(12);
  color: variables.$color-text--secondary;
  padding: functions.rem-calc(4 12);
  background: none;
  border: none;
}

.company {
  background: variables.$green-50;
  color: variables.$color-success;

  svg {
    fill: variables.$color-success;
  }
}

.employmentDuration {
  background: variables.$brown-50;
  color: variables.$brown-500;

  svg {
    fill: variables.$brown-500;
  }
}

.jobCategory {
  background: variables.$purple-50;
  color: variables.$purple-500;

  svg {
    fill: variables.$purple-500;
  }
}

.techStack {
  background: variables.$blue-50;
  color: variables.$color-primary;

  svg {
    fill: variables.$color-primary;
  }
}

.positionTable {
  border: 1px solid variables.$color-border;
  border-radius: functions.rem-calc(4);

  > table {
    > thead > tr > th {
      padding: functions.rem-calc(8 0);
    }

    > tbody > tr > td {
      padding: 0;
    }
  }
}

.avatar {
  width: functions.rem-calc(56);
  height: functions.rem-calc(56);
  min-width: functions.rem-calc(56);
  min-height: functions.rem-calc(56);
  border-radius: 50%;
  border: 1px solid variables.$blue-grey-50;

  > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: contain;
  }
}

.positionInfo {
  display: flex;
  flex-direction: column;
  font-size: functions.rem-calc(14);
  line-height: functions.rem-calc(21);
  gap: functions.rem-calc(4);

  > div:first-of-type {
    display: none;
  }

  > div:nth-of-type(2) {
    font-weight: 500;
  }

  > div:nth-of-type(3) {
    > a {
      color: variables.$color-text--secondary;
    }
  }

  > div:nth-of-type(4) {
    display: none;
  }
}

.competitiveRateTag {
  padding: functions.rem-calc(2 10);
  border-radius: functions.rem-calc(4);
  font-size: functions.rem-calc(12);
  font-weight: 500;

  &.high {
    color: variables.$color-danger;
    background: variables.$red-50;
    border: 1px solid variables.$red-50;
  }

  &.middle {
    color: variables.$blue-grey-700;
    background: #f0f5fa;
    border: 1px solid rgba(215, 226, 235, 0.5);
  }

  &.low {
    color: variables.$color-primary;
    background: variables.$blue-50;
    border: 1px solid variables.$blue-50;
  }
}

@media (variables.$lg-down) {
  .positionInfo {
    > div:nth-of-type(4) {
      display: flex;
      align-items: center;
      color: variables.$color-text--secondary;
      fill: variables.$color-text--secondary;
      gap: functions.rem-calc(4);
    }
  }
}

@media (variables.$md-down) {
  .filterList {
    display: grid;
    grid-template-columns: 1fr 1fr;

    > div:first-child,
    div:nth-child(2) {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    > div:nth-child(3) {
      min-width: 0;
    }

    > div:nth-child(4) {
      min-width: 0;
    }
  }

  .positionInfo {
    font-size: functions.rem-calc(13);
    line-height: functions.rem-calc(19.5);

    > div:first-of-type {
      display: block;
    }
  }

  .competitiveRateTag {
    font-size: functions.rem-calc(10);
    padding: functions.rem-calc(2 4);
  }
}

.autocompleteItem {
  border: 0 !important;
  border-radius: functions.rem-calc(5);

  > li {
    padding: 0;

    > div {
      padding: functions.rem-calc(8 16);
      border: 0 !important;

      &:first-child {
        border-top-left-radius: functions.rem-calc(5);
        border-top-right-radius: functions.rem-calc(5);
      }

      &:last-child {
        border-bottom-left-radius: functions.rem-calc(5);
        border-bottom-right-radius: functions.rem-calc(5);
      }
    }
  }
}
</style>
