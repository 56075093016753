<template>
  <div class="form-group form-location">
    <button
      type="button"
      class="btn btn-block btn-outline-light btn-filter dropdown-toggle"
      data-toggle="dropdown"
      aria-expanded="false"
    >
      {{ I18n.t('job_position.list.search_filter.location.title') }}
    </button>

    <div class="dropdown-menu dropdown-filter dropdown-menu-right" style="z-index: 888">
      <div class="select-wrap">
        <div class="select-map-img">
          <div
            v-for="location in hoveredLocations"
            :key="`hovered${location}`"
            class="hover-img"
            :class="location"
          ></div>
          <div
            v-for="location in locations"
            :key="`selected${location}`"
            class="hover-img"
            :class="location"
          ></div>
          <img src="~images/map-seoul-all.png" class="map-img">

          <div class="wrap-point">
            <span
              v-for="point in locationPoints"
              :key="point"
              class="point"
              :class="point"
            >
              {{ I18n.t(`job_position.list.search_filter.location.point.${point}`) }}
            </span>
          </div>

          <div
            v-for="location in primaryLocationFilterOptions"
            :key="`mapLabel${location}`"
            class="map-area"
            :class="location"
            @click.stop="mapAreaClickHandler(location, $event)"
            @mouseover="mapLabelMouseoverHandler(location)"
            @mouseout="mapLabelMouseoutHandler(location)"
          >
            <span>
              {{ I18n.t(`company.location.${location}`) }}
            </span>
          </div>
        </div>

        <ul class="select-checkbox">
          <li
            v-for="location in locationFilterOptions"
            :key="location"
            class="dropdown-item"
          >
            <label
              class="location_label"
              @mouseover="mapLabelMouseoverHandler(location)"
              @mouseout="mapLabelMouseoutHandler(location)"
              @click.stop
            >
              <input
                :id="`job_position_location_${location}`"
                v-model="locations"
                type="checkbox"
                :value="location"
              >
              {{ I18n.t(`company.location.${location}`) }}
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  inject,
  ref,
} from '@vue/composition-api';
import { FilterState } from '../../hooks/filter';
import {
  locationFilterOptions,
  locationPoints,
  primaryLocationFilterOptions,
} from '@/exportables/constants/job_position.constant';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'JobPositionLocationFilter',
  setup() {
    const { locations } = inject<FilterState>('filter')!;
    const hoveredLocations = ref<string[]>([]);
    const I18n = useI18n();

    const mapAreaClickHandler = (location: string) => {
      if (locations.value.includes(location)) {
        locations.value.splice(locations.value.indexOf(location), 1);
      } else {
        locations.value.push(location);
      }
    };

    const mapLabelMouseoverHandler = (location: string) => {
      if (!hoveredLocations.value.includes(location)) {
        hoveredLocations.value.push(location);
      }
    };

    const mapLabelMouseoutHandler = (location: string) => {
      if (hoveredLocations.value.includes(location)) {
        hoveredLocations.value.splice(hoveredLocations.value.indexOf(location), 1);
      }
    };

    return {
      I18n,
      hoveredLocations,
      locationFilterOptions,
      locationPoints,
      locations,
      mapAreaClickHandler,
      mapLabelMouseoutHandler,
      mapLabelMouseoverHandler,
      primaryLocationFilterOptions,
    };
  },
});
</script>
