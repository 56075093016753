import {
  Ref,
  unref,
} from '@vue/composition-api';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';

const I18n = useI18n();

export function copy(text: string | Ref<string>) {
  navigator.clipboard.writeText(unref(text))
    .then(
      () => appendToast(I18n.t('snackbar.common.copied')),
      () => appendToast(I18n.t('snackbar.common.error_copied')),
    );
}

export async function paste(): Promise<string> {
  return await navigator.clipboard.readText();
}
