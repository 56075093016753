import localforage from 'localforage';
import RejectConfirmModal from '../job-offers/components/modal/RejectConfirm.vue';
import {
  AdditionalStatus,
  statusColor,
} from '../job-offers/job-offer.util';
import { BusinessHistoryJobApplication } from '@/exportables/models/career/business/job-application.model';
import { BusinessHistoryJobOffer, BusinessJobOffer } from '@/exportables/models/career/business/job-offer.model';
import { BusinessJobProfile } from '@/exportables/models/career/business/job-profile.model';
import { useI18n } from '@/helpers/i18n';
import { useModal } from '@/helpers/modal';
import { originalUrl } from '@/helpers/url';

export type History = 'applied' | 'offered';
export type Contact = BusinessHistoryJobApplication | BusinessHistoryJobOffer;

const IS_TOP_PROGRAMMERS_VIEWED = 'business.jobProfile.isTopProgrammersFeeViewed';

const I18n = useI18n();
const modal = useModal();

const getStatus = (contact: Contact) => {
  const jobApplication = isJobOffer(contact) ? contact.jobApplication : contact;
  return jobApplication ? jobApplication.status : contact.status;
};

export const getTopProgrammersFeeViewed = () => localforage.getItem<boolean>(IS_TOP_PROGRAMMERS_VIEWED);

export const setTopProgrammersFeeViewed = () => localforage.setItem(IS_TOP_PROGRAMMERS_VIEWED, true);

export const isJobOffer = (contact: Contact): contact is BusinessHistoryJobOffer => (
  Boolean(contact.offeredAt)
);

export const isInProgress = (status: Contact['status'] | AdditionalStatus) => (
  status === 'applied' ||
  status === 'in_progress' ||
  status === 'hired'
);

export const jobApplicationUrl = (contact: Contact) => {
  let jobApplicationId: number | null = contact.id;
  if (isJobOffer(contact)) {
    const { jobApplication } = contact;
    jobApplicationId = jobApplication?.id || null;
  }
  return jobApplicationId ? `/job_applications/${jobApplicationId}` : '';
};

export const jobPositionUrl = (jobProfile: BusinessJobProfile) => originalUrl(`${window.location.origin}/job_positions/${jobProfile.id}`, true);

export const badgeOptions = (key: History, contact: Contact) => {
  const status = getStatus(contact);
  const color = statusColor(status);
  const label = I18n.t(`business.recruit.job_profile.modal.history.${key}.status.${status}`);

  return {
    color,
    label,
    isActive: isInProgress(status),
    isStroked: color === 'gray',
  };
};

export const hasReason = (jobOffer: BusinessJobOffer) => {
  const { etc, options } = jobOffer?.rejectMessages || {};
  return Boolean(etc || options?.length);
};

export const handleClickBadge = (contact: Contact) => {
  const isInJO = isJobOffer(contact);
  const status = getStatus(contact);
  const jobApplicationId = isInJO ? contact.jobApplication?.id : contact.id;

  if (isInProgress(status) && jobApplicationId) {
    window.open(`/job_applications/${jobApplicationId}`, '_target', 'noopener noreferrer');
  }
  if (status === 'offer_rejected') {
    if (!hasReason(contact as BusinessJobOffer)) return;
    modal.show(RejectConfirmModal, { jobOfferId: (contact as BusinessJobOffer).id });
  }
};
