<template>
  <button
    :class="{ active: hasItemName }"
    class="tag-item"
    @click="handleToggleTag({ key: camelCase(category), tag: item })"
  >
    <span class="tag-item-name">
      {{ item.name }} ({{ item.count }})
    </span>
    <SvgIcon
      v-if="hasItemName"
      name="ic-cancel"
      html-class="ic-24"
    />
    <SvgIcon
      v-else
      name="ic-add"
      html-class="ic-24"
    />
  </button>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { FeedCategory } from '../constants';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { UserFeedTab, UserFeedTabExceptUserFeed, UserFeedTag } from '@/exportables/models/career/user-feed.model';
import camelCase from '@/plugins/camelCase';
import { useStore } from '@/store/career';

export default defineComponent({
  name: 'FeedTagButton',
  components: {
    SvgIcon,
  },
  props: {
    category: {
      type: String as PropType<FeedCategory>,
      default: '',
    },
    item: {
      type: Object as PropType<UserFeedTag>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const getUserFeedTagsByTab = (tab: FeedCategory) =>
      store.state.post.userFeed[camelCase(tab) as UserFeedTabExceptUserFeed];

    const hasItemName = computed(() =>
      getUserFeedTagsByTab(camelCase(props.category))
        .map((tag) => tag.name)
        .includes(props.item.name));

    const handleToggleTag = ({ key, tag }: { key: UserFeedTab, tag: UserFeedTag }) => {
      store.dispatch('post/toggleUserFeedTag', { key, tag });
    };

    return {
      hasItemName,
      camelCase,
      handleToggleTag,
    };
  },
});
</script>
