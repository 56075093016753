import { reactive, ref } from '@vue/composition-api';
import { fetchCompetition } from '@/exportables/apis/career/competition.api';
import { Competition } from '@/exportables/models/career/competition.model';
import type { Optional } from '@/exportables/types/general';

export type CompetitionShow = Optional<
Competition,
'resume' | 'registration' | 'registrationButton' | 'freePassedUserName'
>;

const isLoading = ref<boolean>(false);

const state = reactive<CompetitionShow>({
  id: -1,
  title: '',
  tabs: [],
  receiptStartAt: '',
  receiptEndAt: '',
  startAt: '',
  endAt: '',
  testStartAt: '',
  testEndAt: '',
  testLanguages: [],
  snsImageUrl: '',
  smallImageUrl: '',
  largeImageUrl: '',
  canonicalUrl: '',
  description: '',
  hasTest: false,
  isCompetitionEditable: false,
  isFinished: false,
  isJobFair: false,
  isRegistrable: false,
  isRegistrationDestroyable: false,
  isRegistrationEditable: false,
  registrationButton: undefined,
  shortDescription: '',
  showRegistrationGroup: false,
  showCompetitiveRate: false,
  resume: undefined,
  registration: undefined,
  freePassedUserName: undefined,
});

const setState = (newState: Partial<CompetitionShow>) => {
  Object.assign(state, newState);
};

const initialize = async (id: number) => {
  isLoading.value = true;
  const competition = await fetchCompetition(id);
  setState(competition);
  isLoading.value = false;
};

export const useState = () => {
  return {
    isLoading,
    state,
    setState,
    initialize,
  };
};
