<template>
  <div class="form-group">
    <label class="control-label required">
      {{ I18n.t('job_application.common.registration_group') }}
    </label>
    <select
      id="registrationGroupId"
      v-model="registrationGroupId"
      class="form-control"
    >
      <option selected :value="null">
        {{ I18n.t('job_application.registration_group.registration_group_default_option') }}
      </option>
      <option
        v-for="registrationGroup in registrationGroups"
        :key="registrationGroup.id"
        :value="registrationGroup.id"
      >
        {{ registrationGroup.title }}
      </option>
    </select>

    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from '@vue/composition-api';
import { RegistrationGroup as RegistrationGroupType } from '@/exportables/models/registration.model';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'JobApplicationRegistrationGroup',
  props: {
    registrationGroups: {
      type: Array as PropType<RegistrationGroupType[]>,
      default: () => [],
    },
    value: {
      type: Number,
      default: null,
    },
  },
  emits: ['update:registrationGroupId'],
  setup(props, { emit }) {
    const I18n = useI18n();

    const registrationGroupId = computed({
      get: () => props.value,
      set: (value) => {
        emit('update:registrationGroupId', value);
      },
    });
    return {
      I18n,
      registrationGroupId,
    };
  },
});
</script>
