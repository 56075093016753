<template>
  <div
    :id="modalId"
    class="modal fade"
    :class="[
      isMounted && ['show', style.mounted],
      overrideClass.container,
    ]"
  >
    <div
      class="modal-dialog"
      :class="overrideClass.dialog"
    >
      <div
        class="modal-content"
        :class="overrideClass.content"
      >
        <div
          class="modal-header"
          :class="overrideClass.header"
        >
          <slot name="header">
            <button
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="hide"
            >
              <SvgIcon name="ic-close" html-class="ic-24" />
            </button>
            <h3 class="modal-title">
              {{ title }}
            </h3>
          </slot>
        </div>

        <div
          class="modal-body"
          :class="[
            style.body,
            overrideClass.body,
          ]"
        >
          <slot name="body">
            {{ description }}
          </slot>
        </div>

        <div
          class="modal-footer"
          :class="[
            style.footer,
            overrideClass.footer,
            {
              [style.edge]: footerEdge,
            },
          ]"
          :style="overrideClass.footer"
        >
          <slot name="footer">
            <button
              class="btn btn-light"
              data-dismiss="modal"
              aria-label="Close"
              @click="hide"
            >
              {{ buttonName || I18n.t('actions.ok') }}
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  ref,
  useCssModule,
  PropType,
} from '@vue/composition-api';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { useI18n } from '@/helpers/i18n';
import { modalId } from '@/helpers/modal';

type OverrideClass = Partial<
Record<
'container' | 'dialog' | 'content' | 'header' | 'body' | 'footer',
string | (string | Object)[] | Object
>
>;

export default defineComponent({
  name: 'ModalEnhanced',
  components: {
    SvgIcon,
  },
  props: {
    modalId: {
      type: String,
      default: modalId,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    buttonName: {
      type: String,
      default: '',
    },
    footerEdge: {
      type: Boolean,
      default: false,
    },
    overrideClass: {
      type: Object as PropType<OverrideClass>,
      default: () => ({}),
    },
  },
  emits: [
    'show',
    'hide',
  ],
  setup(_, { emit }) {
    const I18n = useI18n();
    const style = useCssModule();

    const isMounted = ref(false);

    const handleScroll = (event: Event) => {
      const { currentTarget, target } = event;
      if (target === currentTarget) {
        event.stopPropagation();
        event.preventDefault();
      }
    };

    const hide = () => emit('hide');

    const { body } = document;
    const { style: bodyStyle } = body;
    const scrollEventNames = [
      'mousewheel',
      'scroll',
      'touchstart',
    ];

    onMounted(() => {
      isMounted.value = true;
      emit('show');
      Object.assign(bodyStyle, { overflowY: 'hidden' });
      for (const eventName of scrollEventNames)
        body.addEventListener(eventName, handleScroll, { passive: false });
    });

    onBeforeUnmount(() => {
      isMounted.value = false;
      Object.assign(bodyStyle, { overflowY: null });
      for (const eventName of scrollEventNames)
        body.removeEventListener(eventName, handleScroll);
    });

    return {
      hide,
      I18n: { t: I18n.t },
      isMounted,
      style,
    };
  },
});
</script>

<style lang="scss" module>
@use '~/stylesheets/variables';

.mounted {
  display: block;
  min-height: 400px;
  -webkit-overflow-scrolling: auto;
  overflow-y: auto;
}

.body {
  width: 100%;
  max-height: 70vh;
  font-size: 16px;
  overflow-y: auto;
}

.footer:not(.edge) {
  border: none !important;
}

.footer.edge {
  border-top: 1px solid variables.$color-border;
}
</style>
