<template>
  <div class="form-group form-category">
    <button
      type="button"
      class="btn btn-block btn-outline-light btn-filter dropdown-toggle"
      data-toggle="dropdown"
      aria-expanded="false"
    >
      {{ I18n.t('job_position.list.search_filter.job_category_id.title') }}
    </button>

    <div class="dropdown-menu dropdown-filter" :class="$style['dropdown-job-category']">
      <ul :class="$style['dropdown-item-wrapper']">
        <li
          v-for="category in jobCategories"
          :key="category.id"
          class="dropdown-item"
        >
          <label class="job_category_label" @click.stop>
            <input v-model="jobCategoryIds" type="checkbox" :value="category.id">
            {{ category.name }}
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
} from '@vue/composition-api';
import { FilterState } from '../../hooks/filter';
import { useStore } from '../../hooks/store';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'JobPositionJobCategoryFilter',
  setup() {
    const { jobCategoryIds } = inject<FilterState>('filter')!;
    const I18n = useI18n();
    const store = useStore();
    const jobCategories = computed(() => store.state.jobCategories);

    return {
      I18n,
      jobCategories,
      jobCategoryIds,
    };
  },
});
</script>
<style lang="scss" module>
@import "~stylesheets/variables";

.dropdown-job-category {
  padding: 0.5rem;
  margin: 0 -0.2rem;
  min-width: 100%;
}

.dropdown-item-wrapper {
  column-count: 2;
  padding: 0;
  margin: 0;

  .dropdown-item {
    padding: 0.125rem 0rem;
  }
}
</style>
