<template>
  <UserFeedModal
    :user-name="userName"
    @close="handleClose"
    @save="handleSave"
  />
</template>

<script lang="ts">
import { computed, defineComponent } from '@vue/composition-api';
import UserFeedModal from '../components/UserFeedModal.vue';
import { FEED_TAB } from '../constants';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';
import { useRouter } from '@/router/career';
import { useStore } from '@/store/career';
import types from '@/store/career/post/mutation-types';

const DEFAULT_PAGE = 1;

export default defineComponent({
  name: 'UserFeedModalContainer',
  components: {
    UserFeedModal,
  },
  setup() {
    const I18n = useI18n();
    const store = useStore();
    const router = useRouter();

    const userName = computed(() => store.state.post.userName);
    const hasUserFeedTags = computed<boolean>(() => store.getters['post/hasUserFeedTags']);

    const handleClose = () => {
      if (!store.getters['post/hasUnsavedChangesInUserFeedTags']) {
        $('#user-feed-modal').modal('hide');
        return;
      }

      if (window.confirm(I18n.t('post.user_feed_setting.message.close_confirm'))) {
        store.dispatch('post/restoreChangesInUserFeedTags');
        $('#user-feed-modal').modal('hide');
      }
    };

    const handleSave = async () => {
      if (!store.getters['post/hasUnsavedChangesInUserFeedTags']) {
        $('#user-feed-modal').modal('hide');
        return;
      }

      await store.dispatch('post/saveUserFeedTags');
      appendToast(I18n.t('snackbar.common.save'));

      if (hasUserFeedTags.value) {
        await store.dispatch('post/setTabAndPage', { tab: FEED_TAB.feed });
      } else {
        await store.dispatch('post/setTabAndPage', { tab: FEED_TAB.all });
      }

      store.commit(`post/${types.SET_CURRENT_PAGE}`, DEFAULT_PAGE);

      router.push('/posts');
      $('#user-feed-modal').modal('hide');
    };

    return {
      userName,
      handleClose,
      handleSave,
    };
  },
});
</script>
