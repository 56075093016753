import types from './mutation-types';
import type { PostState } from './state';
import { UserFeedTab, UserFeedTag } from '@/exportables/models/career/user-feed.model';

export default {
  [types.SET_LOGIN](state: PostState, payload: boolean) {
    state.login = payload;
  },
  [types.SET_CURRENT_PAGE](state: PostState, payload: PostState['currentPage']) {
    state.currentPage = payload;
  },
  [types.SET_FEED_TAB](state: PostState, payload: PostState['feedTab']) {
    state.feedTab = payload;
  },
  [types.SET_POSTS](state: PostState, payload: PostState['posts']) {
    state.posts = payload;
  },
  [types.SET_TOTAL_PAGE](state: PostState, payload: PostState['totalPage']) {
    state.totalPage = payload;
  },
  [types.SET_USER_NAME](state: PostState, payload: PostState['userName']) {
    state.userName = payload;
  },
  [types.SET_USER_FEED](state: PostState, payload: PostState['userFeed']) {
    state.userFeed = payload;
  },
  [types.SET_USER_FEED_DEFAULT](state: PostState, payload: PostState['userFeedDefault']) {
    state.userFeedDefault = payload;
  },
  [types.SET_BACKUP_USER_FEED](state: PostState, payload: PostState['backupUserFeed']) {
    state.backupUserFeed = payload;
  },
  [types.SET_FEED_SETTING_TAB](state: PostState, payload: PostState['feedSettingTab']) {
    state.feedSettingTab = payload;
  },
  [types.REMOVE_USER_FEED_TAG](state: PostState, payload: { key: UserFeedTab, tag: UserFeedTag }) {
    state.userFeed = {
      ...state.userFeed,
      [payload.key]: state.userFeed[payload.key].filter((tag) => tag.id !== payload.tag.id),
    };
  },
  [types.ADD_USER_FEED_TAG](state: PostState, payload: { key: UserFeedTab, tag: UserFeedTag }) {
    state.userFeed = {
      ...state.userFeed,
      [payload.key]: [...state.userFeed[payload.key], payload.tag],
    };
  },
};
