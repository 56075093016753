<template>
  <div :class="style.container">
    <div :class="style.progressWrap">
      <SemicircularProgressBar v-skeleton="isLoading" :value="score" />
      <div>
        <span>{{ I18n.t('job_profile.completion_status.title') }}</span>
        <span
          v-tooltip:top="{
            message: I18n.t('job_profile.completion_status.tooltip'),
            width: '280px',
            isMountBody: true,
            style: { fontWeight: 400, wordBreak: 'keep-all' },
          }"
        >
          <SvgIcon
            name="ic-help-16"
          />
        </span>
      </div>
    </div>
    <div>
      <h5 v-skeleton="isLoading" :class="style.description">
        {{ descriptionText }}
      </h5>
      <div v-skeleton="isLoading" :class="style.chipList">
        <Chip
          v-for="chip in chips"
          :key="chip.name"
          :is-active="chip.isActive"
          @click="chip.handleClick"
        >
          {{ chip.name }}
        </Chip>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, useCssModule } from '@vue/composition-api';
import SemicircularProgressBar from '../../../../components/shared/ui/SemicircularProgressBar.vue';
import { JobProfile } from '../../../../exportables/models/career/job-profile.model';
import { Resume } from '../../../../exportables/models/career/resume.model';
import { useI18n } from '../../../../helpers/i18n';
import { camelToKebab, camelToSnake } from '../../../../helpers/string';
import Chip from './Chip.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { tooltip, skeleton } from '@/directives';

export type CompletitionStatus = {
  [key in keyof (Pick<Resume,
  'educations' |
  'experiences' |
  'primaryTags' |
  'projects'
  > & Pick<JobProfile, 'jobCategories'>)]: boolean;
};

export default defineComponent({
  name: 'ProfileGuide',
  components: {
    Chip,
    SemicircularProgressBar,
    SvgIcon,
  },
  directives: {
    tooltip,
    skeleton,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    completionStatus: {
      type: Object as PropType<CompletitionStatus>,
      required: true,
    },
    score: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const I18n = useI18n();
    const style = useCssModule();
    const descriptionText = computed(() => {
      return (Object.values(props.completionStatus).every(Boolean) ?
        I18n.t('job_profile.completion_status.completed') : I18n.t('job_profile.completion_status.not_completed')
      );
    });

    const chips = computed(() =>
      (Object.keys(props.completionStatus) as Array<keyof CompletitionStatus>).map((key) => ({
        name: I18n.t(`chip.${camelToSnake(key)}`),
        isActive: props.completionStatus[key],
        handleClick: () => {
          const el = document.getElementById(camelToKebab(key));
          if (!el) return;
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
          el.classList.add(style.highlight);
          setTimeout(() => {
            el.classList.remove(style.highlight);
          }, 4000);
        },
      })));

    return {
      I18n,
      style,
      descriptionText,
      chips,
    };
  },
});
</script>

<style lang="scss" module>
@use "~/stylesheets/functions";
@use "~/stylesheets/variables";

@keyframes highlight-in {
  0% {
    background: none;
  }

  40% {
    background: #F2F8FF;
  }

  100% {
    background: #F2F8FF;
  }
}

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: functions.rem-calc(32);
  padding: functions.rem-calc(24 40);
  word-break: keep-all;
  font-size: initial;
}

.progressWrap {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: functions.rem-calc(12);
  row-gap: functions.rem-calc(8);
  color: variables.$color-text--secondary;

  > div:nth-of-type(2) {
    > span {
      width: max-content;
    }
  }

  > div:last-of-type {
    display: flex;
    align-items: center;
    line-height: functions.rem-calc(18);
    gap: functions.rem-calc(4);

    > span {
      transform: translateY(-1px);

      > svg {
        width: functions.rem-calc(16);
        height: functions.rem-calc(16);
        fill: variables.$color-text--secondary;
        cursor: pointer;

        &:hover {
          fill: variables.$blue-grey-500;
        }
      }
    }
  }
}

.highlight {
  animation: highlight-in cubic-bezier(0.075, 0.82, 0.165, 1) 2s infinite alternate;
}

.description {
  color: variables.$color-text--body;
  margin-bottom: functions.rem-calc(8);
  font-size: functions.rem-calc(18);
  font-weight: 700;
}

.chipList {
  display: inline-flex;
  flex-flow: wrap;
  gap: functions.rem-calc(8);
}

@media (variables.$md-down) {
  .container {
    flex-direction: column;
    gap: functions.rem-calc(16);
    align-items: flex-start;
  }

  .description {
    font-size: functions.rem-calc(16);
    margin-bottom: functions.rem-calc(8);
  }
}
</style>
