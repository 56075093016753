<template>
  <section v-if="isEditable || resume.primaryTags.length || resume.secondaryTags.length" id="primary-tags" class="stacks">
    <div v-if="isEditable || (resume.primaryTags.length && isVisible('primaryTags'))" class="stacks__primary">
      <div class="resume-section-title">
        <span>
          {{ I18n.t('resume.stacks.primary_tags') }}
        </span>
        <span class="count">
          {{ I18n.t('terms.count_with', { count: resume.primaryTags.length }) }}
        </span>

        <SectionEditButton
          :is-editable="isEditable"
          :tooltip="I18n.t('resume.stacks.tooltip_1')"
          type="Stacks"
        />
      </div>

      <ul class="list-stack">
        <li
          v-for="tag in resume.primaryTags"
          :key="tag.id"
          class="col-item"
        >
          <button
            class="stack-item"
            :class="clickedTagName === tag.name && 'active'"
            @click="handleClickTag(tag.name)"
            @mouseover="setMouseOveredTagName(tag.name)"
            @mouseout="setMouseOveredTagName('')"
          >
            {{ tag.name }}
          </button>
        </li>
      </ul>
    </div>

    <div v-if="isEditable || (resume.secondaryTags.length && isVisible('secondaryTags'))" class="stacks__secondary">
      <div class="resume-section-title">
        <span>
          {{ I18n.t('resume.stacks.secondary_tags') }}
        </span>
        <span class="count">
          {{ I18n.t('terms.count_with', { count: resume.secondaryTags.length }) }}
        </span>
        <span
          v-if="isEditable"
          v-tooltip:top="{
            message: I18n.t('resume.stacks.tooltip_2'),
            width: '210px',
            isMountBody: true,
            style: { fontWeight: 400, wordBreak: 'keep-all' },
          }"
          class="btn-help dark"
        >
          <SvgIcon name="ic-help-full" html-class="ic-20" />
        </span>
      </div>

      <ul class="list-stack">
        <li
          v-for="tag in resume.secondaryTags"
          :key="tag.id"
          class="col-item"
        >
          <button
            class="stack-item"
            :class="clickedTagName === tag.name && 'active'"
            @click="handleClickTag(tag.name)"
            @mouseover="setMouseOveredTagName(tag.name)"
            @mouseout="setMouseOveredTagName('')"
          >
            {{ tag.name }}
          </button>
        </li>
      </ul>
    </div>
  </section>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  toRefs,
  unref,
} from '@vue/composition-api';
import { Store } from 'vuex';
import { ResumeShowStates, getResume } from '../hooks/state';
import SectionEditButton from './SectionEditButton.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { tooltip } from '@/directives';
import { OpenSetting } from '@/exportables/models/career/job-profile.model';
import { useI18n } from '@/helpers/i18n';
import { showPublicOption } from '@/pages/career/job-profiles/job-profile.util';
import { CareerStore, useStore } from '@/store/career';
import { SET_CLICKED_TAG_NAME, SET_MOUSE_OVERED_TAG_NAME } from '@/store/career/resume/mutation-types';

export default defineComponent({
  name: 'ResumeStacks',
  components: {
    SectionEditButton,
    SvgIcon,
  },
  directives: {
    tooltip,
  },
  setup() {
    const state = inject<ResumeShowStates>('state')!;
    const I18n = useI18n();
    const store = useStore();
    const { clickedTagName, mouseOveredTagName, isEditable, isSharable } = toRefs(store.state.resume);
    const resume = computed(() => getResume(state));
    const isPublicPage = computed(() => store.getters['resume/isPublicPage']);

    const isVisible = (key: keyof OpenSetting) => {
      const isTrue = showPublicOption(state?.jobProfile?.openSetting || {});
      return (unref(isSharable) && !unref(isPublicPage)) ? isTrue(key) : true;
    };

    const setClickedTagName = setEventTagName(store, 'click');
    const setMouseOveredTagName = setEventTagName(store, 'mouseOver');

    const handleClickTag = (tagName: string) => unref(clickedTagName) === tagName ? setClickedTagName('') : setClickedTagName(tagName);

    return {
      I18n: { t: I18n.t },
      clickedTagName,
      handleClickTag,
      isEditable,
      isVisible,
      mouseOveredTagName,
      resume,
      setClickedTagName,
      setMouseOveredTagName,
    };
  },
});

function setEventTagName(
  store: Store<CareerStore>,
  type: 'click' | 'mouseOver',
) {
  const mutationType = (
    type === 'click' && SET_CLICKED_TAG_NAME ||
    type === 'mouseOver' && SET_MOUSE_OVERED_TAG_NAME ||
    ''
  );
  return (tagName: string) => store.commit(`resume/${mutationType}`, tagName);
}
</script>
