import VueCompositionAPI, { ref } from '@vue/composition-api';
import Vue from 'vue';

Vue.use(VueCompositionAPI);

type Response = { status: number, message?: string };

export const response = ref<Response | null>(null);

export const useResponseStatus = () => {
  const setResponse = (status: number, message?: string) => {
    response.value = { status, message };
  };

  return { response, setResponse };
};
