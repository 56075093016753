import VueCompositionAPI, {
  computed,
  ref,
  unref,
  watchEffect,
  nextTick,
  onUnmounted,
} from '@vue/composition-api';
import Vue from 'vue';
import { ResumeShowStates, getResume, initialize } from './state';
import { updateResume } from '@/exportables/apis/career/resume.api';
import {
  Resume,
  ResumeCategory,
  ResumePart,
} from '@/exportables/models/career/resume.model';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';
import { swapReactive } from '@/helpers/utils';
import { useStore } from '@/store/career';
import { SET_EDITING_PART_COUNT } from '@/store/career/resume/mutation-types';

Vue.use(VueCompositionAPI);

type ResumeModel = { new (...args: any[]): any };

const I18n = useI18n();
const store = useStore();

export const handleUpdateForm = async (
  state: ResumeShowStates,
  params: Partial<Resume>,
) => {
  const resumeId = getResume(state).id;

  await updateResume(resumeId, { resume: params });

  initialize(state, resumeId);
};

export const useForm = (
  state: ResumeShowStates,
  category: ResumeCategory,
  Model: ResumeModel,
) => {
  const targetState = computed(() => getResume(state)[category]);
  const newItem = ref<ResumeModel>(null!);

  const handleAddItem = () => {
    const isEditing = store.getters['resume/isEditing'];

    if (isEditing) {
      appendToast(I18n.t('resume.messages.save_first'));
      return;
    }

    !newItem.value &&
      store.commit(`resume/${SET_EDITING_PART_COUNT}`, { category, delta: 1 });
    newItem.value = new Model();
  };

  const handleDeleteItem = (index: number) => {
    if (['activities', 'awards', 'foreignLanguages', 'educations', 'experiences', 'projects', 'publications'].includes(category)) {
      targetState.value[index].destroy = true;
    } else {
      targetState.value.splice(index, 1);
    }

    handleUpdateForm(state, { [category]: unref(targetState) });
  };

  return {
    newItem,
    handleAddItem,
    handleDeleteItem,
  };
};

export const usePart = (parts: ResumePart[]) => {
  const length = computed(() => parts.length);

  const handleAddItem = () => {
    const newPart = new ResumePart();
    parts.push(newPart);
  };

  const handleDeleteItem = (index: number) => parts.splice(index, 1);

  const handleChangeIndex = (targetIndex: number, newIndex: number) =>
    swapReactive(parts, targetIndex, newIndex);

  return {
    length,
    handleAddItem,
    handleDeleteItem,
    handleChangeIndex,
  };
};

export const useFocusInputOnMount = () => {
  const formRef = ref<Vue | null>(null);

  const stopWatchEffect = watchEffect(async () => {
    if (formRef.value) {
      await nextTick();
      const targetInput = formRef.value.$el.querySelector('input');
      if (!targetInput) return;
      targetInput.focus();
      targetInput.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  });

  onUnmounted(() => {
    stopWatchEffect();
  });

  return {
    ref: formRef,
  };
};
