import { GetterTree } from 'vuex';
import { PostState } from './state';
import { TagsList } from '@/exportables/apis/career/post.api';
import { Tag } from '@/exportables/models/career/tag.model';
import { isSameUserFeedTags } from '@/pages/career/posts/utils';

export type Getters = {
  tagExists(state: PostState): (tag: Tag) => boolean,
  allUserFeedTags(state: PostState): Tag[],
  hasUserFeedTags(state: PostState, getters: GetterTree<PostState, PostState>): boolean,
  hasUnsavedChangesInUserFeedTags(state: PostState, getters: GetterTree<PostState, PostState>): boolean,
  toTagsList(state: PostState): TagsList[],
};

const getters = {
  tagExists: (state: PostState) => (tag: Tag) => {
    const userFeedTagsFlatten = Object.values(state.userFeed || []).flat(1);

    return userFeedTagsFlatten.some(
      (userFeedTag) => userFeedTag.id === tag.id,
    );
  },
  allUserFeedTags(state: PostState) {
    const { post, technical, jobCategory, company } = state.userFeed || {
      post: [],
      technical: [],
      jobCategory: [],
      company: [],
    };

    return [...post, ...technical, ...jobCategory, ...company];
  },
  hasUserFeedTags(_: PostState, getters: Getters) {
    return getters.allUserFeedTags.length > 0;
  },
  hasUnsavedChangesInUserFeedTags(state: PostState) {
    const { userFeed, backupUserFeed } = state;

    type UserFeedKeys = keyof typeof userFeed;
    const keys = Object.keys(userFeed) as UserFeedKeys[];

    for (const key of keys) {
      const userFeedTags = userFeed[key];
      const backupUserFeedTags = backupUserFeed[key];

      if (!isSameUserFeedTags(userFeedTags, backupUserFeedTags)) {
        return true;
      }
    }

    return false;
  },
  toTagsList(state: PostState) {
    const { userFeed } = state;

    const { post, technical, jobCategory, company } = userFeed;

    return {
      postTagList: post.map(({ name }) => name),
      technicalTagList: technical.map(({ name }) => name),
      jobCategoryTagList: jobCategory.map(({ name }) => name),
      companyTagList: company.map(({ name }) => name),
    };
  },
};

export default getters;
