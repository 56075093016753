<template>
  <div>
    <RegistrationGroup
      v-if="requiredRegistrationGroup"
      v-model="registrationGroupId"
      :registration-groups="registrationGroups"
      @update:registrationGroupId="setRegistrationGroupId"
    >
      <span v-if="errors($v.registrationGroupId) && $v.$dirty" class="error-feedback">
        <SvgIcon html-class="ic-14" name="ic-care-center-14" />
        {{ errors($v.registrationGroupId) }}
      </span>
    </RegistrationGroup>

    <label class="control-label required">
      {{ I18n.t('job_application.select_resume_step.select_resume') }}
    </label>

    <div class="alert alert-warning alert-resume-warning">
      <div class="d-flex align-items-center">
        <SvgIcon name="ic-report" html-class="ic-48 ic-alert d-none d-sm-block mr-3" />
        <div>
          <p>
            {{ I18n.t('application.message.must_input_real_name') }}
            <span class="emphasize">
              {{ I18n.t('application.message.emphasize_real_name') }}
            </span>
          </p>
          <SlottedI18n keypath="application.message.resume_must_be_immutable_html" tag="p">
            <template #icon>
              <SvgIcon name="ic-preview" html-class="ic-20 ic-blue-grey-200" />
            </template>
          </SlottedI18n>
        </div>
      </div>
    </div>

    <table v-if="isPresent(resumes)" class="table table-resumes">
      <tbody>
        <tr>
          <td class="t-thumb t-empty">
            <a href="/resumes/new">
              <div class="thumb-resume">
                <SvgIcon name="ic-add" html-class="ic-24" />
              </div>
            </a>
          </td>
          <td class="t-body t-empty" colspan="2">
            <h5 class="title">
              <a href="/resumes/new">{{ I18n.t('job_application.select_resume_step.register_new_resume') }}</a>
            </h5>
          </td>
        </tr>

        <tr v-for="(resume, index) in resumes" :key="index" class="resume-item">
          <td class="t-thumb">
            <div class="thumb-resume">
              <SvgIcon name="ic-resume" html-class="ic-24" />
            </div>
          </td>

          <td class="t-body">
            <h5 class="title">
              {{ resume.title }}
              <span class="warning-info">
                <SvgIconWithTooltip
                  v-if="resume.errorMessages.length > 0"
                  :tooltip="validationErrorMessages(resume)"
                  html-class="ic-24 ic-danger"
                  name="ic-report"
                />
              </span>
              <span v-if="isProfile(resume)" class="label label-primary lable-profile">{{ I18n.t('job_application.select_resume_step.my_profile') }}</span>
            </h5>

            <h6 class="updated-at">
              {{ I18n.t('job_application.select_resume_step.updated_time', {
                time: formatDate(resume.updatedAt, 'YYYY-MM-DD HH:mm:ss'),
              }) }}
            </h6>
          </td>

          <td class="t-control">
            <div class="t-control-inner">
              <div class="control-buttons">
                <a
                  v-tooltip:top="I18n.t('application.tooltip.modify_content')"
                  :href="editResumePath(resume)"
                  target="_blank"
                  rel="noopener"
                  class="hidden-md-down"
                >
                  <SvgIcon name="ic-title-edit" html-class="ic-24" />
                </a>
                <a
                  v-tooltip:top="I18n.t('application.tooltip.preview')"
                  :href="showResumePath(resume)"
                  target="_blank"
                  rel="noopener"
                  class="hidden-md-down"
                >
                  <SvgIcon name="ic-preview" html-class="ic-24" />
                </a>
                <a href="#" class="hidden-lg-up" data-toggle="dropdown" aria-expanded="false">
                  <SvgIcon name="ic-more-vert" html-class="ic-24" />
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a
                    :href="editResumePath(resume)"
                    class="dropdown-item hidden-lg-up"
                    target="_blank"
                    rel="noopener"
                  >
                    {{ I18n.t('application.tooltip.modify_content') }}
                  </a>
                  <a
                    :href="showResumePath(resume)"
                    class="dropdown-item hidden-lg-up"
                    target="_blank"
                    rel="noopener"
                  >
                    {{ I18n.t('application.tooltip.preview') }}
                  </a>
                </div>
              </div>
              <label
                :class="['btn-check-resume', { 'disabled-label': resume.errorMessages.length > 0 }]"
                data-html="true"
                data-toggle="tooltip"
                :data-title="validationErrorMessages(resume)"
              >
                <input
                  v-model="selectedResume"
                  :value="resume.id"
                  :disabled="resume.errorMessages.length > 0"
                  class="resume-selector"
                  type="radio"
                >
                <span>{{ I18n.t('actions.select') }}</span>
              </label>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-else class="alert alert-light alert-resume-empty">
      <h6>
        <SvgIcon name="ic-info" html-class="ic-24 ic-alert" />
        {{ I18n.t('job_application.select_resume_step.no_resume') }}
      </h6>
      <h6>
        <a href="/resumes/new">{{ I18n.t('job_application.select_resume_step.register_new_resume') }} →</a>
      </h6>
    </div>

    <span v-if="errors($v.selectedResume) && $v.$dirty" class="error-feedback">
      <SvgIcon html-class="ic-14" name="ic-care-center-14" />
      {{ errors($v.selectedResume) }}
    </span>
  </div>
</template>

<script lang="ts">

import { defineComponent, ref, computed, onMounted, PropType } from '@vue/composition-api';
import { useVuelidate, Validation } from '@vuelidate/core';
import { required, helpers, requiredIf } from '@vuelidate/validators';
import RegistrationGroup from './RegistrationGroup.vue';
import SlottedI18n from '@/components/shared/SlottedI18n.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import SvgIconWithTooltip from '@/components/shared/SvgIconWithTooltip.vue';
import { tooltip } from '@/directives';
import { fetchResumes } from '@/exportables/apis/career/resume.api';
import { Response } from '@/exportables/models/career/job-application.model';
import { RegistrationGroup as RegistrationGroupType } from '@/exportables/models/career/registration.model';
import { ResumeElement } from '@/exportables/models/career/resume.model';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'JobApplicationSelectResumeStep',
  directives: {
    tooltip,
  },
  components: {
    RegistrationGroup,
    SlottedI18n,
    SvgIcon,
    SvgIconWithTooltip,
  },
  props: {
    hasJobPosition: {
      type: Boolean,
      default: false,
    },
    registrationGroups: {
      type: Array as PropType<RegistrationGroupType[]>,
      default: () => [],
    },
    response: {
      type: Object as PropType<Response>,
      required: true,
    },
  },
  emits: ['update:updateSelectedResume', 'update:updateRegistrationGroup'],
  setup(props, { emit }) {
    const I18n = useI18n();
    const resumes = ref<ResumeElement[]>([]);
    const requiredRegistrationGroup = computed(() => props.registrationGroups.length > 1 && !props.hasJobPosition);
    const resumeSelectWarningSecondMessage = computed(() => I18n.t('application.message.resume_must_be_immutable_html').split(/[{}]/));
    const registrationGroupId = computed({
      get: () => {
        return props.response.registrationGroupId;
      },
      set: (id) => {
        emit('update:updateRegistrationGroup', id);
      },
    });
    const selectedResume = computed({
      get: () => {
        return props.response.resume?.id;
      },
      set: async (resumeId) => {
        resumes.value = (await fetchResumes()).resumes;
        const resume = resumes.value.find((r) => r.id === resumeId);
        emit('update:updateSelectedResume', resume);
      },
    });

    const editResumePath = (resume: ResumeElement) => `/resumes/${resume.id}/edit`;

    const errors = (field: Validation) => field.$errors.length ? field.$errors[0].$message : '';

    const isValidated = () => {
      $v.value.$touch();
      return !$v.value.$invalid;
    };

    const isProfile = (resume: ResumeElement) => resume.isProfile;

    const setRegistrationGroupId = (id: number) => emit('update:updateRegistrationGroup', id);

    const showResumePath = (resume: ResumeElement) => `/resumes/${resume.id}`;

    const validationErrorMessages = (resume: ResumeElement) => {
      if (resume.errorMessages.length === 0) return [];
      return `${I18n.t('resume.error.invalid')} <br> ${resume.errorMessages}`;
    };

    const rules = {
      selectedResume: {
        id: helpers.withMessage(I18n.t('job_application.select_resume_step.must_select_resume'), required),
      },
      registrationGroupId: {
        requiredRegistrationGroupId: helpers.withMessage(I18n.t('job_application.contact_step.no_registration_group'), requiredIf(() => requiredRegistrationGroup.value)),
      },
    };
    const $v = useVuelidate(rules, { selectedResume, registrationGroupId });

    onMounted(async () => {
      resumes.value = (await fetchResumes()).resumes;
    });

    return {
      $v,
      I18n,
      editResumePath,
      errors,
      isProfile,
      isValidated,
      registrationGroupId,
      resumes,
      resumeSelectWarningSecondMessage,
      requiredRegistrationGroup,
      selectedResume,
      setRegistrationGroupId,
      showResumePath,
      validationErrorMessages,
    };
  },
});
</script>
