<template>
  <Modal
    :title="`${I18n.t(`section.${type}.title`)} ${I18n.t('resume.modal.guide.example')}`"
    @hide="closeModal"
  >
    <template #body>
      <div class="guide-tip">
        <h5>
          {{ I18n.t(`resume.${type}`) }} {{ I18n.t('resume.modal.guide.tip') }}
        </h5>

        <ul>
          <li v-for="tip in description.tips" :key="tip">
            {{ tip }}
          </li>
        </ul>
      </div>

      <div class="guide-example">
        <h5>
          {{ I18n.t('resume.modal.guide.example') }}
        </h5>

        <ul :class="type === 'project' && 'project'">
          <li v-for="example in description.examples" :key="example">
            {{ example }}
          </li>
        </ul>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import Modal from '@/components/shared/Modal2.vue';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'ResumeGuideModal',
  components: {
    Modal,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    description: {
      type: Object,
      required: true,
    },
  },
  emits: ['hide'],
  setup(_, { emit }) {
    const I18n = useI18n();

    const closeModal = () => emit('hide');

    return {
      I18n: { t: I18n.t },
      closeModal,
    };
  },
});
</script>
