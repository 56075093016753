<template>
  <Modal
    :title="I18n.t('terms.print_option')"
    class="modal-print-option"
    @hide="closeModal"
  >
    <template #body>
      <h5>
        {{ I18n.t('print.modal.title') }}
      </h5>

      <form class="modal-radio__selection">
        <div
          v-for="(option, index) in PRINT_OPTIONS"
          :key="option.key"
          class="col-item"
        >
          <label :for="`salary_masking_${option.masking}`" class="modal-radio__label">
            <input
              :id="`salary_masking_${option.masking}`"
              :value="option.masking"
              :checked="option.masking === isMasked"
              type="radio"
              name="salary_masking"
              @change="isMasked = option.masking"
            >
            <span class="radio-circle">
              <SvgIcon name="ic-check-14" html-class="ic-14" />
            </span>

            <div class="modal-radio__card">
              <img :src="getOptionImage(index + 1)">
              <h5 class="mb-1 font-weight-700">
                {{ I18n.t(`print.modal.options.${option.key}.title`) }}</h5>
              <h6 class="color-dark-gray">
                {{ I18n.t(`print.modal.options.${option.key}.description`) }}</h6>
            </div>
          </label>
        </div>
      </form>
    </template>

    <template #footer>
      <button
        class="btn btn-light"
        type="button"
        data-dismiss="modal"
        aria-label="Close"
        @click="closeModal"
      >
        {{ I18n.t('buttons.cancel') }}
      </button>
      <button class="btn btn-primary" @click="handlePrint">
        {{ I18n.t('terms.print') }}
      </button>
    </template>
  </Modal>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  ref,
  unref,
} from '@vue/composition-api';
import { ResumeShowStates, getResume } from '../../hooks/state';
import constants from '../../resume.constant';
import Modal from '@/components/shared/Modal2.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { useI18n } from '@/helpers/i18n';
import { useRouter } from '@/router/career';

const { PRINT_OPTIONS } = constants;

export default defineComponent({
  name: 'ResumePrintOptionModal',
  components: {
    Modal,
    SvgIcon,
  },
  emits: ['hide'],
  setup(_, { emit }) {
    const state = inject<ResumeShowStates>('state')!;
    const router = useRouter();
    const { t } = useI18n();
    const isMasked = ref(false);
    const resume = computed(() => getResume(state));
    const printPath = computed(() => `/resumes/${resume.value?.id || ''}/print`);

    const closeModal = () => emit('hide');

    const handlePrint = () => {
      const unreffedPrintPath = unref(printPath);
      router.push(`${unreffedPrintPath}${unref(isMasked) ? '?salary_masking=true' : ''}`);
      closeModal();
    };

    return {
      I18n: { t },
      PRINT_OPTIONS,
      closeModal,
      emit,
      getOptionImage,
      handlePrint,
      isMasked,
    };
  },
});

function getOptionImage(index = 1) {
  return require(`~/images/print-resume-0${index}.png`);
}
</script>
