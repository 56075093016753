<template>
  <Card
    v-if="isLoadable"
    :is-shrinked="true"
    class="timeline"
  >
    <template #title>
      <h4 class="resume-card-header-title">
        {{ I18n.t('section.timeline') }}

        <span
          v-if="isEditable && !isBusinessPage"
          v-tooltip:top="{
            message: I18n.t('resume.timeline.tooltip'),
            width: '200px',
            isMountBody: true,
            style: { fontWeight: 400, wordBreak: 'keep-all' },
          }"
          class="btn-help dark"
        >
          <SvgIcon name="ic-help-full" html-class="ic-20" />
        </span>
      </h4>
    </template>

    <template #body>
      <div class="resume-card-item">
        <div class="timeline-wrap">
          <div v-if="isVisible('experiences') && totalEmploymentDuration" class="timeline-period">
            <span v-if="devEmploymentDuration" class="resume-card-item-label">
              {{ I18n.t('resume.timeline.dev_employeement') }} {{ durationYearAndMonth(devEmploymentDuration) }}
            </span>
            <span v-else class="resume-card-item-label">
              {{ I18n.t('resume.timeline.total_employeement') }} {{ durationYearAndMonth(totalEmploymentDuration) }}
            </span>
            <span v-if="devEmploymentDuration && totalEmploymentDuration !== devEmploymentDuration" class="resume-card-item-period">
              ({{ I18n.t('resume.timeline.total_employeement') }} {{ durationYearAndMonth(totalEmploymentDuration) }})
            </span>
          </div>

          <div class="grid-timeline-wrap">
            <div class="grid-timeline-labels">
              <div
                v-for="year in timelineYears"
                :key="year"
                class="label-year"
              >
                {{ year }}
              </div>
            </div>

            <div v-if="isGridable" class="grid-timeline">
              <template v-if="isVisible('educations')">
                <template v-for="education in resume.educations">
                  <div
                    v-if="education.startAt"
                    :key="education.title"
                    :style="makeTimelineStyle(education.startAt, education.endAt)"
                    :data-original-title="makeTimelineTitle(education.name, education.major)"
                    :data-content="makeTimelineContent(education.startAt, education.endAt)"
                    class="event-item education"
                    data-html="true"
                    data-toggle="popover"
                    data-placement="top"
                  >
                    {{ education.name }}
                    <span> {{ education.major }}</span>
                  </div>
                </template>
              </template>

              <template v-if="isVisible('experiences')">
                <template v-for="experience in resume.experiences">
                  <div
                    v-if="experience.startAt"
                    :key="experience.title"
                    :style="makeTimelineStyle(experience.startAt, experience.endAt)"
                    :data-original-title="makeTimelineTitle(experience.name, experience.role)"
                    :data-content="makeTimelineContent(experience.startAt, experience.endAt)"
                    class="event-item experience"
                    data-html="true"
                    data-toggle="popover"
                    data-placement="top"
                  >
                    {{ experience.name }}
                    <span> {{ experience.role }}</span>
                  </div>
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  toRefs,
  unref,
} from '@vue/composition-api';
import { ResumeShowStates, getResume } from '../hooks/state';
import Card from './Card.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { tooltip } from '@/directives';
import { OpenSetting } from '@/exportables/models/career/job-profile.model';
import { DefaultDate } from '@/exportables/types/general.d';
import { subtractMonths, durationYearAndMonth } from '@/helpers/datetime';
import { useI18n } from '@/helpers/i18n';
import { isBusinessPage } from '@/helpers/url';
import { showPublicOption } from '@/pages/career/job-profiles/job-profile.util';
import { useStore } from '@/store/career';

const now = new Date();
const currentYear = now.getFullYear();

const I18n = useI18n();

export default defineComponent({
  name: 'ResumeTimeline',
  components: {
    Card,
    SvgIcon,
  },
  directives: {
    tooltip,
  },
  setup() {
    const state = inject<ResumeShowStates>('state')!;
    const store = useStore();
    const { isEditable, isSharable } = toRefs(store.state.resume);
    const resume = computed(() => getResume(state));
    const isSettingPage = computed(() => unref(isSharable) && !store.getters['resume/isPublicPage']);
    const isGridable = computed(() => {
      const { educations, experiences } = unref(resume);
      return Boolean(educations?.length || experiences?.length);
    });
    const timelineRange = computed(() => unref(resume)?.timelineRange || 8);
    const timelineStartYears = computed(() => currentYear - unref(timelineRange) + 1);
    const timelineYears = computed(() => Array.from({ length: unref(timelineRange) }, (_, index) => unref(timelineStartYears) + index));
    const totalEmploymentDuration = computed(() => unref(resume).employmentDuration);
    const devEmploymentDuration = computed(() => unref(resume).devEmploymentDuration);
    const isEnabled = computed(() => showPublicOption(state?.jobProfile?.openSetting || {}));
    const isLoadable = computed(() => (
      unref(isSharable)
        ? unref(isSettingPage)
          ? isEnabled.value('educations') || isEnabled.value('experiences')
          : unref(isGridable)
        : unref(isGridable)
    ));

    const isVisible = (key: keyof OpenSetting) => (unref(isSharable) && unref(isSettingPage)) ? isEnabled.value(key) : true;

    const makeTimelineStyle = createMakeTimelineStyle(unref(timelineStartYears));

    return {
      ...toRefs(state),
      I18n: { t: I18n.t },
      devEmploymentDuration,
      durationYearAndMonth,
      isBusinessPage: isBusinessPage(),
      isEditable,
      isGridable,
      isLoadable,
      isSharable,
      isVisible,
      makeTimelineContent,
      makeTimelineStyle,
      makeTimelineTitle,
      resume,
      timelineRange,
      timelineStartYears,
      timelineYears,
      totalEmploymentDuration,
    };
  },
});

function makeTimelineTitle(name: string, description: string) {
  return `${name} <span>${description}</span>`;
}

function makeTimelineContent(startAt: DefaultDate, endAt?: DefaultDate) {
  const newEndAt = endAt || new Date();
  const totalMonths = subtractMonths(startAt, newEndAt);

  return `
    ${durationYearAndMonth(totalMonths)}
    <h6 class="text-mute">${`${I18n.l('date.formats.year_month', startAt as string)} ~ ${endAt ? `${I18n.l('date.formats.year_month', endAt)}` : I18n.t('datetime.now')}`}</h6>
  `;
}

function createMakeTimelineStyle(timelineStartYears: number) {
  return (startAt: DefaultDate, endAt: DefaultDate) => {
    const timelineStartDatetime = new Date(timelineStartYears, 0, 1);
    const newEndAt = endAt || now;
    const isFuture = Math.sign(subtractMonths(now, newEndAt)) === 1;
    const startPoint = subtractMonths(timelineStartDatetime, startAt);
    const endPoint = subtractMonths(timelineStartDatetime, isFuture ? now : newEndAt);

    return endPoint > 0 ? { gridColumn: `${startPoint <= 0 ? 1 : startPoint} / ${endPoint}` } : { display: 'none' };
  };
}
</script>
