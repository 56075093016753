export const primaryLocationFilterOptions = [
  'central',
  'north_east',
  'north_west',
  'south_east',
  'south_west',
  'pangyo',
];

export const locationFilterOptions = [
  'central',
  'north_east',
  'north_west',
  'south_east',
  'south_west',
  'pangyo',
  'in_capital',
  'out_capital',
];

export const locationPoints = [
  'sangam',
  'mapo',
  'jongno',
  'euljiro',
  'yeouido',
  'guro',
  'gangnam',
  'jamsil',
  'pangyo',
];
