<template>
  <div class="resume-card-item form">
    <div class="form-group-wrap">
      <label class="form-group-title">
        {{ I18n.t('resume.award') }} {{ index + 1 }}
      </label>

      <div class="form-row">
        <div class="col-12">
          <div class="form-group form-required">
            <label>
              {{ I18n.t('section.award.name') }}
            </label>
            <input
              v-model.trim="newAward.title"
              :placeholder="I18n.t('terms.input')"
              class="form-control"
              :class="inputValidationClass($v.title)"
              type="text"
              name="name"
              autocomplete="off"
              @blur="$v.title.$touch"
            >
          </div>
        </div>

        <div class="col-date col-6">
          <Datepicker
            :value="newAward.awardedAt"
            :label="I18n.t('section.award.awarded_at')"
            :class-name="inputValidationClass($v.awardedAt)"
            class="form-group form-required"
            name="awardedAt"
            @initialize="handleInitialize"
            @select="setAwardedAt"
          />
        </div>

        <div class="col-12">
          <div class="form-group">
            <label>
              {{ I18n.t('terms.link') }}
            </label>
            <input
              v-model.trim="newAward.link"
              class="form-control"
              :class="inputValidationClass($v.link)"
              type="text"
              name="link"
              autocomplete="off"
              placeholder="https://"
              @blur="$v.link.$touch"
            >
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label>
              {{ I18n.t('terms.description') }}
            </label>
            <textarea
              v-model.trim="newAward.description"
              :placeholder="I18n.t('section.placeholder', { type: I18n.t('resume.award') })"
              class="form-control"
              rows="3"
              autocomplete="off"
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="form-btn-wrap-lg">
      <button class="btn btn-md btn-light" @click="handleCancelEdit">
        {{ I18n.t('actions.cancel') }}
      </button>
      <button class="btn btn-md btn-primary" @click="handleSubmit">
        {{ I18n.t('actions.save') }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import {
  PropType,
  Ref,
  defineComponent,
  inject,
  reactive,
  ref,
  unref,
} from '@vue/composition-api';
import { useVuelidate } from '@vuelidate/core';
import { required, url } from '@vuelidate/validators';
import { handleUpdateForm } from '../../hooks/form';
import { ResumeShowStates, getResume } from '../../hooks/state';
import { inputValidationClass } from '../../resume.util';
import Datepicker from '../Datepicker.vue';
import { ResumeAward } from '@/exportables/models/career/resume.model';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';
import { cloneArray } from '@/helpers/utils';

type AwardKey = keyof ResumeAward;

export default defineComponent({
  name: 'ResumeAwardForm',
  components: {
    Datepicker,
  },
  props: {
    isAdded: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
    award: {
      type: Object as PropType<ResumeAward>,
      required: true,
    },
  },
  setup(props) {
    const changeEditState = inject<Function>('changeEditState')!;
    const { newItem } = inject<{ newItem: Ref<ResumeAward> }>('form')!;
    const state = inject<ResumeShowStates>('state')!;
    const newAward = reactive(
      new ResumeAward(props.award),
    );
    const isExpirable = ref(
      Boolean(props.award.endAt),
    );
    const rules = {
      title: { required },
      awardedAt: { required },
      link: { url },
    };
    const I18n = useI18n();
    const $v = useVuelidate(rules, newAward);

    const setAward = (key: AwardKey) => (newValue: ResumeAward[AwardKey]) => {
      Object.assign(
        newAward,
        { [key]: newValue },
      );
    };

    const setAwardedAt = setAward('awardedAt');
    const setEndAt = setAward('endAt');

    const handleToggle = () => {
      Object.assign(
        isExpirable,
        { value: !isExpirable.value },
      );
      unref(isExpirable) && setEndAt('');
    };

    const handleInitialize = () => {
      setAwardedAt('');
      setEndAt('');
    };

    const handleCancelEdit = () => {
      props.isAdded && Object.assign(newItem, { value: null });
      changeEditState(false);
    };

    const handleSubmit = () => {
      const unrefed$v = unref($v);
      const resume = getResume(state);
      const awards = cloneArray(resume.awards);

      unrefed$v.$touch();

      if (unrefed$v.$error || unrefed$v.$invalid) {
        appendToast(I18n.t('resume.error.form'));
        return;
      }

      props.isAdded ? awards.push(newAward) : awards.splice(props.index, 1, newAward);

      handleUpdateForm(state, { awards });
      changeEditState(false);

      Object.assign(newItem, { value: null });
    };

    return {
      $v,
      I18n,
      handleCancelEdit,
      handleInitialize,
      handleSubmit,
      handleToggle,
      inputValidationClass,
      isExpirable,
      newAward,
      setAwardedAt,
      setEndAt,
    };
  },
});
</script>
