export default {
  SET_LOGIN: 'SET_LOGIN',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SET_FEED_TAB: 'SET_FEED_TAB',
  SET_POSTS: 'SET_POSTS',
  SET_TOTAL_PAGE: 'SET_TOTAL_PAGE',
  SET_USER_NAME: 'SET_USER_NAME',
  SET_USER_FEED: 'SET_USER_FEED',
  SET_USER_FEED_DEFAULT: 'SET_USER_FEED_DEFAULT',
  SET_BACKUP_USER_FEED: 'SET_BACKUP_USER_FEED',
  SET_FEED_SETTING_TAB: 'SET_FEED_SETTING_TAB',
  REMOVE_USER_FEED_TAG: 'REMOVE_USER_FEED_TAG',
  ADD_USER_FEED_TAG: 'ADD_USER_FEED_TAG',
} as const;
