<template>
  <section v-if="isEditable || (repository && isVisible)" class="repository">
    <div>
      <h5 class="resume-section-title">
        {{ I18n.t('section.repository.link') }}
        <span
          v-if="isEditable"
          v-tooltip:top="{
            message: I18n.t('resume.repository.tooltip'),
            width: '180px',
            isMountBody: true,
            style: { fontWeight: 400, wordBreak: 'keep-all' }
          }"
          class="btn-help dark"
        >
          <SvgIcon name="ic-help-full" html-class="ic-20" />
        </span>

        <template v-if="isEditable">
          <button
            v-if="isLinked"
            class="btn btn-sm btn-light btn-github-link linked"
            @click="handleToggleGithub($event, true)"
          >
            <SvgIcon name="ic-github" html-class="ic-18" />
            {{ title }}
          </button>

          <a
            v-else
            href="/users/auth/github"
            data-method="post"
            @click="handleToggleGithub($event, false)"
          >
            <button class="btn btn-sm btn-light btn-github-link">
              <SvgIcon name="ic-github-14" html-class="ic-16" />
              {{ title }}
            </button>
          </a>
        </template>
      </h5>

      <p v-if="repository" class="repository-address">
        <SvgIcon name="ic-github-14" html-class="ic-14" />
        <a
          :href="repository"
          target="_blank"
          rel="noopener"
        >
          {{ repository }}
        </a>
      </p>
    </div>

    <GitStat v-if="isLinked" :is-editable="isEditable" />

    <Modal
      v-if="isLinked && isOpened"
      :title="title"
      @hide="isOpened = false"
    >
      <template #body>
        <p>
          {{ I18n.t('resume.modal.repository.title') }}
        </p>
      </template>

      <template #footer>
        <div class="flex-end">
          <button class="btn btn-md btn-light" @click="isOpened = false">
            {{ I18n.t('actions.cancel') }}
          </button>
          <a
            :href="unlinkPath"
            :class="style.margin"
            data-method="delete"
          >
            <button class="btn btn-md btn-danger">
              {{ I18n.t('terms.unlink') }}
            </button>
          </a>
        </div>
      </template>
    </Modal>
  </section>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  ref,
  toRefs,
  unref,
  useCssModule,
} from '@vue/composition-api';
import { ResumeShowState, getResume } from '../hooks/state';
import GitStat from './GitStat.vue';
import Modal from '@/components/shared/Modal2.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { tooltip } from '@/directives';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';
import { showPublicOption } from '@/pages/career/job-profiles/job-profile.util';
import { useStore } from '@/store/career';

export default defineComponent({
  name: 'ResumeRepository',
  components: {
    GitStat,
    Modal,
    SvgIcon,
  },
  directives: {
    tooltip,
  },
  setup() {
    const state = inject<ResumeShowState>('state')!;
    const I18n = useI18n();
    const store = useStore();
    const { isEditable, isSharable } = toRefs(store.state.resume);
    const isOpened = ref(false);
    const authorization = computed(() => getResume(state).user?.authorization);
    const isPublicPage = computed(() => store.getters['resume/isPublicPage']);
    const isEditing = computed(() => store.getters['resume/isEditing']);
    const isLinked = computed(() => Boolean(unref(authorization)?.id));
    const isVisible = computed(() => {
      const isTrue = showPublicOption(state?.jobProfile?.openSetting || {});
      return (unref(isSharable) || unref(isPublicPage)) ? isTrue('repository') : true;
    });
    const unlinkPath = computed(() => unref(isLinked) ? `/omniauth_callbacks/${unref(authorization)?.id}` : '');
    const title = computed(() => I18n.t(`resume.repository.${unref(isLinked) ? 'unlink' : 'link'}`));
    const repository = computed(() => unref(authorization)?.url || '');
    const style = useCssModule();

    const handleToggleGithub = (e: Event, destroy: boolean) => {
      if (unref(isEditing)) {
        (e.target as HTMLElement).blur();
        appendToast(I18n.t('resume.repository.alert'));
        return;
      }

      destroy && (isOpened.value = true);
    };

    return {
      I18n: { t: I18n.t },
      handleToggleGithub,
      isEditable,
      isEditing,
      isLinked,
      isOpened,
      isSharable,
      isVisible,
      repository,
      style,
      title,
      unlinkPath,
    };
  },
});
</script>

<style lang="scss" module>
.margin {
  margin-left: 8px;
}

.alert {
  color: #F44336;
  font-size: 12px;
  line-height: 2;
  margin-left: 4px;
}
</style>
