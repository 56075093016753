<template>
  <Card
    v-if="isEditable || isViewable"
    class="test-results"
    :is-editable="isEditable"
    :is-shrinked="true"
  >
    <template #title>
      <h4 class="resume-card-header-title">
        {{ I18n.t('resume.test_results.title') }}
        <span
          v-if="isEditable"
          v-tooltip:top="{
            message: I18n.t('resume.test_results.help'),
            width: '210px',
            isMountBody: true,
            style: { fontWeight: 400, wordBreak: 'keep-all' },
          }"
          class="btn-help dark"
        >
          <SvgIcon name="ic-help-full" html-class="ic-20" />
        </span>
      </h4>
    </template>

    <template #control>
      <button class="btn-light resume-card-btn-add" @click="openModal('TestResults')">
        <SvgIcon name="ic-add" html-class="ic-24" />
        <span>
          {{ I18n.t('actions.add') }}
        </span>
      </button>
    </template>

    <template #body>
      <div v-if="tokens.tryouts.length" class="test-box">
        <TryoutResult
          v-for="(token, index) in tokens.tryouts"
          :key="`${token.title}-${index}`"
          :token="token"
        />
      </div>

      <div v-if="tokens.assignments.length" class="test-box">
        <AssignmentResult
          v-for="(token, index) in tokens.assignments"
          :key="`${token.title}-${index}`"
          :token="token"
        />
      </div>

      <div v-if="tokens.skillCheckAssignments.length" class="test-box">
        <SkillCheckAssignmentResult
          v-for="(token, index) in tokens.skillCheckAssignments"
          :key="`${token.title}-${index}`"
          :token="token"
        />
      </div>

      <div v-if="!resume.hideSkillChecks && skillChecks.length" class="test-box">
        <SkillChecksResult :skill-checks="skillChecks" />
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  toRef,
  unref,
} from '@vue/composition-api';
import { ResumeShowStates, getResume } from '../hooks/state';
import Card from './Card.vue';
import AssignmentResult from './Result/Assignment.vue';
import SkillCheckAssignmentResult from './Result/SkillCheckAssignment.vue';
import SkillChecksResult from './Result/SkillChecks.vue';
import TryoutResult from './Result/Tryout.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { tooltip } from '@/directives';
import { useI18n } from '@/helpers/i18n';
import { useStore } from '@/store/career';

export default defineComponent({
  name: 'ResumeTestResults',
  components: {
    AssignmentResult,
    Card,
    SkillCheckAssignmentResult,
    SkillChecksResult,
    SvgIcon,
    TryoutResult,
  },
  directives: {
    tooltip,
  },
  setup() {
    const openModal = inject<Function>('openModal')!;
    const state = inject<ResumeShowStates>('state')!;
    const I18n = useI18n();
    const store = useStore();
    const tokens = toRef(state, 'tokens');
    const resume = computed(() => getResume(state));
    const skillChecks = computed(() => unref(resume)?.user?.passedSkillChecks || []);
    const isViewable = computed(() => Boolean(
      Object.values(unref(tokens)).reduce((acc, cur) => acc += cur?.length || 0, 0) ||
      unref(skillChecks)?.length && !unref(resume)?.hideSkillChecks,
    ));
    const isEditable = computed(() => store.state.resume.isEditable);

    return {
      I18n: { t: I18n.t },
      isEditable,
      isViewable,
      openModal,
      resume,
      skillChecks,
      tokens,
    };
  },
});
</script>
