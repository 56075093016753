<script lang="tsx">
import { defineComponent } from '@vue/composition-api';
import { useState } from '../../../hooks/show/state';
import Modal from '@/components/shared/Modal2.vue';
import { deleteRegistration } from '@/exportables/apis/career/registration.api';
import { useI18n } from '@/helpers/i18n';
import { useRouter } from '@/router/career';

export default defineComponent({
  name: 'CancelRegistration',
  emits: ['hide'],
  setup(_, { emit }) {
    const I18n = useI18n();
    const router = useRouter();
    const { state } = useState();
    const handleClose = () => emit('hide');
    const handleSubmit = async () => {
      if (state.registration) {
        await deleteRegistration(state.registration.id);
        router.go(0);
      }
    };

    const slots = {
      body: () => (
        <p>
          { I18n.t('competition.show.modal.cancel_registration', { title: state.title }) }
        </p>
      ),
      footer: () => (
        [
          <button
            class="btn btn-light"
            onClick={handleClose}
          >
            { I18n.t('actions.cancel') }
          </button>,
          <button
            class="btn btn-danger"
            onClick={handleSubmit}
          >
            { I18n.t('competition.show.overview.cancel_registration') }
          </button>,
        ]
      ),
    };

    return () => (
      <Modal
        title={I18n.t('competition.show.overview.cancel_registration')}
        scopedSlots={slots}
      />
    );
  },
});
</script>
