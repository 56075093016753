<template>
  <div
    class="tab-pane fade active show"
    role="tabpanel"
    aria-labelledby="tag"
  >
    <Autocomplete
      v-if="category !== 'job_category'"
      :optional-data="{ category }"
      visible-count="post"
      label="아래에 없는 태그는 검색해주세요"
      placeholder="이곳에 검색할 태그를 입력"
      url="/api/tags/search"
      no-result-label="검색결과가 없습니다."
      ul-custom-class="modal-autocomplete"
      @selected="handleSelect"
    />
    <button
      v-else
      :class="{ active: hasAllJobCategoryDefaultTags }"
      class="tag-item"
      @click="handleToggleAllJobCategoryTags"
    >
      <span class="tag-item-name">모든 직군</span>
      <SvgIcon
        v-if="hasAllJobCategoryDefaultTags"
        name="ic-cancel"
        html-class="ic-24"
      />
      <SvgIcon
        v-else
        name="ic-add"
        html-class="ic-24"
      />
    </button>
    <FeedTagButton
      v-for="(tag, index) in tags"
      :key="`${category}_${index}`"
      :category="category"
      :item="tag"
    />
  </div>
</template>

<script lang='ts'>
import { defineComponent, PropType } from '@vue/composition-api';
import { FeedCategory } from '../constants';
import FeedTagButton from './FeedTagButton.vue';
import Autocomplete from '@/components/shared/Autocomplete.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import {
  SearchedTag, UserFeedTag,
} from '@/exportables/models/career/user-feed.model';
import camelCase from '@/plugins/camelCase';

export default defineComponent({
  name: 'UserFeedSearchCategoryTabPane',
  components: {
    FeedTagButton,
    Autocomplete,
    SvgIcon,
  },
  props: {
    category: {
      type: String as PropType<Exclude<FeedCategory, 'user_feed'>>,
      default: '',
    },
    hasAllJobCategoryDefaultTags: {
      type: Boolean,
      required: true,
    },
    tags: {
      type: Array as PropType<UserFeedTag[]>,
      required: true,
    },
  },
  emits: ['select', 'toggle', 'toggleAllJobCategoryTags'],
  setup(_, { emit }) {
    const handleSelect = (tag: SearchedTag) => emit('select', tag);
    const handleToggleAllJobCategoryTags = () => emit('toggleAllJobCategoryTags');

    return {
      camelCase,
      handleSelect,
      handleToggleAllJobCategoryTags,
    };
  },
});
</script>
