import { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import useSWRV, { IConfig as SWRVConfig } from 'swrv';
import { createClient as createHttpClient } from '@/helpers/http';
import { useResponseStatus } from '@/hooks/useResponseStatus';

const httpClient = createHttpClient();
const { setResponse } = useResponseStatus();

httpClient.interceptors?.response.use(
  (response) => {
    if (!response.data) return response;
    const { data } = response;
    setResponse(response.status, data.message);
    return Object.assign(response, {
      data: camelcaseKeys(data, { deep: true }),
    });
  },
  (error: AxiosError) => {
    const { response: errorResponse } = error;
    errorResponse && setResponse(
      errorResponse.status,
      errorResponse.data.message,
    );
    return Promise.reject(error);
  },
);

const baseConfig: SWRVConfig = {
  revalidateOnFocus: false,
  shouldRetryOnError: false,
};

export function useRequest<T>(
  key: string | (() => string | null) | null,
  params?: any,
  config?: SWRVConfig,
  fetcherWrapper?: (fetcher: () => Promise<T>) => T | Promise<T>,
) {
  return useSWRV<T, AxiosError<T>>(
    key,
    (url) =>
      fetcherWrapper
        ? fetcherWrapper(fetcher(url, params))
        : fetcher(url, params)()
    ,
    { ...baseConfig, ...config },
  );
}

function fetcher(key: string, params?: any) {
  return async () => {
    const { data } = await httpClient.get(key, { params });
    return data;
  };
}
