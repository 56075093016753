<template>
  <div
    id="job-profile-display"
    class="resume__preview"
    :class="isEditable && ['edit', style.edit]"
  >
    <ControlBar v-if="isSettingPage" />

    <div class="resume__content" :class="style.content">
      <EventNotification v-if="isEditable" />

      <Topbar v-if="isEditable" :class="style.topbar" />

      <div :class="style.box">
        <ProfileGuide v-if="isEditable" :is-loading="isLoading" :completion-status="completionStatus" :score="completionScore" />
      </div>

      <FloatingButton
        v-if="!isEditable && !isSharable && isPrintable"
        :tooltip-title="I18n.t('print.title', { type: I18n.t('terms.job_profile') })"
      >
        <template #body>
          <button class="btn btn-svg btn-view-print" @click="handleClickPrintButton">
            <SvgIcon name="ic-print" html-class="ic-24" />
          </button>
        </template>
      </FloatingButton>

      <div :class="style.box">
        <div class="resume__summary" :class="[isSettingPage && 'shrinked', style.personalInfo]">
          <PersonalInformation
            v-skeleton="isLoading"
            :is-top-programmers="state.jobProfile.isTopProgrammers"
            :open-setting="state.jobProfile.openSetting"
            :resume="state.jobProfile.resume"
          />
          <Repository v-skeleton="isLoading" />
          <Stacks v-if="isVisible('primaryTags') || isVisible('secondaryTags')" v-skeleton="isLoading" />
          <JobCategories v-if="isEditable" v-skeleton="isLoading" />

          <footer v-if="!isLoading && !isEditable">
            <a class="link-brand" href="https://career.programmers.co.kr/">
              {{ I18n.t('career_service_name') }}
            </a>
          </footer>
        </div>

        <div class="resume__detail" :class="[isSettingPage && 'shrinked', style.detailInfo]">
          <Timeline v-skeleton="isLoading" />
          <Experiences v-if="isVisible('experiences')" v-skeleton="isLoading" />
          <Educations v-if="isVisible('educations')" v-skeleton="isLoading" />
          <Projects v-if="isVisible('projects')" v-skeleton="isLoading" />
          <Awards v-if="isVisible('awards')" v-skeleton="isLoading" />
          <Certificates v-if="isVisible('certificates')" v-skeleton="isLoading" />
          <Publications v-if="isVisible('publications')" v-skeleton="isLoading" />
          <TestResults v-if="isVisible('testTokens')" v-skeleton="isLoading" />
          <Activities v-if="isVisible('activities')" v-skeleton="isLoading" />
          <ForeignLanguages v-if="isVisible('foreignLanguages')" v-skeleton="isLoading" />
          <Attachment v-if="isVisible('resumeAttachment')" v-skeleton="isLoading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onBeforeUnmount,
  provide,
  ref,
  watch,
  unref,
  useCssModule,
} from '@vue/composition-api';
import ControlBar from '../components/ControlBar.vue';
import EventNotification from '../components/EventNotification.vue';
import JobCategories from '../components/JobCategories.vue';
import ProfileGuide from '../components/ProfileGuide.vue';
import { useState } from '../hooks/show/state';
import { showPublicOption } from '../job-profile.util';
import FloatingButton from '@/components/shared/FloatingButton.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { skeleton } from '@/directives';
import { fetchJobProfile, fetchPublicProfile } from '@/exportables/apis/career/job-profile.api';
import { OpenSetting } from '@/exportables/models/career/job-profile.model';
import { dispatchHackleViewEvent } from '@/exportables/services/useHackle';
import { useI18n } from '@/helpers/i18n';
import { useLayout } from '@/helpers/layout';
import { useModal } from '@/helpers/modal';
import Activities from '@/pages/career/resumes/components/Activities.vue';
import Attachment from '@/pages/career/resumes/components/Attachment.vue';
import Awards from '@/pages/career/resumes/components/Awards.vue';
import Certificates from '@/pages/career/resumes/components/Certificates.vue';
import Educations from '@/pages/career/resumes/components/Educations.vue';
import Experiences from '@/pages/career/resumes/components/Experiences.vue';
import ForeignLanguages from '@/pages/career/resumes/components/ForeignLanguages.vue';
import PersonalInformation from '@/pages/career/resumes/components/PersonalInformation.vue';
import Projects from '@/pages/career/resumes/components/Projects.vue';
import Publications from '@/pages/career/resumes/components/Publications.vue';
import Repository from '@/pages/career/resumes/components/Repository.vue';
import Stacks from '@/pages/career/resumes/components/Stacks.vue';
import TestResults from '@/pages/career/resumes/components/TestResults.vue';
import Timeline from '@/pages/career/resumes/components/Timeline.vue';
import Topbar from '@/pages/career/resumes/components/Topbar.vue';
import { usePrint } from '@/pages/career/resumes/hooks/print';
import constants from '@/pages/career/resumes/resume.constant';
import { useStore } from '@/store/career';
import {
  SET_IS_EDITABLE,
  SET_IS_SHARABLE,
  SET_PAGE_TYPE,
  SET_RESUME,
} from '@/store/career/resume/mutation-types';

const { PUBLIC } = constants;
const I18n = useI18n();

export default defineComponent({
  name: 'JobProfileShow',
  components: {
    Activities,
    Attachment,
    Awards,
    Certificates,
    ControlBar,
    Educations,
    EventNotification,
    Experiences,
    FloatingButton,
    ForeignLanguages,
    JobCategories,
    PersonalInformation,
    ProfileGuide,
    Projects,
    Publications,
    Repository,
    Stacks,
    SvgIcon,
    TestResults,
    Timeline,
    Topbar,
  },
  directives: {
    skeleton,
  },
  async beforeRouteEnter(to, _, next) {
    const { coverName } = to.params;

    if (coverName) {
      const { jobProfile } = await fetchPublicProfile(coverName);

      if (jobProfile.resume.name) {
        next();
        return;
      }

      next('/404');
    }

    next();
  },
  props: {
    coverName: {
      type: String,
      required: false,
    },
    id: {
      type: Number,
      required: false,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isSharable: {
      type: Boolean,
      default: false,
    },
  },
  metaInfo() {
    const title = (
      this.coverName && `${I18n.t('topbar.nav_link.public_profile', { name: this.name })}` ||
      `${I18n.t('topbar.nav_link.profile')} | ${I18n.t('service_name')}`
    );
    return {
      title: title,
      robots: 'noindex',
    };
  },
  setup(props) {
    const style = useCssModule();
    const { isLoading, setFetcher, setState, state } = useState();
    const store = useStore();
    const modal = useModal();
    const print = usePrint(state);
    const isSettingPage = computed(() => props.isSharable && !props.id && !props.coverName);
    const isSharable = computed(() => props.isSharable);
    const useRailsLayout = computed(() => props.isEditable && !props.isSharable);
    const name = computed(() => state.jobProfile.resume.name);
    const completionStatus = computed(() => {
      const { educations, experiences, projects, primaryTags } = state.jobProfile.resume;
      const { jobCategories } = state.jobProfile;

      return {
        primaryTags: !!primaryTags.length,
        jobCategories: !!jobCategories.length,
        experiences: !!experiences.length,
        educations: !!educations.length,
        projects: !!projects.length,
      };
    });
    const completionScore = computed(() => store.state.resume.resume.score);

    const isVisible = (key: keyof OpenSetting) => {
      const isTrue = showPublicOption(state.jobProfile.openSetting!);
      return unref(isSharable) ? isTrue(key) : true;
    };

    const openModal = async (modalName: string, props?: any) => {
      const { default: Component } = await import(`@/pages/career/resumes/components/modal/${modalName}.vue`);
      modal.show(Component, props, undefined, { provide: { state, store } });
    };

    const unwatch = useLayout({
      breadcrumb: useRailsLayout,
      footer: useRailsLayout,
      jobbar: ref(false),
      topbar: useRailsLayout,
    });

    setFetcher({
      fetchJobProfile: async () => setState(
        await fetchJobProfile(),
      ),
    });

    provide('isLoading', isLoading);
    provide('modal', modal);
    provide('openModal', openModal);
    provide('state', state);

    watch(
      () => [props.isEditable, props.isSharable],
      ([isEditable, isSharable]) => {
        store.commit(`resume/${SET_IS_EDITABLE}`, isEditable);
        store.commit(`resume/${SET_IS_SHARABLE}`, isSharable);
        isSharable && !unref(isSettingPage) && store.commit(`resume/${SET_PAGE_TYPE}`, PUBLIC);
      },
      { immediate: true },
    );

    watch(
      state,
      (state) => {
        store.commit(`resume/${SET_RESUME}`, state.jobProfile.resume);
      },
    );

    onMounted(async () => {
      isLoading.value = true;

      const jobProfile = (props.isSharable && props.coverName) ? await fetchPublicProfile(props.coverName!) : await fetchJobProfile(props.id);
      setState(
        Object.assign(
          jobProfile,
        ),
      );

      isLoading.value = false;

      if (props.isEditable) {
        dispatchHackleViewEvent('career_profile_viewed');
      }
    });

    onBeforeUnmount(unwatch);

    return {
      ...print,
      I18n: { t: I18n.t },
      style,
      isLoading,
      isSettingPage,
      isVisible,
      name,
      state,
      completionStatus,
      completionScore,
    };
  },
});
</script>

<style lang="scss" module>
@use "~/stylesheets/functions";
@use "~/stylesheets/variables";

.edit {
  > .content {
    max-width: functions.rem-calc(1200);
    background: #f9fafb;

    > .topbar {
      margin: functions.rem-calc(40 20 0);
      padding: functions.rem-calc(0 0 24 0);
      border-bottom: none;
    }
  }

  .box {
    background: variables.$white-color;
    border: 1px solid #f2f2f2;
    border-radius: functions.rem-calc(16);
    margin: functions.rem-calc(0 20);
  }

  .box + .box {
    margin-top: functions.rem-calc(16);
  }

  .personalInfo {
    padding: functions.rem-calc(40 80 40 40);

    > section {
      margin: 0;
      padding: functions.rem-calc(24 8);
    }
  }

  .detailInfo {
    padding: functions.rem-calc(40 24 40 0);
  }
}

@media (variables.$xl-down) {
  .edit {
    .personalInfo {
      padding: functions.rem-calc(40 16 40 16);

      > section {
        padding: functions.rem-calc(24 4);
      }
    }

    .detailInfo {
      padding: functions.rem-calc(40 16 40 16);
    }
  }
}

@media (variables.$md-down) {
  .edit {
    > .content {
      > .topbar {
        margin: functions.rem-calc(24 20 0);
      }
    }

    .box {
      border-left: none;
      border-right: none;
      border-radius: 0;
      margin: 0;

      > * {
        padding-left: functions.rem-calc(16);
        padding-right: functions.rem-calc(16);
      }
    }
  }
}
</style>
