<template>
  <Card
    v-if="isEditable || foreignLanguages.length"
    :is-editable="isEditable"
    :is-shrinked="true"
    :title="I18n.t('resume.foreign_language')"
    @add-item="handleAddItem"
  >
    <template #body>
      <ForeignLanguage
        v-if="newItem"
        ref="newFormRef"
        :index="foreignLanguages.length"
        :info="newItem"
        :is-added="true"
        type="foreignLanguage"
      />
      <ForeignLanguage
        v-for="(language, index) in foreignLanguages"
        :key="language.index"
        :index="index"
        :info="language"
        type="foreignLanguage"
      />
    </template>
  </Card>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  provide,
} from '@vue/composition-api';
import { useFocusInputOnMount, useForm } from '../hooks/form';
import { ResumeShowState, getResume } from '../hooks/state';
import Card from './Card.vue';
import ForeignLanguage from './Index.vue';
import { ResumeForeignLanguage } from '@/exportables/models/career/resume.model';
import { useI18n } from '@/helpers/i18n';
import { useStore } from '@/store/career';

export default defineComponent({
  name: 'ResumeForeignLanguages',
  components: {
    Card,
    ForeignLanguage,
  },
  setup() {
    const state = inject<ResumeShowState>('state')!;
    const I18n = useI18n();
    const store = useStore();
    const foreignLanguages = computed(() => getResume(state).foreignLanguages);
    const isEditable = computed(() => store.state.resume.isEditable);
    const form = useForm(state, 'foreignLanguages', ResumeForeignLanguage);
    const { ref: newFormRef } = useFocusInputOnMount();

    provide('form', form);

    return {
      ...form,
      I18n: { t: I18n.t },
      foreignLanguages,
      isEditable,
      newFormRef,
    };
  },
});
</script>
