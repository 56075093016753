<template>
  <Card
    v-if="isEditable || educations.length"
    id="educations"
    :is-editable="isEditable"
    :title="I18n.t('resume.education')"
    @add-item="handleAddItem"
  >
    <template #body>
      <Education
        v-if="newItem"
        ref="newFormRef"
        :index="educations.length"
        :info="newItem"
        :is-added="true"
        type="education"
      />
      <Education
        v-for="(education, index) in educations"
        :key="education.index"
        :index="index"
        :info="education"
        type="education"
      />
    </template>
  </Card>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  provide,
} from '@vue/composition-api';
import { useFocusInputOnMount, useForm } from '../hooks/form';
import { ResumeShowStates, getResume } from '../hooks/state';
import Card from './Card.vue';
import Education from './Index.vue';
import { ResumeEducation } from '@/exportables/models/career/resume.model';
import { useI18n } from '@/helpers/i18n';
import { useStore } from '@/store/career';

export default defineComponent({
  name: 'ResumeEducations',
  components: {
    Card,
    Education,
  },
  setup() {
    const state = inject<ResumeShowStates>('state')!;
    const I18n = useI18n();
    const store = useStore();
    const educations = computed(() => getResume(state).educations);
    const isEditable = computed(() => store.state.resume.isEditable);
    const form = useForm(state, 'educations', ResumeEducation);
    const { ref: newFormRef } = useFocusInputOnMount();

    provide('form', form);

    return {
      ...form,
      educations,
      I18n: { t: I18n.t },
      isEditable,
      newFormRef,
    };
  },
});
</script>
