<template>
  <div class="resume-card-item form">
    <div class="form-group-wrap">
      <label class="form-group-title">
        {{ I18n.t('section.foreign_language.title') }} {{ index + 1 }}
      </label>

      <div class="form-row">
        <div class="col-4" :class="style.noMargin">
          <div class="form-group form-required">
            <label>
              {{ I18n.t('resume.foreign_language') }}
            </label>

            <select
              class="form-control"
              :class="inputValidationClass($v.name)"
              @blur="$v.name.$touch"
              @change="($event) => (newForeignLanguage.name = $event.target.value)"
            >
              <option
                v-for="language in foreignLanguages"
                :key="language"
                :value="language"
                :selected="language === 'else' && isElseLanguage || language === newForeignLanguage.name"
              >
                {{ I18n.t(`section.foreign_language.${language}`) }}
              </option>
            </select>
          </div>
        </div>

        <div
          v-if="isElseLanguage"
          class="col-6"
        >
          <div class="form-group">
            <label :class="style.noLabel">&nbsp;</label>
            <input
              v-model="newForeignLanguage.name"
              :placeholder="I18n.t('section.foreign_language.placeholder.name')"
              class="form-control"
              type="text"
              autocomplete="off"
            >
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label>
              {{ I18n.t('section.foreign_language.exam') }}
            </label>

            <div class="exam-wrap">
              <ExamForm
                v-for="(exam, idx) in newForeignLanguage.exams"
                :key="exam.index"
                :index="idx"
                :length="newForeignLanguage.exams.length"
                :exam="exam"
                @change-index="handleChangeIndex"
                @delete-item="handleDeletePartItem"
              />
            </div>

            <button
              v-tooltip:top="I18n.t('section.foreign_language.add')"
              class="btn btn-sm form-btn-add-part"
              @click="handleAddPartItem"
            >
              <SvgIcon name="ic-add" html-class="ic-24" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="form-btn-wrap-lg">
      <button class="btn btn-md btn-light" @click="handleCancelEdit">
        {{ I18n.t('actions.cancel') }}
      </button>
      <button class="btn btn-md btn-primary" @click="handleSubmit">
        {{ I18n.t('actions.save') }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  reactive,
  unref,
  useCssModule,
  watch,
} from '@vue/composition-api';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { handleUpdateForm } from '../../hooks/form';
import { ResumeShowStates, getResume } from '../../hooks/state';
import constants from '../../resume.constant';
import { inputValidationClass } from '../../resume.util';
import ExamForm from './ExamForm.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { tooltip } from '@/directives';
import { ResumeForeignLanguage } from '@/exportables/models/career/resume.model';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';
import { swapReactive, cloneArray } from '@/helpers/utils';

const { FOREIGN_LANGUAGES } = constants;

export default defineComponent({
  name: 'ResumeForeignLanguageForm',
  components: {
    ExamForm,
    SvgIcon,
  },
  directives: {
    tooltip,
  },
  props: {
    isAdded: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
    foreignLanguage: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const changeEditState = inject<Function>('changeEditState')!;
    const { newItem } = inject('form')!;
    const state = inject<ResumeShowStates>('state')!;
    const rules = { name: { required } };
    const foreignLanguage = new ResumeForeignLanguage(props.foreignLanguage);
    const newForeignLanguage = reactive<ResumeForeignLanguage>({
      ...foreignLanguage,
      exams: cloneArray(foreignLanguage.exams),
    });
    const I18n = useI18n();
    const $v = useVuelidate(rules, newForeignLanguage);
    const style = useCssModule();
    const isElseLanguage = computed(() => newForeignLanguage.name === 'else' || !FOREIGN_LANGUAGES.includes(newForeignLanguage.name));

    const handleChangeIndex = (targetIndex: number, newIndex: number) => swapReactive(newForeignLanguage.exams, targetIndex, newIndex);

    const handleAddPartItem = () => newForeignLanguage.exams.push({
      name: '',
      degree: '',
    });

    const handleDeletePartItem = (index: number) => {
      $('[data-toggle=tooltip]').tooltip('hide');
      newForeignLanguage.exams.splice(index, 1);
    };

    const handleCancelEdit = () => {
      props.isAdded && Object.assign(newItem, { value: null });
      changeEditState(false);
    };

    const handleSubmit = () => {
      const unreffed$v = unref($v);
      const resume = getResume(state);
      const foreignLanguages = cloneArray(resume.foreignLanguages);

      unreffed$v.$touch();

      if (unreffed$v.$error || unreffed$v.$invalid) {
        appendToast(I18n.t('resume.error.form'));
        return;
      }

      props.isAdded ? foreignLanguages.push(newForeignLanguage) : foreignLanguages.splice(props.index, 1, newForeignLanguage);

      handleUpdateForm(state, { foreignLanguages });
      changeEditState(false);

      Object.assign(newItem, { value: null });
    };

    watch(
      isElseLanguage,
      (isElse, prev) => {
        !prev && isElse && (newForeignLanguage.name = '');
      },
    );

    return {
      $v,
      I18n: { t: I18n.t },
      foreignLanguages: FOREIGN_LANGUAGES,
      inputValidationClass,
      isElseLanguage,
      handleAddPartItem,
      handleCancelEdit,
      handleChangeIndex,
      handleDeletePartItem,
      handleSubmit,
      newForeignLanguage,
      style,
    };
  },
});
</script>

<style lang="scss" module>
@import "~/stylesheets/functions";
@import "~/stylesheets/variables";

@media ($sm-down) {
  .noMargin {
    &,
    .form-group {
      margin-bottom: 0 !important;
    }
  }

  .noLabel {
    display: none;
  }
}
</style>
