import {
  reactive,
  ref,
} from '@vue/composition-api';
import { JobProfile } from '@/exportables/models/career/job-profile.model';
import { Tokens } from '@/exportables/models/career/tokens.model';

export type JobProfileShowFetcher = {
  fetchJobProfile: Function,
};

export type JobProfileShowState = {
  jobProfile: JobProfile,
  tokens: Tokens,
};

const isLoading = ref(false);

const fetcher = reactive<JobProfileShowFetcher>({
  fetchJobProfile: () => {},
});

const state = reactive<JobProfileShowState>({
  jobProfile: JobProfile.new(),
  tokens: Tokens.new(),
});

const setFetcher = (f: Partial<JobProfileShowFetcher>) => Object.assign(fetcher, f);

const setState = (data: Partial<JobProfileShowState>) => {
  const { jobProfile, tokens } = data;

  Object.assign(
    state,
    jobProfile && { jobProfile: JobProfile.new(jobProfile) },
    tokens && { tokens: Tokens.new(tokens) },
  );
};

export const useState = () => ({
  fetcher,
  isLoading,
  setFetcher,
  setState,
  state,
});
