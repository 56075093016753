<template>
  <div class="resume-card-item form">
    <div class="form-group-wrap">
      <label class="form-group-title">
        {{ I18n.t('resume.activity') }} {{ index + 1 }}
      </label>

      <div class="form-row">
        <div class="col-12">
          <div class="form-group form-required">
            <label>
              {{ I18n.t('section.activity.name') }}
            </label>

            <input
              v-model.trim="newActivity.title"
              :placeholder="I18n.t('terms.input')"
              class="form-control"
              :class="inputValidationClass($v.title)"
              type="text"
              autocomplete="off"
              @blur="$v.title.$touch"
            >
          </div>
        </div>

        <div class="col-date col-6">
          <Datepicker
            :value="newActivity.startAt"
            :to="newActivity.endAt || maxDate"
            :label="I18n.t('section.item.start_at')"
            :class-name="inputValidationClass($v.startAt)"
            class="form-group form-required"
            @initialize="handleInitialize"
            @select="setStartAt"
          >
            <Toggle
              :is-active="isInWorked"
              :labels="[I18n.t('terms.in_progress')]"
              @toggle="handleToggle"
            />
          </Datepicker>
        </div>

        <div class="col-date col-6">
          <Datepicker
            v-if="!isInWorked"
            :value="newActivity.endAt"
            :from="newActivity.startAt || minDate"
            :label="I18n.t('section.item.end_at')"
            class="form-group"
            @initialize="() => setEndAt('')"
            @select="setEndAt"
          />
        </div>

        <div class="col-12">
          <div class="form-group">
            <label>
              {{ I18n.t('terms.link') }}
            </label>

            <input
              v-model="newActivity.link"
              class="form-control"
              :class="inputValidationClass($v.link)"
              type="text"
              autocomplete="off"
              placeholder="https://"
              @blur="$v.link.$touch"
            >
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label>
              {{ I18n.t('section.item.description') }}
            </label>

            <textarea
              v-model="newActivity.description"
              :placeholder="
                I18n.t('section.placeholder', {
                  type: I18n.t('resume.activity'),
                })
              "
              class="form-control"
              rows="3"
              autocomplete="off"
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="form-btn-wrap-lg">
      <button class="btn btn-md btn-light" @click="handleCancelEdit">
        {{ I18n.t('actions.cancel') }}
      </button>
      <button class="btn btn-md btn-primary" @click="handleSubmit">
        {{ I18n.t('actions.save') }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import {
  PropType,
  defineComponent,
  inject,
  reactive,
  ref,
  unref,
} from '@vue/composition-api';
import { useVuelidate } from '@vuelidate/core';
import { required, url } from '@vuelidate/validators';
import { handleUpdateForm } from '../../hooks/form';
import { ResumeShowStates, getResume } from '../../hooks/state';
import constants from '../../resume.constant';
import { inputValidationClass } from '../../resume.util';
import Datepicker from '../Datepicker.vue';
import Toggle from '../Toggle.vue';
import { ResumeActivity } from '@/exportables/models/career/resume.model';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';
import { cloneArray } from '@/helpers/utils';

type ActivityKey = keyof ResumeActivity;

const { minDate, maxDate } = constants.DATEPICKER;

export default defineComponent({
  name: 'ResumeActivityForm',
  components: {
    Datepicker,
    Toggle,
  },
  props: {
    isAdded: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
    activity: {
      type: Object as PropType<ResumeActivity>,
      required: true,
    },
  },
  setup(props) {
    const changeEditState = inject<Function>('changeEditState')!;
    const { newItem } = inject('form')!;
    const state = inject<ResumeShowStates>('state')!;
    const newActivity = reactive(new ResumeActivity(props.activity));
    const rules = {
      title: { required },
      startAt: { required },
      link: { url },
    };
    const isInWorked = ref(newActivity.endAt ? false : true);
    const I18n = useI18n();
    const $v = useVuelidate(rules, newActivity);

    const setActivity =
      (key: ActivityKey) => (newValue: ResumeActivity[ActivityKey]) => {
        Object.assign(newActivity, { [key]: newValue });
      };

    const setStartAt = setActivity('startAt');
    const setEndAt = setActivity('endAt');

    const handleToggle = () => {
      Object.assign(isInWorked, { value: !isInWorked.value });
      unref(isInWorked) && setEndAt('');
    };

    const handleInitialize = () => {
      setStartAt('');
      setEndAt('');
    };

    const handleCancelEdit = () => {
      props.isAdded && Object.assign(newItem, { value: null });
      changeEditState(false);
    };

    const handleSubmit = () => {
      const unreffed$v = unref($v);
      const resume = getResume(state);
      const activities = cloneArray(resume.activities);

      unreffed$v.$touch();

      if (unreffed$v.$error || unreffed$v.$invalid) {
        appendToast(I18n.t('resume.error.form'));
        return;
      }

      props.isAdded
        ? activities.push(newActivity)
        : activities.splice(props.index, 1, newActivity);

      handleUpdateForm(state, { activities });
      changeEditState(false);

      Object.assign(newItem, { value: null });
    };

    return {
      $v,
      I18n: { t: I18n.t },
      handleCancelEdit,
      handleInitialize,
      handleSubmit,
      handleToggle,
      isInWorked,
      inputValidationClass,
      maxDate,
      minDate,
      newActivity,
      setEndAt,
      setStartAt,
    };
  },
});
</script>
