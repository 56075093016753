<template>
  <div>
    <FeaturedPosts />
    <PostsContainer />
  </div>
</template>

<script lang="ts">
import {
  defineComponent, onMounted, watch,
} from '@vue/composition-api';
import FeaturedPosts from '../components/FeaturedPosts.vue';
import { FEED_TAB } from '../constants';
import PostsContainer from '../containers/PostsContainer.vue';
import { dispatchHackleViewEvent } from '@/exportables/services/useHackle';
import { useRoute } from '@/router/career';
import { useStore } from '@/store/career';

type RouteQueryFeed = 'false' | 'true' | undefined;
const DEFAULT_PAGE = 1;

type RouteQuery = {
  page?: string,
  feed?: RouteQueryFeed,
};

export default defineComponent({
  name: 'PostsIndex',
  components: {
    FeaturedPosts,
    PostsContainer,
  },
  setup() {
    const route = useRoute();
    const store = useStore();

    const reflectRouteQuery = async (query: RouteQuery) => {
      const page = Number(query.page) || DEFAULT_PAGE;
      const feedTab = (query.feed as RouteQueryFeed) === 'false'
        ? FEED_TAB.all
        : FEED_TAB.feed;

      await store.dispatch('post/setLogin');

      if (!store.state.post.login) {
        await store.dispatch('post/setTabAndPage', {
          tab: FEED_TAB.all,
          page,
        });
        return;
      }

      await store.dispatch('post/setUserFeed');

      if (!store.getters['post/hasUserFeedTags']) {
        await store.dispatch('post/setTabAndPage', {
          tab: FEED_TAB.all,
          page,
        });
        return;
      }

      await store.dispatch('post/setTabAndPage', {
        tab: feedTab,
        page,
      });
    };

    watch(
      () => route.query,
      (query: RouteQuery) => reflectRouteQuery(query),
      { immediate: true },
    );

    onMounted(() => {
      dispatchHackleViewEvent('career_post_viewed');
    });
  },
});
</script>
