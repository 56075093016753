<template>
  <li
    class="list-position-item"
    :class="isRecommended && 'recommend-item profile-recommend'"
    @click="handleClickListItem"
  >
    <div class="item-header">
      <img
        v-skeleton="{ value: isLoading, width: 40, height: 40 }"
        :src="company.logoUrl"
        class="company-logo"
        :alt="company.name"
      >
    </div>

    <div class="item-body">
      <div class="position-title-wrapper">
        <span v-show="isWanted" class="external-label">
          원티드 공고
        </span>
        <h5
          ref="positionTitle"
          v-tooltip:top="{
            message: isPositionTitleOverflow ? jobPosition.title : null,
            isMountBody: true,
          }"
          class="position-title"
        >
          <a
            class="position-link"
            aria-label="Go to job position URL"
            @click.prevent="handleClickPositionTitle"
          >
            {{ jobPosition.title }}
          </a>
        </h5>
        <span v-show="isRecommended" class="recommend-label">
          {{ I18n.t('job_position.show.recommend') }}
        </span>
      </div>

      <ImpressionTracker
        :trackable-id="jobPosition.id"
        :trackable-type="isWanted ? 'WantedJobPosition' : 'JobPosition'"
      />

      <h6 v-skeleton="isLoading" class="company-name">
        <a
          v-if="!isWanted"
          :href="companyLink"
          class="company-link"
          aria-label="Go to company URL"
        >
          {{ company.name }}
        </a>
        <span v-else>
          {{ company.name }}
        </span>

        <span
          v-if="company.averageResponseTime"
          class="label label-square label-light-primary"
        >
          {{
            I18n.t('job_position.show.response_time', {
              time: company.averageResponseTime,
            })
          }}
        </span>
      </h6>

      <ul v-skeleton="isLoading" class="company-info">
        <li v-if="!isWanted" class="experience">
          <SvgIcon name="ic-laptop-14" />
          {{ jobPosition.career }}
        </li>

        <li v-if="extractedAddress" class="location">
          <SvgIcon name="ic-location-14" />
          {{ extractedAddress }}
        </li>
      </ul>

      <ul v-skeleton="isLoading" class="list-position-tags">
        <li
          v-for="category in slicedPositionCategories"
          :key="`jobCategory_${category.id}`"
          class="stack-item cursor-pointer job-category"
        >
          {{ category.name }}
        </li>

        <li
          v-for="category in slicedSkillCategories"
          :key="`skillCategory_${category.id}`"
          class="stack-item cursor-pointer skill-category"
        >
          {{ category.name }}
        </li>

        <li
          v-for="category in externalSlicedPositionCategories"
          :key="`jobCategory_${category}`"
          class="stack-item cursor-pointer job-category"
        >
          {{ category }}
        </li>

        <li
          v-if="otherCategoryCount > 0"
          class="stack-item"
        >
          {{ I18n.t('terms.etc_count', { count: otherCategoryCount }) }}
        </li>
      </ul>
    </div>

    <div>
      <PositionBookmarkButton
        v-if="!isWanted"
        :id="jobPosition.id"
        :is-active="jobPosition.isBookmarked"
        :is-logged-in="user.isLoggedIn"
      />
    </div>
  </li>
</template>

<script lang="ts">
import {
  PropType,
  computed,
  defineComponent,
  inject,
  toRefs,
  unref,
} from '@vue/composition-api';
import { FilterState } from '../../hooks/filter';
import { useStore } from '../../hooks/store';
import { jobPositionUrl } from '../../job-position.util';
import ImpressionTracker from '@/components/shared/ImpressionTracker.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { skeleton, tooltip } from '@/directives';
import { Company } from '@/exportables/models/career/company.model';
import { JobPosition } from '@/exportables/models/career/job-position.model';
import { User } from '@/exportables/models/user.model';
import { dispatchHackleViewEvent } from '@/exportables/services/useHackle';
import { thousandSeparator } from '@/exportables/utils/number.util';
import { useI18n } from '@/helpers/i18n';
import { moveTo } from '@/helpers/url';
import { useIsOverflow } from '@/hooks/useIsOverflow';
import PositionBookmarkButton from '@/shared/career/components/PositionBookmarkButton.vue';

const MAX_TAG_COUNT = 3;

export default defineComponent({
  name: 'JobPositionListItem',
  components: {
    SvgIcon,
    ImpressionTracker,
    PositionBookmarkButton,
  },
  directives: {
    skeleton,
    tooltip,
  },
  props: {
    company: {
      type: Object as PropType<Company>,
      required: true,
    },
    isRecommended: {
      type: Boolean,
      default: false,
    },
    isWanted: {
      type: Boolean,
      default: false,
    },
    jobPosition: {
      type: Object as PropType<JobPosition>,
      required: true,
    },
    personalized: {
      type: Boolean,
      default: false,
    },
    useFilters: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const filter = inject<FilterState>('filter');
    const isLoading = inject('isLoading');
    const user = inject<User>('currentUser')!;

    const { jobPosition, isRecommended, company, personalized, isWanted } = toRefs(props);

    const { filterQuery } = filter!;

    const I18n = useI18n();

    const store = useStore();

    const jobCategories = computed(() => store.state.jobCategories);

    const extractedAddress = computed(() => {
      const { address } = unref(jobPosition);
      return address ? address.split(' ', 2).join(' ') : '';
    });

    const externalSlicedPositionCategories = computed(() =>
      unref(jobPosition).externalCategoryNames?.slice(0, MAX_TAG_COUNT));

    const slicedPositionCategories = computed(() =>
      unref(jobCategories).filter((category) =>
        unref(jobPosition)
          .jobCategoryIds?.slice(0, MAX_TAG_COUNT)
          .includes(category.id!)));

    const slicedSkillCategories = computed(() => {
      const slicedPositionCategoryLength = unref(
        slicedPositionCategories,
      ).length;
      return slicedPositionCategoryLength === MAX_TAG_COUNT
        ? []
        : unref(jobPosition).technicalTags?.slice(
          0,
          MAX_TAG_COUNT - slicedPositionCategoryLength,
        ) || [];
    });

    const otherCategoryCount = computed(() => {
      const totalCategoryLength =
        unref(jobPosition).jobCategoryIds?.length +
        unref(jobPosition).technicalTags?.length +
        unref(jobPosition).externalCategoryNames?.length;
      const slicedCategoryLength =
        unref(slicedPositionCategories).length +
        unref(slicedSkillCategories).length +
        unref(externalSlicedPositionCategories).length;
      return totalCategoryLength - slicedCategoryLength > 0
        ? totalCategoryLength - slicedCategoryLength
        : 0;
    });

    const themeId = computed(() => filterQuery?.jobThemeIds[0]);

    const jobPositionLink = computed(() => {
      if (unref(isWanted)) {
        return unref(jobPosition).url;
      }

      return jobPositionUrl({
        id: unref(jobPosition).id,
        isRecommended: unref(isRecommended),
        personalized: unref(personalized),
        themeId: unref(themeId),
      });
    });

    const companyLink = computed(() => `/companies/${unref(company).id}`);

    const moveToJobPosition = () => {
      if (unref(isWanted)) {
        window.open(unref(jobPosition).url, '_target', 'noopener noreferrer');
        return;
      }
      moveTo(unref(jobPositionLink), true);
    };

    const moveToCompany = () => {
      moveTo(unref(companyLink));
    };

    const handleClickPositionTitle = () => {
      dispatchHackleViewEvent(hackleEvent);
      moveToJobPosition();
    };

    const handleClickListItem = (event: MouseEvent) => {
      const target = event.target as Element;

      if (target.tagName === 'A') return;
      if (target.closest('[aria-label="북마크"]')) return;

      const companyLink = target.closest('.company-link');

      if (companyLink) {
        moveToCompany();
        return;
      }

      const positionLink = target.closest('.position-link');

      dispatchHackleViewEvent(hackleEvent);

      if (positionLink) {
        moveToJobPosition();
        return;
      }

      const listPositionItem = target.closest('.list-position-item');

      if (listPositionItem) {
        moveToJobPosition();
      }
    };

    const positionProviderType = () => {
      if (unref(isWanted)) {
        return 'wanted';
      } else if (unref(isRecommended)) {
        return 'recommend';
      } else {
        return 'programmers';
      }
    };

    const hackleEvent = {
      key: 'career_job_position_clicked',
      properties: {
        provider: positionProviderType(),
        positionId: unref(jobPosition).id,
        job: unref(jobPosition).title,
        company: unref(company).name,
      },
    };

    const {
      refElement: positionTitle,
      isOverflow: isPositionTitleOverflow,
    } = useIsOverflow();

    return {
      I18n,
      isLoading,
      extractedAddress,
      otherCategoryCount,
      slicedPositionCategories,
      slicedSkillCategories,
      externalSlicedPositionCategories,
      themeId,
      jobPositionLink,
      companyLink,
      positionTitle,
      isPositionTitleOverflow,
      user,
      thousandSeparator,
      handleClickListItem,
      handleClickPositionTitle,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "~/stylesheets/functions";
@import "~/stylesheets/variables";

.position-title-wrapper {
  display: flex;
  align-items: center;

  .recommend-label {
    flex-shrink: 0;
    align-self: baseline;
  }

  .external-label {
    flex-shrink: 0;
    align-self: baseline;
    display: inline-block;
    padding: rem-calc(2 8);
    margin: rem-calc(2 8) 0 0;
    background-color: #0078FF;
    border-radius: rem-calc(4);
    color: white;
    font-size: rem-calc(12);
    font-weight: 500;
    line-height: 1.5;
    vertical-align: top;
  }
}
</style>
