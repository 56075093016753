export class Tag {
  id: number | null;
  name: string;

  constructor(tag?: Partial<Tag>) {
    Object.assign(this, tag);
  }

  static new(tag?: Partial<Tag>) {
    return new Tag(tag);
  }
}

export type CompanyTag = Partial<{
  ceoName: string,
  companyId: number | null,
}> & Tag;

export type TagHandler = (tag?: Tag) => any;
