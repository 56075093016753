<template>
  <div class="container-sm">
    <div
      v-if="!user.hasJobProfile"
      class="alert alert-dismissible alert-info noti-banner"
    >
      <h4 class="title">
        {{ I18n.t('job_application.completed.no_job_profile.title') }}
      </h4>
      <div class="contents">
        <div class="noti-body">
          <p>
            {{ `${I18n.t('job_application.completed.no_job_profile.suggest_profile')} ${I18n.t('job_application.completed.no_job_profile.resume_required')}` }}
            <a
              class="alert-link font-weight-bold"
              :href="`/job_profiles/edit?resume_id=${selectedResume.id}`"
            >
              {{ I18n.t('job_application.completed.no_job_profile.add_profile') }}
            </a>
          </p>
        </div>
      </div>
    </div>
    <div class="complete-page-content container">
      <img
        src="~/images/img-check-link.png"
        class="complete_icon"
        :alt="I18n.t('job_application.competition.completed.image')"
      >
      <h2 class="headline">
        {{ I18n.t('job_application.completed.headline') }}
      </h2>
      <table class="table apply-table">
        <tbody>
          <tr>
            <td>{{ I18n.t('job_application.completed.company_name') }}</td>
            <td>{{ company.name }}</td>
          </tr>
          <tr>
            <td>{{ I18n.t('job_application.completed.job_position') }}</td>
            <td>{{ jobPosition.title }}</td>
          </tr>
        </tbody>
      </table>
      <p>
        {{ I18n.t('job_application.completed.desc') }}
      </p>
      <div class="btn-recruit no-label">
        <a
          href="/job_applications"
          class="btn btn-primary btn-block"
        >
          {{ I18n.t('job_application.completed.to_status') }}
        </a>
        <a
          href="/job_positions"
          class="btn btn-light btn-block"
        >
          {{ I18n.t('job_application.completed.to_list') }}
        </a>
      </div>
      <h6 class="help-block">
        {{ I18n.t('job_application.completed.modify_resume') }}
      </h6>
    </div>

    <div v-if="recommendations.length" class="container" :class="style['recommendation-container']">
      <div :class="style['recommendation-title']">
        {{ I18n.t('job_application.completed.recommend_positions') }}
      </div>
      <div :class="style['recommendation-group']">
        <div
          v-for="recommendation in recommendations"
          :key="recommendation.id"
          :class="style['recommendation-item']"
          @click="handleClickRecommendation(recommendation, $event)"
        >
          <span class="recommendation-image">
            <a
              :href="recommendation.companyLink"
              aria-label="Go to company URL"
              class="company-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                :src="recommendation.company.logoUrl"
                :alt="recommendation.company.name"
              >
            </a>
          </span>
          <div :class="style['recommendation-info']">
            <a
              :href="recommendation.jobPositionLink"
              aria-label="Go to job position URL"
              class="position-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h5>{{ recommendation.title }}</h5>
            </a>
            <a
              :href="recommendation.companyLink"
              aria-label="Go to company URL"
              class="company-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h6>
                {{ recommendation.company.name }} • {{ recommendation.address }}
              </h6>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
  toRefs,
  useCssModule,
  nextTick,
  computed,
} from '@vue/composition-api';
import { fetchRecommendedPositions } from '@/exportables/apis/career/job-position.api';
import { appendTagManager } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';

const { t } = useI18n();

export default defineComponent({
  name: 'JobApplicationCompleted',
  props: {
    company: {
      type: Object,
      default: null,
    },
    jobPosition: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    location: {
      type: String,
      default: '',
    },
    tagManager: {
      type: Object,
      default: null,
    },
    resume: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const style = useCssModule();

    const { jobPosition, location, tagManager } = toRefs(props);

    const recommendations = ref([]);

    const currentInstance = getCurrentInstance();

    const selectedResume = computed(() => props.resume);

    onMounted(async () => {
      const { jobPositions: recommendationPositions } =
        await fetchRecommendedPositions(jobPosition.value.id);

      recommendations.value = recommendationPositions.map(
        ({ address, id, ...rest }) => {
          const slicedAddress = address.split(' ').slice(0, 2).join(' ');

          const jobPositionLink = `/job_positions/${id}?isRecommended=true&referer=fromCompleted&job_positions_count=${recommendationPositions.length}`;

          const companyLink = `/companies/${rest.company.id}`;

          return {
            ...rest,
            id,
            jobPositionLink,
            companyLink,
            address: slicedAddress,
          };
        },
      );

      if (tagManager.value) {
        await nextTick();
        appendTagManager(currentInstance.proxy, tagManager.value);
      }

      history.replaceState(null, '', location.value);
    });

    const handleClickRecommendation = (recommendation, event) => {
      if (event.target.className.startsWith('recommendation-item')) {
        window.open(recommendation.jobPositionLink, '_blank', 'noopener,noreferrer');
        return;
      }
    };

    return {
      I18n: {
        t,
      },
      recommendations,
      style,
      selectedResume,
      moveTo,
      handleClickRecommendation,
    };
  },
});
</script>
<style lang="scss" module>
@use '~/stylesheets/variables';
@use '~/stylesheets/functions';
@use '~/stylesheets/mixins';

.recommendation-container {
  max-width: functions.rem-calc(640);
  text-align: left;
  background: variables.$white-color;
  -webkit-font-smoothing: antialiased;

  @media (variables.$md-up) {
    margin-top: functions.rem-calc(16);
    padding: functions.rem-calc(24);
    border: functions.rem-calc(1) solid rgba(0, 0, 0, 0.1);
    border-radius: functions.rem-calc(4);
  }

  @media (variables.$md-down) {
    margin-top: functions.rem-calc(16);
    padding: functions.rem-calc(32);
    border: functions.rem-calc(1) solid variables.$blue-grey-75;
    background: variables.$white-color;
    border-radius: functions.rem-calc(10);
  }
}

.recommendation-title {
  font-size: functions.rem-calc(18);
  margin-bottom: functions.rem-calc(4);
  @include mixins.font-weight-700;
}

.recommendation-group {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (variables.$md-down) {
    display: flex;
    flex-direction: column;
  }
}

.recommendation-item {
  display: flex;
  margin-top: functions.rem-calc(12);
  cursor: pointer;

  span {
    width: functions.rem-calc(48);
    height: functions.rem-calc(48);
    margin-right: functions.rem-calc(12);
    align-self: center;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: functions.rem-calc(4);
  }
}

.recommendation-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: functions.rem-calc(176);

  @media (variables.$md-down) {
    max-width: calc(100% - #{functions.rem-calc(60)});
  }

  a {
    color: variables.$blue-grey-900;

    &:hover {
      h5 {
        color: variables.$blue-500;
        text-decoration: underline;
      }

      h6 {
        text-decoration: underline;
      }
    }
  }

  h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;
    font-size: functions.rem-calc(16);
    line-height: 1.5;
  }

  h6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: functions.rem-calc(12);
    color: variables.$blue-grey-300;
  }
}
</style>
