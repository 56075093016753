import { GetterTree } from 'vuex';
import { CareerStore } from '..';
import { ResumeStoreState } from './state';
import constants from '@/pages/career/resumes/resume.constant';

const { PRINT, PUBLIC } = constants;

const getters: GetterTree<ResumeStoreState, CareerStore> = {
  isPrintPage(state: ResumeStoreState) {
    return state.pageType === PRINT;
  },
  isPublicPage(state: ResumeStoreState) {
    return state.pageType === PUBLIC;
  },
  isEditing(state: ResumeStoreState) {
    return Object.values(state.editingPartCount).some((count) => count > 0);
  },
};

export default getters;
