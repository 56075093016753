export function resumeChildComponents(name = 'Activity') {
  return require.context('@/pages/career/resumes', true, /\.vue$/)
    .keys()
    .filter((fileName) => fileName.includes(`${name}/`))
    .reduce((components, fileName) => {
      const path = fileName.replace('./', '');
      const type = (
        fileName.includes('Form') && 'Form' ||
        fileName.includes('Preview') && 'Preview' ||
        false
      );

      if (type) {
        const componentConfig = () => import(`@/pages/career/resumes/${path}`);

        Object.assign(components, {
          [`${name}${type}`]: componentConfig,
        });
      }
      return components;
    }, {});
}
