<template>
  <div :class="style.container">
    <img v-if="!isPrintPage" :src="iconPath" :class="style.logo" :alt="`${info.examType} 로고`">

    <div :class="style.infoWrap">
      <h4 :class="style.title">
        {{ `${info.examType} (${I18n.t(`section.certificate.${info.examType.toLowerCase()}`)})` }}
      </h4>
      <h6 :class="style.description">
        <span>{{ I18n.t('section.certificate.start_at') }}: {{ startAt }} · </span>
        <span>{{ I18n.t('section.certificate.end_at') }}: {{ expireAt }}</span>
      </h6>
      <h6 :class="style.description">
        <span>{{ info.language }} · </span>
        <span>Lv.{{ info.level }}</span>
      </h6>
    </div>
  </div>
</template>

<script lang="ts">
import {
  PropType,
  computed,
  defineComponent,
  useCssModule,
} from '@vue/composition-api';
import dayjs from 'dayjs';
import { tooltip } from '@/directives';
import { ResumeCertificate } from '@/exportables/models/career/resume.model';
import { useI18n } from '@/helpers/i18n';

const DATE_FORMAT = 'YYYY-MM-DD';

export default defineComponent({
  name: 'CertificateInfo',
  directives: {
    tooltip,
  },
  props: {
    info: {
      type: Object as PropType<ResumeCertificate>,
      required: true,
    },
    isPrintPage: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const I18n = useI18n();
    const style = useCssModule();
    const startAt = computed(() =>
      dayjs(props.info.startAt, DATE_FORMAT).format(DATE_FORMAT));
    const expireAt = computed(() =>
      dayjs(props.info.expireAt, DATE_FORMAT).format(DATE_FORMAT));
    const iconPath = computed(() => {
      const { examType, level } = props.info;
      return require(`~/images/ic-${examType.toLowerCase()}-lv${level}.png`);
    });

    return {
      I18n,
      dayjs,
      expireAt,
      iconPath,
      startAt,
      style,
    };
  },
});
</script>

<style lang="scss" module>
@use '~/stylesheets/functions';

.container {
  width: 100%;
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: functions.rem-calc(12);
}

.infoWrap {
  width: 100%;
  overflow: hidden;
  margin: auto;
}

.logo {
  width: functions.rem-calc(64);
  height: functions.rem-calc(64);
  flex-shrink: 0;
  margin: 0;
}

.title {
  width: 100%;
  white-space: initial;
  line-height: 1.4;
}

.description {
  width: 100%;
  margin-top: functions.rem-calc(4);
}
</style>
