export type DefaultDate = Date | string | null;

export class Registration {
  id: number = -1;
  createdAt?: DefaultDate;
  jobPositions?: JobPosition[];
  competition: Competition = {
    id: -1,
    title: '',
    isPreference: false,
  };
  registrationGroup?: RegistrationGroup = {
    id: -1,
    title: '',
  };
  constructor(registration?: Partial<Registration>) {
    Object.assign(this, registration);
  }

  static new(registration?: Partial<Registration>) {
    return new Registration(registration);
  }
}

export type RegistrationGroup = {
  id: number,
  title: string,
};

type Competition = {
  id: number,
  title: string,
  isPreference: boolean,
  showRegistrationGroup: boolean,
};

type JobPosition = {
  id: number,
  title: string,
  url: string,
  company?: {
    name: string,
  },
};

export type AuthenticatedInfo = {
  name: string,
  email: string,
  phone: string,
};
