import { datetime } from '../../job-offers/job-offer.util';
import { hasReason, isInProgress } from './../job-profiles/job-profile.util';
import { BusinessJobApplication } from '@/exportables/models/career/business/job-application.model';
import { BusinessJobOffer } from '@/exportables/models/career/business/job-offer.model';
import { Status as JobApplicationStatus } from '@/exportables/models/career/job-application.model';
import { Status } from '@/exportables/models/career/job-offer.model';
import { DefaultDate } from '@/exportables/types/general.d';
import { isPassed } from '@/helpers/datetime';
import { useI18n } from '@/helpers/i18n';

export type Action = 'show' | 'edit';
export type Modal = 'cancel' | 'confirm' | 'edit' | 'reject_confirm';

type BusinessJobApplicationDatetime = Pick<BusinessJobApplication, 'appliedAt' | 'inProgressAt' | 'rejectedAt' | 'hiredAt' | 'midwayRejectedAt'>;
type BusinessJobOfferDatetime = Pick<BusinessJobOffer, 'offeredAt' | 'candidateViewedAt' | 'offerRevokedAt' | 'offerRejectedAt' | 'appliedAt'>;

const I18n = useI18n();

export const getHistory = (
  jobApplication?: BusinessJobApplicationDatetime,
  jobOffer?: BusinessJobOfferDatetime,
) => {
  const history: [string, DefaultDate][] = [];
  if (!jobApplication && !jobOffer) return history;

  if (jobOffer?.offeredAt) history.push(['offered', jobOffer.offeredAt]);
  if (jobOffer?.candidateViewedAt) history.push(['viewed', jobOffer.candidateViewedAt]);
  if (jobOffer?.offerRevokedAt) history.push(['offer_revoked', jobOffer.offerRevokedAt]);
  if (jobOffer?.offerRejectedAt) history.push(['offer_rejected', jobOffer.offerRejectedAt]);
  if (jobOffer?.appliedAt || jobApplication?.appliedAt) history.push(['applied', jobOffer?.appliedAt! || jobApplication?.appliedAt!]);
  if (jobApplication?.inProgressAt) history.push(['in_progress', jobApplication.inProgressAt]);
  if (jobApplication?.rejectedAt) history.push(['rejected', jobApplication.rejectedAt]);
  if (jobApplication?.hiredAt) history.push(['hired', jobApplication.hiredAt]);
  if (jobApplication?.midwayRejectedAt) history.push(['midway_rejected', jobApplication.midwayRejectedAt]);
  return history;
};

export const getStatus = (jobOffer: BusinessJobOffer) => {
  const { candidateViewedAt, jobApplication, sendAt, status } = jobOffer;
  const { id, status: jobApplicationStatus } = jobApplication || {};

  return (
    status === 'applied' && !id && 'deleted' ||
    jobApplicationStatus ||
    status === 'offered' && (
      candidateViewedAt && 'viewed' ||
      sendAt && !isPassed(sendAt) && 'sent' ||
      'waiting'
    ) ||
    status
  );
};

export const statusActions = (status: Status) => {
  const actions: string[] = ['history'];
  status === 'offered' && actions.unshift('edit', 'cancel');
  return ['confirm'].concat(actions);
};

export type AdditionalStatus = Status | 'deleted' | 'sent' | 'waiting' | 'viewed';

export const statusColor = (status: AdditionalStatus | JobApplicationStatus) => (
  (status === 'applied' || status === 'in_progress') && 'blue' ||
  status === 'hired' && 'green' ||
  status === 'waiting' && 'yellow' ||
  'gray'
);

export const isWaiting = (jobOffer: BusinessJobOffer) => getStatus(jobOffer) === 'waiting';

export const isJobApplicationDeleted = (jobOffer: BusinessJobOffer) => (
  jobOffer.status === 'applied' && !jobOffer.jobApplication?.id
);

export const badgeOptions = (jobOffer: BusinessJobOffer) => {
  const status = getStatus(jobOffer);
  const color = statusColor(status);
  const label = I18n.t(`business.recruit.job_offer.status.${status}`);

  return {
    color,
    label,
    isActive: isInProgress(status) || status === 'offer_rejected',
    isStroked: color === 'gray',
  };
};

export const tooltipOptions = (jobOffer: BusinessJobOffer) => {
  const status = getStatus(jobOffer);

  switch (status) {
    case 'applied':
    case 'deleted':
    case 'in_progress':
    case 'hired':
    case 'offer_revoked':
    case 'viewed':
      return I18n.t(`business.recruit.job_offer.index.${status}`);
    case 'waiting':
      return I18n.t('business.recruit.job_offer.index.send_at', { datetime: datetime(jobOffer.sendAt as string) });
    case 'offer_rejected':
      return hasReason(jobOffer) ? I18n.t('business.recruit.job_offer.index.offer_rejected') : '';
    default:
      return '';
  }
};
