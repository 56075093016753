<template>
  <div class="form-row part exam">
    <div class="col-5">
      <div class="form-group">
        <input
          v-model="exam.name"
          class="form-control"
          type="text"
          autocomplete="off"
          :placeholder="I18n.t('section.foreign_language.name')"
        >
      </div>
    </div>

    <div class="col-5">
      <div class="form-group">
        <input
          v-model="exam.degree"
          class="form-control"
          type="text"
          autocomplete="off"
          :placeholder="I18n.t('section.foreign_language.placeholder.degree')"
        >
      </div>
    </div>

    <div class="col-2 form-btn-wrap-sm">
      <button
        class="btn btn-xs"
        :class="index === 0 && 'disabled'"
        @click="emit('change-index', index, index - 1)"
      >
        <SvgIcon name="ic-keyboard-arrow-up" html-class="ic-24" />
      </button>
      <button
        class="btn btn-xs"
        :class="index === length - 1 && 'disabled'"
        @click="emit('change-index', index, index + 1)"
      >
        <SvgIcon name="ic-keyboard-arrow-down" html-class="ic-24" />
      </button>
      <button
        class="btn btn-xs"
        data-toggle="tooltip"
        data-placement="top"
        :data-title="I18n.t('actions.remove')"
        @click="emit('delete-item', index)"
      >
        <SvgIcon name="ic-cancel" html-class="ic-24" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from '@vue/composition-api';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { ForeignLanguageExam } from '@/exportables/models/career/resume.model';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'ResumeForeignLanguageExamForm',
  components: {
    SvgIcon,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
    exam: {
      type: Object as PropType<ForeignLanguageExam>,
      required: true,
    },
  },
  emits: [
    'change-index',
    'delete-item',
  ],
  setup(_, { emit }) {
    const I18n = useI18n();

    return {
      I18n: { t: I18n.t },
      emit,
    };
  },
});
</script>
