<template>
  <div class="toast-content">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'BookmarkToastContent',
  props: {
    className: {
      type: String,
      default: '',
    },
  },
});
</script>

<style lang="scss" scoped>
@use "~/stylesheets/variables";
@use "~/stylesheets/functions";

.toast-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: functions.rem-calc(320);

  @media (variables.$sm-down) {
    width: calc(100% - functions.rem-calc(16));
  }

  > * {
    display: inline-block;
    color: #fff;
    font-size: functions.rem-calc(14);
  }

  > a {
    color: #fff;
    font-size: functions.rem-calc(12);
    font-weight: 700;
    text-decoration: underline;

    &:hover {
      color: variables.$blue-200;
    }
  }
}
</style>
