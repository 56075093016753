import { onMounted, ref } from '@vue/composition-api';
import { fetchFeaturedPosts } from '@/exportables/apis/career/post.api';
import { FeaturedPost } from '@/exportables/models/career/featured-post.model';

const useFeaturedPosts = () => {
  const featuredPosts = ref<FeaturedPost[]>([]);

  const getFeaturedPosts = async () => {
    const { featuredPosts: newFeaturedPosts } = await fetchFeaturedPosts();

    featuredPosts.value = newFeaturedPosts.sort(
      (a, b) => b.featuredExposure - a.featuredExposure,
    );
  };

  onMounted(getFeaturedPosts);

  return {
    featuredPosts,
  };
};

export default useFeaturedPosts;
