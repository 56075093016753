<template>
  <UserFeedTabs
    :tabs="tabs"
    :current-tab="currentTab"
    :tag-count-by-tabs="tagCountByTabs"
    @change="handleChangeTab"
  />
</template>

<script lang="ts">
import { computed, defineComponent } from '@vue/composition-api';
import UserFeedTabs from '../components/UserFeedTabs.vue';
import { FeedCategory, FEED_CATEGORIES } from '../constants';
import { tabToTagCountMap } from '../utils';
import { useStore } from '@/store/career';
import types from '@/store/career/post/mutation-types';

export default defineComponent({
  name: 'UserFeedTabsContainer',
  components: {
    UserFeedTabs,
  },
  setup() {
    const store = useStore();

    const currentTab = computed(() => store.state.post.feedSettingTab);
    const tagCountByTabs = computed(() => tabToTagCountMap(FEED_CATEGORIES, store.state.post.userFeed));

    const handleChangeTab = (tab: FeedCategory) => {
      store.commit(`post/${types.SET_FEED_SETTING_TAB}`, tab);
    };

    return {
      tabs: FEED_CATEGORIES,
      currentTab,
      tagCountByTabs,
      handleChangeTab,
    };
  },
});
</script>
