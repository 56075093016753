<template>
  <Modal
    :title="I18n.t('resume.personal_information.title')"
    @hide="closeModal"
  >
    <template #body>
      <div class="form-row">
        <div class="form-group form-required col-6">
          <label for="name">
            {{ I18n.t('terms.name') }}
          </label>

          <input
            v-model="name"
            class="form-control"
            :class="inputValidationClass($v.name)"
            type="text"
            autocomplete="off"
            :placeholder="I18n.t('terms.name')"
            @blur="$v.name.$touch"
            id="name"
          >
        </div>

        <div class="form-group form-required col-6">
          <label>
            {{ I18n.t('terms.phone') }}

            <span
              v-if="isPhoneVerification"
              v-tooltip:bottom="{ message: I18n.t('resume.personal_information.verification.tooltip.phone'), width: '210px' }"
              class="btn-help dark"
            >
              <SvgIcon name="ic-help-full" html-class="ic-20" />
            </span>
          </label>

          <input v-if="isPhoneVerification" class="form-control is-valid disabled" :value="phone" disabled>
          <input
            v-else
            v-model="phone"
            class="form-control"
            :class="inputValidationClass($v.phone)"
            type="tel"
            autocomplete="off"
            placeholder="010-1234-5678"
            @input="$v.phone.$touch"
          >
        </div>

        <div class="form-group form-required col-12">
          <label>
            {{ I18n.t('terms.email') }}

            <span
              v-if="isEmailVerification"
              v-tooltip:top="{ message: I18n.t('resume.personal_information.verification.tooltip.email'), width: '210px' }"
              class="btn-help dark"
            >
              <SvgIcon name="ic-help-full" html-class="ic-20" />
            </span>
          </label>

          <input v-if="isEmailVerification" class="form-control is-valid disabled" :value="email" disabled>
          <input
            v-else
            v-model="email"
            class="form-control"
            :class="inputValidationClass($v.email)"
            type="email"
            autocomplete="off"
            placeholder="email@programmers.co.kr"
            @blur="$v.email.$touch"
          >
        </div>

        <div class="form-group col-12">
          <label :class="style.notRequired">
            {{ I18n.t('resume.personal_information.blog') }}
          </label>

          <input
            v-model="blog"
            class="form-control"
            :class="inputValidationClass($v.blog)"
            type="url"
            autocomplete="off"
            placeholder="https://website.com"
            @blur="$v.blog.$touch"
          >
        </div>

        <div class="form-group col-6">
          <label :class="style['notRequired']">
            {{ I18n.t('resume.personal_information.salary') }}
          </label>

          <div class="input-group">
            <input
              v-model="salary"
              class="form-control"
              :class="inputValidationClass($v.salary)"
              type="number"
              min="0"
              max="100000000"
              autocomplete="off"
              :placeholder="I18n.t('resume.personal_information.placeholder.salary')"
              @input="$v.salary.$touch"
            >
            <div class="input-group-append">
              <span class="input-group-text font-size-normal">
                {{ I18n.t('resume.personal_information.salary_unit') }}</span>
            </div>
          </div>
        </div>

        <div class="form-group col-12">
          <label :class="style['notRequired']">
            {{ I18n.t('resume.personal_information.introduction') }}
          </label>

          <textarea
            v-model="introduction"
            class="form-control"
            :placeholder="I18n.t('terms.input')"
            rows="6"
          ></textarea>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex-end">
        <button
          class="btn btn-md btn-light"
          data-dismiss="modal"
          aria-label="Close"
          @click="closeModal"
        >
          {{ I18n.t('actions.cancel') }}
        </button>
        <button
          class="btn btn-md btn-primary"
          @click="handleSubmit"
        >
          {{ I18n.t('actions.save') }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  reactive,
  toRefs,
  unref,
  useCssModule,
} from '@vue/composition-api';
import { ValidationArgs, useVuelidate } from '@vuelidate/core';
import {
  email as emailRule,
  integer,
  required,
  url as urlRule,
  between,
} from '@vuelidate/validators';
import { handleUpdateForm } from '../../hooks/form';
import { usePhoneNumber } from '../../hooks/phone';
import { ResumeShowStates, getResume } from '../../hooks/state';
import { inputValidationClass } from '../../resume.util';
import Modal from '@/components/shared/Modal2.vue';
import { tooltip } from '@/directives';
import { Resume } from '@/exportables/models/career/resume.model';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';

type PersonalInformation = Pick<Resume,
| 'blog'
| 'email'
| 'introduction'
| 'name'
| 'phone'
| 'salary'
| 'isEmailVerification'
| 'isPhoneVerification'>;

export default defineComponent({
  name: 'ResumePersonalInformationModal',
  components: {
    Modal,
  },
  directives: {
    tooltip,
  },
  emits: ['hide'],
  setup(_, { emit }) {
    const state = inject<ResumeShowStates>('state')!;
    const {
      value: {
        name,
        phone,
        email,
        blog,
        salary,
        introduction,
        isEmailVerification,
        isPhoneVerification,
      },
    } = computed(() => getResume(state));
    const info = reactive<PersonalInformation>({
      blog,
      email,
      introduction,
      name,
      phone,
      salary,
      isEmailVerification,
      isPhoneVerification,
    });
    const I18n = useI18n();
    const { phoneNumber, isValid } = usePhoneNumber(computed(() => info.phone));
    const style = useCssModule();

    const rules: ValidationArgs = {
      name: { required },
      phone: {
        required,
        phoneNumber: isValid,
      },
      email: {
        required,
        email: emailRule,
      },
      blog: { url: urlRule },
      salary: {
        integer,
        range: between(0, 100000000),
      },
    };
    const $v = useVuelidate(rules, info);

    const closeModal = () => emit('hide');

    const handleInvalidation = () => {
      const unreffed$v = unref($v);
      const firstError = unreffed$v?.$errors?.[0];
      const isRequiredError = firstError?.$validator === 'required';
      const message = firstError
        ? isRequiredError
          ? I18n.t(`resume.error.required.${firstError.$property}`)
          : I18n.t(`resume.error.${firstError.$property}`)
        : '';

      message && appendToast(message);
    };

    const handleSubmit = () => {
      const unreffed$v = unref($v);
      unreffed$v.$touch();

      if (unreffed$v.$errors.length || unreffed$v.$invalid) {
        handleInvalidation();
        return;
      }

      info.phone = unref(phoneNumber);

      handleUpdateForm(state, info);
      closeModal();
    };

    return {
      ...toRefs(info),
      $v,
      I18n: { t: I18n.t },
      closeModal,
      handleSubmit,
      inputValidationClass,
      style,
    };
  },
});
</script>

<style lang="scss" module>
.notRequired {
  margin-bottom: 3px;
}
</style>
