<template>
  <div class="resume-card-item form">
    <div class="form-group-wrap">
      <label class="form-group-title">
        {{ I18n.t('resume.publication') }} {{ index + 1 }}
      </label>

      <div class="form-row">
        <div class="col-12">
          <div class="form-group form-required">
            <label>
              {{ I18n.t('section.publication.name') }}
            </label>

            <input
              v-model="newPublication.title"
              class="form-control"
              :class="inputValidationClass($v.title)"
              type="text"
              autocomplete="off"
              :placeholder="I18n.t('terms.input')"
              @blur="$v.title.$touch"
            >
          </div>
        </div>

        <div class="col-4">
          <div class="form-group">
            <label>
              {{ I18n.t('section.publication.unique_number') }}
            </label>

            <input
              v-model="newPublication.uniqueNumber"
              class="form-control"
              type="text"
              autocomplete="off"
              :placeholder="I18n.t('terms.input')"
            >
          </div>
        </div>

        <div class="col-4">
          <div class="form-group">
            <label>
              {{ I18n.t('section.publication.publisher') }}
            </label>

            <input
              v-model="newPublication.publisher"
              class="form-control"
              type="text"
              autocomplete="off"
              :placeholder="I18n.t('terms.input')"
            >
          </div>
        </div>

        <div class="col-4">
          <div class="form-group">
            <label>
              {{ I18n.t('section.publication.author') }}
            </label>

            <input
              v-model="newPublication.author"
              class="form-control"
              type="text"
              autocomplete="off"
              :placeholder="I18n.t('terms.input')"
            >
          </div>
        </div>

        <div class="col-date col-4">
          <Datepicker
            :value="newPublication.publishedAt"
            :label="I18n.t('section.publication.published_at')"
            class="form-group"
            @initialize="() => setPublishedAt('')"
            @select="setPublishedAt"
          />
        </div>

        <div class="col-12">
          <div class="form-group">
            <label>
              {{ I18n.t('terms.link') }}
            </label>

            <input
              v-model="newPublication.link"
              class="form-control"
              :class="inputValidationClass($v.link)"
              type="text"
              autocomplete="off"
              placeholder="https://"
              @blur="$v.link.$touch"
            >
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label>
              {{ I18n.t('terms.description') }}
            </label>

            <textarea
              v-model="newPublication.description"
              class="form-control"
              rows="3"
              autocomplete="off"
              :placeholder="I18n.t('terms.input')"
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="form-btn-wrap-lg">
      <button class="btn btn-md btn-light" @click="handleCancelEdit">
        {{ I18n.t('actions.cancel') }}
      </button>
      <button class="btn btn-md btn-primary" @click="handleSubmit">
        {{ I18n.t('actions.save') }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import {
  PropType,
  defineComponent,
  inject,
  reactive,
  unref,
} from '@vue/composition-api';
import { useVuelidate } from '@vuelidate/core';
import { required, url } from '@vuelidate/validators';
import { handleUpdateForm } from '../../hooks/form';
import { ResumeShowStates, getResume } from '../../hooks/state';
import constants from '../../resume.constant';
import { inputValidationClass } from '../../resume.util';
import Datepicker from '../Datepicker.vue';
import { ResumePublication } from '@/exportables/models/career/resume.model';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';
import { cloneArray } from '@/helpers/utils';

type PublicationKey = keyof ResumePublication;

const { minDate } = constants.DATEPICKER;

export default defineComponent({
  name: 'ResumePublicationForm',
  components: {
    Datepicker,
  },
  props: {
    isAdded: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
    publication: {
      type: Object as PropType<ResumePublication>,
      required: true,
    },
  },
  setup(props) {
    const changeEditState = inject<Function>('changeEditState')!;
    const { newItem } = inject('form')!;
    const state = inject<ResumeShowStates>('state')!;
    const { t } = useI18n();
    const newPublication = reactive(
      new ResumePublication(props.publication),
    );
    const rules = {
      title: { required },
      link: { url },
    };
    const $v = useVuelidate(rules, newPublication);

    const setPublication = (key: PublicationKey) => (newValue: ResumePublication[PublicationKey]) => {
      Object.assign(
        newPublication,
        { [key]: newValue },
      );
    };
    const setPublishedAt = setPublication('publishedAt');

    const handleCancelEdit = () => {
      props.isAdded && Object.assign(newItem, { value: null });
      changeEditState(false);
    };

    const handleSubmit = () => {
      const unreffed$v = unref($v);
      const resume = getResume(state);
      const publications = cloneArray(resume.publications);

      unreffed$v.$touch();

      if (unreffed$v.$error || unreffed$v.$invalid) {
        appendToast(t('resume.error.form'));
        return;
      }

      props.isAdded ? publications.push(newPublication) : publications.splice(props.index, 1, newPublication);

      handleUpdateForm(state, { publications });
      changeEditState(false);

      Object.assign(newItem, { value: null });
    };

    return {
      $v,
      I18n: { t },
      handleCancelEdit,
      handleSubmit,
      inputValidationClass,
      minDate,
      newPublication,
      setPublishedAt,
    };
  },
});
</script>
