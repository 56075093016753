import qs from 'qs';
import { AxiosError } from 'axios';
import { Query } from '../../types/general.d';
import { useHttpClient } from '../httpClient';
import {
  Resume,
  ResumeCertificate,
  ResumeElement,
} from '@/exportables/models/career/resume.model';
import { appendToast } from '@/helpers/append';
import { createClient } from '@/helpers/http';
import { useI18n } from '@/helpers/i18n';

const RESUME_URL = '/resumes';

const I18n = useI18n();
const httpClient = useHttpClient();
const httpFormClient = createClient({
  headers: { 'content-type': 'multipart/form-data' },
});

export async function fetchResume(id: number, params?: Query) {
  try {
    const { data, status } = await httpClient.get(`${RESUME_URL}/${id}`, {
      params,
    });
    if (status !== 200)
      throw new Error(data.message || I18n.t('error.internal'));
    return data;
  } catch (error) {
    error instanceof Error && appendToast(error.message);
    throw error;
  }
}

export async function fetchResumes(): Promise<{ resumes: ResumeElement[] }> {
  try {
    const { data, status } = await httpClient.get(RESUME_URL);
    if (status !== 200)
      throw new Error(data.message || I18n.t('error.internal'));
    return data;
  } catch (error) {
    appendToast(I18n.t('activerecord.errors.request.fetch'));
    throw error;
  }
}

export async function updateResume(
  id: number,
  params: { resume: Partial<Resume & { jobPositionCategoryIds: number[] }> },
) {
  try {
    const { guide_modal, guide_banner } = qs.parse(
      window.location.search.replace('?', ''),
    );
    const { data, status } = await httpClient.patch(
      `${RESUME_URL}/${id}`,
      {
        ...params,
      },
      {
        params: {
          guide_banner,
          guide_modal,
        },
      },
    );
    if (status !== 200)
      throw new Error(data.message || I18n.t('error.internal'));
    return data;
  } catch (error) {
    appendToast(I18n.t('activerecord.errors.request.update'));
    throw error;
  }
}

export async function deleteResume(id: number) {
  try {
    const { status } = await httpClient.delete(`${RESUME_URL}/${id}`);
    if (status === 204) return true;
    throw new Error(I18n.t('error.internal'));
  } catch (error) {
    appendToast(I18n.t('activerecord.errors.request.delete'));
    throw error;
  }
}

export async function importResume(
  id: number,
  params: { importing_resume_id: number },
) {
  try {
    const { data, status } = await httpClient.post(
      `${RESUME_URL}/${id}/import`,
      params,
    );
    if (status !== 200)
      throw new Error(data.message || I18n.t('error.internal'));
    return data;
  } catch (error) {
    appendToast(I18n.t('activerecord.errors.request.update'));
    throw error;
  }
}

export async function createTestTokens(
  id: number,
  params: { testTokenId: number },
) {
  try {
    const { status } = await httpClient.post(
      `${RESUME_URL}/${id}/resume_test_tokens`,
      params,
    );
    if (status !== 200) throw new Error();
  } catch (error) {
    appendToast(I18n.t('errors.messages.request'));
    throw error;
  }
}

export async function deleteTestTokens(
  resumeId: number,
  resumeTestTokenId: number,
) {
  try {
    const { status } = await httpClient.delete(
      `${RESUME_URL}/${resumeId}/resume_test_tokens/${resumeTestTokenId}`,
    );
    if (status !== 204) throw new Error();
  } catch (error) {
    appendToast(I18n.t('errors.messages.request'));
    throw error;
  }
}

type FetchCertificatesResponsePayload = ResumeCertificate[];
export async function fetchCertificates(
  resumeId: Resume['id'],
  params: Partial<Query> = {},
) {
  try {
    const { data, status } = await httpClient.get(
      `/v1/career${RESUME_URL}/${resumeId}/resume_certificates`,
      { params },
    );
    if (status !== 200)
      throw new Error(data.message || I18n.t('error.internal'));
    return data as FetchCertificatesResponsePayload;
  } catch (error) {
    error instanceof Error && appendToast(error.message);
    throw error;
  }
}

export async function fetchRegistrableCertificates(resumeId: Resume['id']) {
  try {
    const { data, status } = await httpClient.get(
      `/v1/career${RESUME_URL}/${resumeId}/resume_certificates/registrable`,
    );
    if (status !== 200)
      throw new Error(data.message || I18n.t('error.internal'));
    return data as FetchCertificatesResponsePayload;
  } catch (error) {
    error instanceof Error && appendToast(error.message);
    throw error;
  }
}

type CreateCertificateParams = ResumeCertificate;
type CreateCertificatesResponsePayload = {
  id: ResumeCertificate['id'],
  score: Resume['score'],
};

export async function createCertificate(
  resumeId: Resume['id'],
  params: CreateCertificateParams,
) {
  try {
    const { guide_modal, guide_banner } = qs.parse(
      window.location.search.replace('?', ''),
    );
    const { data, status } = await httpClient.post(
      `/v1/career${RESUME_URL}/${resumeId}/resume_certificates`,
      params,
      {
        params: {
          guide_banner,
          guide_modal,
        },
      },
    );
    if (status !== 201)
      throw new Error(data.message || I18n.t('error.internal'));
    return data as CreateCertificatesResponsePayload;
  } catch (error) {
    error instanceof Error && appendToast(error.message);
    throw error;
  }
}

type DeleteCertificatesResponsePayload = {
  score: Resume['score'],
};

export async function deleteCertificate(
  resumeId: Resume['id'],
  resumeCertificateId: ResumeCertificate['id'],
) {
  try {
    const { guide_modal, guide_banner } = qs.parse(
      window.location.search.replace('?', ''),
    );
    const { data, status } = await httpClient.delete(
      `/v1/career${RESUME_URL}/${resumeId}/resume_certificates/${resumeCertificateId}`,
      {
        params: {
          guide_banner,
          guide_modal,
        },
      },
    );
    if (status !== 200)
      throw new Error(data.message || I18n.t('error.internal'));
    return data as DeleteCertificatesResponsePayload;
  } catch (error) {
    error instanceof Error && appendToast(error.message);
    throw error;
  }
}

export async function updateResumeAttachment(id: number, params: FormData) {
  let message = '';

  try {
    const { status } = await httpFormClient.post(
      `${RESUME_URL}/${id}/resume_attachments`,
      params,
    );

    if (status === 204) {
      message = I18n.t('messages.upload_success');
      return;
    }

    throw new Error();
  } catch (e) {
    message = I18n.t('errors.messages.upload');
  } finally {
    message && appendToast(message);
  }
}

export async function deleteResumeAttachment(
  resumeId: number,
  resumeAttachmentId: number,
) {
  let message = '';

  try {
    const { status } = await httpClient.delete(
      `${RESUME_URL}/${resumeId}/resume_attachments/${resumeAttachmentId}`,
    );

    if (status === 204) {
      message = I18n.t('messages.delete_success');
      return;
    }

    throw new Error();
  } catch (error) {
    message = I18n.t('errors.messages.delete');
  } finally {
    message && appendToast(message);
  }
}

export async function toggleGitstats(id: number) {
  try {
    const { status } = await httpClient.post(
      `${RESUME_URL}/${id}/toggle_git_stats_show`,
    );
    if (status !== 200) throw new Error();
  } catch (error) {
    appendToast(I18n.t('errors.messages.request'));
    throw error;
  }
}

export async function refreshGitstat() {
  let message = '';

  try {
    const { data, status } = await httpClient.post(
      `${RESUME_URL}/refresh_git_stat`,
    );

    if (status === 200) {
      message = data?.message;
      return;
    }

    throw new Error(data.message);
  } catch (error) {
    if (error instanceof Error) {
      message = (error as AxiosError).response?.data?.message || error.message || I18n.t('resume.repository.refresh.error');
    }
  } finally {
    message && appendToast(message);
  }
}

export async function toggleSkillChecks(id: number) {
  try {
    const { status } = await httpClient.post(
      `${RESUME_URL}/${id}/toggle_skill_checks_show`,
    );
    if (status !== 200) throw new Error();
  } catch (error) {
    appendToast(I18n.t('errors.messages.request'));
    throw error;
  }
}
