<template>
  <nav aria-label="Page">
    <ul :class="navClass" class="pagination">
      <li
        :class="{
          disabled: currentPage === min,
        }"
        class="page-item"
      >
        <a class="page-link" @click.prevent="prevPage">
          {{ prev || I18n.t('terms.prev') }}
        </a>
      </li>

      <!-- TODO:
          숫자 버튼이 ... 버튼으로 바뀔때, class 변화로 인해 색이 변하는 것이 관측됩니다.
          이로 인해 page 숫자를 key로 사용하려고 했는데,
          pageRange에는 ...이 최대 두개까지 포함되므로 키 충돌이 일어나게 됩니다.
          따라서 key에 index를 조건부 포함하여 다음과 같이 작성하였습니다.
          코드가 좀더 직관적으로 보일 수 있도록 개선해볼 수 있을것 같습니다.
      -->
      <li
        v-for="(page, index) in pageRange"
        :key="typeof page === 'number' ? page : page + index"
        :class="[pageClass(currentPage, page)]"
        class="page-item"
      >
        <a class="page-link" @click.prevent="setCurrentPage(page)">
          {{ page }}
        </a>
      </li>

      <li
        :class="{
          disabled: currentPage === max || pageRange.length < 2,
        }"
        class="page-item"
      >
        <a class="page-link" @click.prevent="nextPage">
          {{ next || I18n.t('terms.next') }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  PropType,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api';
import { useI18n } from '@/helpers/i18n';

const ELLIPSIS = '...';

export default defineComponent({
  name: 'SharedPagination',
  props: {
    initialPage: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 1,
    },
    navClass: {
      type: String,
      default: 'flex-center',
    },
    next: {
      type: String,
      default: '',
    },
    prev: {
      type: String,
      default: '',
    },
    visibleNavSize: {
      type: Number,
      default: 3,
    },
    setCurrentPageCallback: {
      type: Function as PropType<(page: number) => void>,
      default: () => {},
    },
  },
  emits: ['update:initial-page'],
  setup(props, { emit }) {
    const currentPage = ref(props.initialPage);
    const { max, min, visibleNavSize } = toRefs(props);
    const I18n = useI18n();
    const pageRange = computed(() => generatePageRange(currentPage.value, max.value, visibleNavSize.value));

    const setCurrentPage = (page: number | typeof ELLIPSIS) => {
      if (page !== ELLIPSIS) {
        currentPage.value = page as number;
        props.setCurrentPageCallback(page);
      }
    };

    const prevPage = () => {
      if (currentPage.value > min.value) {
        currentPage.value -= 1;
        props.setCurrentPageCallback(currentPage.value);
      }
    };

    const nextPage = () => {
      if (currentPage.value < max.value) {
        currentPage.value += 1;
        props.setCurrentPageCallback(currentPage.value);
      }
    };

    watch(
      currentPage,
      (currentPage, oldPage) => {
        if (currentPage === oldPage) return;
        emit('update:initial-page', currentPage);
      },
    );

    watch(
      () => props.initialPage,
      (initialPage) => currentPage.value = initialPage,
    );

    return {
      ELLIPSIS,
      I18n: {
        t: I18n.t,
      },
      currentPage,
      nextPage,
      pageClass,
      pageRange,
      prevPage,
      setCurrentPage,
    };
  },
});

function pageClass(currentPage: number, page: number | string) {
  return (
    page === currentPage && 'active' ||
    page === ELLIPSIS && 'disabled' ||
    ''
  );
}

function generatePageRange(currentPage: number, lastPage: number, visibleNavSize: number) {
  const range = [];

  for (let i = Math.max(2, currentPage - visibleNavSize); i <= Math.min((lastPage - 1), currentPage + visibleNavSize); i++) {
    range.push(i);
  }

  (currentPage - visibleNavSize) > 2 && range.unshift(ELLIPSIS);
  (currentPage + visibleNavSize) < (lastPage - 1) && range.push(ELLIPSIS);
  range.unshift(1);
  lastPage > 1 && range.push(lastPage);

  return range;
}
</script>
