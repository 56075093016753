<template>
  <div class="resume-card-item form">
    <div class="form-group-wrap">
      <label class="form-group-title">
        {{ I18n.t('resume.education') }} {{ index + 1 }}
      </label>

      <div class="form-row">
        <div class="col-4">
          <div class="form-group form-required">
            <label>
              {{ I18n.t('section.education.name') }}
            </label>

            <input
              v-model.trim="newEducation.name"
              class="form-control"
              :class="inputValidationClass($v.name)"
              type="text"
              name="name"
              autocomplete="off"
              :placeholder="I18n.t('section.education.placeholder.name')"
              @blur="$v.name.$touch"
            >
          </div>
        </div>

        <div class="col-4">
          <div class="form-group form-required">
            <label>
              {{ I18n.t('section.education.major') }}
            </label>

            <input
              v-model="newEducation.major"
              class="form-control"
              :class="inputValidationClass($v.major)"
              type="text"
              name="major"
              autocomplete="off"
              :placeholder="I18n.t('section.education.placeholder.major')"
              @blur="$v.major.$touch"
            >
          </div>
        </div>

        <div class="col-4">
          <div class="form-group form-required">
            <label>
              {{ I18n.t('section.education.degree') }}
            </label>

            <input
              v-model="newEducation.degree"
              class="form-control"
              :class="inputValidationClass($v.degree)"
              type="text"
              name="degree"
              autocomplete="off"
              :placeholder="I18n.t('section.education.placeholder.degree')"
              @blur="$v.degree.$touch"
            >
          </div>
        </div>

        <div class="col-date col-4">
          <Datepicker
            :value="newEducation.startAt"
            :label="I18n.t('section.education.start_at')"
            :to="newEducation.endAt || maxDate"
            :class-name="inputValidationClass($v.startAt)"
            class="form-group form-required"
            name="startAt"
            @initialize="handleInitialize"
            @select="setStartAt"
          />
        </div>

        <div class="col-date col-4">
          <Datepicker
            :value="newEducation.endAt"
            :from="newEducation.startAt || minDate"
            :label="I18n.t('section.education.end_at')"
            class="form-group"
            name="endAt"
            @initialize="() => setEndAt('')"
            @select="setEndAt"
          />
        </div>

        <div class="col-2">
          <div class="form-group">
            <label :class="style.absoluteLabel">
              {{ I18n.t('section.education.my_score') }}
            </label>

            <input
              v-model="newEducation.creditScore"
              class="form-control"
              :class="inputValidationClass($v.creditScore)"
              type="number"
              name="creditScore"
              autocomplete="off"
              placeholder="3.5"
              min="0"
              max="5.0"
              step="0.1"
            >
          </div>
        </div>

        <div class="col-2">
          <div class="form-group">
            <label :class="style.absoluteLabel">
              {{ I18n.t('section.education.perfect_score') }}
            </label>

            <input
              v-model="newEducation.perfectCreditScore"
              class="form-control"
              :class="inputValidationClass($v.perfectCreditScore)"
              type="number"
              name="perfectCreditScore"
              autocomplete="off"
              placeholder="4.5"
              min="0"
              max="5.0"
              step="0.1"
            >
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label>
              {{ I18n.t('terms.else') }}
            </label>

            <textarea
              v-model="newEducation.description"
              class="form-control"
              name="description"
              rows="3"
              autocomplete="off"
              :placeholder="
                I18n.t('section.placeholder', {
                  type: I18n.t('resume.education'),
                })
              "
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="form-btn-wrap-lg">
      <button class="btn btn-md btn-light" @click="handleCancelEdit">
        {{ I18n.t('actions.cancel') }}
      </button>
      <button class="btn btn-md btn-primary" @click="handleSubmit">
        {{ I18n.t('actions.save') }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import {
  PropType,
  defineComponent,
  inject,
  reactive,
  unref,
  useCssModule,
} from '@vue/composition-api';
import { useVuelidate } from '@vuelidate/core';
import { between, required } from '@vuelidate/validators';
import { handleUpdateForm, useForm } from '../../hooks/form';
import { ResumeShowStates, getResume } from '../../hooks/state';
import constants from '../../resume.constant';
import { inputValidationClass } from '../../resume.util';
import Datepicker from '../Datepicker.vue';
import { ResumeEducation } from '@/exportables/models/career/resume.model';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';
import { cloneArray } from '@/helpers/utils';

type TargetKey = 'perfectCreditScore' | 'creditScore';
type EducationKey = keyof ResumeEducation;

const { minDate, maxDate } = constants.DATEPICKER;

export default defineComponent({
  name: 'ResumeEducationForm',
  components: {
    Datepicker,
  },
  props: {
    isAdded: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
    education: {
      type: Object as PropType<ResumeEducation>,
      required: true,
    },
  },
  setup(props) {
    const changeEditState = inject<Function>('changeEditState')!;
    const { newItem } = inject<ReturnType<typeof useForm>>('form')!;
    const state = inject<ResumeShowStates>('state')!;
    const newEducation = reactive(new ResumeEducation(props.education));
    const style = useCssModule();
    const I18n = useI18n();
    const rules = {
      name: { required },
      major: { required },
      degree: { required },
      startAt: { required },
      perfectCreditScore: {
        between: between(0, 5),
        compareScore: compareScoreValidator('perfectCreditScore'),
      },
      creditScore: {
        between: between(0, 5),
        compareScore: compareScoreValidator('creditScore'),
      },
    };
    const $v = useVuelidate(rules, newEducation);

    const setEducation =
      (key: EducationKey) => (newValue: ResumeEducation[EducationKey]) => {
        Object.assign(newEducation, { [key]: newValue });
      };

    const setStartAt = setEducation('startAt');
    const setEndAt = setEducation('endAt');

    function compareScoreValidator(targetKey: TargetKey) {
      return (value: string) => {
        const parsed = parseFloat(value);

        if (targetKey === 'creditScore') {
          const perfectCreditScore = parseFloat(
            newEducation.perfectCreditScore,
          );
          if (Number.isNaN(parsed) && Number.isNaN(perfectCreditScore))
            return true;
          return !perfectCreditScore || perfectCreditScore >= parsed;
        }

        if (targetKey === 'perfectCreditScore') {
          const creditScore = parseFloat(newEducation.creditScore);
          if (Number.isNaN(parsed)) return true;
          return !creditScore || parsed >= creditScore;
        }

        return false;
      };
    }

    const handleInitialize = () => {
      setStartAt('');
      setEndAt('');
    };

    const handleCancelEdit = () => {
      props.isAdded && Object.assign(newItem, { value: null });
      changeEditState(false);
    };

    const handleSubmit = () => {
      const unreffed$v = unref($v);
      const resume = getResume(state);
      const educations = cloneArray(resume.educations);

      unreffed$v.$touch();

      if (unreffed$v.$error || unreffed$v.$invalid) {
        appendToast(I18n.t('resume.error.form'));
        return;
      }

      props.isAdded
        ? educations.push(newEducation)
        : educations.splice(props.index, 1, newEducation);

      handleUpdateForm(state, { educations });
      changeEditState(false);

      Object.assign(newItem, { value: null });
    };

    return {
      $v,
      I18n: { t: I18n.t },
      handleCancelEdit,
      handleInitialize,
      handleSubmit,
      inputValidationClass,
      maxDate,
      minDate,
      newEducation,
      setEndAt,
      setStartAt,
      style,
    };
  },
});
</script>

<style lang="scss" module>
.absoluteLabel {
  &::after {
    position: absolute;
  }
}
</style>
