<template>
  <section
    id="top-filter-form"
    ref="filterFormRef"
    class="top-positions-filter"
    :class="{
      open: isMobileFilterOpened,
      sticky: isFilterBarSticky,
    }"
  >
    <div id="search-form" class="container control-panel">
      <div class="m-filter-header">
        <h4 class="title">
          {{ I18n.t('job_position.list.search_filter.title') }}
        </h4>
        <button type="button" class="m-filter-close" @click="closeMobileFilter">
          <span class="bar"></span>
          <span class="bar"></span>
        </button>
      </div>

      <div class="list-forms job-position-search">
        <JobCategoryFilter />
        <TagSearchFilter />
        <CompanySearchFilter />
      </div>

      <div class="list-forms job-position-forms">
        <div class="form-group" :class="style.between">
          <MinCareerFilter />
          <MinSalaryFilter />
        </div>

        <div class="form-group" :class="style.between">
          <MinEmployeesFilter />
          <LocationFilter />
        </div>

        <JobThemeFilter />
      </div>

      <FilterChipList />
    </div>
  </section>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  ref,
  Ref,
  inject,
  onMounted,
  onUnmounted,
  unref,
  useCssModule,
} from '@vue/composition-api';
import CompanySearchFilter from './CompanySearchFilter.vue';
import FilterChipList from './FilterChipList.vue';
import JobCategoryFilter from './JobCategoryFilter.vue';
import JobThemeFilter from './JobThemeFilter.vue';
import LocationFilter from './LocationFilter.vue';
import MinCareerFilter from './MinCareerFilter.vue';
import MinEmployeesFilter from './MinEmployeesFilter.vue';
import MinSalaryFilter from './MinSalaryFilter.vue';
import TagSearchFilter from './TagSearchFilter.vue';
import { useI18n } from '@/helpers/i18n';
import { debounce, throttle } from '@/helpers/lazy';

export default defineComponent({
  name: 'JobPositionSearchFilter',
  components: {
    CompanySearchFilter,
    FilterChipList,
    JobCategoryFilter,
    JobThemeFilter,
    LocationFilter,
    MinCareerFilter,
    MinEmployeesFilter,
    MinSalaryFilter,
    TagSearchFilter,
  },
  setup() {
    const isMobileFilterOpened = inject<Ref>('isMobileFilterOpened')!;
    const isMobileWidth = ref(false);
    const isScrollUnderFilterForm = ref(false);
    const filterFormRef = ref<HTMLElement | null>(null);
    const filterFormTop = ref(0);
    const I18n = useI18n();
    const style = useCssModule();

    const isFilterBarSticky = computed(
      () => !isMobileWidth.value && isScrollUnderFilterForm.value,
    );

    const closeMobileFilter = () => (isMobileFilterOpened.value = false);

    const updateIsMobileWidth = () => {
      isMobileWidth.value = window.matchMedia('( max-width: 767px )').matches;
    };

    const updateIsFilterBarSticky = () => {
      const { top } = unref(filterFormRef)?.getBoundingClientRect() || {};
      top && Number(top) > unref(filterFormTop) && (filterFormTop.value = top);

      isScrollUnderFilterForm.value = window.scrollY > unref(filterFormTop);
    };

    let updateIsFilterBarStickyTimeout:
    | ReturnType<typeof setTimeout>
    | undefined;
    const handleScroll = throttle(() => {
      updateIsFilterBarSticky();
      updateIsFilterBarStickyTimeout &&
        clearTimeout(updateIsFilterBarStickyTimeout);
      updateIsFilterBarStickyTimeout = setTimeout(updateIsFilterBarSticky, 100);
    }, 100);

    const handleResize = debounce(updateIsMobileWidth, 100);

    onMounted(() => {
      updateIsMobileWidth();
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleResize);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    });

    return {
      I18n,
      closeMobileFilter,
      filterFormRef,
      isFilterBarSticky,
      isMobileFilterOpened,
      style,
    };
  },
});
</script>

<style lang="scss" module>
@use '~/stylesheets/display';
@use '~/stylesheets/functions';

.between {
  @extend .flex-between;
  gap: functions.rem-calc(8);
}
</style>
