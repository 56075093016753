import { convertDate } from '../../../helpers/datetime';
import { Status } from '@/exportables/models/career/job-offer.model';
import { useI18n } from '@/helpers/i18n';

const I18n = useI18n();

const datetimeOptions = {
  withTime: true,
};

export const datetime = (date: string, options: typeof datetimeOptions = datetimeOptions) => {
  if (!date) return '';
  const d = convertDate(date);
  return I18n.l(
    options.withTime ? 'time.formats.number' : 'time.formats.number_without_time',
    d.toISOString(),
  );
};

export const statusColor = (status: Status) => (
  status === 'offered' && 'yellow' ||
  status === 'applied' && 'blue' ||
  'gray'
);

export const addCount = (items: { count: number }[]) => items.reduce((acc, cur) => acc += cur.count, 0);
