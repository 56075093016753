<template>
  <Card v-if="isEditable || pccp.length || pcce.length" :is-editable="isEditable">
    <template #title>
      <h4 class="resume-card-header-title">
        {{ I18n.t('section.certificate.title') }}
        <span
          v-if="isEditable"
          v-tooltip:top="{
            message: I18n.t('section.certificate.title_help_tooltip_message'),
            width: '180px',
            isMountBody: true,
            style: { fontWeight: 400, wordBreak: 'keep-all' },
          }"
          class="btn-help dark"
        >
          <SvgIcon name="ic-help-full" html-class="ic-20" />
        </span>
      </h4>
    </template>

    <template #control>
      <button
        v-if="isEditable"
        class="btn-light resume-card-btn-add"
        @click="openModal"
      >
        <SvgIcon name="ic-add" html-class="ic-24" />
        <span>
          {{ I18n.t('actions.add') }}
        </span>
      </button>
    </template>

    <template #body>
      <ul v-if="pccp.length" class="certificate" :class="style.list">
        <li
          v-for="info in pccp"
          :key="info.id"
          class="resume-card-item"
        >
          <div class="resume-card-left">
            <h5 v-if="info.startAt" class="resume-card-item-period">
              {{ I18n.l('date.formats.year_month', info.startAt) }} ~
              <template v-if="info.expireAt">
                <br>
                {{ I18n.l('date.formats.year_month', info.expireAt) }}
              </template>
            </h5>
          </div>

          <div class="resume-card-right" :class="style.boxWrap">
            <CertificateInfo :info="info" :class="style.info" :isPrintPage="isPrintPage" />
            <table :class="style.tableBox">
              <tr>
                <td>{{ I18n.t('terms.language') }}</td>
                <td>{{ I18n.t('terms.level') }}</td>
              </tr>
              <tr>
                <td>{{ info.language }}</td>
                <td>Lv.{{ info.level }}</td>
              </tr>
            </table>
          </div>
        </li>
      </ul>

      <ul v-if="pcce.length" class="certificate" :class="style.list">
        <li
          v-for="info in pcce"
          :key="info.id"
          class="resume-card-item"
        >
          <div class="resume-card-left">
            <h5 v-if="info.startAt" class="resume-card-item-period">
              {{ I18n.l('date.formats.year_month', info.startAt) }} ~
              <template v-if="info.expireAt">
                <br>
                {{ I18n.l('date.formats.year_month', info.expireAt) }}
              </template>
            </h5>
          </div>

          <div class="resume-card-right" :class="style.boxWrap">
            <CertificateInfo :info="info" :class="style.info" :isPrintPage="isPrintPage" />
            <table :class="style.tableBox">
              <tr>
                <td>{{ I18n.t('terms.language') }}</td>
                <td>{{ I18n.t('terms.level') }}</td>
              </tr>
              <tr>
                <td>{{ info.language }}</td>
                <td>Lv.{{ info.level }}</td>
              </tr>
            </table>
          </div>
        </li>
      </ul>
    </template>
  </Card>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  unref,
  useCssModule,
  watch,
  inject,
} from '@vue/composition-api';
import Card from './Card.vue';
import CertificateInfo from './CertificateInfo.vue';
import CertificatesModal from './modal/Certificates.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { tooltip } from '@/directives';
import { useI18n } from '@/helpers/i18n';
import { useModal } from '@/helpers/modal';
import { getResume, ResumeShowStates } from '@/pages/career/resumes/hooks/state';
import { useStore } from '@/store/career';
import {
  SET_RESUME_CERTIFICATES,
} from '@/store/career/resume/mutation-types';

export default defineComponent({
  name: 'ResumeCertificates',
  components: {
    Card,
    CertificateInfo,
    SvgIcon,
  },
  directives: {
    tooltip,
  },
  props: {
    isPrintPage: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const I18n = useI18n();
    const modal = useModal();
    const store = useStore();
    const state = inject<ResumeShowStates>('state')!;
    const certificates = computed(() => getResume(state).certificates);
    const style = useCssModule();
    const pcce = computed(() => store.state.resume.resumeCertificates.filter(
      ({ examType }) => examType === 'PCCE',
    ));
    const pccp = computed(() => store.state.resume.resumeCertificates.filter(
      ({ examType }) => examType === 'PCCP',
    ));
    const isEditable = computed(() => store.state.resume.isEditable);

    const openModal = () => modal.show(CertificatesModal);

    watch(
      certificates,
      (certificates) => {
        store.commit(`resume/${SET_RESUME_CERTIFICATES}`, unref(certificates));
      },
      { immediate: true },
    );

    return {
      I18n,
      isEditable,
      openModal,
      pcce,
      pccp,
      style,
    };
  },
});
</script>

<style lang="scss" module>
@use "~/stylesheets/functions";
@use "~/stylesheets/variables";

.list {
  width: 100%;
  padding: 0;
  margin: 0;

  &:first-of-type {
    li:last-of-type {
      .boxWrap {
        border-bottom: 1px solid variables.$color-border;
        padding-bottom: functions.rem-calc(24);
      }
    }
  }

  li {
    width: 100%;
  }
}

.info {
  h6 {
    font-size: functions.rem-calc(16);

    &:last-of-type {
      display: none;
    }
  }
}

.boxWrap {
  display: flex;
  flex-direction: column;
  gap: functions.rem-calc(8);
}

.tableBox {
  border: 1px solid variables.$color-border;
  > tr {
    border-bottom: 1px solid variables.$color-border;
  }
  td {
    padding: functions.rem-calc(4 16);
    font-size: functions.rem-calc(14);
  }
}

.box {
  border: 1px solid variables.$color-border;
  border-radius: functions.rem-calc(4);
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  padding: 0;

  > li {
    font-size: functions.rem-calc(14);
    border-top: 1px solid variables.$color-border;
    border-left: 1px solid variables.$color-border;
    padding: functions.rem-calc(4 16);

    &:nth-of-type(2),
    &:last-of-type {
      border-left: none;
    }

    @media (variables.$sm-up) {
      &:first-of-type,
      &:nth-of-type(2) {
        border-top: none;
      }

      &:first-of-type,
      &:nth-of-type(3) {
        border-left: none;
      }
    }
  }
}

@media (variables.$sm-down) {
  .list {
    &:first-of-type {
      li:last-of-type {
        .boxWrap {
          border-bottom: 1px solid variables.$color-border;
          padding-bottom: functions.rem-calc(12);
        }
      }
    }
  }

  .info {
    flex-wrap: wrap;

    h4 {
      white-space: initial;
    }

    h6 {
      font-size: functions.rem-calc(15);
    }

    img {
      display: block;
    }
  }

  .box {
    border: none;
    margin-top: functions.rem-calc(8);
    grid-template-columns: 1fr 2fr;

    > li {
      font-size: functions.rem-calc(13);
      border: none;
      border-top: 1px solid variables.$color-border;
      padding: functions.rem-calc(4 8);

      &:nth-of-type(2) {
        order: 3;
      }

      &:nth-of-type(3) {
        order: 4;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@use "~/stylesheets/functions";
@use "~/stylesheets/variables";

.resume-card-item {
  border: none;
  padding: 0;
  padding-top: functions.rem-calc(24);
}

.test-box {
  gap: functions.rem-calc(8);
}

@media (variables.$sm-down) {
  .resume-card-item-period {
    display: none;
  }
}
</style>
