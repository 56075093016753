import { JobTheme } from '@/exportables/models/career/job-theme.model';
import { Tag } from '@/exportables/models/career/tag.model';

export type JobPositionState = {
  jobCategories: Tag[],
  jobThemes: JobTheme[],
};

const state: JobPositionState = {
  jobCategories: [],
  jobThemes: [],
};

export default state;
