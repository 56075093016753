<template>
  <div id="job-application-display" class="resume__preview">
    <div class="resume__content">
      <FloatingButton
        v-if="isPrintable"
        :tooltip-title="I18n.t('print.title', { type: I18n.t('terms.resume') })"
      >
        <template #body>
          <button
            class="btn btn-svg btn-view-print"
            @click="handleClickPrintButton"
          >
            <SvgIcon name="ic-print" html-class="ic-24" />
          </button>
        </template>
      </FloatingButton>

      <div class="resume__summary">
        <PersonalInformation v-skeleton="isLoading" :resume="state.resume" />
        <Repository v-skeleton="isLoading" />
        <Stacks v-skeleton="isLoading" />

        <footer>
          <a class="link-brand" href="https://career.programmers.co.kr/">
            {{ I18n.t('career_service_name') }}
          </a>
        </footer>
      </div>

      <div class="resume__detail tryout-tokens-show">
        <section class="alert alert-info alert-resume-show">
          <span>{{ registrationMessage }}</span>
        </section>
        <Timeline v-skeleton="isLoading" />
        <Experiences v-skeleton="{ value: isLoading, height: '50px' }" />
        <Educations v-skeleton="isLoading" />
        <Projects v-skeleton="isLoading" />
        <Awards v-skeleton="isLoading" />
        <Certificates v-skeleton="isLoading" />
        <Publications v-skeleton="isLoading" />
        <TestResults v-skeleton="isLoading" />
        <Activities v-skeleton="isLoading" />
        <ForeignLanguages v-skeleton="isLoading" />
        <Attachment v-skeleton="isLoading" />
        <Customs v-skeleton="isLoading" />
        <JobFairApplications
          v-if="jobPositions.length > 0"
          :job-positions="jobPositions"
          :is-preference="isPreference"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  provide,
  reactive,
  ref,
  watch,
} from '@vue/composition-api';
import FloatingButton from '@/components/shared/FloatingButton.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { skeleton } from '@/directives';
import {
  initialize,
  RegistrationShowState,
} from '@/exportables/apis/career/registration.api';
import { Registration } from '@/exportables/models/career/registration.model';
import { Resume } from '@/exportables/models/career/resume.model';
import { Tokens } from '@/exportables/models/career/tokens.model';
import { useI18n } from '@/helpers/i18n';
import { useModal } from '@/helpers/modal';
import JobFairApplications from '@/pages/career/job-applications/components/JobFairApplications.vue';
import Activities from '@/pages/career/resumes/components/Activities.vue';
import Attachment from '@/pages/career/resumes/components/Attachment.vue';
import Awards from '@/pages/career/resumes/components/Awards.vue';
import Certificates from '@/pages/career/resumes/components/Certificates.vue';
import Customs from '@/pages/career/resumes/components/Customs.vue';
import Educations from '@/pages/career/resumes/components/Educations.vue';
import Experiences from '@/pages/career/resumes/components/Experiences.vue';
import ForeignLanguages from '@/pages/career/resumes/components/ForeignLanguages.vue';
import PersonalInformation from '@/pages/career/resumes/components/PersonalInformation.vue';
import Projects from '@/pages/career/resumes/components/Projects.vue';
import Publications from '@/pages/career/resumes/components/Publications.vue';
import Repository from '@/pages/career/resumes/components/Repository.vue';
import Stacks from '@/pages/career/resumes/components/Stacks.vue';
import TestResults from '@/pages/career/resumes/components/TestResults.vue';
import Timeline from '@/pages/career/resumes/components/Timeline.vue';
import { usePrint } from '@/pages/career/resumes/hooks/print';
import { useStore } from '@/store/career';
import { SET_RESUME } from '@/store/career/resume/mutation-types';

const I18n = useI18n();

export default defineComponent({
  name: 'RegistrationShowPage',
  components: {
    Activities,
    Attachment,
    Awards,
    Certificates,
    Customs,
    Educations,
    Experiences,
    FloatingButton,
    ForeignLanguages,
    JobFairApplications,
    PersonalInformation,
    Projects,
    Publications,
    Repository,
    Stacks,
    SvgIcon,
    TestResults,
    Timeline,
  },
  directives: {
    skeleton,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup({ id }) {
    const state = reactive<RegistrationShowState>({
      registration: Registration.new(),
      resume: Resume.new(),
      tokens: Tokens.new(),
    });
    const isLoading = ref(false);
    const registrationMessage = computed(() => {
      const { registration } = state;

      return registration.registrationGroup?.title &&
        registration?.competition?.showRegistrationGroup
        ? I18n.t('job_application.applied_with_registration', {
          datetime: I18n.l('time.formats.detail', registration.createdAt!),
          title: registration?.competition?.title,
          registration: registration?.registrationGroup?.title,
        })
        : I18n.t('job_application.applied', {
          datetime: I18n.l('time.formats.detail', registration.createdAt!),
          title: registration?.competition?.title,
        });
    });
    const jobPositions = computed(() => state.registration?.jobPositions || []);
    const isPreference = computed(
      () => state.registration?.competition?.isPreference || false,
    );

    const modal = useModal();
    const print = usePrint(state);
    const store = useStore();

    const openModal = async (modalName: string, props?: any) => {
      const { default: Component } = await import(
        `@/pages/career/job-applications/components/modal/${modalName}.vue`
      );
      modal.show(Component, props, undefined, { provide: { state } });
    };

    watch(state, (state) => {
      store.commit(`resume/${SET_RESUME}`, state.resume);
    });

    onMounted(async () => {
      isLoading.value = true;
      await initialize(state, id);
      isLoading.value = false;
    });

    provide('isLoading', isLoading);
    provide('modal', modal);
    provide('openModal', openModal);
    provide('state', state);

    return {
      ...print,
      I18n: { t: I18n.t },
      isLoading,
      isPreference,
      jobPositions,
      registrationMessage,
      state,
    };
  },
});
</script>
