<template>
  <Posts
    :posts="posts"
    :is-feed="isFeed"
    :has-user-feed-tags="hasUserFeedTags"
    :current-page="currentPage"
    :total-page="totalPage"
    @clickNavTab="handleClickNavTab"
    @clickPage="handleClickPage"
  />
</template>

<script lang="ts">
import { computed, defineComponent, toRefs } from '@vue/composition-api';
import Posts from '../components/Posts.vue';
import { FeedTab, FEED_TAB } from '../constants';
import { useRouter } from '@/router/career';
import { useStore } from '@/store/career';

const DEFAULT_PAGE = 1;

export default defineComponent({
  name: 'PostsContainer',
  components: {
    Posts,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const {
      posts,
      currentPage,
      totalPage,
      feedTab,
    } = toRefs(store.state.post);

    const isFeed = computed(() => feedTab.value === FEED_TAB.feed);
    const hasUserFeedTags = computed(() => store.getters['post/hasUserFeedTags']);

    const handleClickNavTab = (tab: FeedTab) => {
      router.push(`/posts?page=${DEFAULT_PAGE}&feed=${tab === FEED_TAB.feed}`);
    };

    const handleClickPage = (page: number) => {
      window.scrollTo(0, 0);

      if (!hasUserFeedTags.value) {
        router.push(`/posts?page=${page}&feed=false`);
        return;
      }

      router.push(`/posts?page=${page}&feed=${feedTab.value === FEED_TAB.feed}`);
    };

    return {
      posts,
      currentPage,
      totalPage,
      isFeed,
      hasUserFeedTags,
      handleClickNavTab,
      handleClickPage,
    };
  },
});
</script>
