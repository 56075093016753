<script lang="ts">
import {
  PropType,
  computed,
  defineComponent,
  h,
  provide,
  ref,
  unref,
} from '@vue/composition-api';
import { resumeChildComponents as ChildComponents } from '../hooks/import';
import { Type, pluralType } from '../resume.util';
import { pascalize } from '@/helpers/string';
import { useStore } from '@/store/career';
import { SET_EDITING_PART_COUNT } from '@/store/career/resume/mutation-types';

export default defineComponent({
  props: {
    isAdded: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    info: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String as PropType<Type>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const isEditing = ref(false);
    const pascalizedType = computed(() => pascalize(props.type));
    const childComponents = computed(() =>
      ChildComponents(unref(pascalizedType)));

    const changeEditState = (editState: boolean) => {
      const category = pluralType[props.type];
      store.commit(`resume/${SET_EDITING_PART_COUNT}`, {
        category,
        delta: editState ? 1 : -1,
      });
      Object.assign(isEditing, { value: editState });
    };

    provide('changeEditState', changeEditState);

    return () => {
      const unrefedChildComponets = unref(childComponents);
      const unrefedPascalizedType = unref(pascalizedType);
      const key = `${unrefedPascalizedType}${
        unref(isEditing) || props.isAdded ? 'Form' : 'Preview'
      }`;

      return h('component', {
        is: unrefedChildComponets[key],
        class: props.type,
        components: {
          ...unrefedChildComponets,
        },
        name: `Resume${unrefedPascalizedType}`,
        props: {
          isAdded: props.isAdded,
          index: props.index,
          [props.type]: props.info,
        },
      });
    };
  },
});
</script>
