<template>
  <div>
    <RegistrationGroup
      v-if="requiredRegistrationGroup"
      v-model="registrationGroupId"
      :registration-groups="registrationGroups"
      @update:registrationGroupId="setRegistrationGroupId"
    />
    <h6 v-if="errors($v.registrationGroupId) && $v.$dirty" class="error-feedback">
      <SvgIcon html-class="ic-14" name="ic-care-center-14" />
      <span>{{ errors($v.registrationGroupId) }}</span>
    </h6>

    <fieldset>
      <label class="control-label required">
        {{ I18n.t('job_application.job_position_step.select_position') }}
        <span v-if="currentSelectedCount > 0" class="label-caption">
          {{ I18n.t('job_application.job_position_step.current_selelct', { number: currentSelectedCount }) }}
        </span>
      </label>

      <div class="alert alert-light">
        {{ positionSelectMessage }}
      </div>

      <div class="form-group">
        <template v-for="(jobPosition, index) of jobPositions">
          <hr :key="index">
          <div :key="jobPosition.id" class="job-position-item">
            <label
              :set="notSuitableMinCareer = notSuitableMinCareerJobPosition(jobPosition)"
              class="job-position-checkbox"
              :class="{ 'input-checkbox': !autoSelected }"
            >
              <span v-tooltip:top="checkboxTooltipMessage(notSuitableMinCareer)">
                <input
                  v-model="selectedJobPositions"
                  type="checkbox"
                  rel="job-position-inputs"
                  :value="jobPosition.id"
                  :disabled="notSuitableMinCareer || autoSelected"
                >
              </span>
              <div class="input-checkbox-label" :class="{ disabled: notSuitableMinCareer }">
                <b class="company-name">{{ jobPosition.company.name }}</b> ― <span class="title">{{ jobPosition.title }}</span> <span class="career">({{ jobPosition.career }})</span>
                <span
                  v-if="notSuitableMinCareer"
                  v-tooltip:top="I18n.t('job_application.job_position_step.need_min_career')"
                  class="label label-warning"
                >
                  {{ I18n.t('job_application.job_position_step.not_suitable_career') }}
                </span>
              </div>
            </label>
            <a
              :href="`/job_positions/${jobPosition.id}`"
              target="_blank"
              class="position-view"
            >
              {{ I18n.t('job_application.job_position_step.view_position') }}
            </a>
          </div>
        </template>
        <hr v-if="jobPositions.length">
      </div>
    </fieldset>

    <h6 v-if="errors($v.selectedJobPositions) && $v.$dirty" class="error-feedback">
      <SvgIcon html-class="ic-14" name="ic-care-center-14" />
      <span>{{ errors($v.selectedJobPositions) }}</span>
    </h6>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { useVuelidate, Validation } from '@vuelidate/core';
import { helpers, maxLength, required, requiredIf } from '@vuelidate/validators';
import RegistrationGroup from './RegistrationGroup.vue';
import { tooltip } from '@/directives';
import { Response } from '@/exportables/models/job-application.model';
import { RegistrationJobPosition } from '@/exportables/models/job-position.model';
import { RegistrationGroup as RegistrationGroupType } from '@/exportables/models/registration.model';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'JobApplicationJobPositionStep',
  components: {
    RegistrationGroup,
  },
  directives: {
    tooltip,
  },
  props: {
    autoSelected: {
      type: Boolean,
      default: false,
    },
    jobPositions: {
      type: Array as PropType<RegistrationJobPosition[]>,
      required: true,
    },
    response: {
      type: Object as PropType<Response>,
      required: true,
    },
    registrationGroups: {
      type: Array as PropType<RegistrationGroupType[]>,
      default: () => [],
    },
    applicationLimit: {
      type: Number,
      default: null,
    },
    multiAppliable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:updateSelectedJobPositions', 'update:updateRegistrationGroup'],
  setup(props, { emit }) {
    const I18n = useI18n();

    const registrationGroupId = computed({
      get: () => props.response.registrationGroupId,
      set: (id) => {
        emit('update:updateRegistrationGroup', id);
      },
    });
    const selectedJobPositions = computed({
      get: () => props.response.jobPositions || [],
      set: (value) => {
        emit('update:updateSelectedJobPositions', value);
      },
    });
    const companyIds = computed(() => [...new Set(props.jobPositions.map((jobPosition) => jobPosition.company.id))]);
    const currentSelectedCount = computed(() => selectedJobPositions.value.length);
    const requiredRegistrationGroup = computed(() => props.registrationGroups.length > 1);
    const selectableCount = computed(() => Math.min(props.applicationLimit, props.jobPositions.length));
    const hasDuplicateCompanyId = () => {
      if (props.multiAppliable) return true;
      const companyIds = new Set(selectedJobPositions.value.map((selectedJobPosition) => getCompanyId(selectedJobPosition)));
      return companyIds.size === selectedJobPositions.value.length;
    };
    const positionSelectMessage = computed(() => {
      if (!registrationGroupId.value) return I18n.t('job_application.job_position_step.select_group_first');
      else if (props.autoSelected) return I18n.t('job_application.job_position_step.select_auto');
      else if (selectableCount.value === 1) return I18n.t('job_application.job_position_step.select_position_only_one');
      else if (props.multiAppliable) return I18n.t('job_application.job_position_step.select_position_max', { number: selectableCount.value });
      else if (companyIds.value.length === 1) return I18n.t('job_application.job_position_step.select_position_only_one');
      return I18n.t('job_application.job_position_step.select_position_max_per_compnay', { number: selectableCount.value });
    });
    const checkboxTooltipMessage = (notSuitableMinCareer: boolean) => {
      if (props.autoSelected) return I18n.t('job_application.job_position_step.select_auto');
      else if (notSuitableMinCareer) return I18n.t('job_application.job_position_step.not_suitable_min_career');
      return '';
    };

    const errors = (field: Validation) => field.$errors.length ? field.$errors[0].$message : '';

    const setRegistrationGroupId = (id: number) => {
      registrationGroupId.value = id;
      emit('update:updateRegistrationGroup', id);
    };

    const getCompanyId = (jobPositionId: number) => {
      for (const jobPosition of props.jobPositions) {
        if (jobPosition.id === jobPositionId) return jobPosition.company.id;
      }
      return null;
    };

    const getMinCareer = (careerRangeStr: String) => careerRangeStr.split('...')[0];

    const notSuitableMinCareerJobPosition = (jobPosition: RegistrationJobPosition) => {
      const selectedResume = props.response.resume;
      if (!jobPosition.minCareerRequired || !jobPosition.careerRange) return false;
      const minCareer = getMinCareer(jobPosition.careerRange);
      return selectedResume!.devEmploymentDuration! < Number(minCareer) * 12;
    };

    const rules = {
      selectedJobPositions: {
        required: helpers.withMessage(I18n.t('job_application.job_position_step.must_select_position'), required),
        maxLength: helpers.withMessage(I18n.t('job_application.job_position_step.select_max', { number: props.applicationLimit })
          , maxLength(props.applicationLimit)),
        duplicateCompany: helpers.withMessage(I18n.t('job_application.job_position_step.apply_per_company_only_one'), hasDuplicateCompanyId),
      },
      registrationGroupId: {
        requiredRegistrationGroupId: helpers.withMessage(I18n.t('job_application.job_position_step.select_group'), requiredIf(() => requiredRegistrationGroup.value)),
      },
    };

    const $v = useVuelidate(rules, { selectedJobPositions, registrationGroupId });

    const isValidated = () => {
      $v.value.$touch();
      return !$v.value.$invalid;
    };

    return {
      $v,
      I18n,
      checkboxTooltipMessage,
      companyIds,
      currentSelectedCount,
      errors,
      getMinCareer,
      hasDuplicateCompanyId,
      isValidated,
      notSuitableMinCareerJobPosition,
      positionSelectMessage,
      registrationGroupId,
      requiredRegistrationGroup,
      selectableCount,
      selectedJobPositions,
      setRegistrationGroupId,
    };
  },
});
</script>
