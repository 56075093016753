import { useI18n } from '@/helpers/i18n';

const I18n = useI18n();

export const datetime = (date: string) => (
  date ? I18n.l('time.formats.number', date) : ''
);

export const computeSteps = (
  resumeRequired: boolean,
  hasCustomForm: boolean,
  hasJobPosition: boolean,
  isPreference: boolean,
  action: string,
) => {
  const steps: Record<number, string> = {};
  let idx = 0;

  if (action === 'edit') {
    steps[idx] = 'job_position';

    if (isPreference) {
      steps[++idx] = 'preference';
    }

    steps[++idx] = 'preview';
    return steps;
  }

  if (resumeRequired) {
    steps[idx] = 'select_resume';
  } else {
    steps[idx] = 'contact';
  }

  if (hasCustomForm) {
    steps[++idx] = 'custom_form';
  }

  if (hasJobPosition) {
    steps[++idx] = 'job_position';
    if (isPreference) {
      steps[++idx] = 'preference';
    }
  }
  steps[++idx] = 'preview';

  return steps;
};
