<template>
  <div id="user-feed-modal" class="modal fade" role="document">
    <div class="modal-dialog modal-dialog-scrollable modal-tag-setting" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            {{ userName }}님의 관심 분야를 설정해주세요.
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="handleClose"
          >
            <SvgIcon name="ic-close" html-class="ic-24" />
          </button>
        </div>
        <div class="modal-body">
          <UserFeedTabsContainer />
          <div class="main-content">
            <div id="tag-setting-tabContent" class="tab-content">
              <UserFeedTabPaneContainer />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            @click="handleClose"
          >
            취소
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="handleSave"
          >
            저장
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import UserFeedTabPaneContainer from '../containers/UserFeedTabPaneContainer.vue';
import UserFeedTabsContainer from '../containers/UserFeedTabsContainer.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';

export default defineComponent({
  name: 'UserFeedModal',
  components: {
    UserFeedTabsContainer,
    UserFeedTabPaneContainer,
    SvgIcon,
  },
  props: {
    userName: {
      type: String,
      required: true,
    },
  },
  emits: ['close', 'save'],
  setup(_, { emit }) {
    const handleClose = () => emit('close');
    const handleSave = () => emit('save');

    return {
      handleClose,
      handleSave,
    };
  },
});
</script>
