import Vue from 'vue';
import Vuex from 'vuex';
import post from './post';
import resume from './resume';

Vue.use(Vuex);

export type CareerStore = {
  post: typeof post.state,
  resume: typeof resume.state,
};

const store = new Vuex.Store<CareerStore>({
  modules: {
    post,
    resume,
  },
});

export const useStore = () => store;
