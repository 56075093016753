<template>
  <div class="custom-form">
    <label :class="{ required: customForm.required }" class="control-label">
      {{ customForm.label }}
      <span v-if="!customForm.required" class="label-help">({{ I18n.t('job_application.custom_form_step.option') }})</span>
    </label>

    <div class="form-group">
      <p v-if="isPresent(description)" class="help-block">
        <MarkdownViewer
          :value="`<pre><code>${description}</code></pre>`"
          :class="['markdown-viewer']"
          :viewer-options="viewerOptions"
        />
      </p>

      <textarea
        v-if="checkFormType('textarea')"
        ref="input"
        v-model="answer"
        class="form-control"
        rows="8"
        :placeholder="I18n.t('terms.input')"
      ></textarea>

      <input
        v-else-if="checkFormType('text')"
        ref="input"
        v-model="answer"
        type="text"
        class="form-control"
        :placeholder="customForm.placeholder"
        :required="required"
      >

      <label
        v-for="(option, value) in customForm.options"
        v-else-if="checkFormType('radio')"
        :key="value"
        class="input-radio"
      >
        <input
          ref="input"
          v-model="answer"
          type="radio"
          :name="`radio-type-${customForm.id}`"
          :class="{ 'input-radio-etc': isAdditionalTextOption(option) }"
          :required="required"
          :value="value"
        > {{ option.label }}
        <input
          v-if="isAdditionalTextOption(option)"
          v-model="subjectiveAnswer[value]"
          type="text"
          class="form-control input-text-etc"
        >
      </label>

      <label
        v-for="(option, value) in customForm.options"
        v-else-if="checkFormType('checkbox')"
        :key="value"
        :class="checkboxClass(customForm.options.length)"
        class="input-checkbox"
      >
        <input
          ref="input"
          v-model="answer"
          type="checkbox"
          :class="{ 'input-radio-etc': isAdditionalTextOption(option) }"
          :value="value"
          :required="required"
        >
        {{ option.label }}
        <input
          v-if="isAdditionalTextOption(option)"
          v-model="subjectiveAnswer[value]"
          type="text"
          class="form-control input-text-etc"
          :disabled="!answer.includes(value)"
          @click.self.prevent
        >
      </label>

      <label v-else-if="checkFormType('select_box')" class="p-0 col-12">
        <select
          v-model="answer"
          class="form-control"
        >
          <option value="">
            {{ I18n.t('terms.not_optional') }}
          </option>
          <option
            v-for="(option, value) in customForm.options"
            :key="value"
            :value="value"
          >{{ option.label }}</option>
        </select>
      </label>

      <div
        v-for="(_, idx) in customForm.max_selection_count"
        v-else-if="checkFormType('ordered_select')"
        :key="idx"
        class="ordered-select"
      >
        <label>
          {{ I18n.t('terms.optional') }} {{ idx + 1 }}
        </label>
        <select
          v-model="answer[idx]"
          class="form-control"
        >
          <option value="">
            {{ I18n.t('terms.not_optional') }}
          </option>
          <option
            v-for="(option, value) in customForm.options"
            :key="value"
            :value="value"
            :disabled="disableChecked(value, idx)"
          >
            {{ option.label }} {{ selectedLabel(value, idx) }}
          </option>
        </select>
      </div>
    </div>

    <div v-if="errors($v.answer) && $v.$dirty" class="error-feedback">
      <SvgIcon html-class="ic-14" name="ic-care-center-14" />
      <span>{{ errors($v.answer) }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType, watch } from '@vue/composition-api';
import { useVuelidate, Validation } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import MarkdownViewer from '@/components/shared/MarkdownViewer.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { Option, Response } from '@/exportables/models/career/job-application.model';
import { CustomForm } from '@/exportables/models/career/resume.model';
import { useI18n } from '@/helpers/i18n';
import { isPresent } from '@/helpers/utils';

export default defineComponent({
  name: 'JobApplicationCustomForm',
  components: {
    SvgIcon,
    MarkdownViewer,
  },
  props: {
    customForm: {
      type: Object as PropType<CustomForm>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    response: {
      type: Object as PropType<Response>,
      required: true,
    },
  },
  emits: ['update:updateCustomFormAnswers', 'update:updateSubjectiveAnswer'],
  setup(props, { emit }) {
    const I18n = useI18n();
    const answer = ref<string[] | string>(props.response.customFormAnswers[props.customForm.id] || '');
    const subjectiveAnswer = ref(isPresent(props.response.customFormAnswers['subjective'])
      ? props.response.customFormAnswers['subjective']![props.customForm.id] || {} : {});
    const hasError = ref(false);

    // NOTE: CustomForm Type이 Checkbox 일 경우 Multiple Selector 이기 때문에 기본 값을 Array 로 초기화
    if ((props.customForm.form_type === 'checkbox' || props.customForm.form_type === 'ordered_select') && answer.value === '') {
      answer.value = [];
      if (props.customForm.form_type === 'ordered_select') answer.value = new Array(props.customForm.max_selection_count).fill('');
    }

    const formType = computed(() => props.customForm.form_type);
    const description = computed(() => props.customForm.description);
    const required = computed(() => props.customForm.required);

    const isAdditionalTextOption = (option: Option) => option.input_type === 'text';

    const disableChecked = (value: string, index: number) => {
      return !!(answer.value as string[]).find((answer: string, answerIndex: number) => index !== answerIndex && value === answer);
    };

    const selectedIndex = (value: string) => value === '' ? -1 : (answer.value as string[]).findIndex((answer) => value === answer);

    const selectedLabel = (value: string, index: number) => {
      const selectedIndexValue = selectedIndex(value);
      if (selectedIndexValue >= 0 && selectedIndexValue !== index) return `(선택 ${selectedIndexValue + 1})`;
      return '';
    };

    const checkboxClass = (count: number) => count >= 10 ? 'col-6' : 'col-12';

    const checkFormType = (targetFormType: string) => formType.value === targetFormType;

    const isFilled = (answer: string[] | string) => {
      if (required.value) {
        if (checkFormType('ordered_select')) return !answer.includes('');
        else return isPresent(answer);
      }
      return true;
    };

    const isNotDuplicated = (answer: string[] | string) => {
      if (checkFormType('ordered_select')) {
        const notEmptyAnswer = (answer as string[]).filter((elem) => elem !== '');
        if (notEmptyAnswer.length !== new Set(notEmptyAnswer).size) return false;
      }
      return true;
    };

    const rules = {
      answer: {
        required: helpers.withMessage(I18n.t('job_application.custom_form_step.input_required'), isFilled),
        duplicated: helpers.withMessage(I18n.t('job_application.custom_form_step.select_no_duplicate'), isNotDuplicated),
      },
    };

    const $v = useVuelidate(rules, { answer });

    const isValidated = () => {
      $v.value.$touch();
      return !$v.value.$invalid;
    };

    const errors = (field: Validation) => field.$errors.length ? field.$errors[0].$message : '';

    watch(answer, (newVal, oldVal) => {
      emit('update:updateCustomFormAnswers', props.customForm.id, newVal);
      if (checkFormType('radio')) subjectiveAnswer.value = {};
      if (checkFormType('checkbox') && newVal.length < oldVal.length) {
        const removeVal = (oldVal as string[]).find((val) => !newVal.includes(val));
        const newSubjectiveAnswer: { [key: string]: string } = {};
        Object.keys(subjectiveAnswer.value).filter((key) => key !== removeVal).forEach((key) => {
          newSubjectiveAnswer[key] = subjectiveAnswer.value[key];
        });
        subjectiveAnswer.value = newSubjectiveAnswer;
      }
    });

    watch(subjectiveAnswer, (newVal) => {
      emit('update:updateSubjectiveAnswer', props.customForm.id, answer.value, newVal);
    }, { deep: true });

    return {
      $v,
      I18n,
      answer,
      checkboxClass,
      checkFormType,
      description,
      disableChecked,
      errors,
      formType,
      hasError,
      isAdditionalTextOption,
      isValidated,
      required,
      selectedIndex,
      selectedLabel,
      subjectiveAnswer,
      viewerOptions: { linkAttribute: { target: '_blank', rel: 'noopener' } },
    };
  },
});
</script>
