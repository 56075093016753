<template>
  <ul class="pagination">
    <li :class="['page-item', { disabled: currentPage < 2 }]">
      <span class="page-link" @click.prevent="pageItemClickHandler(currentPage - 1)">&lt;</span>
    </li>

    <template v-if="start !== 1">
      <li class="page-item">
        <span class="page-link" @click.prevent="pageItemClickHandler(1)">1</span>
      </li>

      <li v-if="totalPages > size + 1" class="page-item disabled">
        <span class="page-link">…</span>
      </li>
    </template>

    <li
      v-for="item in items"
      :key="item"
      :class="['page-item', { active: currentPage === item }]"
    >
      <span class="page-link" @click.prevent="pageItemClickHandler(item)">
        {{ item }}
      </span>
    </li>

    <template v-if="end !== totalPages">
      <li v-if="totalPages > size + 1" class="page-item disabled">
        <span class="page-link">…</span>
      </li>

      <li class="page-item">
        <span class="page-link" @click.prevent="pageItemClickHandler(totalPages)">
          {{ totalPages }}
        </span>
      </li>
    </template>

    <li :class="['page-item', { disabled: currentPage > totalPages - 1 }]">
      <span class="page-link" @click.prevent="pageItemClickHandler(currentPage + 1)">&gt;</span>
    </li>
  </ul>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  toRefs,
  unref,
} from '@vue/composition-api';
import {
  usePage,
  useRoute,
  useRouter,
} from '@/router/career';

export default defineComponent({
  name: 'JobPositionPaginationNavigator',
  props: {
    limit: {
      type: Number,
      required: true,
    },
    size: {
      type: Number,
      default: 5,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const { size, totalPages } = toRefs(props);
    const route = useRoute();
    const router = useRouter();
    const currentPage = usePage();

    const start = computed(() => {
      const unrefedCurrentPage = unref(currentPage);
      const unrefedTotalPages = unref(totalPages);
      return (
        (unrefedCurrentPage < size.value && 1) ||
        (unrefedTotalPages - unrefedCurrentPage + 1 < size.value && Math.max(unrefedTotalPages - size.value + 1, 1)) ||
        currentPage.value - 2
      );
    });

    const end = computed(() => Math.min(start.value + size.value - 1, totalPages.value));

    const items = computed(() => {
      const length = Math.min(end.value - start.value + 1, totalPages.value);
      return Array.from({ length }).map((_, index) => index + start.value);
    });

    const pageItemClickHandler = (pageValue: number) => {
      const {
        query: { page: pageQuery, ...restQuery },
      } = route;

      if (Number(pageQuery) !== pageValue) {
        const page = pageValue > 0 ? pageValue.toString() : undefined;
        const query = { page, ...restQuery };

        window.scrollTo({ top: 0, behavior: 'smooth' });
        router.push({ name: 'job-position-index', query });
      }
    };

    return {
      currentPage,
      end,
      items,
      pageItemClickHandler,
      start,
    };
  },
});
</script>
