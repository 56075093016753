<template>
  <div class="item-tag-wrapper">
    <a
      v-for="(tag, index) in tags"
      :key="index"
      :href="`/posts/tag/${encodeURIComponent(tag.replace(/\./g, '%2E'))}`"
      class="item-tag"
      target="_blank"
      rel="noopener"
    > {{ tag }} </a>
  </div>
</template>

<script>
export default {
  name: 'CustomTags',
  props: {
    tags: {
      type: Array,
      required: true,
    },
  },
};
</script>
