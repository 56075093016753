<template>
  <a
    v-if="isBeforeEndTime"
    rel="noopener noreferrer"
    target="_blank"
    href="/events/profile-review-2022"
    :class="style.container"
  >
    <div :class="style.left">
      <span>
        <SvgIcon name="ic-megaphone-16" class="ic-16" />
      </span>
      <span>[EVENT] 프로필 완성도를 높이고 네이버, 카카오 개발자에게 피드백을 받아보세요 :)</span>
    </div>
    <div :class="style.right">
      <span>프로필 피드백 받기</span>
      <SvgIcon name="ic-keyboard-arrow-right" class="ic-22" />
    </div>
  </a>
</template>

<script lang="ts">
import { defineComponent, useCssModule } from '@vue/composition-api';
import dayjs from 'dayjs';
import SvgIcon from '../../../../components/shared/SvgIcon.vue';

const EVENT_END_TIME = '2023-01-03T00:00:00.000+09:00';

export default defineComponent({
  name: 'EventNotification',
  components: { SvgIcon },
  setup() {
    const style = useCssModule();
    const isBeforeEndTime = dayjs().isBefore(EVENT_END_TIME);

    return {
      style,
      isBeforeEndTime,
    };
  },
});
</script>

<style lang="scss" module>
@use "~/stylesheets/functions";
@use "~/stylesheets/variables";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: functions.rem-calc(16);
  width: calc(100% - functions.rem-calc(40));
  max-width: functions.rem-calc(1240);
  height: functions.rem-calc(68);
  font-size: initial;
  background: rgba(0, 120, 255, 0.06);
  border: 1px solid rgba(0, 120, 255, 0.06);
  border-radius: functions.rem-calc(4);
  margin: functions.rem-calc(40) auto functions.rem-calc(-16);

  &:hover {
    background: rgba(0, 120, 255, .09);
  }
}

.left {
  display: flex;
  align-items: center;
  column-gap: functions.rem-calc(16);

  > span:first-of-type {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: functions.rem-calc(34);
    height: functions.rem-calc(34);
    background: variables.$white-color;
    border-radius: 50%;

    > svg {
      fill: variables.$color-primary;
    }
  }

  > span:last-of-type {
    color: variables.$color-text--primary;
    font-weight: 500;
  }
}

.right {
  display: flex;
  align-items: center;

  > svg {
    fill: variables.$color-primary;
  }
}

@media (variables.$lg-down) {
  .container {
    font-size: functions.rem-calc(14);
  }
}

@media (variables.$md-down) {
  .container {
    display: none;
  }
}
</style>
