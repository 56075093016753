<template>
  <div class="container-tech-feed">
    <main class="main-content">
      <section class="recent-content">
        <ul class="content-list">
          <Post
            v-for="post in posts"
            :key="post.id"
            :post="post"
          />
        </ul>
        <Pagination
          v-if="totalPage"
          :max="totalPage"
          :initial-page="currentPage"
          @update:initial-page="handleClickPage"
        />
      </section>
    </main>
    <aside class="side-content">
      <section class="side-content__register">
      </section>
    </aside>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from '@vue/composition-api';
import Post from './Post.vue';
import Pagination from '@/components/shared/Pagination.vue';
import { fetchTagPosts } from '@/exportables/apis/career/post.api';
import { Post as PostType } from '@/exportables/models/career/post.model';
import { useRouter } from '@/router/career';

export default defineComponent({
  name: 'TagPosts',
  components: {
    Post,
    Pagination,
  },
  props: {
    tag: {
      type: String,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();

    const posts = ref<PostType[]>([]);
    const totalPage = ref<number>(1);

    const getTagPosts = async () => {
      const {
        posts: newPosts,
        totalPage: newTotalPage,
      } = await fetchTagPosts(props.tag, props.currentPage);

      posts.value = newPosts;
      totalPage.value = newTotalPage;
    };

    const handleClickPage = (page: number) => {
      window.scrollTo(0, 0);

      router.push(`/posts/tag/${props.tag}?page=${page}`);
    };

    watch(
      [() => props.currentPage, () => props.tag],
      getTagPosts,
      { immediate: true },
    );

    return {
      posts,
      totalPage,
      handleClickPage,
    };
  },
});
</script>
