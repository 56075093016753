<template>
  <Card
    v-if="isEditable || projects.length"
    id="projects"
    :is-editable="isEditable"
    :title="I18n.t('section.project.title')"
  >
    <template #control>
      <span class="resume-card-text-example" @click="openGuideModal">
        {{ I18n.t('resume.modal.guide.example') }}
      </span>

      <button class="btn-light resume-card-btn-add" @click="handleAddItem">
        <SvgIcon name="ic-add" html-class="ic-24" />
        <span>
          {{ I18n.t('actions.add') }}
        </span>
      </button>
    </template>

    <template #body>
      <Project
        v-if="newItem"
        ref="newFormRef"
        :index="projects.length"
        :info="newItem"
        :is-added="true"
        type="project"
      />
      <Project
        v-for="(project, index) in projects"
        :key="project.index"
        :index="index"
        :info="project"
        type="project"
      />
    </template>
  </Card>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  provide,
} from '@vue/composition-api';
import { useFocusInputOnMount, useForm } from '../hooks/form';
import { getResume, ResumeShowStates } from '../hooks/state';
import Card from './Card.vue';
import Project from './Index.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { ResumeProject } from '@/exportables/models/career/resume.model';
import { useI18n } from '@/helpers/i18n';
import { useStore } from '@/store/career';

export default defineComponent({
  name: 'ResumeProjects',
  components: {
    Card,
    Project,
    SvgIcon,
  },
  setup() {
    const openModal = inject<Function>('openModal')!;
    const state = inject<ResumeShowStates>('state')!;
    const I18n = useI18n();
    const store = useStore();
    const projects = computed(() => getResume(state).projects);
    const isEditable = computed(() => store.state.resume.isEditable);
    const form = useForm(state, 'projects', ResumeProject);
    const { ref: newFormRef } = useFocusInputOnMount();

    const openGuideModal = () => openModal('Guide', {
      type: 'project',
      description: {
        tips: Array.from({ length: 3 }, (_, index) => I18n.t(`resume.modal.guide.project_tip_${index + 1}`)),
        examples: Array.from({ length: 7 }, (_, index) => I18n.t(`resume.modal.guide.project_example_${index + 1}`)),
      },
    });

    provide('form', form);

    return {
      ...form,
      I18n: { t: I18n.t },
      isEditable,
      newFormRef,
      openGuideModal,
      projects,
    };
  },
});
</script>
