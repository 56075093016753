import { ActionTree } from 'vuex';
import { CareerStore } from '..';
import * as types from './mutation-types';
import { ResumeStoreState } from './state';
import { fetchResume, fetchCertificates } from '@/exportables/apis/career/resume.api';
import { Resume } from '@/exportables/models/career/resume.model';
import { Query } from '@/exportables/types/general.d';

const actions: ActionTree<ResumeStoreState, CareerStore> = {
  async fetchResume({ commit }, id: number) {
    const resume = await fetchResume(id);
    commit(types.SET_RESUME, resume);
  },
  async fetchResumeCertificates({ commit }, { resumeId, params }: { resumeId: Resume['id'], params?: Query }) {
    const certificates = await fetchCertificates(resumeId, params);
    commit(types.SET_RESUME_CERTIFICATES, certificates);
  },
};

export default actions;
