<script lang="tsx">
import { computed, defineComponent, onMounted, onUnmounted, ref, useCssModule, watch } from '@vue/composition-api';
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useState } from '../../../hooks/show/state';
import { useTab } from '../../../hooks/show/tab';
import Pagination from '@/components/shared/Pagination.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { skeleton } from '@/directives';
import { COMPETITION_URL } from '@/exportables/apis/career/competition.api';
import { useHttpClient } from '@/exportables/apis/httpClient';
import { CompetitionLeaderboardsTab, LeaderBoardTest, TestForLearderboard } from '@/exportables/models/career/competition.model';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';
import { useRequest } from '@/hooks/useRequest';

if (!('duration' in dayjs)) {
  dayjs.extend(duration);
}

const PER_PAGE = 30;

export default defineComponent({
  name: 'CompetitionShowLeaderboardTab',
  directives: {
    skeleton,
  },
  setup(_, { refs }) {
    const style = useCssModule();
    const I18n = useI18n();
    const httpClient = useHttpClient();
    const intervalId = ref(0);
    const now = ref<Dayjs>(dayjs());
    const { state } = useState();
    const { isLoading: isTabLoading, tabContents, setState: setTabState } = useTab();
    const selectedTest = ref<TestForLearderboard | null>();
    const isFinalLeaderboard = ref<boolean>(false);
    const { isValidating: isTestsLoading, data: testsData, error: testsError } = useRequest<{ tests: TestForLearderboard[] }>(() => (
      !tabContents.leaderboards ? `${COMPETITION_URL}/${state.id}/tests` : ''
    ));
    const { isValidating: isTestLoading, data: testData, error: testError } = useRequest<{ test: LeaderBoardTest }>(() => (
      (selectedTest.value && selectedTest.value.id !== tabContents.leaderboards?.test?.id) ?
        `${COMPETITION_URL}/${state.id}/tests/${selectedTest.value?.id}` : ''
    ));
    const { data: tokensData, error: tokensError } = useRequest<Omit<CompetitionLeaderboardsTab, 'tests' | 'test'>>(() => {
      if (!tabContents.leaderboards?.test) return '';
      return httpClient.getUri({
        url: `${COMPETITION_URL}/${state.id}/tests/${tabContents.leaderboards.test.id}/tokens`,
        params: {
          page: tabContents.leaderboards.page,
          perPage: PER_PAGE,
          category: isFinalLeaderboard.value ? 'final' : 'public',
        },
      });
    });
    const remainTimeFromNowToEnd = computed(() => {
      const endAt = dayjs(tabContents?.leaderboards?.test?.endAt);
      const diff = endAt.diff(now.value);
      if (diff < 0) return dayjs.duration(0);
      return dayjs.duration(diff);
    });
    const remainTimeFromFrozenToEnd = computed(() => {
      const endAt = dayjs(tabContents?.leaderboards?.test?.endAt);
      const frozenAt = dayjs(tabContents?.leaderboards?.test?.frozenAt);
      const diff = endAt.diff(frozenAt);
      return dayjs.duration(diff);
    });

    const handleChangeTest = (test: TestForLearderboard) => {
      if (test.id === selectedTest.value?.id) return;
      selectedTest.value = test;
      handleChangePage(1);
    };

    const handleChangePage = (page: number) => {
      (refs.container as HTMLElement).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      const { leaderboards } = tabContents;
      if (!leaderboards) return;
      setTabState({
        leaderboards: {
          ...leaderboards,
          page,
        },
      });
    };

    const handleChangeCategory = (e: PointerEvent, category: CompetitionLeaderboardsTab['category']) => {
      e.preventDefault();
      if (category === 'final') {
        isFinalLeaderboard.value = true;
      } else {
        isFinalLeaderboard.value = false;
      }
    };

    watch(
      [testsError, testError, tokensError],
      ([testsError, testError, tokensError]) => {
        if (tokensError || testError || testsError) {
          appendToast(I18n.t('competition.show.tabs.message.fetch_error', { tab: I18n.t('competition.show.tabs.leaderboards.title') }));
        }
      },
    );

    watch(
      testsData,
      (data) => {
        if (data) {
          const { tests } = data;
          setTabState({
            leaderboards: {
              tests,
            },
          });
          selectedTest.value = tests[0];
        }
      },
    );

    watch(
      testData,
      (data) => {
        if (data && tabContents.leaderboards) {
          const { test } = data;
          setTabState({
            leaderboards: {
              ...tabContents.leaderboards,
              test,
            },
          });
        }
      },
    );

    watch(
      tokensData,
      (leaderboards) => {
        if (leaderboards && tabContents.leaderboards) {
          setTabState({
            leaderboards: {
              ...tabContents.leaderboards,
              ...leaderboards,
            },
          });
          isTabLoading.value = false;
        } else {
          isTabLoading.value = true;
        }
      },
      {
        immediate: true,
      },
    );

    onMounted(() => {
      intervalId.value = window.setInterval(() => {
        now.value = dayjs();
      }, 1000);
    });

    onUnmounted(() => {
      intervalId.value && window.clearInterval(intervalId.value);
    });

    return () => {
      const { test, tests, tokens, category, page, totalPages, totalEntries } = tabContents.leaderboards || {};

      if (!tests || !test || !tokens)
        return null;

      return (
        <div class={style.container} ref="container">
          {
            test.isFrozen &&
            <div class="alert alert-info">
              { I18n.t('competitions.leaderboards.frozen_message', { remain_minutes: remainTimeFromFrozenToEnd.value.minutes() }) }
            </div>
          }
          <div class={style.assignmentHeader}>
            {
              tests.length > 1 ?
                [
                  <button
                    class={['dropdown-toggle', style.testDropdown]}
                    data-toggle="dropdown" aria-expanded="false"
                    v-skeleton={isTestsLoading.value}
                  >
                    { I18n.t(`solvable.${test.solvableType}`) } : { test.title }
                  </button>,
                  <div class="dropdown-menu dropdown-menu-right">
                    {
                      tests.map((test) =>
                        <button onClick={() => handleChangeTest(test)} key={test.id} class="dropdown-item">
                          { test.title }
                        </button>)
                    }
                  </div>,
                ] :
                <span
                  class={style.testDropdown}
                >
                  { I18n.t(`solvable.${test.solvableType}`) } : { test.title }
                </span>
            }
            <div
              v-skeleton={isTestsLoading.value || isTestLoading.value}
              class={style.timer}
            >
              <table class="table-timer js-leaderboard-remain-timer">
                <tbody class="tbody-border-none">
                  <tr class={test.isEnded ? 'expired' : 'remain-time'}>
                    <td class="day remain-day">
                      <div class="time">
                        <SvgIcon name="ic-time-card-accessory" htmlClass="ic-24 ic-accessory" />
                        <time>
                          { test.isEnded ? '-' : remainTimeFromNowToEnd.value.days() }
                        </time>
                        <h6>Days</h6>
                      </div>
                    </td>
                    <td class="hour remain-hour">
                      <div class="time">
                        <SvgIcon name="ic-time-card-accessory" htmlClass="ic-24 ic-accessory" />
                        <time>
                          { test.isEnded ? '-' : remainTimeFromNowToEnd.value.hours() }
                        </time>
                        <h6>Hrs</h6>
                      </div>
                    </td>
                    <td class="min remain-min">
                      <div class="time">
                        <SvgIcon name="ic-time-card-accessory" htmlClass="ic-24 ic-accessory" />
                        <time>
                          { test.isEnded ? '-' : remainTimeFromNowToEnd.value.minutes() }
                        </time>
                        <h6>Mins</h6>
                      </div>
                    </td>
                    <td class="sec remain-sec">
                      <div class="time">
                        <SvgIcon name="ic-time-card-accessory" htmlClass="ic-24 ic-accessory" />
                        <time>
                          { test.isEnded ? '-' : remainTimeFromNowToEnd.value.seconds() }
                        </time>
                        <h6>Secs</h6>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class='pane-content'>
            {
              test.isStarted ?
                test.solvableType === 'tryout' ?
                  (
                    <div class={['leaderboard-content', style.content]}>
                      <div class="btn-fullscreen-wrap">
                        <a
                          v-skeleton={isTestsLoading.value || isTestLoading.value}
                          href={test.isStarted ? `/competitions/${state.id}/leaderboard/${test.id}/fullscreen` : 'javascript:void(0)'}
                          target={test.isStarted ? '_blank' : ''}
                          class={['btn-fullscreen', test.isStarted ? '' : 'disabled']}
                        >
                          <span>
                            { I18n.t('competition.show.tabs.leaderboards.realtime_rank') }
                          </span>
                          <SvgIcon name="ic-link-arrow-right-16" htmlClass="ic-16 ic-arrow" />
                        </a>
                      </div>
                      <div class="table-responsive-wrap">
                        <table
                          v-skeleton={isTestsLoading.value || isTestLoading.value}
                          class="table table-sm leaderboard-table"
                        >
                          <thead>
                            <tr>
                              <th class="rank">
                              </th>
                              <th rowspan="2" class="participant">
                                <p class="participant-title">
                                  { I18n.t('terms.developer') }
                                </p>
                                <h6 class="participant-number">
                                  { I18n.t('terms.people_count', { count: totalEntries }) }
                                </h6>
                              </th>
                              <th rowspan="2" class="total-score">
                                <p class="total-score-title">
                                  { I18n.t('terms.total_score') }
                                </p>
                                <h6 class="total-score-number">
                                  { test.perfectScore.toFixed(1) }
                                </h6>
                              </th>
                              <th rowspan="2" class="time-reached">
                                <p class="time-reached-title">
                                  { I18n.t('competition.show.tabs.leaderboards.reached_time') }
                                </p>
                                <h6 class="time-reached-number">HH:MM:SS</h6>
                              </th>
                              {
                                test.challenges.map(({ id, perfectScore }, index) => (
                                  <th key={id} rowspan="2" class="challenge">
                                    <p class="challenge-title">
                                      { I18n.t('competition.show.tabs.leaderboards.nth_challenge', { number: index + 1 }) }
                                    </p>
                                    <h6 class="challenge-number">
                                      { perfectScore.toFixed(1) }
                                    </h6>
                                  </th>
                                ))
                              }
                            </tr>
                          </thead>
                          {
                            tokens.length ?
                              <tbody>
                                {
                                  tokens.map(({ id, rank, participant, totalScore, reachedTime, challenges, isCurrentUserToken }) => (
                                    <tr key={id} class={
                                      isCurrentUserToken ? 'row-leaderboard-highlight' :
                                        rank === 1 ? 'row-leaderboard-grand-prize' :
                                          rank === 2 ? 'row-leaderboard-gold' :
                                            rank === 3 ? 'row-leaderboard-silver' : ''
                                    }>
                                      <td class="rank">{ rank ? `${rank}.` : '-' }</td>
                                      <td class="participant">{ participant }</td>
                                      <td class="total-score">{ totalScore?.toFixed(1) || 0 }</td>
                                      <td class="time-reached">{ reachedTime }</td>
                                      {
                                        challenges.map(({ id, score, time }) => (
                                          <td key={id} class="challenge">
                                            <p class="challenge-score">{ score?.toFixed(1) || '-' }</p>
                                            <h6 class="challenge-time">{ time }</h6>
                                          </td>
                                        ))
                                      }
                                    </tr>
                                  ))
                                }
                              </tbody>
                              :
                              (
                                <caption>
                                  <div class="empty">
                                    <h2>
                                    :-)
                                    </h2>
                                    <h6>
                                      { I18n.t('competition.show.tabs.leaderboards.getting_ready') }
                                    </h6>
                                  </div>
                                </caption>
                              )
                          }
                        </table>
                      </div>
                      {
                        totalPages && totalPages > 1 &&
                        <Pagination
                          max={totalPages}
                          initialPage={page}
                          setCurrentPageCallback={handleChangePage}
                        />
                      }
                    </div>
                  )
                  :
                  (
                    <div class="leaderboard-content">
                      <ul class="nav nav-tabs leaderboard-nav-tabs" role="tablist">
                        <li class="nav-item">
                          <a
                            class={['nav-link js-nav-leaderboard', !isFinalLeaderboard.value ? 'active' : '']}
                            onClick={(e: PointerEvent) => handleChangeCategory(e, 'public')}
                          >
                            { I18n.t('competitions.leaderboards.public.title') }
                          </a>
                        </li>
                        {
                          test.isFinalLeaderboardOpened &&
                        <li class="nav-item">
                          <a
                            class={['nav-link js-nav-leaderboard', isFinalLeaderboard.value ? 'active' : '']}
                            onClick={(e: PointerEvent) => handleChangeCategory(e, 'final')}
                          >
                            { I18n.t('competitions.leaderboards.final.title') }
                          </a>
                        </li>
                        }
                      </ul>
                      <div class="tab-content">
                        <div class={['alert alert-info', style.alert]}>
                          { I18n.t(`competitions.leaderboards.${category}.description`, { percentage: test.publicPercentage }) }
                        </div>
                        <div class="table-responsive-wrap">
                          <table class="table table-sm leaderboard-table">
                            <thead>
                              <tr class="leaderboard-table-data">
                                <th class="rank">
                                  <p class="rank-title"></p>
                                </th>
                                <th class="participant">
                                  <p class="participant-title">
                                    { I18n.t('terms.developer') }
                                  </p>
                                </th>
                                <th class="total-score">
                                  <p class="total-score-title">
                                    { I18n.t('terms.total_score') }
                                  </p>
                                </th>
                                <th class="entry">
                                  <p class="entry-title">
                                    { I18n.t('competition.show.tabs.leaderboards.submit_count') }
                                  </p>
                                </th>
                                <th class="last-entry">
                                  <p class="last-entry-title">
                                    { I18n.t('competition.show.tabs.leaderboards.last_submit') }
                                  </p>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                (category === 'final' && !test.isEnded) ?
                                  <tr>
                                    <td colspan="5" class="empty">
                                      <div class="thumb-resume">
                                        <SvgIcon name="ic-throphy" htmlClass="ic-48" />
                                      </div>
                                      <h6>
                                        { I18n.t('competition.show.tabs.leaderboards.final_leaderboard_not_yet') }
                                      </h6>
                                    </td>
                                  </tr>
                                  :
                                  tokens.map(({ id, rank, isCurrentUserToken, participant, totalScore, submissionCount, lastSubmission }) =>
                                    <tr key={id} class={
                                      isCurrentUserToken ? 'row-leaderboard-highlight' :
                                        rank === 1 ? 'row-leaderboard-grand-prize' :
                                          rank === 2 ? 'row-leaderboard-gold' :
                                            rank === 3 ? 'row-leaderboard-silver' : ''
                                    }>
                                      <td class="rank">{ rank ? `${rank}.` : '-' }</td>
                                      <td class="participant">{ participant }</td>
                                      <td class="total-score">{ totalScore?.toFixed(1) || '-' }</td>
                                      <td class="entry">{ submissionCount }</td>
                                      <td class="last-entry">{ lastSubmission }</td>
                                    </tr>)
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {
                        (category === 'public' && test.isEnded && (totalPages && totalPages > 1) &&
                          <div class="d-flex justify-content-center">
                            <Pagination
                              max={totalPages}
                              initialPage={page}
                              setCurrentPageCallback={handleChangePage}
                            />
                          </div>
                        )
                      }
                    </div>
                  )
                :
                (
                  <div class="text-center before-competition">
                    <SvgIcon name="ic-public" htmlClass="ic-48 ic-black" />
                    <h6>
                      { I18n.t('competition.show.tabs.leaderboards.before_competition') }
                    </h6>
                  </div>
                )
            }
          </div>
        </div>
      );
    };
  },
});
</script>

<style lang="scss" module>
@use '~/stylesheets/variables';
@use '~/stylesheets/functions';

.testDropdown {
  height: 100%;
  font-size: functions.rem-calc(20);
  font-weight: 700;
  border: none;
  background: none;
  padding: functions.rem-calc(1 6);
}

.container {
  position: relative;
  min-height: functions.rem-calc(420);
}

.content {
  > div > table > tbody {
    border-top: none;
  }
}

.timer {
  display: flex;
  align-items: flex-end;
}

.assignmentHeader {
  display: flex;
  justify-content: space-between;
  min-height: functions.rem-calc(110);

  &:not(:first-child) {
    margin-top: functions.rem-calc(16);
  }
}

.alert {
  margin-top: functions.rem-calc(16) !important;
}

@media (variables.$md-down) {
  .testDropdown {
    font-size: functions.rem-calc(15);
    margin-bottom: functions.rem-calc(24);
  }

  .assignmentHeader {
    min-height: 0;
  }

  .content {
    margin-top: 0;
  }

  .timer {
    display: none;
  }
}
</style>
