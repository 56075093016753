const format: Format = Object.freeze({
  ALPHA: 'alpha',
  BETA: 'beta',
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
  FILE: {
    max_size: 1024 * 1024 * 30,
  },
  LANGUAGE: {
    c: {
      name: 'C',
      extension: 'c',
    },
    cpp: {
      name: 'C++',
      extension: 'cpp',
    },
    csharp: {
      name: 'C#',
      extension: 'cs',
    },
    go: {
      name: 'Go',
      extension: 'go',
    },
    java: {
      name: 'Java',
      extension: 'java',
    },
    javascript: {
      name: 'JavaScript',
      extension: 'js',
    },
    kotlin: {
      name: 'Kotlin',
      extension: 'kt',
    },
    python: {
      name: 'Python2',
      extension: 'py',
    },
    python3: {
      name: 'Python3',
      extension: 'py',
    },
    R: {
      name: 'R',
      extension: 'R',
    },
    ruby: {
      name: 'Ruby',
      extension: 'rb',
    },
    scala: {
      name: 'Scala',
      extension: 'scala',
    },
    solidity: {
      name: 'Solidity',
      extension: 'sol',
    },
    swift: {
      name: 'Swift',
      extension: 'swift',
    },
    mysql: {
      name: 'MySQL',
      extension: 'sql',
    },
    oracle: {
      name: 'Oracle',
      extension: 'sql',
    },
  },
  LOCALE: {
    ko: 'ko_KR',
  },
  UP_DOWN: ['up', 'down'],
  POSITION: ['top', 'right', 'bottom', 'left'],
  LEFT_RIGHT_POSITION: ['left', 'right'],
  TOP_BOTTOM_POSITION: ['top', 'bottom'],
  ABSOLUTE_POSITION: [
    'top left',
    'top right',
    'bottom left',
    'bottom right',
  ],
  TEST: {
    assignment: {
      web: ['ChallengeWeb'],
      api: ['ChallengeApi'],
      app: ['ChallengeApp'],
      'data-science': ['ChallengeDataScience'],
      'machine-learning': ['ChallengeMachineLearning'],
    },
    tryout: {
      algorithm: ['ChallengeAlgorithm'],
      sql: ['ChallengeSql'],
      debugging: [],
      'multiple-choice': ['QuizGroup'],
      essay: ['ChallengeEssay'],
    },
  },
  TIME: {
    assignment: '%Y년 %m월 %d일 %H:%M',
    compact: '%m월 %d일 %p %H:%M',
    competition_end: '%m월 %d일 %H:%M',
    competition_start: '%y년 %m월 %d일 %H:%M',
    day: '%d',
    deadline: '%Y년 %m월 %d일 %H시 %M분',
    default: '%Y-%m-%d %H:%M',
    detail: '%Y년 %m월 %d일 (%a) %P %l시 %M분',
    detail_2: '%Y년 %m월 %d일 %P %l:%M',
    duration_time: '%02d분 %02d초',
    duration_with_hour: '%02d시간 %02d분 %02d초',
    expire_time: '%y/%m/%d %H:%M까지',
    full: '%Y-%m-%d %H:%M:%S',
    month: '%m',
    number: '%Y.%m.%d %H:%M',
    number_without_time: '%Y.%m.%d',
    period: '%y년 %m월 %d일 %H:%M',
    picker: '%Y/%m/%d %H:%M',
    short: '%Y. %m',
    short_deadline: '%B %e일 %H:%M',
    simplify: '%Y년 %m월 %d일',
    simplify_picker: '%y/%m/%d',
    start_time: '%y/%m/%d',
    without_time: '%Y-%m-%d',
    without_year: '%m-%d %H:%M',
    year_month: '%Y년 %m월',
    with_weekday: '%y년 %m월 %d일(%a)',
    time: '%H:%M',
    simple_with_weekday: '%-m월 %d일(%a)',
    start_time_without_year: '%m/%d',
  },
});

export default format;

export type Development = 'alpha' | 'beta';

export type File = {
  max_size: number,
};

const languages: string[] = Object.keys(format.LANGUAGE);
export type Language = typeof languages[number];

export type Locale = {
  ko: string,
};

type Position = string[];

const assignmentTypes = Object.values(format.TEST.assignment).flat(1);
const tryoutTypes = Object.values(format.TEST.tryout).flat(1);
export type ChallengeableType = typeof assignmentTypes[number] | typeof tryoutTypes[number];

export type Assignment = {
  web: ['ChallengeWeb'],
  api: ['ChallengeApi'],
  app: ['ChallengeApp'],
  'data-science': ['ChallengeDataScience'],
  'machine-learning': ['ChallengeMachineLearning'],
};

export type Tryout = {
  algorithm: ['ChallengeAlgorithm'],
  sql: ['ChallengeSql'],
  debugging: string[],
  'multiple-choice': ['QuizGroup'],
  essay: ['ChallengeEssay'],
};

export type Test = {
  assignment: Assignment,
  tryout: Tryout,
};

export type Format = {
  ABSOLUTE_POSITION: Position,
  ALPHA: string,
  BETA: string,
  DEVELOPMENT: string,
  FILE: File,
  LANGUAGE: Record<Language, {
    name: string,
    extension: string,
  }>,
  LEFT_RIGHT_POSITION: Position,
  LOCALE: Locale,
  POSITION: Position,
  PRODUCTION: string,
  TIME: Record<string, string>,
  TEST: Test,
  TOP_BOTTOM_POSITION: Position,
  UP_DOWN: Position,
};
