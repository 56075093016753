<template>
  <div id="competitions">
    <ul class="list-competitions">
      <CompetitionItem
        v-for="competition in competitionList"
        :key="competition.id"
        :competition="competition"
      />
    </ul>
    <Pagination
      v-if="competitionList.length"
      :max="totalPage"
      :initial-page.sync="currentPage"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api';
import CompetitionItem from '../components/CompetitionItem.vue';
import Pagination from '@/components/shared/Pagination.vue';
import { fetchCompetitions } from '@/exportables/apis/career/competition.api';
import { CompetitionItem as Competition } from '@/exportables/models/career/competition.model';

export default defineComponent({
  name: 'CompetitionList',
  components: {
    CompetitionItem,
    Pagination,
  },
  setup() {
    const competitionList = ref<Competition[]>([]);
    const totalPage = ref(0);
    const currentPage = ref(1);

    onMounted(async () => {
      if (currentPage.value === 1) {
        const { competitions, totalPages } = await fetchCompetitions(false, 1);

        totalPage.value = totalPages;
        competitionList.value = competitions;
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else currentPage.value = 1;
    });

    watch(currentPage, async (newVal) => {
      const { competitions, totalPages } = await fetchCompetitions(false, newVal);

      totalPage.value = totalPages;
      competitionList.value = competitions;
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });

    return {
      competitionList,
      totalPage,
      currentPage,
    };
  },
});
</script>
