<template>
  <div class="form-group form-employee">
    <button
      type="button"
      class="btn btn-block btn-outline-light btn-filter dropdown-toggle"
      data-toggle="dropdown"
      aria-expanded="false"
    >
      {{ I18n.t('job_position.list.search_filter.min_employee.title') }}
    </button>
    <div class="dropdown-menu dropdown-filter dropdown-menu-right">
      <div class="row justify-content-center">
        <div>
          <div class="dropdown-item">
            <label class="min_employees_label">
              <input v-model="minEmployees" type="radio" value="" name="minEmployees">
              {{ I18n.t('job_position.list.search_filter.total') }}
            </label>
          </div>
          <div
            v-for="employeesCount in employeesCountOptions"
            :key="employeesCount"
            class="dropdown-item"
          >
            <label class="min_employees_label" @click.stop>
              <input
                v-model="minEmployees"
                type="radio"
                :value="employeesCount"
                name="minEmployees"
              >
              {{ I18n.t('job_position.list.search_filter.min_employee.label', { min_employee: employeesCount }) }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  inject,
} from '@vue/composition-api';
import { FilterState } from '../../hooks/filter';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'JobPositionMinEmployeesFilter',
  setup() {
    const { minEmployees } = inject<FilterState>('filter')!;
    const employeesCountOptions = [5, 20, 50, 100];
    const I18n = useI18n();

    return {
      I18n,
      employeesCountOptions,
      minEmployees,
    };
  },
});
</script>

<style lang="scss" scoped>
.dropdown-menu {
  width: 100%;

  .dropdown-item {
    text-align: left;
  }
}
</style>
