<script lang="tsx">
import {
  defineComponent,
  useCssModule,
} from '@vue/composition-api';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'JobOfferPositionBox',
  props: {
    positionTitle: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    useStatus: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const I18n = useI18n();
    const style = useCssModule();

    return () => (
      <div class={style.container}>
        <div class={style.box}>
          <h5>{I18n.t('terms.position')}</h5>
          <h5>{props.positionTitle}</h5>
        </div>

        {props.useStatus ?
          <div class={style.box}>
            <h5>{I18n.t('terms.status')}</h5>
            <h5>{I18n.t(`business.recruit.job_offer.status.${props.status}`)}</h5>
          </div> :
          null
        }
      </div>
    );
  },
});
</script>

<style lang="scss" module>
@use "~/stylesheets/display";
@use "~/stylesheets/functions";

.container {
  @extend .flex-column;
  gap: functions.rem-calc(8);
}

.box {
  @extend .flex-column;
  align-items: flex-start;
  gap: functions.rem-calc(4);

  h5 {
    &:last-child {
      font-weight: 700;
      word-break: break-all;
    }
  }
}
</style>
