<template>
  <div
    v-if="value"
    class="markdown github"
  >
    <Viewer
      ref="viewerRef"
      :initial-value="value"
      :height="height"
      :options="normalizedViewerOptions"
      :class="className"
    />
  </div>
</template>

<script lang="ts">
import 'tui-color-picker/dist/tui-color-picker.css';
import 'tui-chart/dist/tui-chart.css';
import chart from '@toast-ui/editor-plugin-chart';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import tableMergedCell from '@toast-ui/editor-plugin-table-merged-cell';
import uml from '@toast-ui/editor-plugin-uml';
import { Viewer } from '@toast-ui/vue-editor';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  ref,
} from '@vue/composition-api';
import hljs from 'highlight.js';
import { useViewer } from '@/helpers/viewer';
import { latexPlugin } from '@/plugins/latex.plugin';

const defaultOptions = {
  minHeight: '300px',
  plugins: [
    [codeSyntaxHighlight, { hljs }],
    latexPlugin,
    colorSyntax,
    chart,
    tableMergedCell,
    uml,
  ],
  useDefaultHTMLSanitizer: true,
};

export default defineComponent({
  name: 'MarkdownViewer',
  components: {
    Viewer,
  },
  props: {
    className: {
      type: String,
      required: false,
      default: '',
    },
    height: {
      type: String,
      required: false,
      default: '',
    },
    useOriginalStyle: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    viewerOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup(props) {
    const viewerRef = ref(null);
    const normalizedViewerOptions = computed(() => Object.assign(defaultOptions, props.viewerOptions));

    const unwatch = useViewer(
      viewerRef,
      () => props.value,
      props.useOriginalStyle,
    );

    onBeforeUnmount(unwatch);

    return {
      normalizedViewerOptions,
      viewerRef,
    };
  },
});
</script>

<style lang="scss">
.tui-editor-contents table th {
  color: black !important;
}

.tui-editor-contents ul > li::before {
  background-color: initial !important;
}
</style>
