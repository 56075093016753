<template>
  <div
    v-if="isEditable || (isGitable && !hideGitStats)"
    class="git-stat"
    :class="style.container"
  >
    <h5 class="resume-section-title">
      {{ I18n.t('section.repository.language') }}
      <span
        v-if="isEditable"
        v-tooltip:top="{ message: I18n.t('resume.git_stat.tooltip'), width: '210px' }"
        class="btn-help dark"
      >
        <SvgIcon name="ic-help-full" html-class="ic-20" />
      </span>

      <div v-if="isEditable" :class="style.absolute">
        <Toggle
          v-if="analyzedAt && commits"
          :is-active="!hideGitStats"
          :labels="[I18n.t('section.repository.attach')]"
          :class="style.label"
          @toggle="handleAttachToggle"
        />

        <button
          v-if="inProgress"
          v-tooltip:top="{
            message: I18n.t('section.repository.tooltip.in_progress'),
            style: { wordBreak: 'keep-all' },
            width: '250px',
          }"
          class="btn btn-sm btn-light"
          :class="style.refresh"
        >
          <SvgIcon name="ic-refresh" :html-class="`ic-16 ${style.loadingAnimation}`" />
          <span class="ml-1">
            {{ I18n.t('section.repository.in_progress') }}
          </span>
        </button>

        <template v-else>
          <a
            v-if="isGitable"
            v-tooltip:top="I18n.t('section.repository.tooltip.description')"
            :class="style.refresh"
            @click="handleRefreshGitStat"
          >
            <SvgIcon name="ic-refresh" html-class="ic-16" />
          </a>
          <button
            v-else
            class="btn btn-sm btn-primary"
            @click="handleRefreshGitStat"
          >
            {{ I18n.t('resume.repository.refresh.action') }}
          </button>
        </template>
      </div>
    </h5>

    <div
      v-if="isGitable && (isEditable ? true : !hideGitStats)"
      :class="hideGitStats && style.translucent"
    >
      <p class="update mt-2 mb-0">
        <span>{{ I18n.t('section.repository.commit', { count: commits }) }}</span>
        <span v-if="analyzedAt"> ― ({{ I18n.t('section.repository.updated', { date: I18n.l('time.formats.detail_2', analyzedAt) }) }})</span>
      </p>

      <div class="stat-chart-wrap">
        <div ref="donutRef" class="stat-chart"></div>

        <ul class="stat-chart-legend">
          <li v-for="{ name, counts } in linesCountByLanguage" :key="name">
            {{ name }} ― <span>{{ counts }} lines</span>
          </li>
        </ul>
      </div>
    </div>

    <div v-else-if="isEditable" class="section-alert">
      <template v-if="inProgress">
        <p v-for="idx in 2" :key="idx">
          {{ I18n.t(`section.repository.message.in_progress_${idx}`) }}
        </p>
      </template>
      <template v-else>
        <p>{{ I18n.t('section.repository.message.no_result') }}</p>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  onUpdated,
  ref,
  unref,
  useCssModule,
} from '@vue/composition-api';
import {
  ResumeShowStates,
  getResume,
  initialize,
} from '../hooks/state';
import Toggle from './Toggle.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { tooltip } from '@/directives';
import { refreshGitstat, toggleGitstats } from '@/exportables/apis/career/resume.api';
import { useI18n } from '@/helpers/i18n';
import { sortObjectItemsAscendingByStringKey } from '@/helpers/utils';

declare const google: any;

export default defineComponent({
  name: 'GitStat',
  components: {
    SvgIcon,
    Toggle,
  },
  directives: {
    tooltip,
  },
  props: {
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const state = inject<ResumeShowStates>('state')!;
    const I18n = useI18n();
    const style = useCssModule();
    const donutRef = ref<HTMLElement | null>(null);
    const resume = computed(() => getResume(state));
    const hideGitStats = computed(() => unref(resume).hideGitStats);
    const repository = computed(() => {
      const { repository, user } = unref(resume);
      return user?.authorization?.url || repository || '';
    });
    const gitStat = computed(() => unref(resume)?.gitStat || null);
    const analyzedAt = computed(() => unref(gitStat)?.analyzedAt || null);
    const commits = computed(() => unref(gitStat)?.commitsCountByUser || 0);
    const inProgress = computed(() => unref(gitStat)?.inProgress);
    const isGitable = computed(() => Boolean(unref(analyzedAt) && unref(commits)));
    const linesCountByLanguage = computed(() => {
      const { linesCountByUser } = unref(gitStat) || {};
      return linesCountByUser?.length ? sortObjectItemsAscendingByStringKey(linesCountByUser, 'counts') : [];
    });

    const handleAttachToggle = async () => {
      await toggleGitstats(unref(resume).id);
      initialize(state);
    };

    const handleRefreshGitStat = async () => {
      await refreshGitstat();
      initialize(state);
    };

    const drawChart = () => {
      const unrefedDonutRef = unref(donutRef);
      if (!unrefedDonutRef) return;

      const rawData = [['Language', 'Lines']];

      for (const line of unref(linesCountByLanguage)) {
        rawData.push([line.name, line.counts]);
      }

      const chart = new google.visualization.PieChart(unrefedDonutRef);
      const data = google.visualization.arrayToDataTable(rawData);
      const options = {
        width: '100%',
        height: '190',
        pieSliceText: 'label',
        legend: 'none',
        chartArea: {
          width: '80%',
          height: '84%',
          left: '10px',
          top: '10px',
        },
        pieHole: 0.4,
        pieSliceTextStyle: {
          fontSize: '12',
          color: '#263747',
        },
        pieSliceBorderWidth: 2,
        pieSliceBorderColor: 'white',
        slices: [
          { color: '#CDD7E0' },
          { color: '#D7E2EB' },
          { color: '#E9ECF3' },
          { color: '#F7F7FB' },
          { color: '#F7F7FB' },
          { color: '#F7F7FB' },
          { color: '#F7F7FB' },
          { color: '#F7F7FB' },
        ],
      };

      chart?.draw(data, options);
    };

    const initChart = () => {
      if (unref(isGitable) && unref(linesCountByLanguage)?.length && unref(donutRef)) {
        const { charts } = google;
        if (!charts) return;

        google.charts.load('current', { packages: ['timeline', 'calendar', 'corechart', 'bar'] });
        google.charts.setOnLoadCallback(drawChart);
      }
    };

    onMounted(initChart);
    onUpdated(initChart);

    return {
      I18n,
      analyzedAt,
      commits,
      donutRef,
      handleAttachToggle,
      handleRefreshGitStat,
      hideGitStats,
      inProgress,
      isGitable,
      linesCountByLanguage,
      refreshGitstat,
      repository,
      style,
    };
  },
});
</script>

<style lang="scss" module>
@import "~/stylesheets/display";
@import "~/stylesheets/functions";
@import "~/stylesheets/variables";

svg {
  g:last-child {
    g:last-child {
      pointer-events: none;
    }
  }
}

div.google-visualization-tooltip {
  pointer-events: none
}

.container {
  margin-top: rem-calc(24);
  position: relative;
}

.label {
  label {
    font-size: rem-calc(12);
    color: $blue-grey-300;
    margin: rem-calc(0 8 0);
  }
}

.refresh {
  @extend .flex-center;
  margin: rem-calc(0 0 0 8);

  svg {
    fill: $blue-grey-300;
  }
}

.absolute {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1.5;
}

.translucent {
  opacity: 0.4;
}

.loadingAnimation {
  animation: rotate-animation 1.2s linear infinite;
}

@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
