<template>
  <div
    :class="{
      [style.container]: true,
      [style.checked]: isChecked,
      [style.disabled]: isDisabled,
    }"
    @click="handleCheck"
  >
    <input type="checkbox" :class="style.input" @click.prevent>
    <span :class="style.label">
      {{ label }}
    </span>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  useCssModule,
} from '@vue/composition-api';

export default defineComponent({
  name: 'ResumeCheckbox',
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  emits: ['check'],
  setup(_, { emit }) {
    const style = useCssModule();

    const handleCheck = () => emit('check');

    return {
      handleCheck,
      style,
    };
  },
});
</script>

<style lang="scss" module>
@use "~/stylesheets/functions";
@use "~/stylesheets/variables";

.container {
  width: 100%;
  min-height: functions.rem-calc(40);
  background-color: variables.$blue-grey-50;
  border-radius: 4px;
  padding: functions.rem-calc(10);
  display: inline-flex;
  position: relative;
  cursor: pointer;

  &.checked {
    color: white;
    background-color: variables.$blue-500;

    .label {
      &::after {
        background: url('~images/img-check-link.png') no-repeat center;
        background-size: cover;
      }
    }
  }

  &.disabled:not(.checked) {
    cursor: not-allowed;
    opacity: .3;
  }
}

.input {
  opacity: 0;
  width: 0;
  height: 0;
  left: 0;
  margin: 0;
  position: absolute;
  pointer-events: none;
}

.label {
  display: flex;
  position: relative;
  word-break: break-word;

  &::before {
    content: '';
    width: functions.rem-calc(20);
    height: functions.rem-calc(20);
    background-color: white;
    border-radius: 4px;
    margin-right: functions.rem-calc(10);
    display: inline-block;
    flex-shrink: 0;
  }

  &::after {
    content: '';
    width: functions.rem-calc(14);
    height: functions.rem-calc(12);
    display: block;
    position: absolute;
    top: functions.rem-calc(4);
    left: functions.rem-calc(3);
    z-index: 1000;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .input {
    width: 1rem;
    min-width: 1rem;
    max-width: 1rem;
    height: 1rem;
    border: 0.125rem solid #B2C0CC;
    margin-right: 0.375rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    border-radius: 2px;
    outline: none !important;
    vertical-align: middle;
  }

  .container {
    &.checked {
      border-color: variables.$blue-grey-900;
      background-size: functions.rem-calc(10 8);
    }
  }
}
</style>
