import { UserFeedTags, UserFeedDefaultTags } from '@/exportables/models/career/user-feed.model';
import { FeedTab, FeedCategory, FEED_TAB } from '@/pages/career/posts/constants';

export type PostState = {
  login: boolean,
  ready: boolean,
  feedTab: FeedTab,
  feedSettingTab: FeedCategory,
  totalPage: number,
  currentPage: number,
  posts: any[],
  userName: string,
  userFeed: UserFeedTags,
  backupUserFeed: UserFeedTags,
  userFeedDefault: UserFeedDefaultTags,
};

const state: PostState = {
  login: false,
  ready: false,
  feedTab: FEED_TAB.all,
  feedSettingTab: 'user_feed',
  totalPage: 1,
  currentPage: 1,
  posts: [],
  userName: '',
  userFeed: {
    post: [],
    company: [],
    technical: [],
    jobCategory: [],
    companyBenefit: [],
  },
  backupUserFeed: {
    post: [],
    company: [],
    technical: [],
    jobCategory: [],
    companyBenefit: [],
  },
  userFeedDefault: {
    post: [],
    company: [],
    technical: [],
    jobCategory: [],
    companyBenefit: [],
  },
};

export default state;
