<template>
  <input
    ref="inputRef"
    :value="input.name"
    class="search-input"
    :class="style.input"
    type="search"
    :autofocus="autofocus"
    :placeholder="placehloder"
    @input="($event) => input.name = $event.target.value"
    @keyup="handleKey"
    @submit.prevent>
</template>

<script>
import {
  defineComponent,
  inject,
  ref,
  unref,
  useCssModule,
} from '@vue/composition-api';
import constants from '@/constant/format';
import { debounce } from '@/helpers/lazy';

const UP_DOWN_SET = new Set(constants.UP_DOWN);

export default defineComponent({
  name: 'SearchInput',
  props: {
    autofocus: {
      type: Boolean,
      default: true,
    },
    placehloder: {
      type: String,
      default: '',
    },
  },
  setup() {
    const search = inject('search');
    const inputRef = ref(null);
    const style = useCssModule();

    const {
      handleClickItem,
      highlightedIndex,
      input,
      isClicked,
      result,
      searchType,
    } = search;

    const handleKey = debounce((event) => {
      const unreffedResult = unref(result);
      const unreffedHighlightedIndex = unref(highlightedIndex);
      const target = event.target.closest('.search-select');

      switch (event.key) {
        case 'ArrowDown': {
          const lastIndex = unreffedResult.length - 1;
          highlightedIndex.value = unreffedHighlightedIndex < lastIndex ? unreffedHighlightedIndex + 1 : lastIndex;
          fixScrollY(target);
          break;
        }
        case 'ArrowUp': {
          highlightedIndex.value = unreffedHighlightedIndex > 0 ? unreffedHighlightedIndex - 1 : 0;
          fixScrollY(target, 'up');
          break;
        }
        case 'Enter': {
          event.stopPropagation();
          const selected = unreffedResult[unreffedHighlightedIndex];
          const isCompanyTag = searchType === 'company';

          handleClickItem(selected ||
            (
              isCompanyTag ? {
                id: input?.id || null,
                name: input.name || '',
                companyId: input?.companyId || null,
              } : {
                id: null,
                name: '',
              }
            ));
          isClicked.value = Boolean(selected);
          break;
        }
      }
    }, 120);

    return {
      handleKey,
      inputRef,
      input,
      style,
    };
  },
});

function fixScrollY(targetElement, key = 'down', offsetY = 0) {
  if (!UP_DOWN_SET.has(key)) return;
  const highlighted = targetElement.querySelector('.highlighted');

  if (!highlighted) return;
  const height = highlighted.clientHeight;
  const gap = (key === 'down' ? 0 : height * 2);

  highlighted?.parentElement?.scrollTo(0, highlighted.offsetTop - gap - offsetY);
}
</script>

<style lang="scss" module>
@import "~/stylesheets/variables";
@import "~/stylesheets/functions";

.input {
  width: 100%;
  color: $color-text--primary;
  font-size: rem-calc(16);
  border: none;
  background-color: transparent;
  padding: 0;

  &:active,
  &:focus {
    outline: none;
  }

  @media ($sm-down) {
    height: rem-calc(18);
    font-size: rem-calc(13);
  }
}
</style>
