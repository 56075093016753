<template>
  <Modal
    :title="I18n.t('resume.modal.test_results.title')"
    @hide="closeModal"
  >
    <template #body>
      <div class="test-box">
        <div class="test-header">
          <h4>
            {{ I18n.t('terms.test') }}
          </h4>
        </div>

        <ul v-if="tokens.tryouts.length || tokens.assignments.length" class="test-list">
          <li
            v-for="(tryout, index) in tokens.tryouts"
            :key="tryout.id"
            class="test-item"
          >
            <div class="test-item-icon">
              <SvgIcon :name="testIcon(tryout, 'tryout')" html-class="ic-20" />
            </div>

            <div class="test-item-wrap">
              <div class="test-item-info">
                <h5>
                  {{ tryout.title }}
                </h5>
                <p>
                  {{ I18n.l('time.formats.period', tryout.startedAt) }} ~ {{ I18n.l('time.formats.period', tryout.finishedAt) }} • {{ tryout.testDuration }}
                </p>
                <p v-if="tryout.shareResultPolicy">
                  <span>
                    {{ testLanguages(tryout) }}</span>
                  <span> • {{ I18n.t('terms.score_with', { score: tryout.score.toFixed(1) }) }} ({{ I18n.t('terms.score_with', { score: tryout.perfectScore }) }})</span>
                  <span v-if="tryout.rank"> • {{ I18n.t('terms.top', { percent: percentileRank(tryout) }) }}</span>
                </p>
              </div>

              <div class="test-item-btn">
                <a
                  v-if="tryout.shareResultPolicy"
                  :href="tryout.resultUrl"
                  target="_blank"
                  rel="noopener"
                >
                  <button class="btn btn-sm btn-primary">{{ I18n.t('resume.test_results.view_code') }}</button>
                </a>
              </div>

              <div class="test-item-toggle">
                <Toggle
                  :is-active="isSelectedTryouts[index]"
                  @toggle="handleToken(isSelectedTryouts[index], tryout.id, 'tryouts')"
                />
              </div>
            </div>
          </li>

          <li
            v-for="(assignment, index) in tokens.assignments"
            :key="assignment.id"
            class="test-item"
          >
            <div class="test-item-icon">
              <SvgIcon :name="testIcon(assignment, 'assignment')" html-class="ic-20" />
            </div>

            <div class="test-item-wrap">
              <div class="test-item-info">
                <h5>
                  {{ assignment.title }}
                </h5>
                <p>
                  {{ I18n.l('time.formats.period', assignment.startedAt) }} ~ {{ I18n.l('time.formats.period', assignment.finishedAt) }} • {{ assignment.testDuration }}
                </p>
                <p v-if="assignment.shareResultPolicy">
                  <span>
                    {{ testFrameworks(assignment) }}</span>
                  <span> • {{ I18n.t('terms.score_with', { score: assignment.score.toFixed(1) }) }} ({{ I18n.t('terms.score_with', { score: assignment.perfectScore }) }})</span>
                  <span v-if="assignment.rank"> • {{ I18n.t('terms.top', { percent: percentileRank(assignment) }) }}</span>
                </p>
              </div>

              <div class="test-item-btn">
                <a
                  v-if="assignment.shareResultPolicy"
                  :href="assignment.resultUrl"
                  target="_blank"
                  rel="noopener"
                >
                  <button class="btn btn-sm btn-primary">{{ I18n.t('resume.test_results.view_code') }}</button>
                </a>
              </div>

              <div class="test-item-toggle">
                <Toggle
                  :is-active="isSelectedAssignments[index]"
                  @toggle="handleToken(isSelectedAssignments[index], assignment.id, 'assignments')"
                />
              </div>
            </div>
          </li>
        </ul>

        <div v-else class="no-test">
          <p>
            {{ I18n.t('resume.modal.test_results.no_test') }}
          </p>
          <p>
            {{ I18n.t('resume.modal.test_results.invite_to_hiring_program') }}
          </p>

          <a href="/job">
            <button class="btn btn-md btn-move">
              {{ I18n.t('resume.modal.test_results.move_to_hiring_program') }}
            </button>
          </a>
        </div>
      </div>

      <div class="test-box">
        <div class="test-header">
          <h4>
            {{ I18n.t('terms.skill_check_assignment') }}
          </h4>
        </div>

        <ul v-if="tokens.skillCheckAssignments.length" class="test-list">
          <li
            v-for="(skillCheckAssignment, index) in tokens.skillCheckAssignments"
            :key="skillCheckAssignment.id"
            class="test-item"
          >
            <div class="test-item-icon">
              <SvgIcon :name="testIcon(skillCheckAssignment, 'assignment')" html-class="ic-20" />
            </div>

            <div class="test-item-wrap">
              <div class="test-item-info">
                <h5>
                  {{ skillCheckAssignment.title }}
                </h5>
                <p>
                  {{ I18n.l('time.formats.period', skillCheckAssignment.startedAt) }} ~ {{ I18n.l('time.formats.period', skillCheckAssignment.finishedAt) }} • {{ skillCheckAssignment.testDuration }}
                </p>
              </div>

              <div class="test-item-btn">
                <a
                  :href="skillCheckAssignment.codeUrl"
                  target="_blank"
                  rel="noopener"
                >
                  <button class="btn btn-sm btn-primary">{{ I18n.t('resume.test_results.view_code') }}</button>
                </a>
              </div>

              <div class="test-item-toggle">
                <Toggle
                  :is-active="isSelectedSkillCheckAssignments[index]"
                  @toggle="handleToken(isSelectedSkillCheckAssignments[index], skillCheckAssignment.id, 'skillCheckAssignments')"
                />
              </div>
            </div>
          </li>
        </ul>

        <div v-else class="no-test">
          <p>
            {{ I18n.t('resume.modal.test_results.no_skill_check_assignment') }}
          </p>
          <p>
            {{ I18n.t('resume.modal.test_results.invite_to_skill_check_assignment') }}
          </p>
          <a href="/skill_check_assignments">
            <button class="btn btn-md btn-move">
              {{ I18n.t('resume.modal.test_results.move_to_skill_check_assignment') }}</button>
          </a>
        </div>
      </div>

      <div class="test-box">
        <div class="test-header">
          <h4>
            {{ I18n.t('terms.skill_check') }}
          </h4>
        </div>

        <div v-if="skillChecks.length" class="test-body">
          <h5 class="flex-start">
            {{ I18n.t('resume.test_results.skill_check_level') }} {{ maxSkillCheckLevel }}

            <div :class="style.toggle">
              <Toggle
                :is-active="isSelectedSkillChecks"
                @toggle="handleToggleSkillCheck"
              />
            </div>
          </h5>

          <div class="section-items-skill-check">
            <a
              v-for="check in skillChecks"
              :key="check.level"
              :href="check.codeUrl"
              :data-original-title="`<h5><b>${I18n.t('resume.test_results.skill_check_level')} ${check.level}</b></h5>`"
              :data-content="tooltipContent(check)"
              class="card-resume-detail--skillcheck js-skill-check"
              data-container="body"
              data-html="true"
              data-placement="top"
              data-toggle="popover"
              target="_blank"
              rel="noopener"
            >
              <div class="badge-wrapper">
                <img
                  :src="skillCheckLevelImg(check.level)"
                  class="img-badge-skill"
                  :alt="I18n.t('resume.test_results.skill_check_level_image')"
                >
              </div>
              <div class="overlay"></div>
              <div class="info">
                <div class="info-txt">
                  <SvgIcon name="ic-launch-16" html-class="ic-16 ic-white" />
                  {{ I18n.t('resume.test_results.view_code') }}
                </div>
              </div>
            </a>
          </div>
        </div>

        <div v-else class="no-test">
          <p>
            {{ I18n.t('resume.modal.test_results.no_skill_check') }}
          </p>
          <p>
            {{ I18n.t('resume.modal.test_results.invite_to_skill_check') }}
          </p>
          <a href="/skill_checks">
            <button class="btn btn-md btn-move">
              {{ I18n.t('resume.modal.test_results.move_to_skill_check') }}</button>
          </a>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex-end">
        <button
          class="btn btn-md btn-light"
          data-dismiss="modal"
          aria-label="Close"
          @click="closeModal"
        >
          {{ I18n.t('actions.close') }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  ref,
  unref,
  useCssModule,
  watch,
} from '@vue/composition-api';
import {
  ResumeShowStates,
  getResume,
  initialize,
} from '../../hooks/state';
import {
  percentileRank,
  skillCheckLevelImg,
  testIcon,
  testFrameworks,
  testLanguages,
  tooltipContent,
} from '../../resume.util';
import Toggle from '../Toggle.vue';
import Modal from '@/components/shared/Modal2.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import {
  createTestTokens,
  deleteTestTokens,
  toggleSkillChecks,
} from '@/exportables/apis/career/resume.api';
import { fetchTestTokens } from '@/exportables/apis/user.api';
import { TokenType, Tokens } from '@/exportables/models/career/tokens.model';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'ResumeTestResultsModal',
  components: {
    Modal,
    SvgIcon,
    Toggle,
  },
  emits: ['hide'],
  setup(_, { emit }) {
    const state = inject<ResumeShowStates>('state')!;
    const I18n = useI18n();
    const style = useCssModule();
    const attachableTokens = ref<Tokens>({
      assignments: [],
      skillCheckAssignments: [],
      tryouts: [],
    });
    const isSelectedTryouts = ref<boolean[]>([]);
    const isSelectedAssignments = ref<boolean[]>([]);
    const isSelectedSkillCheckAssignments = ref<boolean[]>([]);
    const resume = computed(() => getResume(state));
    const skillChecks = computed(() => unref(resume).user.passedSkillChecks);
    const isSelectedSkillChecks = computed(() => !unref(resume)?.hideSkillChecks);
    const maxSkillCheckLevel = computed(() => Math.max(...unref(skillChecks)!.map((check) => check.level) || 0));

    const closeModal = () => emit('hide');

    const handleActive = async (testTokenId: number) => {
      await createTestTokens(unref(resume).id, { testTokenId });
      initialize(state);
    };

    const handleInactive = async (testTokenId: number, type: TokenType) => {
      const token = state.tokens?.[type].filter((t) => t.id === testTokenId)?.[0] || {};
      const { resumeTestTokenId } = token;
      if (!resumeTestTokenId) return;

      await deleteTestTokens(unref(resume).id, resumeTestTokenId);
      initialize(state);
    };

    const handleToken = (
      isActive: boolean,
      testTokenId: number,
      type: TokenType,
    ) => isActive ? handleInactive(testTokenId, type) : handleActive(testTokenId);

    const handleToggleSkillCheck = async () => {
      await toggleSkillChecks(unref(resume).id);
      initialize(state);
    };

    watch(
      [() => state.tokens, attachableTokens],
      ([tokens, attachableTokens]) => {
        const {
          assignments,
          skillCheckAssignments,
          tryouts,
        } = tokens;
        const {
          assignments: attachableAssignments,
          skillCheckAssignments: attachableSkillCheckAssignments,
          tryouts: attachableTryouts,
        } = attachableTokens;

        isSelectedAssignments.value = attachableAssignments.map((assignment) => assignments.map((a) => a.id).includes(assignment.id));
        isSelectedSkillCheckAssignments.value = attachableSkillCheckAssignments.map((skillCheckAssignment) => skillCheckAssignments.map((s) => s.id).includes(skillCheckAssignment.id));
        isSelectedTryouts.value = attachableTryouts.map((tryout) => tryouts.map((t) => t.id).includes(tryout.id));
      },
    );

    onMounted(async () => {
      const { tokens } = await fetchTestTokens();
      attachableTokens.value = Tokens.new(tokens);
    });

    return {
      I18n,
      closeModal,
      handleToggleSkillCheck,
      handleToken,
      isSelectedTryouts,
      isSelectedAssignments,
      isSelectedSkillCheckAssignments,
      isSelectedSkillChecks,
      maxSkillCheckLevel,
      percentileRank,
      skillCheckLevelImg,
      skillChecks,
      style,
      testFrameworks,
      testIcon,
      testLanguages,
      tokens: attachableTokens,
      tooltipContent,
    };
  },
});
</script>

<style lang="scss" module>
.toggle {
  margin-left: 8px;
}
</style>
