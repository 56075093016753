import { ActionTree } from 'vuex';
import types from './mutation-types';
import { PostState } from './state';
import { fetchPosts, fetchUserFeed, fetchUserFeedPosts, updateUserFeedTags } from '@/exportables/apis/career/post.api';
import { fetchCurrentUser } from '@/exportables/apis/user.api';
import { UserFeedTab, UserFeedTag } from '@/exportables/models/career/user-feed.model';
import { cloneArray, cloneObject } from '@/helpers/utils';
import { FeedTab, FEED_TAB } from '@/pages/career/posts/constants';
import { CareerStore } from '@/store/career';

const {
  SET_LOGIN,
  SET_CURRENT_PAGE,
  SET_FEED_TAB,
  SET_POSTS,
  SET_TOTAL_PAGE,
  SET_USER_NAME,
  SET_USER_FEED,
  SET_USER_FEED_DEFAULT,
  SET_BACKUP_USER_FEED,
  REMOVE_USER_FEED_TAG,
  ADD_USER_FEED_TAG,
} = types;

const actions: ActionTree<PostState, CareerStore> = {
  async setLogin({ commit }) {
    const { isLoggedIn } = await fetchCurrentUser();

    commit(SET_LOGIN, isLoggedIn);
  },
  async setTabAndPage({ commit }, { tab, page }: { tab: FeedTab, page: number }) {
    const { posts, totalPage } = await (
      tab === FEED_TAB.feed
        ? fetchUserFeedPosts(page)
        : fetchPosts(page)
    );

    commit(SET_CURRENT_PAGE, page);
    commit(SET_FEED_TAB, tab);
    commit(SET_POSTS, posts);
    commit(SET_TOTAL_PAGE, totalPage);
  },
  async setUserFeed({ commit }) {
    const { userName, userFeed, userFeedDefault } = await fetchUserFeed();

    commit(SET_USER_NAME, userName);
    commit(SET_USER_FEED, userFeed);
    commit(SET_USER_FEED_DEFAULT, userFeedDefault ?? {
      post: [],
      company: [],
      technical: [],
      jobCategory: [],
      companyBenefit: [],
    });
    commit(SET_BACKUP_USER_FEED, cloneObject(userFeed));
  },
  restoreChangesInUserFeedTags({ commit, state }) {
    commit(SET_USER_FEED, cloneObject(state.backupUserFeed));
  },
  async saveUserFeedTags({ commit, getters }) {
    const tagsList = getters.toTagsList;
    const { userFeed: newUserFeed } = await updateUserFeedTags(tagsList);

    commit(SET_USER_FEED, newUserFeed);
    commit(SET_BACKUP_USER_FEED, cloneObject(newUserFeed));
  },
  toggleUserFeedTag({ commit, getters }, { key, tag }: { key: UserFeedTab, tag: UserFeedTag }) {
    getters.tagExists(tag)
      ? commit(REMOVE_USER_FEED_TAG, { key, tag })
      : commit(ADD_USER_FEED_TAG, { key, tag });
  },
  toggleAllJobCategoryTags({ commit, state }) {
    const { userFeed, userFeedDefault } = state;

    const hasAllJobCategoryTags =
      userFeed.jobCategory.length === (userFeedDefault.jobCategory || []).length;

    if (hasAllJobCategoryTags) {
      commit(SET_USER_FEED, {
        ...userFeed,
        jobCategory: [],
      });
      return;
    }

    const newUserFeedTags = {
      ...userFeed,
      jobCategory: cloneArray(userFeedDefault.jobCategory || []),
    };

    commit(SET_USER_FEED, newUserFeedTags);
  },
};
export default actions;
