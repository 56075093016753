<template>
  <aside class="side-content">
    <section v-if="isLoggedIn && hasUserFeedTags" class="feed-setting">
      <div class="feed-setting__top">
        <h5 class="feed-setting__header">
          테크 피드 주제
        </h5>
        <button
          data-toggle="tooltip"
          data-placement="top"
          data-title="관심 주제 설정"
          class="btn btn-svg btn-more"
          @click="handleSetting"
        >
          <SvgIcon name="ic-setting" html-class="ic-24 ic-blue-grey-300" />
        </button>
      </div>
      <div class="feed-setting__bottom">
        <ul class="feed-setting__keyword">
          <li class="feed-setting__tag">
            <a
              v-for="(tag, index) in tags"
              :key="index"
              :href="`posts/tag/${encodeURIComponent(tag.name.replace(/\./g, '%2E'))}`"
              class="feed-setting__tag-keyword"
              target="_blank"
              rel="noopener"
            >
              {{ tag.name }} ({{ tag.count }})
            </a>
          </li>
        </ul>
      </div>
    </section>

    <section v-else class="alert alert-info alert-feed-setting">
      <h5 class="alert-header">
        나만의 테크 피드를 만들어보세요.
      </h5>
      <h6>관심 분야 태그를 선택하여 프로그래머스가 제공하는 테크 피드를 구독하세요!</h6>
      <button
        class="btn btn-md btn-primary btn-setting"
        @click="handleSetting"
      >
        관심 분야 설정
      </button>
    </section>
    <UserFeedModalContainer v-if="isLoggedIn" />
    <section class="side-content__register">
      <h5 class="font-weight-700">
        함께 읽어요!
      </h5>
      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLSejUXiKLohZH9f7ouLzL5heA4M4GGKHhBhFjlT3D89fV4IGGw/viewform"
        class="register-article"
        target="_blank"
      >
        <h6 class="mb-1 color-blue-grey-900 font-weight-700 register-article-title">
          다른 개발자들과 함께 읽고 싶은 게시물이 있나요? 링크를 공유해주세요.
        </h6>
        <img class="w-100" src="~images/img-share-article.png">
      </a>
    </section>
  </aside>
</template>

<script lang="ts">
import { defineComponent, PropType } from '@vue/composition-api';
import UserFeedModalContainer from '../containers/UserFeedModalContainer.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { UserFeedTag } from '@/exportables/models/career/user-feed.model';

export default defineComponent({
  name: 'UserFeedPreview',
  components: {
    SvgIcon,
    UserFeedModalContainer,
  },
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true,
    },
    hasUserFeedTags: {
      type: Boolean,
      required: true,
    },
    tags: {
      type: Array as PropType<UserFeedTag[]>,
      required: true,
    },
  },
  emits: ['setting'],
  setup(_, { emit }) {
    const handleSetting = () => emit('setting');

    return {
      handleSetting,
    };
  },
});
</script>
