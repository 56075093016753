<template>
  <section class="personal-information">
    <TopProgrammersBadge v-if="isTopProgrammers && !isSharable" />

    <div class="name">
      <h1 :class="style.name">
        {{ resume.name || '' }}
      </h1>

      <button
        v-if="isBusinessPage && !isEditable && hasHistory"
        class="btn btn-sm btn-outline-primary"
        :class="style.historyBtn"
        @click.prevent="openHistoryModal"
      >
        {{ I18n.t('business.recruit.job_profile.detail.buttons.show_record.content') }}
      </button>

      <SectionEditButton
        v-if="!isBusinessPage"
        :is-editable="isEditable"
        :tooltip="I18n.t('resume.personal_information.tooltip')"
        type="PersonalInformation"
      />
    </div>

    <ul class="contacts">
      <li v-if="resume.phone && isVisible('phone')" class="tel">
        <SvgIcon name="ic-phone-14" html-class="ic-14" />
        <a :href="`tel:${resume.phone}`">{{ resume.phone }}</a>
        <div v-if="resume.isPhoneVerification" :class="style.verified">
          {{ I18n.t('profile.authenticated_info.status.confirmed') }}
        </div>
      </li>

      <li v-if="resume.email && isVisible('email')" class="email">
        <SvgIcon name="ic-email-14" html-class="ic-14" />
        <a :href="`mailto:${resume.email}`">{{ resume.email }}</a>
        <div v-if="resume.isEmailVerification" :class="style.verified">
          {{ I18n.t('profile.email.status.confirmed') }}
        </div>
      </li>

      <li v-if="resume.blog && isVisible('blog')" class="website">
        <SvgIcon name="ic-public-14" html-class="ic-14" />
        <a
          :href="resume.blog"
          target="_blank"
          rel="noopener"
        >{{ resume.blog }}</a>
      </li>

      <li v-if="resume.salary && isVisible('salary') && !isMasking" class="salary">
        <SvgIcon name="ic-money-14" html-class="ic-14" />
        <span>{{ I18n.t('resume.personal_information.salary') }} ― {{ resume.salary }} {{ I18n.t('resume.personal_information.salary_unit') }}</span>
      </li>
    </ul>

    <MarkdownViewer
      v-if="resume.introduction && isVisible('introduction')"
      class="markdown-viewer description"
      :value="resume.introduction"
    />
  </section>
</template>

<script lang="ts">
import { Viewer } from '@toast-ui/vue-editor';
import {
  PropType,
  computed,
  defineComponent,
  ref,
  toRefs,
  unref,
  useCssModule,
  watch,
} from '@vue/composition-api';
import SectionEditButton from './SectionEditButton.vue';
import MarkdownViewer from '@/components/shared/MarkdownViewer.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { History } from '@/exportables/models/career/business/history.model';
import { OpenSetting } from '@/exportables/models/career/job-profile.model';
import { Resume } from '@/exportables/models/career/resume.model';
import { useI18n } from '@/helpers/i18n';
import { useModal } from '@/helpers/modal';
import { isBusinessPage } from '@/helpers/url';
import { useViewer } from '@/helpers/viewer';
import HistoryModal from '@/pages/career/business/job-applications/components/modal/History.vue';
import { showPublicOption } from '@/pages/career/job-profiles/job-profile.util';
import { useRoute } from '@/router/career';
import TopProgrammersBadge from '@/shared/career/components/TopProgrammersBadge.vue';
import { useStore } from '@/store/career';

export default defineComponent({
  name: 'ResumePersonalInformation',
  components: {
    MarkdownViewer,
    SectionEditButton,
    SvgIcon,
    TopProgrammersBadge,
  },
  props: {
    fetchHistory: {
      type: Function as PropType<() => History>,
      default: () => {},
    },
    hasHistory: {
      type: Boolean,
      default: false,
    },
    isTopProgrammers: {
      type: Boolean,
      default: false,
    },
    openSetting: {
      type: Object as PropType<OpenSetting>,
      default: () => ({}),
    },
    resume: {
      type: Object as PropType<Resume>,
      required: true,
    },
  },
  setup(props) {
    const I18n = useI18n();
    const style = useCssModule();
    const modal = useModal();
    const route = useRoute();
    const store = useStore();
    const viewerRef = ref<Viewer>(null!);
    const isMasking = ref(false);
    const { isEditable, isSharable } = toRefs(store.state.resume);
    const isPublicPage = computed(() => store.getters['resume/isPublicPage']);
    const isPrintPage = computed(() => store.getters['resume/isPrintPage']);

    const isVisible = (key: keyof OpenSetting) => {
      const isTrue = showPublicOption(props.openSetting);
      const isSettingPage = unref(isSharable) && !unref(isPublicPage);
      return isSettingPage ? isTrue(key) : true;
    };

    const openHistoryModal = async () => {
      if (!props.hasHistory) return;

      const history = await props.fetchHistory();
      modal.show(HistoryModal, { name: props.resume.name, history });
    };

    useViewer(viewerRef, () => props.resume.introduction);

    watch(
      () => route.query,
      (query) => {
        if (!unref(isPrintPage)) return;
        isMasking.value = Boolean(query?.salary_masking);
      },
      { immediate: true },
    );

    return {
      I18n: { t: I18n.t },
      isBusinessPage: isBusinessPage(),
      isEditable,
      isMasking,
      isSharable,
      isVisible,
      openHistoryModal,
      style,
      viewerRef,
    };
  },
});
</script>

<style lang="scss" module>
@use "~/stylesheets/display";
@use "~/stylesheets/variables";
@use "~/stylesheets/functions";

.name {
  @extend .flex-between;
  width: 100%;
  gap: functions.rem-calc(8);
}

.historyBtn {
  flex-shrink: 0;
  padding: functions.rem-calc(5 13);
  font-size: 14px;
  line-height: 20px;

  @media (variables.$sm-down) {
    padding: functions.rem-calc(2 6);
    font-size: 12px;
    line-height: 150%;
  }
}

.verified {
  display: flex;
  align-items: center;
  width: fit-content;
  font-weight: 500;
  margin-top: 0;
  margin-left: 0.5rem;
  background: rgba(0, 120, 255, 0.1);
  border: 1px solid rgba(0, 120, 255, 0.1);
  border-radius: functions.rem-calc(4);
  color: variables.$color-primary;
  padding: functions.rem-calc(2 8);
  font-size: functions.rem-calc(12);
}
</style>
