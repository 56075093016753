import { SkillCheck } from './career/tokens.model';

export class User {
  id: number = -1;
  authorization?: Auth;
  canFilterRecentFreshness?: boolean;
  canSelectInteractMode?: boolean;
  canSelectMode?: boolean;
  company: {
    id: number,
    name: string,
  } = {
    id: -1,
    name: '',
  };
  coverName: string = '';
  hasJobProfile?: boolean;
  hasSlackNotiUrl?: boolean;
  isAdmin?: boolean;
  isLoggedIn: boolean = false;
  isPremiumPlan?: boolean;
  isSchoolPremium?: boolean;
  isShowTopProgrammers?: boolean;
  isTestManageable?: boolean;
  isTestManager?: boolean;
  name: string = '';
  passedSkillChecks?: SkillCheck[] = [];
  phone?: string;
  printPolicy?: boolean;
  profileImageUrl?: string | HTMLImageElement = require('~/images/img-profile-default.jpg');
  roles?: string[] = [];

  email?: string;

  constructor(user?: Partial<User>) {
    Object.assign(this, user);
  }

  static new(user?: Partial<User>) {
    return new User(user);
  }
}

export type Auth = {
  id: number,
  provider?: string,
  url?: string,
};
