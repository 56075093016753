<script lang="tsx">
import {
  defineComponent,
  useCssModule,
  PropType,
  ref,
  computed,
  watchEffect,
} from '@vue/composition-api';
import SvgIcon from './SvgIcon.vue';
import { TabControlItem } from './TabControl.type';
import { skeleton } from '@/directives';

export default defineComponent({
  name: 'TabControl',
  directives: {
    skeleton,
  },
  props: {
    isNavLoading: {
      type: Boolean,
      default: false,
    },
    isContentLoading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array as PropType<TabControlItem<string>[]>,
      required: true,
    },
    defaultIndex: {
      type: Number,
      default: 0,
    },
  },
  emits: ['change-tab'],
  setup(props, { emit, slots }) {
    const style = useCssModule();
    const selectedIndex = ref(props.defaultIndex);
    const selectedItem = computed(() => props.items[selectedIndex.value]);

    const handleChange = (item: TabControlItem<string>, index: number) => {
      if (props.isContentLoading) return;
      if (selectedIndex.value === index) return;
      selectedIndex.value = index;
      emit('change-tab', item);
    };

    watchEffect(() => {
      selectedIndex.value = props.defaultIndex;
    });

    return () => (
      <div class={style.container}>
        <div v-skeleton={props.isNavLoading} class={[style.inner, style.navContainer]}>
          <nav class={{
            [style.nav]: true,
            [style.navSkeleton]: props.isNavLoading,
          }}>
            {
              props.items.map((item, index) => (
                <ul class={item.className} key={index}>
                  <button
                    class={{
                      [style.selected]: selectedIndex.value === index,
                      [style.loading]: props.isContentLoading,
                    }}
                    onClick={() => handleChange(item, index)}
                  >
                    <SvgIcon name={item.iconName} htmlClass="ic-18" />
                    { item.label }
                  </button>
                </ul>
              ))
            }
          </nav>
        </div>
        <section ref="contentRef" class={style.content}>
          <div v-skeleton={props.isContentLoading} class={[style.inner, props.isContentLoading && style.contentSkeleton]}>
            {
              slots?.[selectedItem.value?.name]?.() ?? null
            }
          </div>
        </section>
      </div>
    );
  },
});
</script>

<style lang="scss" module>
@use "~/stylesheets/variables";
@use "~/stylesheets/functions";

.container {
  position: relative;
}

.inner {
  width: 100%;
  margin: 0 auto;
  padding: functions.rem-calc(0 16);
  max-width: functions.rem-calc(992);
}

.contentSkeleton {
  min-height: functions.rem-calc(420);
}

.navSkeleton {
  min-height: functions.rem-calc(37);
}

.navContainer {
  overflow-x: auto;

  .nav {
    display: inline-flex;
    justify-content: flex-start;
    gap: functions.rem-calc(16);

    > ul {
      margin: 0;
      padding: 0;

      > button {
        display: inline-flex;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: functions.rem-calc(16);
        gap: functions.rem-calc(6);
        background: none;
        padding: functions.rem-calc(8 2);
        border: none;
        border-radius: functions.rem-calc(4 4 0 0);
        white-space: nowrap;
        cursor: pointer;
        color: variables.$color-text--primary;

        &.loading {
          cursor: wait;
        }

        &.selected {
          position: relative;
          color: variables.$color-primary;
          border-bottom: functions.rem-calc(3) solid variables.$color-primary;
          padding-bottom: functions.rem-calc(5);

          > svg {
            fill: variables.$color-primary;
          }
        }

        > svg {
          fill: variables.$color-text--primary;
        }
      }
    }
  }
}

.content {
  border-top: functions.rem-calc(1) solid variables.$color-border;
  padding: functions.rem-calc(32 0);

  @media (variables.$md-down) {
    padding: functions.rem-calc(24 0);
  }
}

@media (variables.$md-down) {
  .navContainer {
    .nav {
      gap: functions.rem-calc(12);

      > ul {
        > button {
          font-size: functions.rem-calc(15);

          svg {
            display: none;
          }
        }
      }
    }
  }
}
</style>
