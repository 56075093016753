<template>
  <div class="resume-card-item">
    <div class="resume-card-left">
      <h5 class="resume-card-item-period">
        {{ I18n.l('date.formats.year', project.startAt) }}
      </h5>
    </div>

    <div ref="hoverRef" class="resume-card-right">
      <h4 class="resume-card-item-label">
        {{ project.name }}
      </h4>

      <ul v-if="project.tags && project.tags.length" class="tag-list">
        <li
          v-for="tag in project.tags"
          :key="tag.id"
          :class="isHighlightableTag(tag.name) && 'highlight'"
        >
          {{ tag.name }}
        </li>
      </ul>

      <h5 v-if="project.description" class="resume-card-item-text">
        {{ project.description }}
      </h5>

      <MarkdownViewer
        class="markdown-viewer"
        :value="project.roleDescription"
      />

      <ul class="list-contributions">
        <li v-if="project.teamDescription" class="list-contribution-item">
          {{ I18n.t('section.project.team_type') }} ― {{ teamDescription }}
        </li>

        <template v-for="url in productUrls">
          <li
            v-if="url[1]"
            :key="url[0]"
            class="list-contribution-item"
          >
            <a
              :href="url[1]"
              target="_blank"
              rel="noopener"
            >
              {{ url[1] }}
            </a>
          </li>
        </template>

        <li v-if="project.repository" class="list-contribution-item">
          <a
            :href="project.repository"
            target="_blank"
            rel="noopener"
          >
            {{ project.repository }}
          </a>
        </li>
      </ul>

      <a
        v-if="project.analyzedLink"
        :href="project.analyzedLink"
        class="btn btn-svg btn-md btn-outline-primary btn-repo-analyze"
        data-remote="true"
      >
        <SvgIcon name="ic-code" html-class="ic-24" />
        <span>
          Github {{ I18n.t('section.project.view_code') }}
        </span>
      </a>

      <HoverButtonWrapper
        v-if="isHovered"
        type="project"
        :delete-item="() => handleDeleteItem(index)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  PropType,
  computed,
  defineComponent,
  inject,
  ref,
  toRef,
  unref,
  onBeforeUnmount,
} from '@vue/composition-api';
import HoverButtonWrapper from '../HoverButtonWrapper.vue';
import MarkdownViewer from '@/components/shared/MarkdownViewer.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { ResumeProject } from '@/exportables/models/career/resume.model';
import { useHover } from '@/helpers/event';
import { useI18n } from '@/helpers/i18n';
import { useStore } from '@/store/career';

export default defineComponent({
  name: 'ResumeEducationPreview',
  components: {
    HoverButtonWrapper,
    MarkdownViewer,
    SvgIcon,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    project: {
      type: Object as PropType<ResumeProject>,
      required: true,
    },
  },
  setup(props) {
    const { handleDeleteItem } = inject('form')!;
    const hoverRef = ref(null);
    const I18n = useI18n();
    const { isHovered, unwatch } = useHover(hoverRef);
    const store = useStore();
    const project = toRef(props, 'project');
    const clickedTagName = computed(() => store.state.resume.clickedTagName);
    const mouseOveredTagName = computed(() => store.state.resume.mouseOveredTagName);
    const teamDescription = computed(() => {
      const unreffedTeamDescription = unref(project).teamDescription;
      return unreffedTeamDescription === 'person' ? I18n.t('section.project.person') : unreffedTeamDescription;
    });
    const productUrls = computed(() => {
      const { productUrl = {} } = unref(project);
      return productUrl && Object.entries(productUrl).filter((type, url) => url && [type, url]) || [];
    });

    const isHighlightableTag = (tagName: string) => {
      const unreffedMouseOveredTagName = unref(mouseOveredTagName);
      return (
        unreffedMouseOveredTagName === tagName ||
        unreffedMouseOveredTagName === '' && unref(clickedTagName) === tagName ||
        false
      );
    };

    onBeforeUnmount(unwatch);

    return {
      I18n,
      clickedTagName,
      handleDeleteItem,
      hoverRef,
      isHovered,
      isHighlightableTag,
      mouseOveredTagName,
      productUrls,
      teamDescription,
    };
  },
});
</script>
