<template>
  <Card
    v-if="isEditable || activities.length"
    :is-editable="isEditable"
    :title="I18n.t('resume.activity')"
    @add-item="handleAddItem"
  >
    <template #body>
      <Activity
        v-if="newItem"
        ref="newFormRef"
        :index="activities.length"
        :info="newItem"
        :is-added="true"
        type="activity"
      />
      <Activity
        v-for="(activity, index) in activities"
        :key="activity.index"
        :index="index"
        :info="activity"
        type="activity"
      />
    </template>
  </Card>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  provide,
} from '@vue/composition-api';
import { useFocusInputOnMount, useForm } from '../hooks/form';
import { ResumeShowStates, getResume } from '../hooks/state';
import Card from './Card.vue';
import Activity from './Index.vue';
import { ResumeActivity } from '@/exportables/models/career/resume.model';
import { useI18n } from '@/helpers/i18n';
import { useStore } from '@/store/career';

export default defineComponent({
  name: 'ResumeActivities',
  components: {
    Activity,
    Card,
  },
  setup() {
    const state = inject<ResumeShowStates>('state')!;
    const I18n = useI18n();
    const store = useStore();
    const activities = computed(() => getResume(state).activities);
    const isEditable = computed(() => store.state.resume.isEditable);
    const form = useForm(state, 'activities', ResumeActivity);
    const { ref: newFormRef } = useFocusInputOnMount();

    provide('form', form);

    return {
      ...form,
      I18n: { t: I18n.t },
      activities,
      isEditable,
      newFormRef,
    };
  },
});
</script>
