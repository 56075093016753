<template>
  <Card
    v-if="isEditable || publications.length"
    :is-editable="isEditable"
    :title="I18n.t('resume.publication')"
    @add-item="handleAddItem"
  >
    <template #body>
      <Publication
        v-if="newItem"
        ref="newFormRef"
        :index="publications.length"
        :info="newItem"
        :is-added="true"
        type="publication"
      />
      <Publication
        v-for="(publication, index) in publications"
        :key="publication.index"
        :index="index"
        :info="publication"
        type="publication"
      />
    </template>
  </Card>
</template>

<script lang="ts">
import {
  Ref,
  computed,
  defineComponent,
  inject,
  provide,
} from '@vue/composition-api';
import { useFocusInputOnMount, useForm } from '../hooks/form';
import { ResumeShowStates, getResume } from '../hooks/state';
import Card from './Card.vue';
import Publication from './Index.vue';
import { ResumePublication } from '@/exportables/models/career/resume.model';
import { useI18n } from '@/helpers/i18n';
import { useStore } from '@/store/career';

export default defineComponent({
  name: 'ResumePublications',
  components: {
    Card,
    Publication,
  },
  setup() {
    const state = inject<ResumeShowStates>('state')!;
    const I18n = useI18n();
    const store = useStore();
    const publications = computed(() => getResume(state).publications);
    const isEditable = computed(() => store.state.resume.isEditable);
    const form = useForm(state, 'publications', ResumePublication);
    const { ref: newFormRef } = useFocusInputOnMount();

    provide('form', form);

    return {
      ...form,
      I18n: { t: I18n.t },
      isEditable,
      newFormRef,
      publications,
    };
  },
});
</script>
