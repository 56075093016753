<template>
  <li
    v-if="currentUser.isLoggedIn"
    class="list-position-item recommend-item profile-recommend"
  >
    <div class="item-header">
      <img
        src="~images/img-item-recommend.png"
        class="img-item-reccoment company-logo"
        alt="reccommend-image"
      >
    </div>

    <div class="item-body">
      <h5 v-skeleton="isLoading" class="position-title">
        <RouterLink to="/job_profiles/edit">
          {{ I18n.t('job_position.show.profile_guide.title') }}
        </RouterLink>
      </h5>

      <h6
        v-skeleton="isLoading"
        class="sub-title"
        v-html="I18n.t('job_position.show.profile_guide.description_html', { name: currentUser.name })"
      ></h6>

      <div class="buttons">
        <RouterLink to="/job_profiles/edit" class="btn btn-sm btn-primary btn-more">
          {{ I18n.t('job_position.show.profile_guide.new_profile') }}
        </RouterLink>

        <a
          :href="`https://form.typeform.com/to/ij683i?ukey=${currentUser.id}`"
          class="btn btn-sm btn-primary btn-more"
          target="_blank"
          rel="noopener"
        >
          {{ I18n.t('job_position.show.profile_guide.from_typeform') }}
        </a>
      </div>
    </div>
  </li>
</template>

<script lang="ts">
import {
  defineComponent,
  inject,
} from '@vue/composition-api';
import { skeleton } from '@/directives';
import { useI18n } from '@/helpers/i18n';

const I18n = useI18n();

export default defineComponent({
  name: 'JobPositionJobProfileGuideItem',
  directives: {
    skeleton,
  },
  props: {
    currentUser: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const isLoading = inject('isLoading');

    return {
      I18n,
      isLoading,
    };
  },
});
</script>
