<template>
  <ErrorPage
    v-bind="$attrs"
    :only-show-error="onlyShowError"
    :title="title"
    title-sub="404 Page not found"
    class="not-found-error"
  >
    <img slot="homeIcon" src="~images/ic_hall_24_FF9800.png" class="home-icon">
    <div slot="body">
      <img src="~images/error-404.png" class="img-title">
    </div>
  </ErrorPage>
</template>

<script>
import ErrorPage from '@/components/shared/ErrorPage.vue';
import { useI18n } from '@/helpers/i18n';

export default {
  name: 'ErrorNotFound',
  components: {
    ErrorPage,
  },
  props: {
    onlyShowError: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    title() {

      const I18n = useI18n();
      return I18n.t('exceptions.message.not_found.default');
    },
  },
};
</script>

<style lang="scss">
  .not-found-error {
    padding: 0 40px 40px;
  }
</style>
