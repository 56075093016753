import { useHttpClient } from '../httpClient';
import { FeaturedPost } from '@/exportables/models/career/featured-post.model';
import { Post } from '@/exportables/models/career/post.model';
import { UserFeed } from '@/exportables/models/career/user-feed.model';
import { useI18n } from '@/helpers/i18n';

export const POST_URL = '/posts';
export const FEATURED_POST_URL = `${POST_URL}/featured`;
export const TAG_POST_URL = `${POST_URL}/tag`;
export const USER_FEED_URL = '/user_feeds';

const httpClient = useHttpClient();
const I18n = useI18n();

export const fetchFeaturedPosts = async (): Promise<{ featuredPosts: FeaturedPost[] }> => {
  const { data, status } = await httpClient.get(FEATURED_POST_URL);

  if (status !== 200) {
    throw new Error(data?.message || I18n.t('error.internal'));
  }

  return data;
};

export const fetchPosts = async (page: number = 1): Promise<{
  posts: Post[],
  totalPage: number,
}> => {
  const { data, status } = await httpClient.get(POST_URL, {
    params: {
      page,
      feed: false,
    },
  });

  if (status !== 200) {
    throw new Error(data?.message || I18n.t('error.internal'));
  }

  return data;
};

export const fetchTagPosts = async (tag: string, page: number = 1) => {
  const { data, status } = await httpClient.get(TAG_POST_URL, {
    params: {
      tag,
      page,
    },
  });

  if (status !== 200) {
    throw new Error(data?.message || I18n.t('error.internal'));
  }

  return data;
};

export const fetchUserFeedPosts = async (
  page: number = 1,
): Promise<{
  posts: Post[],
  totalPage: number,
}> => {
  const { data, status } = await httpClient.get(POST_URL, {
    params: {
      page,
      feed: true,
    },
  });

  if (status !== 200) {
    throw new Error(data?.message || I18n.t('error.internal'));
  }

  return data;
};

export const fetchUserFeed = async (): Promise<UserFeed> => {
  const { data, status } = await httpClient.get(USER_FEED_URL);

  if (status !== 200) {
    throw new Error(data?.message || I18n.t('error.internal'));
  }

  return data;
};

export type TagsList = {
  postTagList: string[],
  technicalTagList: string[],
  jobCategoryTagList: string[],
  companyTagList: string[],
};

export const updateUserFeedTags = async (
  tagsList: TagsList,
): Promise<Pick<UserFeed, 'userFeed'>> => {
  const { data, status } = await httpClient.patch(USER_FEED_URL, tagsList);

  if (status !== 200) {
    throw new Error(data?.message || I18n.t('error.internal'));
  }

  return data;
};
