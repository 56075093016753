import { useHttpClient } from '../httpClient';
import { CompetitionItem, CompetitionJobPositionsTab, CompetitionSchedulesTab } from '@/exportables/models/career/competition.model';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';
import { CompetitionShow } from '@/pages/career/competitions/hooks/show/state';

export const COMPETITION_URL = '/competitions';
export const REGISTRATION_GROUPS_URL = '/registration_groups';

const httpClient = useHttpClient();
const I18n = useI18n();

export type CompetitionsWithPage = {
  competitions: CompetitionItem[],
  page: number,
  totalPages: number,
};

export type UpdateCompetitionPayload =
  Pick<CompetitionShow, 'title' | 'description' | 'receiptStartAt' | 'receiptEndAt'> &
  {
    faq: string,
    scheduleDescription: string,
  };

export const fetchCompetition = async (id: number) => {
  try {
    const { data, status } = await httpClient.get(`${COMPETITION_URL}/${id}`);
    if (status !== 200) throw new Error(data?.message || I18n.t('error.internal'));
    return data as CompetitionShow;
  } catch (error) {
    error instanceof Error && appendToast(error.message);
    throw error;
  }
};

export const fetchCompetitionDescription = async (id: number) => {
  try {
    const { data, status } = await httpClient.get(`${COMPETITION_URL}/${id}/description`);
    if (status !== 200) throw new Error(data?.message || I18n.t('error.internal'));
    return data as {
      description: string,
    };
  } catch (error) {
    error instanceof Error && appendToast(error.message);
    throw error;
  }
};

export const fetchCompetitionFaq = async (id: number) => {
  try {
    const { data, status } = await httpClient.get(`${COMPETITION_URL}/${id}/faq`);
    if (status !== 200) throw new Error(data?.message || I18n.t('error.internal'));
    return data as {
      faq: string,
    };
  } catch (error) {
    error instanceof Error && appendToast(error.message);
    throw error;
  }
};

export const fetchCompetitionJobPositions = async (id: number, filterParams: CompetitionJobPositionsTab['filterParams']) => {
  try {
    const { data, status } = await httpClient.get(`${COMPETITION_URL}/${id}/job_positions`, { params: filterParams });
    if (status !== 200) throw new Error(data?.message || I18n.t('error.internal'));
    return data as CompetitionJobPositionsTab;
  } catch (error) {
    error instanceof Error && appendToast(error.message);
    throw error;
  }
};

export const fetchCompetitionSchedules = async (id: number) => {
  try {
    const { data, status } = await httpClient.get(`${COMPETITION_URL}/${id}/schedules`);
    if (status !== 200) throw new Error(data?.message || I18n.t('error.internal'));
    return data as CompetitionSchedulesTab;
  } catch (error) {
    error instanceof Error && appendToast(error.message);
    throw error;
  }
};

export const updateCompetition = async (id: number, payload: UpdateCompetitionPayload) => {
  try {
    const { data, status } = await httpClient.patch(`${COMPETITION_URL}/${id}`, payload);
    if (status !== 200) throw new Error(data?.message || I18n.t('error.internal'));
    return data as UpdateCompetitionPayload & { id: number };
  } catch (error) {
    error instanceof Error && appendToast(error.message);
    throw error;
  }
};

export const fetchCompetitions = async (registered: boolean, page: number) => {
  try {
    const { data, status } = await httpClient.get(`${COMPETITION_URL}?registered=${registered}&page=${page}`);
    if (status !== 200) throw new Error(data?.message || I18n.t('error.internal'));
    return data as CompetitionsWithPage;
  } catch (error) {
    error instanceof Error && appendToast(error.message);
    throw error;
  }
};

export const fetchJobPositions = async (id: number) => {
  try {
    const { data, status } = await httpClient.get(`${REGISTRATION_GROUPS_URL}/${id}/job_positions`);
    if (status !== 200) throw new Error(data?.message || I18n.t('error.internal'));
    return data;
  } catch (error) {
    error instanceof Error && appendToast(error.message);
    throw error;
  }
};
