<template>
  <div class="test-item">
    <h4 class="resume-card-item-label">
      {{ I18n.t('resume.test_results.skill_check_level') }} {{ maxSkillCheckLevel }}
      <span
        v-if="isBusinessPage"
        v-tooltip:top="I18n.t('resume.test_results.introduction')"
        class="btn-help"
        @click="openGuideModal"
      >
        <SvgIcon name="ic-help-full" html-class="ic-20" />
      </span>
    </h4>

    <div class="section-items-skill-check">
      <div
        v-for="check in skillChecks"
        :key="check.level"
        :data-original-title="`<h5><b>${I18n.t('resume.test_results.skill_check_level')} ${check.level}</b></h5>`"
        :data-content="tooltipContent(check)"
        class="card-resume-detail--skillcheck js-skill-check"
        data-container="body"
        data-html="true"
        data-placement="top"
        :data-toggle="!isSharable && 'popover'"
        :style="isSharable && 'pointer-events: none;'"
        @click="!isSharable && moveTo(check.codeUrl, true)"
      >
        <div class="badge-wrapper">
          <img
            :src="skillCheckLevelImg(check.level)"
            class="img-badge-skill"
            :alt="I18n.t('resume.test_results.skill_check_level_image')"
          >
        </div>

        <template v-if="!isSharable">
          <div class="overlay"></div>
          <div class="info">
            <div class="info-txt">
              <SvgIcon name="ic-launch-16" html-class="ic-16 ic-white" />
              {{ I18n.t('resume.test_results.view_code') }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  PropType,
  computed,
  defineComponent,
} from '@vue/composition-api';
import {
  skillCheckLevelImg,
  tooltipContent,
} from '../../resume.util';
import GuideModal from '../modal/SkillCheckGuide.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { tooltip } from '@/directives';
import { SkillCheck } from '@/exportables/models/career/tokens.model';
import { useI18n } from '@/helpers/i18n';
import { useModal } from '@/helpers/modal';
import { isBusinessPage, moveTo } from '@/helpers/url';
import { useStore } from '@/store/career';

export default defineComponent({
  name: 'ResumeSkillChecksResult',
  components: {
    SvgIcon,
  },
  directives: {
    tooltip,
  },
  props: {
    skillChecks: {
      type: Array as PropType<SkillCheck[]>,
      default: () => [],
    },
  },
  setup(props) {
    const { skillChecks } = props;
    const I18n = useI18n();
    const modal = useModal();
    const store = useStore();
    const isSharable = computed(() => store.state.resume.isSharable);
    const maxSkillCheckLevel = Math.max(...skillChecks.map((check) => check.level));

    const openGuideModal = () => modal.show(GuideModal);

    return {
      I18n,
      isBusinessPage: isBusinessPage(),
      isSharable,
      maxSkillCheckLevel,
      moveTo,
      openGuideModal,
      skillCheckLevelImg,
      tooltipContent,
    };
  },
});
</script>
