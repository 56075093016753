<template>
  <div class="sidebar-nav">
    <div
      id="tag-setting-tab"
      class="nav flex-column nav-pills"
      role="tablist"
      aria-orientation="vertical"
    >
      <a
        v-for="(tab, index) in tabs"
        :key="`${tab}_${index}`"
        :class="{ 'active show': (tab === currentTab) }"
        data-toggle="pill"
        class="nav-link"
        role="tab"
        aria-controls="tag-entire"
        aria-selected="false"
        @click="handleChangeTab(tab)"
      >
        {{ tabName(tab) }}
        <span class="count-selected-tag">
          {{ tagCountByTabs[camelCase(tab)] }}
        </span>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from '@vue/composition-api';
import { FeedCategory } from '../constants';
import { useI18n } from '@/helpers/i18n';
import camelCase from '@/plugins/camelCase';

export default defineComponent({
  name: 'UserFeedTabs',
  props: {
    tabs: {
      type: Array as PropType<FeedCategory[]>,
      required: true,
    },
    currentTab: {
      type: String,
      required: true,
    },
    tagCountByTabs: {
      type: Object as PropType<Record<FeedCategory, number>>,
      required: true,
    },
  },
  emits: ['change'],
  setup(_, { emit }) {
    const I18n = useI18n();

    const tabName = (tab: FeedCategory) => I18n.t(`user_feed.category.${tab}`);
    const handleChangeTab = (tab: FeedCategory) => emit('change', tab);

    return {
      camelCase,
      tabName,
      handleChangeTab,
    };
  },
});
</script>
