<template>
  <div v-if="input.name && !isClicked" class="search-wrap">
    <ul ref="listRef" class="search-list">
      <li
        v-for="(item, index) in result"
        :key="item.id"
        class="search-item"
        :class="index === highlightedIndex && 'highlighted'"
        @click="handleClickItem(item)"
        @mouseover="highlightedIndex = index">
        <slot name="search-item" :item="item">
          {{ item.name }}
        </slot>
      </li>

      <li v-if="!result.length" class="search-item none">
        <slot name="no-item" :item="input"></slot>
      </li>
    </ul>

    <div class="search-footer" @click="handleClickFooter">
      <slot name="footer" :item="input"></slot>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  inject,
  nextTick,
  ref,
  unref,
  watch,
} from '@vue/composition-api';

export default defineComponent({
  name: 'SearchResult',
  setup() {
    const search = inject('search');
    const listRef = ref(null);
    const isInModal = computed(() => unref(listRef)?.closest('.modal')?.childElementCount > 0);

    watch(
      search.result,
      () => unref(isInModal) && nextTick(unref(listRef)?.scrollIntoView()),
    );

    return {
      ...search,
      listRef,
    };
  },
});
</script>
