<template>
  <fieldset class="fieldset-contact-step">
    <RegistrationGroup
      v-if="requiredRegistrationGroup"
      v-model="registrationGroupId"
      :registration-groups="registrationGroups"
      @update:registrationGroupId="setRegistrationGroupId"
    >
      <span v-if="errors($v.registrationGroupId)" class="error-feedback">{{
        errors($v.registrationGroupId)
      }}</span>
    </RegistrationGroup>

    <div class="form-group form-group-name">
      <label class="control-label required">{{ I18n.t('terms.name') }}</label>
      <input
        v-model="$v.name.$model"
        placeholder="머쓱이"
        type="text"
        class="form-control"
        :disabled="generalAuthenticate"
      >
      <span v-if="errors($v.name) && $v.$dirty" class="error-feedback">{{
        errors($v.name)
      }}</span>
    </div>

    <div class="form-group form-group-email">
      <label class="control-label required">{{ I18n.t('terms.email') }}</label>
      <input
        v-model="$v.email.$model"
        placeholder="mussg@grepp.co"
        type="email"
        class="form-control"
        :disabled="generalAuthenticate"
      >
      <span v-if="errors($v.email) && $v.$dirty" class="error-feedback">{{
        errors($v.email)
      }}</span>
    </div>

    <div class="form-group form-group-phone">
      <label class="control-label required">{{ I18n.t('terms.phone') }}</label>
      <input
        v-model="$v.phone.$model"
        placeholder="'-' 제외"
        type="tel"
        class="form-control"
        :disabled="generalAuthenticate"
      >
      <span v-if="errors($v.phone) && $v.$dirty" class="error-feedback">{{
        errors($v.phone)
      }}</span>
    </div>
  </fieldset>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch, PropType, WritableComputedRef } from '@vue/composition-api';
import { useVuelidate, Validation } from '@vuelidate/core';
import { required, helpers, requiredIf, email as emailValidator } from '@vuelidate/validators';
import RegistrationGroup from './RegistrationGroup.vue';
import { Response } from '@/exportables/models/job-application.model';
import { RegistrationGroup as RegistrationGroupType } from '@/exportables/models/registration.model';
import { Resume } from '@/exportables/models/resume.model';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'JobApplicationContactStep',
  components: {
    RegistrationGroup,
  },
  props: {
    allowedDomains: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    registrationGroups: {
      type: Array as PropType<RegistrationGroupType[]>,
      default: () => [],
    },
    response: {
      type: Object as PropType<Response>,
      required: true,
    },
    restrictedDomains: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    hasJobPosition: {
      type: Boolean,
      default: false,
    },
    generalAuthenticate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:updateRegistrationGroup', 'update:updateSelectedResume'],
  setup(props, { emit }) {
    const I18n = useI18n();

    const selectedResume: WritableComputedRef<Partial<Resume>> = computed({
      get: () => props.response.resume || { name: '', email: '', phone: '' },
      set: (resume) => {
        emit('update:updateSelectedResume', resume);
      },
    });
    const registrationGroupId = computed({
      get: () => props.response.registrationGroupId,
      set: (id) => {
        emit('update:updateRegistrationGroup', id);
      },
    });
    const requiredRegistrationGroup = computed(() => props.registrationGroups.length > 1 && !props.hasJobPosition);

    const email = ref(selectedResume.value.email);
    const name = ref(selectedResume.value.name);
    const phone = ref(selectedResume.value.phone);

    const setRegistrationGroupId = (id: number) => {
      registrationGroupId.value = id;
      emit('update:updateRegistrationGroup', id);
    };

    const errors = (field: Validation) => field.$errors.length ? field.$errors[0].$message : '';

    const isAllowedDomain = (email: string) => {
      const emailDomain = email.split('@')[1];
      if (props.allowedDomains.length === 0 || emailDomain === undefined) return true;

      return props.allowedDomains.some((allowedDomain) => {
        const allowedDomainRegex = new RegExp(allowedDomain);
        if (allowedDomainRegex.test(emailDomain)) return true;
      });
    };

    const isRestrictedDomain = (email: string) => {
      const emailDomain = email.split('@')[1];
      if (props.restrictedDomains.length === 0 || emailDomain === undefined) return true;
      return !props.restrictedDomains.some((restrictedDomain) => {
        const restrictedDomainRegex = new RegExp(restrictedDomain);
        if (restrictedDomainRegex.test(emailDomain)) return true;
      });
    };

    const rules = {
      name: {
        requiredName: helpers.withMessage(I18n.t('job_application.contact_step.no_name'), required),
      },
      email: {
        requiredEmail: helpers.withMessage(I18n.t('job_application.contact_step.no_email'), required),
        patternEmail: helpers.withMessage(I18n.t('job_application.contact_step.pattern_email'), emailValidator),
        allowedEmail: helpers.withMessage(I18n.t('job_application.contact_step.allow_email'), isAllowedDomain),
        restrictedEmail: helpers.withMessage(I18n.t('job_application.contact_step.restrict_email', {
          domains: props.restrictedDomains.map((d) => d).join(', '),
        }), isRestrictedDomain),
      },
      registrationGroupId: {
        requiredRegistrationGroupId: helpers.withMessage(I18n.t('job_application.contact_step.no_registration_group'), requiredIf(() => requiredRegistrationGroup.value)),
      },
      phone: {
        requiredPhone: helpers.withMessage(I18n.t('job_application.contact_step.no_phone'), required),
        patternPhone: helpers.withMessage(I18n.t('job_application.contact_step.pattern_phone'), (phone: string) => {
          return /^\d+$/.test(phone);
        }),
      },
    };

    const $v = useVuelidate(rules, { name, email, registrationGroupId, phone });

    const isValidated = () => {
      $v.value.$touch();
      return !$v.value.$invalid;
    };

    watch([name, email, phone], ([newName, newEmail, newPhone]) => {
      selectedResume.value = { name: newName, email: newEmail, phone: newPhone };
    });

    return {
      $v,
      I18n,
      email,
      errors,
      isAllowedDomain,
      isRestrictedDomain,
      isValidated,
      name,
      registrationGroupId,
      requiredRegistrationGroup,
      selectedResume,
      setRegistrationGroupId,
      phone,
    };
  },
});
</script>
