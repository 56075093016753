<template>
  <div class="test-item">
    <div class="test-item-header">
      <div class="thumb-icon">
        <SvgIcon :name="icon" html-class="ic-24" />
      </div>

      <div class="test-item-info">
        <h4 class="resume-card-item-label">
          {{ token.title }}
          <span v-if="badgeStatus" class="badge">{{ I18n.t(`resume.test_results.status.${badgeStatus}`) }}</span>
        </h4>

        <h5 class="resume-card-item-text">
          <template v-if="token.finishedAt">
            {{ I18n.l('time.formats.period', token.startedAt) }} ~ {{ I18n.l('time.formats.period', token.finishedAt) }} • {{ token.testDuration }}
          </template>
          <template v-else>
            {{ status }}
          </template>
        </h5>
      </div>
    </div>

    <template v-if="token.finishedAt && token.shareResultPolicy">
      <table class="test-item-table">
        <tr>
          <td class="tcol-1">
            {{ I18n.t('terms.framework') }}
          </td>
          <td class="tcol-2">
            {{ I18n.t('terms.score') }}
          </td>
          <td class="tcol-3">
            {{ I18n.t('terms.grade') }}
          </td>
        </tr>

        <tr>
          <td class="tcol-1">
            {{ frameworks }}
          </td>
          <td class="tcol-2">
            {{ I18n.t('terms.score_with', { score: token.score.toFixed(1) }) }}
          </td>
          <td class="tcol-3">
            {{ I18n.t('terms.rank_with', { rank: token.rank || '-' }) }}
            <span>({{ I18n.t('terms.people_count', { count: token.testeesCount }) }})</span>
          </td>
        </tr>
      </table>

      <a
        v-if="!isSharable && token.resultUrl"
        :href="token.resultUrl"
        class="btn btn-code-preview"
        target="_blank"
        rel="noopener"
      >
        <span>{{ I18n.t('resume.test_results.view_result_detail') }}</span>
        <SvgIcon name="ic-launch-14" html-class="ic-14" />
      </a>
    </template>
  </div>
</template>

<script lang="ts">
import {
  PropType,
  computed,
  defineComponent,
} from '@vue/composition-api';
import { testInfo } from '../../resume.util';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { Assignment } from '@/exportables/models/career/tokens.model';
import { useI18n } from '@/helpers/i18n';
import { useStore } from '@/store/career';

export default defineComponent({
  name: 'ResumeAssignmentResult',
  components: {
    SvgIcon,
  },
  props: {
    token: {
      type: Object as PropType<Assignment>,
      required: true,
    },
  },
  setup(props) {
    const I18n = useI18n();
    const store = useStore();
    const isSharable = computed(() => store.state.resume.isSharable);

    return {
      /**
       * @namespace testInfo
       * @property {string} testInfo.badgeStatus
       * @property {string} testInfo.status
       * @property {string} testInfo.icon
       * @property {string} [testInfo.frameworks]
       */
      ...testInfo(props.token, 'assignment'),
      I18n,
      isSharable,
    };
  },
});
</script>
