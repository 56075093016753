<script lang="tsx">
import { computed, ComputedRef, defineComponent, onMounted, onUnmounted, ref, useCssModule } from '@vue/composition-api';
import { useState } from '../../hooks/show/state';
import CancelRegistrationModal from './modal/CancelRegistration.vue';
import EditCompetition from './modal/EditCompetition.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { skeleton, tooltip } from '@/directives';
import { useI18n } from '@/helpers/i18n';
import { useModal } from '@/helpers/modal';
import { useRouter } from '@/router/career';
import ShareDropdown from '@/components/shared/ShareDropdown.vue';
import { distanceOfTimeInWords } from '@/helpers/datetime';

type CompetitionControl = {
  isVisible: boolean,
  name: string,
  icon: string,
  handleClick: () => void,
};

export default defineComponent({
  name: 'CompetitionShowOverview',
  directives: {
    tooltip,
    skeleton,
  },
  setup(_, { refs }) {
    const observer = ref<IntersectionObserver>(null!);
    const style = useCssModule();
    const { isLoading, state } = useState();
    const router = useRouter();
    const modal = useModal();
    const I18n = useI18n();

    const isRegistrationActive = computed(() => ['test_start', 'registrable'].includes(state.registrationButton?.status || ''));

    const registrationText = computed(() => {
      if (!state.registrationButton) return '';
      if (state.registrationButton.status === 'before_receipt_start') {
        const receiptStartAt = distanceOfTimeInWords(state.receiptStartAt, new Date());
        return I18n.t('competition.show.registration.status.before_receipt_start', { time: receiptStartAt });
      } else {
        return I18n.t(`competition.show.registration.status.${state.registrationButton.status}`);
      }
    });

    const registrationLink = computed(() => state.registrationButton?.link || 'javascript:void(0)');

    const competitionControls: ComputedRef<CompetitionControl[]> = computed(() => [
      {
        isVisible: state.isRegistrationDestroyable,
        handleClick: () => modal.show(CancelRegistrationModal, {}),
        name: I18n.t('competition.show.overview.cancel_registration'),
        icon: 'ic-block',
      },
      {
        isVisible: state.isCompetitionEditable,
        handleClick: () => modal.show(EditCompetition, {}),
        name: I18n.t('competition.show.overview.edit_competition'),
        icon: 'ic-setting-input',
      },
      {
        isVisible: state.isRegistrationEditable && state.isJobFair,
        handleClick: () => {
          const route = router.resolve(`/competitions/${state.id}/registrations/${state.registration!.id}/edit`);
          window.open(route.href, '_blank');
        },
        name: I18n.t('competition.show.overview.edit_registration'),
        icon: 'ic-switch',
      },
      {
        isVisible: Boolean(state.resume),
        handleClick: () => {
          const route = router.resolve(`/registrations/${state.registration!.id}`);
          window.open(route.href, '_blank');
        },
        name: I18n.t('competition.show.overview.view_resume'),
        icon: 'ic-resume',
      },
    ]);

    onMounted(() => {
      const { wrapper, threshold } = refs as { [el in string]: HTMLElement };
      observer.value = new IntersectionObserver((entries) => {
        const [{
          boundingClientRect,
          isIntersecting,
        }] = entries;
        if (!isIntersecting && boundingClientRect.top <= 0) {
          Object.assign(threshold.style, { height: `${wrapper.offsetHeight}px` });
          wrapper.classList.add(style.fixed);
        } else {
          Object.assign(threshold.style, { height: 0 });
          wrapper.classList.remove(style.fixed);
        }
      }, {
        threshold: 0,
      });
      observer.value.observe(threshold);
    });

    onUnmounted(() => {
      observer.value.disconnect();
    })

    return () =>
      <div>
        <div ref="wrapper">
          <div class={style.container}>
            <div class={style.inner}>
              <h2>
                {state.title}
              </h2>
              <div v-skeleton={isLoading.value} class={style.buttonGroup}>
                <a
                  vTooltip:top={{
                    isMountBody: true,
                    width: '280px',
                    message: state.registrationButton?.message || null,
                  }}
                  href={registrationLink.value}
                  class={[
                    'btn btn-primary btn-action-primary',
                    !isRegistrationActive.value && 'disabled',
                  ]}
                >
                  {registrationText.value}
                </a>
                <div>
                  <ShareDropdown
                    text={state.title}
                    imageUrl={state.smallImageUrl}
                  />
                  {
                    (state.isRegistrationDestroyable ||
                      state.resume ||
                      state.isCompetitionEditable) &&
                    <div>
                      <button class="btn btn-light btn-action-secondary" data-toggle="dropdown">
                        <SvgIcon name="ic-more-vert" htmlClass="ic-24" />
                      </button>
                      <div class="dropdown-menu dropdown-menu-right">
                        {
                          competitionControls.value.map(({
                            name,
                            icon,
                            isVisible,
                            handleClick,
                          }) => (
                            isVisible &&
                            <button class="dropdown-item" onClick={handleClick}>
                              <SvgIcon name={icon} htmlClass="ic-24" />
                              {name}
                            </button>
                          ))
                        }
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref="threshold" />
      </div>
  },
});
</script>

<style lang="scss" module>
@use "~/stylesheets/variables";
@use "~/stylesheets/functions";

.container {
  width: 100%;
  margin: 0 auto;
  padding: functions.rem-calc(0 16);
  max-width: functions.rem-calc(992);
}

.inner {
  display: flex;
  gap: functions.rem-calc(16);
  align-items: flex-end;
  justify-content: space-between;
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  margin-top: functions.rem-calc(8);
  height: functions.rem-calc(40);
  gap: functions.rem-calc(8);

  > a {
    width: functions.rem-calc(200);
  }

  > div {
    display: flex;
    align-items: center;
    gap: functions.rem-calc(8);

    > div {
      height: 100%;

      svg {
        display: inline;
      }

      > button {
        width: functions.rem-calc(40);
        padding: 0;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
      };
    }
  }
}

.fixed {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  padding: functions.rem-calc(8 0) !important;
  gap: functions.rem-calc(64);
  background: variables.$white-color;
  box-shadow: functions.rem-calc(0 1 3) rgba(20, 20, 94, .12);
  z-index: 101;

  .inner {
    align-items: center;
  }

  h2 {
    font-size: functions.rem-calc(20);
  }

  div {
    margin: 0;
  }
}

@media (variables.$lg-down) {
  .inner {
    flex-direction: column;
    align-items: flex-start;
  }

  .fixed {
    .inner {
      flex-direction: row;
      align-items: center;
    }
  }
}

@media (variables.$md-down) {
  .buttonGroup {
    width: 100%;
    height: functions.rem-calc(32);
    justify-content: flex-start;

    > a {
      width: 100%;
      max-width: functions.rem-calc(237);
    }
  }

  .fixed {
    .inner {
      flex-direction: column;
      align-items: flex-start;
    }

    h2 {
      font-size: functions.rem-calc(18);
    }
  }
}
</style>
