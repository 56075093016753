<template>
  <section class="container container-positions">
    <div id="list-positions-wrapper" class="list-positions-wrapper">
      <div class="list-positions-header">
        <div class="header-left">
          <h6 class="label-list-positions">
            {{ I18n.t('job_position.list.position_count', { count: totalEntries }) }}
          </h6>

          <FilterNotice />
        </div>

        <div class="header-right">
          <button
            type="button"
            class="btn btn-primary btn-open-filter"
            @click="openMobileFilter"
          >
            <span>
              {{ I18n.t('job_position.list.search_filter.title') }}
            </span>
          </button>

          <OrderFilter />
        </div>
      </div>

      <ul class="list-positions">
        <template v-if="currentUser && !hasFilters">
          <template v-if="currentUser.hasJobProfile">
            <ListItem
              v-for="jobPosition in recommendations"
              :key="`recommeded-job-position-${jobPosition.id}`"
              :company="jobPosition.company"
              :is-recommended="true"
              :job-position="jobPosition"
              :personalized="jobPosition.personalized"
              :use-filters="true"
            />
          </template>

          <template v-else-if="!isLoading">
            <JobProfileGuideItem :current-user="currentUser" />
          </template>
        </template>

        <ListItem
          v-for="jobPosition in jobPositions"
          :key="`job-position-${jobPosition.id}`"
          :company="jobPosition.company"
          :job-position="jobPosition"
          :use-filters="true"
        />

        <ListItem
          v-for="jobPosition in wantedJobPositions"
          :key="`wanted-job-position-${jobPosition.id}`"
          :company="jobPosition.company"
          :job-position="jobPosition"
          :is-wanted="true"
        />
      </ul>

      <div v-if="!isLoading && (!jobPositions || (jobPositions.length === 0 && wantedJobPositions.length === 0))" class="empty">
        <h2>(。_＋)＼</h2>
        <h6>
          <template v-if="isLoading">
            {{ I18n.t('job_position.list.on_loading') }}
          </template>
          <template v-else>
            {{ I18n.t('job_position.list.empty_position') }}
          </template>
        </h6>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import {
  Ref,
  defineComponent,
  inject,
} from '@vue/composition-api';
import { FilterState } from '../../hooks/filter';
import FilterNotice from './FilterNotice.vue';
import JobProfileGuideItem from './JobProfileGuideItem.vue';
import ListItem from './ListItem.vue';
import OrderFilter from './OrderFilter.vue';
import { JobPosition } from '@/exportables/models/career/job-position.model';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'JobPositionList',
  components: {
    ListItem,
    OrderFilter,
    FilterNotice,
    JobProfileGuideItem,
  },
  setup() {
    const jobPositions = inject<Ref<JobPosition[]>>('jobPositions');
    const recommendations = inject<Ref<JobPosition[]>>('jobPositionRecommendations');
    const wantedJobPositions = inject<Ref<JobPosition[]>>('wantedJobPositions');
    const isLoading = inject('isLoading');
    const totalEntries = inject('totalEntries');
    const isMobileFilterOpened = inject<Ref>('isMobileFilterOpened')!;
    const currentUser = inject('currentUser');
    const { hasFilters } = inject<FilterState>('filter')!;
    const I18n = useI18n();

    const openMobileFilter = () => isMobileFilterOpened.value = true;

    return {
      I18n,
      currentUser,
      openMobileFilter,
      hasFilters,
      isLoading,
      isMobileFilterOpened,
      jobPositions,
      recommendations,
      totalEntries,
      wantedJobPositions,
    };
  },
});
</script>
