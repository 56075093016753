<template>
  <div
    v-if="isEditable"
    :class="style.container"
    @click="openModal(type)"
  >
    <span v-tooltip:top="tooltip">
      <SvgIcon name="ic-edit" html-class="ic-24 ic-blue-grey-900" />
    </span>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  inject,
  useCssModule,
} from '@vue/composition-api';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { tooltip } from '@/directives';

export default defineComponent({
  name: 'ResumeSectionEditButton',
  components: {
    SvgIcon,
  },
  directives: {
    tooltip,
  },
  props: {
    isEditable: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      required: true,
    },
  },
  setup() {
    const openModal = inject('openModal');
    const style = useCssModule();

    return {
      openModal,
      style,
    };
  },
});
</script>

<style lang="scss" module>
  @use "~/stylesheets/variables";
  @use '~/stylesheets/functions';

.container {
  height: 16px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  z-index: 9;

  & > span {
    height: 100%;
    line-height: 21px;

    svg {
      padding: functions.rem-calc(4);
      width: functions.rem-calc(32);
      height: functions.rem-calc(32);
      border-radius: functions.rem-calc(4);

      &:hover {
        background-color: variables.$blue-grey-50;
      }
    }
  }
}
</style>
