import { BiteCompany, Company } from './company.model';
import { Competition } from './competition.model';
import { JobApplication } from './job-application.model';
import { Tag } from './tag.model';

export class JobPosition {
  id: number = -1;
  additionalInformation: string = '';
  address: string = '';
  career: string = ''; // '1 ~ 3년'
  careerRange: string = ''; // '1...3'
  careerOption: boolean = false;
  company: JobPositionCompany = JobPositionCompany.new();
  competition?: Partial<Competition>;
  competitiveRate: 'high' | 'middle' | 'low';
  countryCode: string = 'KR';
  createdAt: string = '';
  description: string = '';
  isAppliable: boolean = false;
  jobApplication?: Pick<JobApplication, 'id' | 'status'>;
  jobCategoryIds: number[] = [];
  jobCategories?: Tag[];
  jobType: string = '';
  latitude: number = 0;
  longitude: number = 0;
  maxSalary: number = 0;
  minCareerRequired: boolean;
  minSalary: number = 0;
  period: string = '';
  personalized: boolean = false;
  preferredExperience: string = '';
  recommendations?: JobPositionRecommendation[] = [];
  registrationGroupId?: number;
  requirement: string = '';
  resumeRequired: boolean;
  signingBonus: number = 0;
  startAt: string = '';
  status: string = '';
  teamEnvironment: JobPositionTeamEnvironment = JobPositionTeamEnvironment.new();
  teamTechnicalTags: Tag[] = [];
  technicalTags: Tag[] = [];
  title: string = '';
  updatedAt: string = '';
  url: string = '';
  videoUrl: string = '';
  jobPositionCertificate: JobPositionCertificate;
  isBookmarked?: boolean;
  externalCategoryNames: String[] = [];

  constructor(jobPosition?: Partial<JobPosition>) {
    Object.assign(this, jobPosition);
  }

  static new(jobPosition?: Partial<JobPosition>) {
    return new JobPosition(jobPosition);
  }
}

type JobPositinCompanyInfo = Pick<Company, 'id' | 'logoUrl' | 'name'>;

export class JobPositionCompany implements JobPositinCompanyInfo {
  id: number = -1;
  logoUrl: string = '';
  name: string = '';

  constructor(company?: Partial<Company>) {
    Object.assign(this, company);
  }

  static new(company?: Partial<Company>) {
    return new JobPositionCompany(company);
  }
}

export class JobPositionFilterQuery {
  companies: BiteCompany[] = [];
  companyIds: number[] = [];
  jobCategoryIds: number[] = [];
  jobThemeIds: number[] = [];
  locations: string[] = [];
  minCareer?: string = '';
  minEmployees?: string = '';
  minSalary?: string = '';
  order: string = 'recent';
  tags: Tag[] = [];

  constructor(
    {
      jobCategoryIds,
      jobThemeIds,
      ...rest
    }: Partial<JobPositionFilterQuery> = {},
  ) {
    Object.assign(
      this,
      rest,
      jobCategoryIds?.length && { jobCategoryIds: jobCategoryIds.map(Number) },
      jobThemeIds?.length && { jobThemeIds: jobThemeIds.map(Number) },
    );
  }

  static new(query?: Partial<JobPositionFilterQuery>) {
    return new JobPositionFilterQuery(query);
  }
}

export class JobPositionTeamEnvironment {
  os: string = '';
  reviewTool: string = '';
  reviewMethod: string = '';
  developerCount: string = '';
  versionControl: string = '';
  collaborationTool: string = '';

  constructor(teamEnvironment?: JobPositionTeamEnvironment) {
    Object.assign(this, teamEnvironment);
  }

  static new(teamEnvironment?: JobPositionTeamEnvironment) {
    return new JobPositionTeamEnvironment(teamEnvironment);
  }
}

export type JobPositionRecommendation = Pick<JobPosition, (
  'id' |
  'career' |
  'careerOption' |
  'signingBonus' |
  'technicalTags' |
  'title' |
  'url'
)> &
{
  categoryNames: string[],
  company: Partial<Company>,
};

export type WantedJobPosition = Pick<JobPosition, (
  'id' |
  'address' |
  'url' |
  'externalCategoryNames'
)> &
{
  company: Partial<Company>,
};

export type Query = {
  personalized?: boolean,
};

export type RegistrationJobPosition = Pick<JobPosition, (
  'id' |
  'career' |
  'careerRange' |
  'company' |
  'minCareerRequired' |
  'title'
)>;

export type JobPositionCertificate = {
  status: string,
  examType: string,
  languages: string[],
  level: number,
};
