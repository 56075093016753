import { useHttpClient } from '../httpClient';
import { JobTheme } from '@/exportables/models/career/job-theme.model';
import { useI18n } from '@/helpers/i18n';

const JOB_THEME_URL = '/v1/career/job_themes';
const httpClient = useHttpClient();
const I18n = useI18n();

export const fetchJobThemes = async () => {
  const { data, status } = await httpClient.get<JobTheme[]>(JOB_THEME_URL);
  if (status !== 200) throw new Error(data?.message || I18n.t('error.internal'));
  return data;
};
