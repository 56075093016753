import { User } from '../models/user.model';
import { useHttpClient } from './httpClient';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';

export const USER_URL = '/users';
export const CURRENT_USER_URL = '/users/current';

const I18n = useI18n();
const httpClient = useHttpClient();

export async function fetchCurrentUser() {
  try {
    const { data, status } = await httpClient.get(CURRENT_USER_URL);
    if (status !== 200) throw new Error(data.message || I18n.t('error.internal'));
    return data as User;
  } catch (error) {
    error instanceof Error && appendToast(error.message);
  }
}

export async function updateCoverName(coverName: string) {
  try {
    const { data, status } = await httpClient.patch(`${USER_URL}/cover_name`, { coverName });
    if (status !== 200) throw new Error(data.message || I18n.t('error.internal'));
    return data;
  } catch (error) {
    return error;
  }
}

export async function fetchTestTokens() {
  try {
    const { data, status } = await httpClient.get(`${USER_URL}/attachable_test_tokens`);
    if (status !== 200) throw new Error(data.message || I18n.t('error.internal'));
    return data;
  } catch (error) {
    error instanceof Error && appendToast(error.message);
  }
}
