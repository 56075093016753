<template>
  <Modal
    :title="I18n.t('business.recruit.job_profile.modal.history.title')"
    :button-name="I18n.t('actions.close')"
    @hide="closeModal"
  >
    <template #body>
      <ul :class="style.list">
        <template v-for="({ key, value }) in items">
          <li v-if="value.length" :key="key" :class="key">
            <h5>
              <strong>
                {{ I18n.t(`business.recruit.job_profile.modal.history.${key}.title`) }}
              </strong>
            </h5>

            <p>
              {{
                I18n.t(
                  `business.recruit.job_profile.modal.history.${key}.subtitle`,
                  { name, company: history.company.name, count: value.length },
                )
              }}
            </p>

            <table :class="style.table">
              <thead>
                <tr>
                  <th>
                    {{ I18n.t('business.recruit.job_profile.modal.history.table.job_position') }}
                  </th>
                  <th>
                    {{ I18n.t(`business.recruit.job_profile.modal.history.table.${key}_at`) }}
                  </th>
                  <th>
                    {{ I18n.t('business.recruit.job_profile.modal.history.table.status') }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <template v-for="contact in value">
                  <tr v-if="contact.status" :key="`${contact.id}`">
                    <td>
                      <a
                        :href="jobPositionUrl(contact.jobPosition)"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {{ contact.jobPosition.title }}
                      </a>
                    </td>
                    <td>
                      {{ datetime(contact[`${key}At`], { withTime: false }) }}
                    </td>
                    <td>
                      <StatusBadge
                        v-bind="badgeOptions(key, contact)"
                        @click="() => handleClickBadge(contact)"
                      />
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </li>
        </template>
      </ul>
    </template>
  </Modal>
</template>

<script lang="ts">
import {
  computed,
  defineComponent, PropType, useCssModule,
} from '@vue/composition-api';
import {
  badgeOptions,
  handleClickBadge,
  isInProgress,
  jobApplicationUrl,
  jobPositionUrl,
} from '../../../job-profiles/job-profile.util';
import Modal from '@/components/shared/Modal2.vue';
import { tooltip } from '@/directives';
import { History } from '@/exportables/models/career/business/history.model';
import { useI18n } from '@/helpers/i18n';
import { useModal } from '@/helpers/modal';
import { snakeToCamel } from '@/helpers/string';
import { datetime, statusColor } from '@/pages/career/job-offers/job-offer.util';
import StatusBadge from '@/shared/career/components/StatusBadge.vue';

export default defineComponent({
  name: 'BusinessHistoryModal',
  components: {
    StatusBadge,
    Modal,
  },
  directives: {
    tooltip,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    history: {
      type: Object as PropType<History>,
      required: true,
    },
  },
  setup(props) {
    const { l, t } = useI18n();
    const modal = useModal();
    const style = useCssModule();

    const items = computed(() => [
      {
        key: 'offered',
        value: props.history.jobProfileOffers,
      },
      {
        key: 'applied',
        value: props.history.jobApplications,
      },
    ]);

    const closeModal = () => modal.hide();

    return {
      I18n: { l, t },
      badgeOptions,
      closeModal,
      datetime,
      handleClickBadge,
      items,
      isInProgress,
      jobApplicationUrl,
      jobPositionUrl,
      snakeToCamel,
      statusColor,
      style,
    };
  },
});
</script>

<style lang="scss" module>
@use "~/stylesheets/display";
@use "~/stylesheets/functions";
@use "~/stylesheets/variables";

.list {
  @extend .flex-column;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: functions.rem-calc(40);

  p {
    font-size: functions.rem-calc(14);
    margin: functions.rem-calc(4 0 8);
  }
}

.table {
  margin-top: functions.rem-calc(12);
  width: 100%;
  table-layout: auto;

  tr {
    height: functions.rem-calc(30);
    border-bottom: 1px solid variables.$color-border;

    td,
    th {
      &:first-child {
        width: 45%;
      }

      &:nth-child(2) {
        width: 25%;
        text-align: center;
      }

      &:last-child {
        width: 30%;
        text-align: center;
      }
    }
  }

  th,
  td {
    width: 100%;
    font-size: functions.rem-calc(14);
    padding: functions.rem-calc(0 8);
    vertical-align: middle;
  }

  th {
    font-weight: 500;
  }

  thead {
    tr {
      height: functions.rem-calc(28);
      color: variables.$color-text--secondary;
      font-size: functions.rem-calc(12);
      font-weight: 700;
      border-top: 1px solid variables.$color-border;
    }
  }
}

@media (variables.$sm-down) {
  .table {
    tr {
      td,
      th {
        &:first-child {
          width: 70%;
        }

        &:nth-child(2) {
          display: none;
        }

        &:last-child {
          width: 30%;
        }
      }
    }
  }
}
</style>
