import * as types from './mutation-types';
import { JobPositionState } from './state';
import { JobTheme } from '@/exportables/models/career/job-theme.model';
import { Tag } from '@/exportables/models/career/tag.model';

export default {
  [types.SET_JOB_CATEGORIES](state: JobPositionState, payload: Tag[]) {
    state.jobCategories = payload;
  },
  [types.SET_JOB_THEMES](state: JobPositionState, payload: JobTheme[]) {
    state.jobThemes = payload;
  },
};
