import {
  Ref,
  ref,
  watch,
} from '@vue/composition-api';

function setDisplay(
  rootElement: HTMLElement | null,
  isVisible: boolean = true,
  display: string = 'block',
  isChild: boolean = false,
) {
  if (!rootElement) return;

  const { parentNode } = isChild ? rootElement.parentElement! : rootElement!;

  if (!rootElement || document.body !== parentNode) return;
  rootElement.style.display = isVisible ? display : 'none';
}

export function useLayout(
  {
    breadcrumb = ref(true),
    footer = ref(true),
    jobbar = ref(true),
    topbar = ref(true),
  }: Partial<{
    breadcrumb: Ref<boolean>,
    footer: Ref<boolean>,
    jobbar: Ref<boolean>,
    topbar: Ref<boolean>,
  }>,
) {
  const unwatch = watch(
    [breadcrumb, footer, jobbar, topbar],
    ([breadcrumb, footer, jobbar, topbar]) => {
      const breadCrumbEl = document.querySelector<HTMLElement>('.breadcrumb');
      const footerEl = document.querySelector<HTMLElement>('.footer-wrap');
      const jobbarEl = document.querySelector<HTMLElement>('.nav-tabs');
      const navbarEl = document.querySelector<HTMLElement>('.navbar');

      setDisplay(breadCrumbEl, breadcrumb, 'flex', true);
      setDisplay(footerEl, footer);
      setDisplay(jobbarEl, jobbar, 'flex', true);
      setDisplay(navbarEl, topbar, 'flex');
    },
    { immediate: true },
  );

  return unwatch;
}

export const scrollToTop = (options: { behavior?: 'auto' | 'smooth' } = { behavior: 'smooth' }) => window.scrollTo({ top: 0, left: 0, ...options });
