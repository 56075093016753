<template>
  <Modal
    :title="I18n.t('resume.modal.certificates.title')"
    :button-name="I18n.t('actions.close')"
    @hide="closeModal"
  >
    <template #body>
      <ul :class="style.list">
        <li :class="style.headItem">
          <h4>
            PCCP
            <span
              v-tooltip:bottom="{
                message: I18n.t(
                  'resume.modal.certificates.pccp.tooltip_message',
                ),
                width: '210px',
                style: { whiteSpace: 'break-spaces' },
              }"
              class="btn-help dark"
            >
              <SvgIcon name="ic-help-full" html-class="ic-20" />
            </span>
          </h4>

          <DropdownMenu
            :items="dropdownItems"
            style="background-color: white"
            @onSelect="handleSelect"
          />
        </li>

        <li v-if="!pccp.length" :class="style.emptyItem">
          <p>{{ I18n.t('resume.modal.certificates.pccp.empty_1') }}</p>
          <p>{{ I18n.t('resume.modal.certificates.pccp.empty_2') }}</p>

          <a
            href="https://certi.programmers.co.kr/about/pccp"
            class="btn btn-md btn-secondary btn-dark"
            :class="style.btn"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ I18n.t('resume.modal.certificates.pccp.move_to') }}
          </a>
        </li>

        <li v-for="info in pccp" :key="info.id" :class="style.item">
          <CertificateInfo :info="info" :class="style.info" />
          <Toggle
            :is-active="Boolean(getCertificate(info.code))"
            @toggle="() => handleToggle(info)"
          />
        </li>
      </ul>

      <ul :class="style.list">
        <li :class="style.headItem">
          <h4>
            PCCE
            <span
              v-tooltip:top="{
                message: I18n.t(
                  'resume.modal.certificates.pcce.tooltip_message',
                ),
                width: '210px',
                style: { whiteSpace: 'break-spaces' },
              }"
              class="btn-help dark"
            >
              <SvgIcon name="ic-help-full" html-class="ic-20" />
            </span>
          </h4>
        </li>

        <li v-if="!pcce.length" :class="style.emptyItem">
          <p>{{ I18n.t('resume.modal.certificates.pcce.empty_1') }}</p>
          <p>{{ I18n.t('resume.modal.certificates.pcce.empty_2') }}</p>

          <a
            href="https://certi.programmers.co.kr/about/pcce"
            class="btn btn-md btn-secondary btn-dark"
            :class="style.btn"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ I18n.t('resume.modal.certificates.pcce.move_to') }}
          </a>
        </li>

        <li v-for="info in pcce" :key="info.id" :class="style.item">
          <CertificateInfo :info="info" :class="style.info" />
          <Toggle
            :is-active="Boolean(getCertificate(info.code))"
            @toggle="() => handleToggle(info)"
          />
        </li>
      </ul>
    </template>
  </Modal>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  ref,
  useCssModule,
  watch,
} from '@vue/composition-api';
import CertificateInfo from '../CertificateInfo.vue';
import Toggle from '../Toggle.vue';
import DropdownMenu from '@/components/shared/DropdownMenu.vue';
import Modal from '@/components/shared/Modal2.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { tooltip } from '@/directives';
import {
  createCertificate,
  deleteCertificate,
  fetchRegistrableCertificates,
} from '@/exportables/apis/career/resume.api';
import { ResumeCertificate } from '@/exportables/models/career/resume.model';
import { useI18n } from '@/helpers/i18n';
import { useStore } from '@/store/career';
import {
  SET_RESUME,
} from '@/store/career/resume/mutation-types';

const languages = ['C++', 'Java', 'JavaScript', 'Python'] as const;
type Language = typeof languages[number] | '';

export default defineComponent({
  name: 'ResumeCertificatesModal',
  components: {
    CertificateInfo,
    DropdownMenu,
    Modal,
    SvgIcon,
    Toggle,
  },
  directives: {
    tooltip,
  },
  emits: ['hide'],
  setup(_, { emit }) {
    const I18n = useI18n();
    const store = useStore();
    const style = useCssModule();
    const registrableCertificates = ref<ResumeCertificate[]>([]);
    const selectedLanguage = ref<Language>('');
    const selectedCertificates = computed(() =>
      selectedLanguage.value
        ? registrableCertificates.value.filter(
          ({ language }) => language === selectedLanguage.value,
        )
        : registrableCertificates.value);
    const pcce = computed(() =>
      registrableCertificates.value.filter(
        ({ examType }) => examType === 'PCCE',
      ));
    const pccp = computed(() =>
      selectedCertificates.value.filter(({ examType }) => examType === 'PCCP'));
    const resumeId = computed(() => store.state.resume.resume.id);

    const dropdownItems = [{ label: I18n.t('resume.modal.certificates.all_language'), value: '' }].concat(
      languages.map((language) => ({ label: language, value: language })),
    );

    const getCertificate = (certCode: string) =>
      store.state.resume.resumeCertificates.find(
        ({ code }) => code === certCode,
      );

    const closeModal = () => emit('hide');

    const handleSelect = ({ value }: { value: Language }) => {
      Object.assign(selectedLanguage, { value });
    };

    const handleToggle = async (info: ResumeCertificate) => {
      const certificate = getCertificate(info.code);

      if (certificate) {
        const { score } = await deleteCertificate(resumeId.value, certificate.id);
        store.commit(`resume/${SET_RESUME}`, {
          score,
        });
      } else {
        const { score } = await createCertificate(resumeId.value, info);
        store.commit(`resume/${SET_RESUME}`, {
          score,
        });
      }

      store.dispatch('resume/fetchResumeCertificates', { resumeId: resumeId.value });
    };

    watch(
      resumeId,
      async (resumeId) => {
        if (resumeId > 0) {
          registrableCertificates.value = await fetchRegistrableCertificates(resumeId);
        }
      },
      { immediate: true },
    );

    return {
      I18n,
      closeModal,
      dropdownItems,
      handleSelect,
      handleToggle,
      getCertificate,
      pcce,
      pccp,
      selectedCertificates,
      style,
    };
  },
});
</script>

<style lang="scss" module>
@use '~/stylesheets/display';
@use '~/stylesheets/functions';
@use '~/stylesheets/variables';

.list {
  width: 100%;
  border: 1px solid variables.$color-border;
  border-radius: functions.rem-calc(8);
  margin-bottom: functions.rem-calc(24);
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: functions.rem-calc(16);
  border-top: 1px solid variables.$color-border;
  padding: functions.rem-calc(16 12);

  > * {
    &:first-child {
      width: calc(100% - 48px);
    }
  }
}

.info {
  h4 {
    font-weight: 500;
  }

  span {
    color: variables.$color-text--secondary;
    font-size: functions.rem-calc(12);
  }
}

.headItem {
  background-color: variables.$blue-grey-20;
  padding: functions.rem-calc(8 16);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.emptyItem {
  @extend .flex-center;
  color: variables.$color-text--secondary;
  font-size: functions.rem-calc(14);
  line-height: functions.rem-calc(21);
  border-top: 1px solid variables.$color-border;
  flex-direction: column;
  padding: functions.rem-calc(24 16);

  p {
    margin: 0;
    text-align: center;
  }
}

.btn {
  margin-top: functions.rem-calc(8);
}

@media (variables.$sm-down) {
  .info {
    img {
      display: none;
    }

    h6 {
      font-size: functions.rem-calc(15);
    }
  }

  .emptyItem {
    p {
      font-size: functions.rem-calc(13);
    }
  }
}
</style>

<style lang="scss" scoped>
.btn-help {
  svg {
    display: inline-block;
  }
}
</style>
