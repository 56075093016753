import { FeedCategory } from '../constants';
import { FeaturedPost } from '@/exportables/models/career/featured-post.model';
import {
  UserFeedDefaultTags,
  UserFeedTabExceptUserFeed,
  UserFeedTags,
} from '@/exportables/models/career/user-feed.model';
import camelCase from '@/plugins/camelCase';

export const getAllTagsFromFeaturedPost = (featuredPost: FeaturedPost) => {
  const { companyTagList, jobCategoryTagList, postTagList, technicalTagList } =
    featuredPost;

  return [
    ...companyTagList,
    ...jobCategoryTagList,
    ...postTagList,
    ...technicalTagList,
  ];
};

export const getAllTagsFromUserFeedTags = (userFeedTags: UserFeedTags) => {
  const { post, technical, jobCategory, company } = userFeedTags;

  return [...post, ...technical, ...jobCategory, ...company];
};

/**
 * userFeedTags와 topTaggingCountTags에 있는 모든 태그를 합쳐서 고유한 태그 목록을 반환합니다.
 */
export const getAllTagsByUserFeedTab = ({
  tab,
  userFeedTags,
  topTaggingCountTags,
}: {
  tab: FeedCategory,
  userFeedTags: UserFeedTags,
  topTaggingCountTags: UserFeedDefaultTags,
}) => {
  if (tab === 'user_feed') {
    return [];
  }

  const camelCaseTab = camelCase(tab) as UserFeedTabExceptUserFeed;

  const topTaggingCountTagsOfCurrentTab = topTaggingCountTags[camelCaseTab] || [];
  const userFeedTagsOfCurrentTab = userFeedTags[camelCaseTab] || [];

  return userFeedTagsOfCurrentTab.reduce((acc, userFeedTag) => {
    if (topTaggingCountTagsOfCurrentTab.some(({ id }) => id === userFeedTag.id)) {
      return acc;
    }

    return [...acc, userFeedTag];
  }, topTaggingCountTagsOfCurrentTab);
};
