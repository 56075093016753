<template>
  <header class="container-md container-completed">
    <img
      src="~/images/img-completed.png"
      class="img-completed"
      :alt="I18n.t('job_application.completed.competition.image')"
    >
    <h3 class="title">
      {{ I18n.t('job_application.completed.competition.title') }}
    </h3>
    <p class="body">
      {{ I18n.t('job_application.completed.competition.body.title', { title: competition.title }) }}
      <template v-if="registration.hasStartableTests">
        {{ I18n.t('job_application.completed.competition.body.present_test') }}
      </template>
      <template v-else>
        {{
          I18n.t('job_application.completed.competition.body.not_present_test')
        }}<br>
        <span v-if="competition.hasJobPosition" v-html="deadlineMessage"></span>
        <span v-html="editApplicationMessage"></span>
      </template>
    </p>
    <template v-if="isPromotingJobProfile">
      <div class="alert alert-dismissible alert-info noti-banner">
        <h4 class="title">
          {{ I18n.t('job_application.completed.no_job_profile.title') }}
        </h4>
        <div class="contents">
          <template v-if="competition.resumeRequired">
            <div class="noti-body">
              <p>
                {{
                  `${I18n.t('job_application.completed.no_job_profile.suggest_profile',
                  )} ${I18n.t('job_application.completed.no_job_profile.resume_required')}`
                }}
              </p>
            </div>
            <div class="footer">
              <a
                class="btn btn-primary btn-block"
                :href="`/job_profiles/edit?resume_id=${selectedResume.id}`"
              >
                {{ I18n.t('job_application.completed.no_job_profile.finish_profile') }}
              </a>
            </div>
          </template>
          <template v-else>
            <div class="noti-body">
              <p>
                {{ I18n.t('job_application.completed.no_job_profile.suggest_profile') }}
              </p>
            </div>
            <div class="footer">
              <a class="btn btn-primary btn-block" href="/job_profiles/edit">
                {{ I18n.t('job_application.completed.no_job_profile.edit_profile') }}
              </a>
            </div>
          </template>
        </div>
      </div>
    </template>
    <a class="btn btn-primary btn-back" :href="`/competitions/${competition.id}`">
      {{ I18n.t('job_application.completed.competition.to_challenge') }}
    </a>
  </header>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  toRefs,
} from '@vue/composition-api';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'JobApplicationRegistrationCompleted',
  props: {
    competition: {
      type: Object,
      default: null,
    },
    registration: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    location: {
      type: String,
      default: '',
    },
    resume: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const { t, l } = useI18n();

    const { competition, user, location } = toRefs(props);
    const selectedResume = computed(() => props.resume);

    const isPromotingJobProfile = computed(
      () => competition.value.promoteJobProfile && !user.value.hasJobProfile,
    );
    const deadLineDatetime = computed(() => {
      const receiptEndDate = new Date(competition.value.receiptEndAt);
      return l('time.formats.period', receiptEndDate);
    });
    const deadlineMessage = computed(() =>
      t('job_application.completed.competition.body.edit_job_position', {
        datetime: deadLineDatetime.value,
      }));
    const editApplicationMessage = computed(() =>
      t('job_application.completed.competition.body.edit_application'));
    history.replaceState(null, '', location.value);
    return {
      I18n: {
        t,
      },
      deadLineDatetime,
      deadlineMessage,
      editApplicationMessage,
      isPromotingJobProfile,
      selectedResume,
    };
  },
});
</script>
