import { Location } from 'vue-router';
import { useRouter } from '@/router/career';

type JobPositionUrlParams = {
  id: number,
  isRecommended: boolean,
  personalized: boolean,
  themeId?: number,
};

export function jobPositionUrl({
  id,
  isRecommended = false,
  personalized = false,
  themeId,
}: JobPositionUrlParams): string {
  const router = useRouter();

  const route = Object.assign(
    { path: `/job_positions/${id}` },
    {
      query: (
        isRecommended && personalized && { personalized: true } ||
        themeId && { theme_id: themeId } ||
        {}
      ),
    },
  ) as Location;

  return router.resolve(route).href;
}
