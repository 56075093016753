<template>
  <li :class="{ ended: competition.statusLabel === 'ended' }" class="list-competition-item">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-thumb">
        <span :class="`left-badge-${competition.statusLabel === 'ended' ? 'ended' : 'ongoing'}`">
          {{ I18n.t(`competition.label.${competition.statusLabel}`) }}
        </span>
        <a :href="`/competitions/${competition.id}`" class="img-competition-wrap">
          <img
            class="img-competition"
            :src="competition.imageUrl"
            :alt="I18n.t('competition.description.image', { title: competition.title })"
          >
        </a>
      </div>

      <div class="col-sm-12 col-md-6 col-info">
        <h4 class="title">
          <a :href="`/competitions/${competition.id}`">
            {{ competition.title }}
          </a>
        </h4>
        <ul class="period">
          <li>
            {{ I18n.t('terms.receipt') }}:
            {{ I18n.l('time.formats.competition_start', competition.receiptStartAt) }}
            ― {{ I18n.l('time.formats.competition_end', competition.receiptEndAt) }}
          </li>
          <li v-if="competition.testStartAt && competition.testEndAt">
            {{ I18n.t('terms.test') }}:
            {{ I18n.l('time.formats.competition_start', competition.testStartAt) }}
            ― {{ I18n.l('time.formats.competition_end', competition.testEndAt) }}
          </li>
        </ul>

        <h6 v-if="competition.shortDescription" class="description-short">
          {{ competition.shortDescription }}
        </h6>

        <div class="languages">
          <span
            v-for="language in competition.languages"
            :key="language"
            :class="language"
          >
            <SvgIconWithTooltip
              :key="language"
              :name="`ic-${language}-30`"
              :tooltip="competition.languageNames[language]"
              class="language-item"
              html-class="ic-30"
            />
          </span>
        </div>

        <a :href="`/competitions/${competition.id}`" class="btn btn-md btn-light btn-more">
          {{ I18n.t('actions.details') }}
        </a>
      </div>
    </div>
  </li>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
} from '@vue/composition-api';
import SvgIconWithTooltip from '@/components/shared/SvgIconWithTooltip.vue';
import { CompetitionItem } from '@/exportables/models/career/competition.model';

export default defineComponent({
  name: 'CompetitionItem',
  components: {
    SvgIconWithTooltip,
  },
  props: {
    competition: {
      type: Object as PropType<CompetitionItem>,
      default: null,
    },
  },
});
</script>
