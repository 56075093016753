<template>
  <button
    v-if="isRectangle"
    v-tooltip:top="{
      className: 'bookmark-tooltip',
      message: tooltipMessage,
    }"
    aria-label="북마크"
    class="btn btn-light"
    :class="isActive && 'active'"
    :data-testid="isActive ? 'bookmark-active' : 'bookmark-inactive'"
    @click="handleClick"
  >
    <SvgIcon name="ic-bookmark-add" html-class="ic-24" />
  </button>

  <button
    v-else
    v-tooltip:top="{
      className: 'bookmark-tooltip',
      message: tooltipMessage,
    }"
    class="circle"
    aria-label="북마크"
    :data-testid="isActive ? 'bookmark-active' : 'bookmark-inactive'"
    @click="handleClick"
  >
    <SvgIcon name="ic-bookmark-add" :class="isActive && 'active'" />
  </button>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { tooltip } from '@/directives';

export default defineComponent({
  name: 'BookmarkButton',
  components: {
    SvgIcon,
  },
  directives: {
    tooltip,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    tooltipMessage: {
      type: String,
      default: '',
    },
    isRectangle: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['bookmark'],
  setup(_, { emit }) {
    const handleClick = () => emit('bookmark');

    return {
      handleClick,
    };
  },
});
</script>

<style lang="scss" scoped>
@use "~/stylesheets/variables";
@use "~/stylesheets/functions";

button[aria-label="북마크"].circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin: 0;
  background-color: transparent;
  width: functions.rem-calc(36);
  height: functions.rem-calc(36);
  cursor: pointer;

  &:hover {
    background-color: #f7f7fb;
    border-radius: 50%;
    transition-delay: initial;
    transition-duration: 0.08s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  > svg {
    width: functions.rem-calc(24);
    height: functions.rem-calc(24);

    fill: variables.$blue-grey-200;

    &.active {
      fill: variables.$color-primary;
    }
  }
}

button[aria-label="북마크"].btn-light {
  padding: functions.rem-calc(8);

  &:hover {
    border-color: variables.$blue-grey-75;
    background-color: variables.$blue-grey-75;
  }

  &:focus {
    box-shadow: unset;
  }

  &.active {
    border-color: variables.$color-primary;
    background-color: variables.$color-primary;

    &:hover {
      border-color: variables.$blue-700;
      background-color: variables.$blue-700;
    }

    &:focus {
      box-shadow: unset;
    }

    > svg {
      fill: variables.$white-color;
    }
  }
}

</style>
