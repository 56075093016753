<template>
  <div v-if="state.freePassedUserName" :class="style.notiWrapper">
    <AlertBox icon-name="ic-megaphone" type="primary" :class-name="style.alert">
      {{ state.freePassedUserName }}님은 {{ state.title }}에서 프리패스 대상으로
      선정되어 <span :class="style.highlighted">코딩 테스트 면제 혜택</span>이
      적용됩니다.
    </AlertBox>
  </div>
</template>

<script lang="ts">
import { defineComponent, useCssModule } from '@vue/composition-api';
import { useState } from '../../hooks/show/state';
import AlertBox from '@/components/shared/ui/AlertBox.vue';

export default defineComponent({
  name: 'CompetitionShowNotification',
  components: {
    AlertBox,
  },
  setup() {
    const { state } = useState();
    const style = useCssModule();

    return {
      state,
      style,
    };
  },
});
</script>

<style lang="scss" module>
@use '~/stylesheets/variables';

.notiWrapper {
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  max-width: 992px;
  margin-bottom: 32px;
}

.alert {
  background: rgba(0, 120, 255, 0.06);
  border: 1px solid rgba(0, 120, 255, 0.06);
  border-radius: 4px;
  padding: 16px 12px;
  align-items: center;

  * {
    color: variables.$color-text--primary;
  }

  > div:first-of-type {
    width: 34px;
    height: 34px;
    background-color: white;
    border-radius: 50%;
  }

  > div:last-of-type {
    display: block;
  }
}

.highlighted {
  color: variables.$color-primary;
}

@media (variables.$sm-down) {
  .alert {
    * {
      font-size: 15px;
    }
  }
}
</style>
