import { Ref } from '@vue/composition-api';
import _ from 'lodash';
import { isEmptyObject } from './utils';
import { throttle } from '@/helpers/lazy';
import variables from '~/stylesheets/_variables.scss';

export { variables };

type Styles = Record<string, string>;

const PERCENT = '%';
const PX = 'px';
const REM = 'rem';

export const getCamelCaseStyles = (styles: Styles) => _.mapKeys(styles, (v, k) => _.camelCase(k));

export const getPosition = (directions: string, margin: number) => {
  const obj = {};

  return Object.assign(
    obj,
    directions.split(' ').reduce(
      (acc, cur) => Object.assign(acc, { [cur]: `${margin}px` }),
      {},
    ),
  );
};

export const getWindowWidth = () => {
  if (isEmptyObject(variables) || variables?.default) return '';
  return Object
    .entries(variables)
    .filter(
      ([key]) => key.includes('-down'),
    )
    .find(
      ([, size]) => window.matchMedia(
        size
          .toString()
          .replace('"', '(')
          .replace('"', ')'),
      ).matches,
    )?.[0] || '';
};

type UnitTuple = readonly [number, string?];
export const stripUnit = (value: number | string): UnitTuple => {
  if (value === 0 || value === '0' || value === '0px' || value === '0rem') return [0];
  if (typeof value === 'number') return [value, PX];
  if (value.includes(PERCENT)) return [parseFloat(value.split(PERCENT, 1)[0]), PX];
  if (value.includes(PX)) return [parseFloat(value.split(PX, 1)[0]), PX];
  if (value.includes(REM)) return [parseFloat(value.split(REM, 1)[0]), REM];
  return [parseFloat(value), PX];
};

export const convertToRem = (value: number | string = 0, baseValue: number = 16) => {
  const [val, unit] = stripUnit(value);
  if (unit === PERCENT || unit === REM) return value;
  const result = parseFloat((val / baseValue).toFixed(2));
  return result === 0 ? 0 : [result, REM].join('');
};

export const convertToRems = (value: number | string | (number | string)[], isFlatten = false, baseValue: number = 16) => {
  const result = Array.isArray(value) ? value.map((val) => convertToRem(val, baseValue)) : convertToRem(value, baseValue);
  return Array.isArray(result) && isFlatten ? result.join(' ') : result;
};

export const setBodyOverflow = (hiddenY: boolean, hiddenX?: boolean) => {
  document.body.style.overflowY = hiddenY ? 'hidden' : '';
  document.body.style.overflowX = hiddenX ? 'hidden' : '';
};

export const useHorizontalResizer = (ref: Ref) => {
  const resizeHandler = throttle(
    () => {
      const size = getWindowWidth();
      ref.value = size;
    },
    400,
  );

  window.addEventListener('resize', resizeHandler);

  return () => window.removeEventListener('resize', resizeHandler);
};

type ColorStatus = 'danger' | 'normal' | 'success' | 'warning';
export const getColor = (status: ColorStatus) => variables?.[status] || '#ffffff';

export const getStyle = (text: string | TemplateStringsArray) => text.toString().match(/<style>([\s\S]*?)<\/style>/)?.join('') || '';

export class StyledElement extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });

    const observer = new MutationObserver(
      (mutations) => {
        mutations.forEach((mutation) => {
          const { addedNodes, target } = mutation;
          addedNodes.length && (target as HTMLElement).shadowRoot?.append(...addedNodes);
        });
      },
    );
    observer.observe(this, { childList: true, subtree: true });
  }
}

customElements.define('styled-element', StyledElement);
