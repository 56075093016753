<template>
  <Stack
    :as="as"
    v-bind="$attrs"
    :class="[
      style.hStack,
      {
        [style.full]: full,
        [style.wrap]: wrap,
      },
    ]"
    :gap="gap"
    :spacing="spacing"
    :align="align"
    v-on="$listeners"
  >
    <slot></slot>
  </Stack>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  useCssModule,
} from '@vue/composition-api';
import Stack, { AlignType, SpacingType } from './Stack.vue';

export default defineComponent({
  components: {
    Stack,
  },
  props: {
    as: {
      type: String,
      default: 'div',
    },
    gap: {
      type: Number,
      default: 2,
    },
    spacing: {
      type: String as PropType<SpacingType>,
      default: null,
    },
    align: {
      type: String as PropType<AlignType>,
      default: 'center',
    },
    full: {
      type: Boolean,
      default: false,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      style: useCssModule(),
    };
  },
});
</script>

<style lang="scss" module>
.hStack {
  flex-direction: row;
}

.full {
  width: 100%;
}

.wrap {
  flex-wrap: wrap;
}
</style>
