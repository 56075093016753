<template>
  <Modal
    :title="I18n.t('job_profile.job_categories.title')"
    @hide="closeModal"
  >
    <template #body>
      <div class="form-group">
        <h5 class="control-label">
          {{ I18n.t('job_profile.job_categories.label') }}
        </h5>

        <div :class="style.wrapper">
          <Checkbox
            v-for="category in jobCategories"
            :key="category.id"
            :is-checked="isChecked(category.id)"
            :is-disabled="selectedJobCategories.length === 3"
            :label="category.name"
            @check="handleCheck(category)"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex-end">
        <button
          class="btn btn-md btn-light"
          data-dismiss="modal"
          aria-label="Close"
          @click="closeModal"
        >
          {{ I18n.t('actions.cancel') }}
        </button>
        <button
          class="btn btn-md btn-primary"
          @click="handleSubmit"
        >
          {{ I18n.t('actions.save') }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  ref,
  unref,
  useCssModule,
  watch,
} from '@vue/composition-api';
import Modal from '@/components/shared/Modal2.vue';
import { fetchJobCategories } from '@/exportables/apis/career/job-position.api';
import { updateResume } from '@/exportables/apis/career/resume.api';
import { Tag } from '@/exportables/models/career/tag.model';
import { useI18n } from '@/helpers/i18n';
import { useState } from '@/pages/career/job-profiles/hooks/show/state';
import Checkbox from '@/pages/career/resumes/components/Checkbox.vue';

export default defineComponent({
  name: 'JobProfileJobCategoriesModal',
  components: {
    Checkbox,
    Modal,
  },
  emits: ['hide'],
  setup(_, { emit }) {
    const { fetcher, state } = useState();
    const I18n = useI18n();
    const style = useCssModule();
    const jobCategories = ref<Tag[]>([]);
    const selectedJobCategories = ref<Tag[]>([]);

    const closeModal = () => emit('hide');

    const isChecked = (id: number) => unref(selectedJobCategories)?.some((category) => category.id === id);

    const handleCheck = ({ id, name }: Tag) => {
      const unrefedSelectedJobCategories = unref(selectedJobCategories);
      const index = unrefedSelectedJobCategories.findIndex((category) => category.id === id);

      if (index >= 0) {
        unrefedSelectedJobCategories.splice(index, 1);
      } else {
        if (unrefedSelectedJobCategories.length >= 3) return false;
        unrefedSelectedJobCategories.push({ id, name });
      }
    };

    const handleSubmit = async () => {
      const jobPositionCategoryIds = unref(selectedJobCategories).map((category) => category.id!);

      await updateResume(state.jobProfile.resume.id, { resume: { jobPositionCategoryIds } });
      fetcher.fetchJobProfile();
      closeModal();
    };

    onMounted(async () => {
      const categories = await fetchJobCategories();
      jobCategories.value = categories;
    });

    watch(
      () => state.jobProfile?.jobCategories,
      (jc) => Object.assign(selectedJobCategories.value, jc),
      { immediate: true },
    );

    return {
      I18n: { t: I18n.t },
      closeModal,
      handleCheck,
      handleSubmit,
      isChecked,
      jobCategories,
      selectedJobCategories,
      style,
    };
  },
});
</script>

<style lang="scss" module>
.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2px;
}
</style>
