<script lang="tsx">
import { computed, defineComponent } from '@vue/composition-api';
import { marked } from 'marked';
import extendMarkdownTable from '@/exportables/utils/marked-extended-tables.util';

marked.use(extendMarkdownTable());
marked.use({
  gfm: true,
  pedantic: true,
  smartLists: true,
  smartypants: true,
});

export default defineComponent({
  name: 'CompetitionShowTabContent',
  props: {
    markdown: {
      type: String,
      default: '',
    },
  },
  setup(props, { slots }) {
    const parsedMarkdown = computed(() => marked.parse(props.markdown));

    return () => (
      <div>
        {
          slots?.default?.() ??
          <div
            class="markdown github"
            domPropsInnerHTML={parsedMarkdown.value}
          />
        }
      </div>
    );
  },
});
</script>
