<template>
  <div class="form-row part">
    <div class="col-11">
      <div class="form-group">
        <input
          v-model="info.title"
          type="text"
          class="form-control"
          autocomplete="off"
          :placeholder="I18n.t('section.part.title')"
        >
      </div>
    </div>

    <div class="col-1 form-btn-wrap-sm">
      <button class="btn btn-xs" @click="handleDeleteItem(index)">
        <SvgIcon name="ic-cancel" html-class="ic-24" />
      </button>
    </div>

    <div class="col-date col-6">
      <Datepicker
        :value="info.startAt"
        :to="info.endAt || maxDate"
        :placeholder="I18n.t('section.item.start_at')"
        @initialize="handleInitialize"
        @select="setStartAt"
      />
    </div>

    <div class="col-date col-6">
      <Datepicker
        :value="info.endAt"
        :from="info.startAt || minDate"
        class="part"
        :placeholder="I18n.t('section.item.end_at')"
        @initialize="() => setEndAt('')"
        @select="setEndAt"
      />
    </div>

    <div class="col-12">
      <div class="form-group">
        <textarea
          v-model="info.description"
          class="form-control"
          type="text"
          rows="3"
          autocomplete="off"
          :placeholder="I18n.t('terms.input')"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent, inject } from '@vue/composition-api';
import { usePart } from '../../hooks/form';
import constants from '../../resume.constant';
import Datepicker from '../Datepicker.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { ResumePart } from '@/exportables/models/career/resume.model';
import { useI18n } from '@/helpers/i18n';

type PartKey = keyof ResumePart;

const { minDate, maxDate } = constants.DATEPICKER;

export default defineComponent({
  name: 'ResumePartForm',
  components: {
    Datepicker,
    SvgIcon,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    info: {
      type: Object as PropType<ResumePart>,
      required: true,
    },
  },
  emits: ['update-value'],
  setup(props, { emit }) {
    const part = inject<ReturnType<typeof usePart>>('part')!;
    const { t } = useI18n();

    const setStartAt = (value: ResumePart[PartKey]) => {
      emit('update-value', { startAt: value }, props.index);
    };

    const setEndAt = (value: ResumePart[PartKey]) => {
      emit('update-value', { endAt: value }, props.index);
    };

    const handleInitialize = () => {
      setStartAt('');
      setEndAt('');
    };

    return {
      ...part,
      I18n: { t },
      handleInitialize,
      maxDate,
      minDate,
      setEndAt,
      setStartAt,
    };
  },
});
</script>
