<template>
  <div class="form-group form-theme">
    <button
      class="btn btn-block btn-outline-light btn-filter dropdown-toggle"
      type="button"
      data-toggle="dropdown"
      aria-expanded="false"
    >
      {{ I18n.t('job_position.list.search_filter.job_theme.title') }}
    </button>

    <div class="dropdown-menu dropdown-menu-right dropdown-filter" :class="style.wrapper">
      <div class="row justify-content-center">
        <ul :class="style.list">
          <li
            v-for="jobTheme in jobThemes"
            :key="jobTheme.id"
            class="dropdown-item"
          >
            <label @click="handleClickRadio(jobTheme.id)">
              <input
                type="radio"
                name="jobTheme"
                :value="jobTheme.id"
                :checked="jobThemeIds[0] === jobTheme.id"
              >
              {{ jobTheme.name }}
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  useCssModule,
} from '@vue/composition-api';
import { FilterState } from '../../hooks/filter';
import { useStore } from '../../hooks/store';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'JobPositionJobThemeFilter',
  setup() {
    const { jobThemeIds } = inject<FilterState>('filter')!;
    const style = useCssModule();
    const I18n = useI18n();
    const store = useStore();
    const jobThemes = computed(() => store.state.jobThemes);

    const handleClickRadio = (jobThemeId: number) => (jobThemeIds.value = [jobThemeId]);

    return {
      I18n,
      handleClickRadio,
      jobThemeIds,
      jobThemes,
      style,
    };
  },
});
</script>

<style lang="scss" module>
@use "~stylesheets/variables";

.wrapper {
  min-width: 100%;
  padding: 0.5rem;
  margin: 0 -0.2rem;
}

.list {
  padding: 0;
  margin: 0;
}
</style>
