<script lang="tsx">
import { computed, defineComponent, ref, useCssModule, watch } from '@vue/composition-api';
import useSWRV from 'swrv';
import { useState } from '../../hooks/show/state';
import { useTab } from '../../hooks/show/tab';
import TabContent from './TabContent.vue';
import JobPositionTab from './tabs/JobPosition.vue';
import Leaderboard from './tabs/Leaderboard.vue';
import ScheduleTab from './tabs/Schedule.vue';
import { TabControlItem } from '@/components/shared/TabControl.type';
import TabControl from '@/components/shared/TabControl.vue';
import { COMPETITION_URL } from '@/exportables/apis/career/competition.api';
import { useHttpClient } from '@/exportables/apis/httpClient';
import { CompetitionTabContents, Tab } from '@/exportables/models/career/competition.model';
import type { SnakeToCamel } from '@/exportables/types/string';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';
import { snakeToCamel } from '@/helpers/string';
import { useShare } from '@/hooks/useShare';

type CompetitionTabControlItem = TabControlItem<Tab>;

type TabContentsKey = keyof CompetitionTabContents;

type CompetitionDataPayload<Key extends TabContentsKey> =
  CompetitionTabContents[Key] extends { markdown: infer M } ?
    { [key in Key]: M } : CompetitionTabContents[Key];

type ResponseCompetitionTabData<Type extends TabContentsKey> =
  Type extends any ? {
    type: Type,
    payload: CompetitionDataPayload<Type>,
  } : never;

const getTabIconName = (tabType: Tab) => {
  switch (tabType) {
    case 'description': return 'ic-goal-18';
    case 'event': return 'ic-giftbox-line';
    case 'job_positions': return 'ic-position-line';
    case 'leaderboards': return 'ic-throphy-line';
    case 'commentary': return 'ic-test-22';
    case 'schedules': return 'ic-calendar-16';
    case 'faq': return 'ic-help-full';
  }
};

export default defineComponent({
  name: 'CompetitionShowTabControl',
  setup() {
    const style = useCssModule();
    const I18n = useI18n();
    const httpClient = useHttpClient();
    const { share, copyLink } = useShare();

    const { state, isLoading: isCompetitionLoading } = useState();
    const { isLoading: isTabLoading, tabContents, setState: setTabState } = useTab();

    const selectedTab = ref<CompetitionTabControlItem | { name: '', label: '' }>({ name: '', label: '' });

    const getTabItem = (tabName: Tab) => ({
      name: tabName,
      className: style[tabName],
      iconName: getTabIconName(tabName),
      label: I18n.t(`competition.show.tabs.${tabName}.title`),
    });

    const tabs = computed(() => state.tabs.map((tab) => getTabItem(tab)));

    const selectedIndex = computed(() => tabs.value.findIndex(({ name }) => selectedTab.value.name === name));

    const requestURL = computed(() => {
      if (!selectedTab.value?.name) return '';
      if (state.id === -1) return '';
      if (selectedTab.value.name === 'leaderboards') return '';
      return `${COMPETITION_URL}/${state.id}/${selectedTab.value.name}`;
    });

    const tabSlots = computed(() => {
      const { description, event, faq, commentary } = tabContents;

      return {
        description: () => <TabContent markdown={description?.markdown} />,
        event: () => <TabContent markdown={event?.markdown} />,
        faq: () => <TabContent markdown={faq?.markdown} />,
        commentary: () => <TabContent markdown={commentary?.markdown} />,
        schedules: () =>
          <TabContent>
            <ScheduleTab />
          </TabContent>,
        job_positions: () =>
          <TabContent>
            <JobPositionTab />
          </TabContent>,
        leaderboards: () => <Leaderboard />,
      };
    });

    const { data, isValidating, error } = useSWRV(
      () => requestURL.value,
      async (url: string) => {
        try {
          const tab = snakeToCamel(selectedTab.value.name) as SnakeToCamel<Tab> | '';
          if (!tab) return;
          if (tabContents[tab]) return;
          const { data } = await httpClient.get<CompetitionDataPayload<typeof tab>>(url);
          return {
            type: tab,
            payload: data,
          } as ResponseCompetitionTabData<TabContentsKey>;
        } catch {
          throw new Error(I18n.t('competition.show.tabs.message.fetch_error', { tab: selectedTab.value.label }));
        }
      },
    );

    const handleChange = (tab: CompetitionTabControlItem) => {
      selectedTab.value = tab;
    };

    const handleClickLink = (event: PointerEvent) => {
      const target = event.target as HTMLElement;
      const tabLinkElement = target.closest('[class*="js-"]') as HTMLElement | undefined;
      if (tabLinkElement) {
        const tab = Array.from(tabLinkElement.classList)
          .find((className: string) => /^js-/.test(className))!
          .replace('js-', '') as Tab | undefined;
        if (!tab || !state.tabs.includes(tab)) return;
        event.preventDefault();
        handleChange(getTabItem(tab));
        window.scrollTo(0, 0);
        return;
      }
      const copyLinkElement = target.closest('.copy-link') as HTMLElement | undefined;
      if (copyLinkElement) {
        event.preventDefault();
        copyLink();
        return;
      }
      const snsShareElement = target.closest('[class*="-share"]') as HTMLElement | undefined;
      if (snsShareElement) {
        const snsType = Array.from(snsShareElement.classList)
          .find((className: string) => /-share$/.test(className))?.
          replace('-share', '') as 'twitter' | 'kakao' | 'fb' | 'naver' | undefined;
        if (!snsType) return;
        event.preventDefault();
        switch (snsType) {
          case 'twitter': return share('twitter');
          case 'kakao': return share('kakao');
          case 'fb': return share('facebook');
          case 'naver': return share('naver');
        }
      }
    };

    watch(error, (error) => {
      if (error) {
        appendToast(error.message);
      }
    });

    watch(tabs, (tabs) => {
      const firstTab = tabs[0];
      handleChange(firstTab);
    }, { immediate: true });

    watch(isValidating, (isValidating) => {
      isTabLoading.value = isValidating;
    });

    watch(data, (data) => {
      if (data) {
        switch (data.type) {
          case 'description': {
            setTabState({ description: { markdown: data.payload.description } });
            break;
          }
          case 'jobPositions': {
            setTabState({ jobPositions: data.payload });
            break;
          }
          case 'event': {
            setTabState({ event: { markdown: data.payload.event } });
            break;
          }
          case 'schedules': {
            setTabState({ schedules: data.payload });
            break;
          }
          case 'faq': {
            setTabState({ faq: { markdown: data.payload.faq } });
            break;
          }
          case 'commentary': {
            setTabState({ commentary: { markdown: data.payload.commentary } });
          }
        }
      }
    });

    return () => (
      <div onClick={handleClickLink} class={style.container}>
        <TabControl
          on-change-tab={handleChange}
          items={tabs.value}
          isNavLoading={isCompetitionLoading.value}
          isContentLoading={isCompetitionLoading.value || isTabLoading.value}
          defaultIndex={selectedIndex.value}
          scopedSlots={tabSlots.value}
        />
      </div>
    );
  },
});
</script>

<style lang="scss" module>
@use '~/stylesheets/variables';
@use '~/stylesheets/functions';

.container {
  padding-top: functions.rem-calc(16);
}

.commentary {
  svg {
    width: functions.rem-calc(16);
    height: functions.rem-calc(22);
  }
}
</style>
