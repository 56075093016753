<template>
  <Card
    v-if="customs.length"
    :is-shrinked="true"
    :title="I18n.t('section.custom.name')"
  >
    <template #body>
      <div
        v-for="custom in customs"
        :key="custom.index"
        class="resume-card-item"
      >
        <div>
          <h4 class="resume-card-item-label">
            <span
              v-if="custom.form.adminOnly"
              :class="$style.icon"
              data-toggle="popover"
              data-placement="top"
              :data-content="I18n.t('help.custom_form.admin_only')"
            >
              <SvgIcon name="ic-security-info" html-class="ic-24" />
            </span>
            {{ custom.form.label }}
          </h4>

          <div class="resume-card-item-text" v-html="custom.answerText"></div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
} from '@vue/composition-api';
import { ResumeShowState, getResume } from '../hooks/state';
import Card from './Card.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'ResumeCustoms',
  components: {
    Card,
    SvgIcon,
  },
  setup() {
    const state = inject<ResumeShowState>('state')!;
    const I18n = useI18n();
    const customs = computed(() => getResume(state).customForms || []);

    return {
      I18n: { t: I18n.t },
      customs,
    };
  },
});
</script>

<style lang="scss" module>
@import "~/stylesheets/functions";
@import "~/stylesheets/variables";

.icon {
  height: rem-calc(24);
  margin: rem-calc(4 4 0 0);
  align-self: flex-start;

  svg {
    fill: $deep-purple-500;
  }
}
</style>
