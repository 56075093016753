<template>
  <Card
    v-if="isEditable || attachment.fileUrl"
    class="attachment"
    :is-editable="isEditable"
    :is-shrinked="true"
  >
    <template #title>
      <h4 class="resume-card-header-title">
        {{ I18n.t('resume.attachment.title') }}
        <span
          v-if="isEditable"
          v-tooltip:top="{
            message: I18n.t('resume.attachment.help', { extensions: 'pdf, zip, pptx, docx, hwp, jpg, png, jpeg' }),
            width: '280px',
            isMountBody: true,
            style: { fontWeight: 400, wordBreak: 'keep-all' },
          }"
          class="btn-help dark"
        >
          <SvgIcon name="ic-help-full" html-class="ic-20" />
        </span>
      </h4>
    </template>

    <template #control>
      <button class="btn-light resume-card-btn-add btn-file">
        <span>{{ I18n.t('buttons.file_upload_with_size_bound', { size: 30 }) }}</span>
        <input
          class="input-file"
          type="file"
          accept=".pdf,.zip,.pptx,.docx,.hwp,.jpg,.png,.jpeg"
          @change="handleChangeFile"
        >
      </button>
    </template>

    <template #body>
      <div v-if="attachment.fileUrl" class="resume-card-item">
        <div v-if="isEditable" class="resume-card-left"></div>

        <div
          class="resume-card-right"
          :class="{ hovered: isHovered }"
          :style="{ width: '100%' }"
          @mouseenter="isHovered = true"
          @mouseleave="isHovered = false"
        >
          <a
            :href="attachment.fileUrl"
            class="btn btn-outline-light btn-svg"
            :class="style.btn"
            target="_blank"
            rel="noopener"
          >
            <SvgIcon name="ic-file" html-class="ic-24" />
            <span :class="style.break">
              {{ attachment.fileName }}
            </span>
          </a>

          <HoverButtonWrapper
            v-if="isEditable && isHovered"
            :is-editable="false"
            type="attachment"
            :delete-item="handleDelete"
          />
        </div>
      </div>

      <Modal
        v-if="isOpened"
        :title="I18n.t('section.attachment.modal.title')"
        @hide="handleCancel"
      >
        <template #body>
          <span>
            {{ I18n.t('file.name') }} : {{ file.name }}
          </span>
          <span>
            ({{ fileSize(file.size) }})
          </span>
        </template>

        <template #footer>
          <div class="flex-end">
            <button class="btn btn-md btn-light" @click="handleCancel">
              {{ I18n.t('actions.cancel') }}
            </button>
            <button class="btn btn-md btn-primary" @click="handleUpload">
              {{ I18n.t('actions.upload') }}
            </button>
          </div>
        </template>
      </Modal>
    </template>
  </Card>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  provide,
  ref,
  unref,
  useCssModule,
} from '@vue/composition-api';
import {
  ResumeShowStates,
  getResume,
  initialize,
} from '../hooks/state';
import { fileSize } from '../resume.util';
import Card from './Card.vue';
import HoverButtonWrapper from './HoverButtonWrapper.vue';
import Modal from '@/components/shared/Modal2.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import constants from '@/constant/format';
import { tooltip } from '@/directives';
import {
  deleteResumeAttachment,
  updateResumeAttachment,
} from '@/exportables/apis/career/resume.api';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';
import { useStore } from '@/store/career';

const { max_size } = constants.FILE;

export default defineComponent({
  name: 'ResumeAttachment',
  components: {
    Card,
    HoverButtonWrapper,
    Modal,
    SvgIcon,
  },
  directives: {
    tooltip,
  },
  setup() {
    const state = inject<ResumeShowStates>('state')!;
    const I18n = useI18n();
    const store = useStore();
    const style = useCssModule();
    const isOpened = ref(false);
    const isHovered = ref(false);
    const file = ref<File | null>(null);
    const resume = computed(() => getResume(state));
    const attachment = computed(() => unref(resume)?.attachment || {});
    const isEditable = computed(() => store.state.resume.isEditable);
    const isPrintPage = computed(() => store.getters['resume/isPrintPage']);

    const handleCancel = () => (isOpened.value = false);

    const handleChangeFile = (event: Event) => {
      const { files } = event.target as HTMLInputElement;

      if (files?.length) {
        [file.value] = files;
        isOpened.value = true;
      }
    };

    const handleUpload = async () => {
      const unrefedFile = unref(file);
      const fileSize = unrefedFile?.size || 0;

      if (fileSize && fileSize < max_size) {
        const formData = new FormData();
        const { id } = unref(resume);

        formData.append('file', unrefedFile!);
        formData.append('filename', unrefedFile!.name);

        await updateResumeAttachment(id, formData);
        initialize(state);
      } else {
        const message = (
          !fileSize && I18n.t('exceptions.message.wrong_file') ||
          fileSize >= max_size && I18n.t('exceptions.message.file_size_limit_exceeded', { size: '30' }) ||
          I18n.t('errors.messages.upload')
        );
        message && appendToast(message);
      }

      handleCancel();
    };

    const handleDelete = async () => {
      await deleteResumeAttachment(unref(resume).id, unref(attachment).id);
      initialize(state);
      handleCancel();

      file.value = null;
    };

    provide('changeEditState', () => {});

    return {
      I18n: { t: I18n.t },
      isEditable,
      isOpened,
      isHovered,
      attachment,
      fileSize,
      file,
      handleCancel,
      handleChangeFile,
      handleUpload,
      handleDelete,
      isPrintPage,
      style,
    };
  },
});
</script>

<style lang="scss" module>
@use "~/stylesheets/functions";
@use "~/stylesheets/variables";

.break {
  word-break: break-all;
}

.btn {
  width: fit-content;
  align-items: flex-start;
  display: block !important;

  > svg {
    flex-shrink: 0;
  }

  > span {
    font-size: functions.rem-calc(16);
  }
}

@media (variables.$md-down) {
  .btn {
    > span {
      font-size: functions.rem-calc(14);
    }
  }
}
</style>
