import { FeedCategory } from '../constants';
import {
  UserFeedTag,
  UserFeedTags,
} from '@/exportables/models/career/user-feed.model';
import { SnakeToCamel } from '@/exportables/types/string.d';
import camelCase from '@/plugins/camelCase';

export type UserFeedTabCountMap = {
  [key in SnakeToCamel<FeedCategory>]: number;
};

export const tabToTagCountMap = (tabs: readonly FeedCategory[], userFeed: UserFeedTags) => {
  const camelCaseTabs = tabs.map((tab) => camelCase(tab));

  const counts = {} as UserFeedTabCountMap;

  type CamelFeedCategory = SnakeToCamel<FeedCategory>;

  camelCaseTabs.forEach((tab: CamelFeedCategory) => {
    counts[tab] = 0;
  });

  (Object.entries(userFeed) as Array<[CamelFeedCategory, UserFeedTag[]]>)
    .forEach(
      ([userFeedKey, userFeedTags]) => {
        counts.userFeed += userFeedTags.length;
        counts[userFeedKey] = userFeedTags.length;
      },
    );

  return counts;
};
