import { render, staticRenderFns } from "./FilterChipList.vue?vue&type=template&id=19a85c30&scoped=true&"
import script from "./FilterChipList.vue?vue&type=script&lang=ts&"
export * from "./FilterChipList.vue?vue&type=script&lang=ts&"
import style0 from "./FilterChipList.vue?vue&type=style&index=0&id=19a85c30&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19a85c30",
  null
  
)

export default component.exports