export const DEFAULT_THEME_IMAGE_URL = 'https://grepp-cloudfront.s3.ap-northeast-2.amazonaws.com/programmers_imgs/design/ic-theme-company.png';

export class JobTheme {
  id: number = -1;
  description: string = '';
  displayOrder: number = 0;
  name: string = '';
  imageUrl: string = DEFAULT_THEME_IMAGE_URL;
  backgroundColor: string = '#ffffff';
  jobPositionsCount: number = 0;

  constructor(jobTheme: Partial<JobTheme> = {}) {
    const { imageUrl } = jobTheme;

    Object.assign(
      this,
      jobTheme,
      !imageUrl && { imageUrl: DEFAULT_THEME_IMAGE_URL },
    );
  }

  static new(jobTheme?: Partial<JobTheme>) {
    return new JobTheme(jobTheme);
  }
}
