<template>
  <li class="content-list__item">
    <div class="left">
      <a
        :href="post.url"
        :data-post-id="post.id"
        class="item-title gtm-post-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ post.title }}
      </a>
      <p
        v-if="post.summary"
        class="item-desc"
      >
        {{ post.summary }}
      </p>
      <div class="item-info">
        <img
          :src="postsAuthorImage(post)"
          class="item-info__img"
          alt="프로필 이미지"
        >
        <p class="item-info__post">
          {{ post.author }}
        </p>
        <p class="item-info__post">
          {{ hostname(post) }}
        </p>
      </div>
      <div class="item-tag-wrapper">
        <Tags :tags="tags(post)" />
      </div>
    </div>
    <div class="right">
      <div
        data-toggle="tooltip"
        data-placement="top"
        title="링크 복사"
      >
        <button
          class="btn btn-light btn-like js-btn-copy"
          @click="copy(post.url)"
        >
          <SvgIcon
            name="ic-link"
            html-class="ic-24"
          />
        </button>
      </div>
    </div>
  </li>
</template>

<script lang="ts">
import { defineComponent, PropType } from '@vue/composition-api';
import {
  postsAuthorImage,
  postedAt,
  hostname,
  tags,
} from '../utils/post';
import Tags from './Tags.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { Post } from '@/exportables/models/career/post.model';
import { copy } from '@/helpers/clipboard';

export default defineComponent({
  name: 'CustomPost',
  components: {
    Tags,
    SvgIcon,
  },
  props: {
    post: {
      type: Object as PropType<Post>,
      required: true,
    },
  },
  setup() {
    return {
      postsAuthorImage,
      tags,
      copy,
      postedAt,
      hostname,
    };
  },
});
</script>
