<script lang="tsx">
import {
  defineComponent,
  unref,
  useCssModule,
  watch,
} from '@vue/composition-api';
import MarkdownViewer from '@/components/shared/MarkdownViewer.vue';
import Modal from '@/components/shared/Modal2.vue';
import { BUSINESS_JOB_OFFER_URL } from '@/exportables/apis/career/business/job-offer.api';
import { BusinessJobOffer } from '@/exportables/models/career/business/job-offer.model';
import { rejectMessages } from '@/exportables/models/career/job-offer.model';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';
import { useRequest } from '@/hooks/useRequest';
import PositionBox from '@/shared/career/components/PositionBox.vue';

export default defineComponent({
  name: 'BusinessJobOfferRejectConfirmModal',
  props: {
    jobOfferId: {
      type: Number,
      required: true,
    },
  },
  emits: ['hide'],
  setup(props, { emit }) {
    const I18n = useI18n();
    const style = useCssModule();
    const { data: jobOffer, error } = useRequest<BusinessJobOffer>(`${BUSINESS_JOB_OFFER_URL}/${props.jobOfferId}`);
    const slots = {
      body: () => {
        const unrefedJobOffer = unref(jobOffer);
        const { etc, options } = unrefedJobOffer?.rejectMessages || {};

        return unrefedJobOffer ? (
          <div class={style.container}>
            <div class={style.wrapper} style="background-color: #fbfbfd;">
              <PositionBox
                status={unrefedJobOffer.status}
                positionTitle={unrefedJobOffer.jobPosition.title}
                useStatus={true}
              />
            </div>

            <div class={style.wrapper}>
              <h5>
                {I18n.t('business.recruit.job_offer.modal.reject_confirm.reason')}
              </h5>

              {options?.length ?
                <ul class={{ 'border-bottom': etc }}>
                  {options.map((option) => (
                    <li>{I18n.t(`business.recruit.job_offer.modal.reject_confirm.${rejectMessages[option]}`)}</li>
                  ))}
                </ul> :
                null
              }

              {etc ? <MarkdownViewer value={etc} /> : null}
            </div>
          </div>
        ) : null;
      },
    };

    const closeModal = () => emit('hide');

    watch(
      [jobOffer, error],
      ([, error]) => {
        error && appendToast(I18n.t('business.recruit.job_offer.message.fetch_error'));
      },
    );

    return () => (
      <Modal
        buttonName={I18n.t('actions.close')}
        title={I18n.t('business.recruit.job_offer.modal.reject_confirm.title')}
        on={{ hide: closeModal }}
        scopedSlots={slots}
      />
    );
  },
});
</script>

<style lang="scss" module>
@use "~/stylesheets/display";
@use "~/stylesheets/functions";
@use "~/stylesheets/variables";

.container {
  @extend .flex-column;
  gap: functions.rem-calc(16);
}

.wrapper {
  border: 1px solid variables.$color-border;
  border-radius: functions.rem-calc(12);
  padding: functions.rem-calc(24);

  &:last-child {
    h5 {
      font-weight: 700;
    }
  }

  ul {
    padding: functions.rem-calc(8 24 16);
    margin: functions.rem-calc(8 0 16);
  }
}
</style>
