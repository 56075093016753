<template>
  <BookmarkButton
    v-if="!isLoggedIn"
    :is-active="false"
    :tooltip-message="tooltipMessage"
    :is-rectangle="isRectangle"
    data-testid="guest-bookmark"
    @bookmark="handleClickByGuest"
  />
  <BookmarkButton
    v-else
    :is-active="isBookmarked"
    :tooltip-message="tooltipMessage"
    :is-rectangle="isRectangle"
    :data-testid="isBookmarked ? 'bookmark-active' : 'bookmark-inactive'"
    @bookmark="handleClickBookmark"
  />
</template>

<script lang="tsx">
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import axios from 'axios';
import { VNode } from 'vue';
import BookmarkButton from './BookmarkButton.vue';
import BookmarkToastContent from './BookmarkToastContent.vue';
import HTTP_STATUS from '@/constant/httpStatus';
import { addBookmark, removeBookmark } from '@/exportables/apis/career/job-position.api';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'PositionBookmarkButton',
  components: {
    BookmarkButton,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isLoggedIn: {
      type: Boolean,
      default: false,
    },
    isRectangle: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const I18n = useI18n();

    const isBookmarked = ref(props.isActive);
    const tooltipMessage = computed(() => isBookmarked.value ? '북마크 저장됨' : '북마크 저장');

    const handleClickByGuest = () => window.location.assign('/account/sign_in');

    const add = async () => {
      try {
        await addBookmark(props.id);
        appendToast((() =>
          <BookmarkToastContent>
            <span>{I18n.t('job_position.bookmark.success.add')}</span>
            <a href="/job_position_bookmarks">북마크로 이동</a>
          </BookmarkToastContent>) as unknown as VNode);
      } catch (e) {
        const status = axios.isAxiosError(e) ? e.response?.status : (e as any).status;

        if (status === HTTP_STATUS.UNPROCESSABLE_ENTITY) {
          appendToast(I18n.t('job_position.bookmark.fail.duplicated'));
          isBookmarked.value = true;
        } else {
          appendToast(I18n.t('job_position.bookmark.fail.error'));
          isBookmarked.value = false;
        }
      } finally {
        emit('change', isBookmarked.value);
      }
    };

    const remove = async () => {
      try {
        await removeBookmark(props.id);
      } catch (e) {
        const status = axios.isAxiosError(e) ? e.response?.status : (e as any).status;

        if (status === HTTP_STATUS.NOT_FOUND) {
          appendToast(I18n.t('job_position.bookmark.fail.not_found'));
          isBookmarked.value = false;
        } else {
          appendToast(I18n.t('job_position.bookmark.fail.error'));
          isBookmarked.value = true;
        }
      } finally {
        emit('change', isBookmarked.value);
      }
    };

    const handleClickBookmark = () => {
      const previousValue = isBookmarked.value;
      isBookmarked.value = !previousValue;

      previousValue ? remove() : add();
    };

    watch(() => props.isActive, (newValue) => {
      isBookmarked.value = newValue;
    });

    return {
      isBookmarked,
      tooltipMessage,
      handleClickByGuest,
      handleClickBookmark,
    };
  },
});
</script>
