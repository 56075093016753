<script lang="tsx">
import { computed, defineComponent, onMounted, Ref, ref, useCssModule } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import moment from 'moment';
import { useState } from '../../../hooks/show/state';
import { useTab } from '../../../hooks/show/tab';
import Modal from '@/components/shared/Modal2.vue';
import { fetchCompetitionDescription, fetchCompetitionFaq, fetchCompetitionSchedules, updateCompetition, UpdateCompetitionPayload } from '@/exportables/apis/career/competition.api';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';
import { useRouter } from '@/router/career';

type FormItem = {
  label: string,
  name: string,
  type: string,
  value: string,
  required: boolean,
};

export default defineComponent({
  name: 'EditCompetition',
  emits: ['hide'],
  setup(_, { emit }) {
    const I18n = useI18n();
    const style = useCssModule();
    const router = useRouter();
    const { state } = useState();
    const { tabContents, setState: setTabState } = useTab();

    const formItems: Ref<FormItem[]> = ref([
      {
        label: I18n.t('managers.table.name'),
        name: 'title',
        type: 'text',
        value: state.title,
        required: true,
      },
      {
        label: I18n.t('competition.show.modal.edit_competition.labels.registration_start_at'),
        name: 'receiptStartAt',
        type: 'datetime-local',
        value: moment(state.receiptStartAt).format('YYYY-MM-DD[T]HH:mm'),
        required: true,
      },
      {
        label: I18n.t('competition.show.modal.edit_competition.labels.registration_end_at'),
        name: 'receiptEndAt',
        type: 'datetime-local',
        value: moment(state.receiptEndAt).format('YYYY-MM-DD[T]HH:mm'),
        required: true,
      },
      {
        label: I18n.t('competition.show.modal.edit_competition.labels.description'),
        name: 'description',
        type: 'textarea',
        value: tabContents.description?.markdown || '',
        required: true,
      },
      {
        label: I18n.t('competition.show.modal.edit_competition.labels.faq'),
        name: 'faq',
        type: 'textarea',
        value: tabContents.faq?.markdown || '',
        required: false,
      },
      {
        label: I18n.t('competition.show.modal.edit_competition.labels.schedules'),
        name: 'schedules',
        type: 'textarea',
        value: tabContents.schedules?.scheduleDescription || '',
        required: false,
      },
    ]);

    const rules = {
      title: {
        required: helpers.withMessage(I18n.t('competition.show.modal.edit_competition.tooltip.required'), required),
      },
      receiptStartAt: {
        required: helpers.withMessage(I18n.t('competition.show.modal.edit_competition.tooltip.required'), required),
        datetime: helpers.withMessage(I18n.t('competition.show.modal.edit_competition.tooltip.datetime'),
          (receiptStartAt, vm) => {
            return !moment(receiptStartAt as string).isAfter(moment(vm.receiptEndAt as string));
          }),
      },
      receiptEndAt: {
        required: helpers.withMessage(I18n.t('competition.show.modal.edit_competition.tooltip.required'), required),
      },
      description: {
        required: helpers.withMessage(I18n.t('competition.show.modal.edit_competition.tooltip.required'), required),
      },
    };

    const updatePayload = computed(() => {
      const [title, receiptStartAt, receiptEndAt, description, faq, schedules] = formItems.value;
      const payload: UpdateCompetitionPayload = {
        title: title.value,
        description: description.value,
        faq: faq.value,
        scheduleDescription: schedules.value,
        receiptStartAt: receiptStartAt.value,
        receiptEndAt: receiptEndAt.value,
      };
      return payload;
    });

    const $v = useVuelidate(rules, updatePayload);

    const slots = {
      body: () => (
        [
          <p domPropsInnerHTML={
            I18n.t('competition.show.modal.edit_competition.desc')
          }>
          </p>,
          ...formItems.value.map(({ label, name, type, value, required }) => (
            <div class={['form-group', style.formGroup]}>
              <label for={name} class={required ? 'required' : ''}>
                {label}
              </label>
              { type === 'textarea' ?
                <textarea onChange={(e: Event) => handleChangeInput(e, name)} id={name} class="form-control" type={type} vModel={value} required={required} /> :
                <input onChange={(e: Event) => handleChangeInput(e, name)} id={name} class="form-control" type={type} value={value} required={required} />
              }
            </div>
          )),
        ]
      ),
      footer: () => (
        [
          <button
            class="btn btn-light"
            onClick={handleClose}
          >
            { I18n.t('actions.cancel') }
          </button>,
          <button
            class="btn btn-primary"
            onClick={handleSubmit}
          >
            { I18n.t('actions.save') }
          </button>,
        ]
      ),
    };

    const handleChangeInput = (e: Event, name: string) => {
      const target = e.target as HTMLInputElement;
      const targetIndex = formItems.value.findIndex((item) => item.name === name);
      formItems.value[targetIndex].value = target.value;
      $v.value.$touch();
    };

    const handleClose = () => emit('hide');

    const handleSubmit = async () => {
      $v.value.$touch();
      if ($v.value.$error) {
        return appendToast($v.value.$errors[0].$message as string);
      }
      await updateCompetition(state.id, updatePayload.value);
      router.go(0);
    };

    onMounted(
      async () => {
        const { description, faq, schedules } = tabContents;
        if (!description) {
          const description = (await fetchCompetitionDescription(state.id)).description;
          setTabState({
            description: {
              markdown: description,
            },
          });
          formItems.value[3].value = description;
        }
        if (!faq) {
          const faq = (await fetchCompetitionFaq(state.id)).faq;
          setTabState({
            faq: {
              markdown: faq,
            },
          });
          formItems.value[4].value = faq;
        }
        if (!schedules) {
          const schedules = (await fetchCompetitionSchedules(state.id));
          setTabState({
            schedules,
          });
          formItems.value[5].value = schedules.scheduleDescription;
        }
      },
    );

    return () => (
      <Modal
        onHide={handleClose}
        title={I18n.t('competition.show.modal.edit_competition.title')}
        scopedSlots={slots}
      />
    );
  },
});
</script>

<style lang="scss" module>
.formGroup {
  width: 100%;

  > label {
    width: 100%;
  }
}
</style>
