<template>
  <fieldset>
    <p v-if="customFormGroupDescription" class="description custom-form-description">
      <MarkdownViewer
        :value="`<pre><code>${customFormGroupDescription}</code></pre>`"
        :class="['markdown-viewer']"
      />
    </p>

    <div
      v-for="(customForm, index) in customForms"
      :key="index"
      class="form-group"
      :class="{ required: customForm.required }"
    >
      <CustomForm
        ref="customFormRef"
        :custom-form="customForm"
        :index="index"
        :response="response"
        @update:updateCustomFormAnswers="updateCustomFormAnswers"
        @update:updateSubjectiveAnswer="updateSubjectiveAnswer"
      />
    </div>
  </fieldset>
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from '@vue/composition-api';
import CustomForm from './CustomForm.vue';
import MarkdownViewer from '@/components/shared/MarkdownViewer.vue';
import { CustomForm as CustromFormType, Response } from '@/exportables/models/job-application.model';

export default defineComponent({
  name: 'JobApplicationCustomFormStep',
  components: {
    CustomForm,
    MarkdownViewer,
  },
  props: {
    customFormGroupDescription: {
      type: String,
      default: null,
    },
    customForms: {
      type: Array as PropType<CustromFormType[]>,
      required: true,
    },
    response: {
      type: Object as PropType<Response>,
      required: true,
    },
  },
  emits: ['update:updateCustomFormAnswers', 'update:updateSubjectiveAnswer'],
  setup(_, { emit }) {
    const customFormRef = ref();

    const isValidated = () => {
      return !customFormRef.value.find((customFormComponent: InstanceType<typeof CustomForm>) => {
        return !customFormComponent.isValidated();
      });
    };
    const updateCustomFormAnswers = (answerId: number, value: string | string[]) => {
      emit('update:updateCustomFormAnswers', answerId, value);
    };
    const updateSubjectiveAnswer = (answerId: number, keys: string | string[], value: { [key: string]: string }) => {
      emit('update:updateSubjectiveAnswer', answerId, keys, value);
    };

    return {
      customFormRef,
      isValidated,
      updateCustomFormAnswers,
      updateSubjectiveAnswer,
    };
  },
});
</script>
