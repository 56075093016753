<template>
  <div class="resume-card-item">
    <div v-if="isEditable" class="resume-card-left"></div>

    <div ref="hoverRef" class="resume-card-right">
      <h4 class="resume-card-item-label">
        {{ foreignLanguageName }}
      </h4>

      <h5
        v-for="exam in foreignLanguage.exams"
        :key="exam.name"
        class="resume-card-item-text"
      >
        {{ exam.name || '' }} • {{ exam.degree || '' }}
      </h5>

      <HoverButtonWrapper
        v-if="isHovered"
        type="foreign_language"
        :delete-item="() => handleDeleteItem(index)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  PropType,
  computed,
  defineComponent,
  inject,
  onBeforeUnmount,
  ref,
} from '@vue/composition-api';
import constants from '../../resume.constant';
import HoverButtonWrapper from '../HoverButtonWrapper.vue';
import { ResumeForeignLanguage } from '@/exportables/models/career/resume.model';
import { useHover } from '@/helpers/event';
import { useI18n } from '@/helpers/i18n';
import { useStore } from '@/store/career';

export default defineComponent({
  name: 'ResumeForeignLanguagePreview',
  components: {
    HoverButtonWrapper,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    foreignLanguage: {
      type: Object as PropType<ResumeForeignLanguage>,
      required: true,
    },
  },
  setup(props) {
    const { handleDeleteItem } = inject('form')!;
    const hoverRef = ref(null);
    const I18n = useI18n();
    const store = useStore();
    const foreignLanguageName = computed(() => {
      const name = props.foreignLanguage.name;
      const matched = constants.FOREIGN_LANGUAGE_MATCHER?.[props?.foreignLanguage?.name] || false;

      return (
        constants.FOREIGN_LANGUAGES.includes(name) && I18n.t(`section.foreign_language.${name}`) ||
        matched && I18n.t(`section.foreign_language.${matched}`) ||
        name
      );
    });
    const isEditable = computed(() => store.state.resume.isEditable);
    const { isHovered, unwatch } = useHover(hoverRef);

    onBeforeUnmount(unwatch);

    return {
      foreignLanguageName,
      handleDeleteItem,
      hoverRef,
      isEditable,
      isHovered,
    };
  },
});
</script>
