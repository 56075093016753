<template>
  <UserFeedPreview
    :is-logged-in="isLoggedIn"
    :has-user-feed-tags="hasUserFeedTags"
    :tags="userFeedTags"
    @setting="handleSetting"
  />
</template>

<script lang="ts">
import { computed, defineComponent } from '@vue/composition-api';
import UserFeedPreview from '../components/UserFeedPreview.vue';
import { useStore } from '@/store/career';

export default defineComponent({
  name: 'UserFeedPreviewContainer',
  components: {
    UserFeedPreview,
  },
  setup() {
    const store = useStore();

    const isLoggedIn = computed(() => store.state.post.login);
    const hasUserFeedTags = computed(() => store.getters['post/hasUserFeedTags']);
    const userFeedTags = computed(() => store.getters['post/allUserFeedTags']);

    const handleSetting = () => {
      if (isLoggedIn.value) {
        $('#user-feed-modal').modal('show');
      } else {
        window.location.assign('/users/login');
      }
    };

    return {
      isLoggedIn,
      hasUserFeedTags,
      userFeedTags,
      handleSetting,
    };
  },
});
</script>
