<template>
  <button class="btn-icon" :class="style.btn">
    <span
      v-if="isHovered && value"
      class="btn-clear-date"
      @click="handleInitialize"
    >
      <SvgIcon name="ic-clear" html-class="ic-16" />
    </span>

    <span v-else class="icon-calendar">
      <SvgIcon name="ic-calendar-16" html-class="ic-16" />
    </span>
  </button>
</template>

<script lang="ts">
import {
  defineComponent,
  useCssModule,
} from '@vue/composition-api';
import SvgIcon from '@/components/shared/SvgIcon.vue';

export default defineComponent({
  name: 'DatepickerButton',
  components: {
    SvgIcon,
  },
  props: {
    isHovered: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Date],
      default: '',
    },
  },
  emits: ['initialize'],
  setup(_, { emit }) {
    const style = useCssModule();

    const handleInitialize = () => emit('initialize');

    return {
      handleInitialize,
      style,
    };
  },
});
</script>

<style lang="scss" module>
@import '~/stylesheets/variables';
@import '~/stylesheets/functions';

.btn {
  position: absolute;
  z-index: 2;
  right: rem-calc(12);
  top: rem-calc(35);
  cursor: pointer;

  @media ($sm-down) {
    top: rem-calc(32);
  }
}
</style>
