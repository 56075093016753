import { reactive } from '@vue/composition-api';
import constants from '@/constant/key';
import { copy } from '@/helpers/clipboard';
import { originalUrl } from '@/helpers/url';

declare global {
  interface Window {
    Kakao: {
      Link: any,
      init: (key: string) => void,
    };
  }
}

type ShareType = 'facebook' | 'naver' | 'twitter' | 'kakao';
type ShareInfo = {
  url: string,
  imageUrl: string,
  text: string,
};

type OpenParams = Parameters<typeof window.open>;

const { KAKAO_API_KEY } = constants;

const makeLink = (type: ShareType, shareInfo: ShareInfo) => {
  const { text, url } = shareInfo;
  const encodedUrl = encodeURIComponent(url!);
  const encodedText = encodeURI(text);
  const params: OpenParams = [];

  switch (type) {
    case 'facebook':
      params.push(`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`, 'pop', 'width=600, height=400, scrollbars=no');
      break;
    case 'naver':
      params.push(`http://share.naver.com/web/shareView?url=${encodedUrl}&title=${encodedText}`);
      break;
    case 'twitter':
      params.push(`https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedText}`, 'share-helloworld', 'left=200,top=100,width=550,height=420,toolbar=no,status=no');
      break;
  }

  return params;
};

const makeKakaoLink = (shareInfo: ShareInfo) => {
  const { imageUrl, text: title, url } = shareInfo;
  return {
    objectType: 'feed',
    content: {
      title,
      imageUrl,
      link: {
        mobileWebUrl: url,
        webUrl: url,
      },
    },
    buttons: [
      {
        title: '지원하러 가기',
        link: {
          mobileWebUrl: url,
          webUrl: url,
        },
      },
    ],
  };
};

export const useShare = () => {
  const shareInfo: ShareInfo = reactive({
    imageUrl: require('~/images/img-meta-programmers.png'),
    text: window.document.title,
    url: window.location.href,
  });

  const setShareInfo = ({ imageUrl, text, url }: Partial<ShareInfo>) => {
    Object.assign(
      shareInfo,
      imageUrl && { imageUrl },
      text && { text },
      url && { url: originalUrl(url) },
    );
  };
  const copyLink = () => copy(shareInfo.url);

  const share = (type: ShareType) => {
    if (type === 'kakao') {
      if (!('Link' in (window.Kakao || {}))) {
        window.Kakao.init(KAKAO_API_KEY);
      }
      const kakaoLinkInfo = makeKakaoLink(shareInfo);
      window.Kakao.Link.sendDefault(kakaoLinkInfo);
      return;
    }

    const linkInfo = makeLink(type, shareInfo);
    window.open(...linkInfo);
  };

  return {
    shareInfo,
    setShareInfo,
    copyLink,
    share,
  };
};
