<template>
  <div
    id="resume-display"
    :class="{
      edit: isEditable,
      resume__preview: !isPrintPage,
      resume__print: isPrintPage,
    }"
  >
    <div class="resume__content">
      <div class="container">
        <div class="container"></div>
      </div>

      <Topbar v-if="isEditable" />

      <FloatingButton
        v-if="!isEditable && isPrintable"
        :button-position="printBtnPosition"
        :tooltip-title="I18n.t('print.title', { type: I18n.t('terms.resume') })"
      >
        <template #body>
          <button class="btn btn-svg btn-view-print" @click="handleClickPrintButton">
            <SvgIcon name="ic-print" html-class="ic-24" />
          </button>
        </template>
      </FloatingButton>

      <div class="resume__summary">
        <PersonalInformation
          v-skeleton="isLoading"
          :resume="state.resume"
        />
        <Repository v-skeleton="isLoading" />
        <Stacks v-skeleton="isLoading" />

        <footer v-if="!isEditable">
          <a class="link-brand" href="https://career.programmers.co.kr/">
            {{ I18n.t('career_service_name') }}
          </a>
        </footer>
      </div>

      <div class="resume__detail">
        <Timeline v-if="!isPrintPage" v-skeleton="isLoading" />
        <Experiences v-skeleton="isLoading" />
        <Educations v-skeleton="isLoading" />
        <Projects v-skeleton="isLoading" />
        <Awards v-skeleton="isLoading" />
        <Certificates v-skeleton="isLoading" :isPrintPage="isPrintPage" />
        <Publications v-skeleton="isLoading" />
        <TestResults v-skeleton="isLoading" />
        <Activities v-skeleton="isLoading" />
        <ForeignLanguages v-skeleton="isLoading" />
        <Attachment v-skeleton="isLoading" />
        <Customs v-skeleton="isLoading" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUpdated,
  provide,
  ref,
  watch,
} from '@vue/composition-api';
import Activities from '../components/Activities.vue';
import Attachment from '../components/Attachment.vue';
import Awards from '../components/Awards.vue';
import Certificates from '../components/Certificates.vue';
import Customs from '../components/Customs.vue';
import Educations from '../components/Educations.vue';
import Experiences from '../components/Experiences.vue';
import ForeignLanguages from '../components/ForeignLanguages.vue';
import PersonalInformation from '../components/PersonalInformation.vue';
import Projects from '../components/Projects.vue';
import Publications from '../components/Publications.vue';
import Repository from '../components/Repository.vue';
import Stacks from '../components/Stacks.vue';
import TestResults from '../components/TestResults.vue';
import Timeline from '../components/Timeline.vue';
import Topbar from '../components/Topbar.vue';
import { usePrint } from '../hooks/print';
import { initialize, useState } from '../hooks/state';
import constants from '../resume.constant';
import FloatingButton from '@/components/shared/FloatingButton.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { skeleton } from '@/directives';
import { useI18n } from '@/helpers/i18n';
import { debounce } from '@/helpers/lazy';
import { useModal } from '@/helpers/modal';
import { viewerSet } from '@/helpers/viewer';
import { useRoute } from '@/router/career';
import { useStore } from '@/store/career';
import {
  SET_IS_EDITABLE,
  SET_PAGE_TYPE,
  SET_RESUME,
} from '@/store/career/resume/mutation-types';

const { PRINT, USER } = constants;

export default defineComponent({
  name: 'ResumeShow',
  components: {
    Activities,
    Attachment,
    Awards,
    Certificates,
    Customs,
    Educations,
    Experiences,
    FloatingButton,
    ForeignLanguages,
    PersonalInformation,
    Projects,
    Publications,
    Repository,
    Stacks,
    SvgIcon,
    TestResults,
    Timeline,
    Topbar,
  },
  directives: {
    skeleton,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isPrintPage: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const I18n = useI18n();
    const modal = useModal();
    const { state } = useState();
    const print = usePrint(state);
    const route = useRoute();
    const store = useStore();
    const isLoading = ref(false);
    const printBtnPosition = computed(() => props.isEditable ? 'bottom right' : 'top right');

    const openPrintSetting = debounce(
      async () => {
        await nextTick();
        await Promise.all(viewerSet);
        window.print();
      },
      1000,
    );

    const openModal = async (modalName: string, props?: any) => {
      const { default: Component } = await import(`../components/modal/${modalName}.vue`);
      modal.show(Component, props, undefined, { provide: { state } });
    };

    store.commit(`resume/${SET_IS_EDITABLE}`, props.isEditable);

    provide('isLoading', isLoading);
    provide('openModal', openModal);
    provide('state', state);

    watch(
      () => [props.isEditable, props.isPrintPage],
      ([isEditable, isPrintPage]) => {
        store.commit(`resume/${SET_IS_EDITABLE}`, isEditable);
        store.commit(`resume/${SET_PAGE_TYPE}`, isPrintPage ? PRINT : USER);
      },
      { immediate: true },
    );

    watch(
      state,
      (state) => {
        store.commit(`resume/${SET_RESUME}`, state.resume);
      },
    );

    onMounted(async () => {
      isLoading.value = true;
      await initialize(state, props.id, props.isPrintPage ? { query: route.query } : undefined);
      isLoading.value = false;
    });

    onUpdated(() => {
      if (!props.isPrintPage || isLoading.value) return;
      if (route.query.access_token) {
        return;
      }
      openPrintSetting();
    });

    return {
      ...print,
      I18n: { t: I18n.t },
      isLoading,
      openModal,
      printBtnPosition,
      state,
    };
  },
});
</script>
