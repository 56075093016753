import { reactive } from '@vue/composition-api';
import { JobApplicationShowState } from '../../job-applications/hooks/show/state';
import { JobProfileShowState } from '../../job-profiles/hooks/show/state';
import { fetchResume } from '@/exportables/apis/career/resume.api';
import { JobApplication } from '@/exportables/models/career/job-application.model';
import { JobProfile } from '@/exportables/models/career/job-profile.model';
import { Resume } from '@/exportables/models/career/resume.model';
import { Tokens } from '@/exportables/models/career/tokens.model';
import { Query } from '@/exportables/types/general.d';

export type ResumeShowState = {
  resume: Resume,
  tokens: Tokens,
};

export type ResumeShowStates = ResumeShowState | JobApplicationShowState | JobProfileShowState;

export type ResumeLike = JobApplication | JobProfile | Resume;

const state = reactive({
  resume: Resume.new(),
  tokens: Tokens.new(),
});

export async function initialize(
  state: ResumeShowStates,
  id?: number,
  params?: { query: Query },
) {
  const resumeId = id ?? getResume(state).id;
  if (!resumeId) return;

  setState(
    state,
    await fetchResume(resumeId, params?.query),
  );
}

export function setState(
  state: ResumeShowStates,
  data: Partial<ResumeShowState> = {},
) {
  const {
    resume,
    tokens,
  } = data;
  const newResume = Resume.new(resume);

  Object.assign(
    state,
    { tokens: Tokens.new(tokens) },
    isProfile(state)
      ?
      {
        jobProfile: {
          ...state.jobProfile,
          resume: newResume,
        },
      }
      :
      { resume: newResume },
  );
}

export const useState = () => ({
  initialize,
  setState,
  state,
});

export const isProfile = (state: ResumeShowStates): state is JobProfileShowState => 'jobProfile' in state;

export const getResume = (state: ResumeShowStates) => isProfile(state) ? state.jobProfile.resume : state.resume;

export const useResume = (state: ResumeShowStates) => {
  const resume = getResume(state);
  const newResume = {
    get value() {
      return resume;
    },
    set value(v: Partial<Resume>) {
      setState(state, { resume: { ...resume, ...v } });
    },
  };
  return newResume;
};
