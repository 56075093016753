import { Post } from '@/exportables/models/career/post.model';
import { useI18n } from '@/helpers/i18n';

const DEFAULT_POST_AUTHOR_IMAGE_URL = 'https://grepp-cloudfront.s3.ap-northeast-2.amazonaws.com/programmers_imgs/design/img__default.png';

const I18n = useI18n();

export const postsAuthorImage = (post: Post) => post.authorImageUrl || DEFAULT_POST_AUTHOR_IMAGE_URL;
export const postedAt = (post: Post) => I18n.l('time.formats.number_without_time', post.postedAt);
export const hostname = (post: Post) => new URL(post.url).hostname;

export const tags = (post: Post) => post.companyTagList.concat(
  post.jobCategoryTagList,
  post.postTagList,
  post.technicalTagList,
);
