import {
  nextTick,
  reactive,
  toRefs,
  watch,
} from '@vue/composition-api';
import { useHttpClient } from '@/exportables/apis/httpClient';
import { CompanyTag, Tag } from '@/exportables/models/career/tag.model';
import { debounce } from '@/helpers/lazy';

const httpClient = useHttpClient();

export type InputState = CompanyTag;

type SearchState = {
  isClicked: boolean,
  isError: boolean,
  isLoading: boolean,
  highlightedIndex: number,
  result: Tag[],
};

export type SearchType = 'tag' | 'company';

type CreateHandleSearch = (state: SearchState, url: string) => (term: string, type: SearchType) => Promise<void>;

export const createHandleSearch: CreateHandleSearch = (
  state,
  url,
) => {
  return async (
    term,
    type,
  ) => {
    state.isLoading = true;

    const params = {
      term,
      category: type === 'tag' ? 'technical' : '',
    };

    const {
      data: { results = [] },
      status,
    }: {
      data: {
        results: {
          id: number,
          name: string,
        }[],
      },
      status: number,
    } = await httpClient.get(url, { params });

    state.isLoading = false;

    status === 200
      ? (state.result = results)
      : (state.isError = true);
  };
};

export const useSearch = (
  inputState: InputState,
  searchType: SearchType,
  handler: Record<string, (tag?: Tag) => any> = {},
) => {
  const url = `/${searchType === 'company' ? 'indexed_companies' : searchType + 's'}/search`;
  const newState = reactive<SearchState>({
    isClicked: true,
    isError: false,
    isLoading: false,
    highlightedIndex: 0,
    result: [],
  });

  const handleSearch = createHandleSearch(newState, url);
  const handleInput = debounce(
    async (term: string) => {
      await handleSearch(
        term,
        searchType,
      );

      handler?.input?.();
      newState.highlightedIndex = 0;
    },
    200,
  );

  const handleClickItem = async (
    params: Partial<InputState>,
    isClicked: boolean = true,
  ) => {
    Object.assign(inputState, params);
    const tag = params as Tag;
    tag.name && handler?.click?.(tag);
    await nextTick();
    newState.result = [];
    newState.isClicked = isClicked;
  };

  const handleClickFooter = () => {
    newState.isClicked = true;
    handler?.footer?.(inputState);
  };

  const unwatch = watch(
    () => inputState.name,
    (input, prevInput) => {
      if (input === prevInput) return;

      if (input) {
        newState.isClicked = false;
        handleInput(input);
        return;
      }

      Object.assign(
        inputState,
        {
          id: null,
          name: '',
          companyId: null,
        },
        inputState?.companyId && {
          companyId: null,
        },
      );
      Object.assign(
        newState,
        {
          result: [],
          highlightedIndex: 0,
        },
      );
    },
  );

  return {
    ...toRefs(newState),
    handleClickItem,
    handleClickFooter,
    input: inputState,
    searchType,
    unwatch,
  };
};

export const useSelect = (selectedState: Tag[]) => {
  const handleSelect = (params: InputState) => params.name && !selectedState.some((item) => item.name === params.name) && selectedState.push(params);

  return {
    handleSelect,
    selected: selectedState,
  };
};
