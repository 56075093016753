import { ko } from 'vuejs-datepicker/dist/locale';

const minDate = new Date('1970-01-01');
const maxDate = new Date('9999-12-31');

const ADMIN = 'admin';
const BUSINESS = 'business';
const PERSON = 'person';
const PRINT = 'print';
const PUBLIC = 'public';
const TEAM = 'team';
const USER = 'user';

const JOB_APPLICATION = 'jobApplication';
const JOB_PROFILE = 'jobProfile';
const RESUME = 'resume';

export default Object.freeze({
  ADMIN,
  BUSINESS,
  JOB_APPLICATION,
  JOB_PROFILE,
  PERSON,
  PRINT,
  PUBLIC,
  RESUME,
  TEAM,
  USER,
  DATEPICKER: {
    minDate,
    maxDate,
    options: {
      class: 'form-group',
      format: 'yyyy-MM',
      initialView: 'month',
      inputClass: 'form-control monthpicker date',
      maximumView: 'year',
      minimumView: 'month',
      typeable: true,
      language: ko,
    },
  },
  FOREIGN_LANGUAGES: ['english', 'japanese', 'chinese', 'else'],
  FOREIGN_LANGUAGE_MATCHER: {
    영어: 'english',
    일본어: 'japanese',
    중국어: 'chinese',
    기타: 'else',
  },
  PRINT_OPTIONS: [
    {
      key: 'no_masking',
      masking: false,
    },
    {
      key: 'masking',
      masking: true,
    },
  ],
  SKILL_CHECK: {
    introductions: [
      {
        level: 5,
        percentage: 1,
        width: 99,
      },
      {
        level: 4,
        percentage: 10,
        width: 90,
      },
      {
        level: 3,
        percentage: 30,
        width: 70,
      },
      {
        level: 2,
        percentage: 60,
        width: 40,
      },
      {
        level: 1,
        percentage: 90,
        width: 10,
      },
    ],
    job_category_difficulties: {
      junior_intern: 'one-two',
      si: 'one-two',
      game: 'two-three',
      server: 'one-two',
      research: 'two-three',
      highschool_graduate: 'two-three',
      in_house_evaluation: 'one-two',
      programming_contest: 'one-four',
    },
  },
});
