<template>
  <div class="search-select multi">
    <div class="form-control">
      <ul class="select-list">
        <li
          v-for="(item, index) in selected"
          :key="item.id"
          class="select-item">
          <slot
            name="select-item"
            :item="item"
            :idx="index">
            <span>{{ item.name }}</span>
            <span @click="handleDeleteSelectedItem(index)">
              <SvgIcon name="ic-delete-14" html-class="ic-14" />
            </span>
          </slot>
        </li>

        <li class="select-item input">
          <SearchInput :autofocus="autofocus" :placehloder="placeholder" />
        </li>
      </ul>
    </div>

    <SearchResult>
      <template #search-item="{ item }">
        <slot name="search-item" :item="item"></slot>
      </template>

      <template #no-item="{ item }">
        <slot name="no-item" :item="item"></slot>
      </template>

      <template #footer="{ item }">
        <slot name="footer" :item="item"></slot>
      </template>
    </SearchResult>
  </div>
</template>

<script>
import {
  defineComponent,
  provide,
  toRef,
} from '@vue/composition-api';
import SearchInput from './SearchInput.vue';
import SearchResult from './SearchResult.vue';
import SvgIcon from './SvgIcon.vue';

export default defineComponent({
  name: 'MultiSelect',
  components: {
    SearchInput,
    SearchResult,
    SvgIcon,
  },
  props: {
    autofocus: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    search: {
      type: Object,
      default: () => ({}),
    },
    select: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { search, select } = props;
    const selected = toRef(select, 'selected');

    const handleDeleteSelectedItem = (index) => selected.value.splice(index, 1);

    provide('search', search);

    return {
      selected,
      handleDeleteSelectedItem,
    };
  },
});
</script>
