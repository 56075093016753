import * as types from './mutation-types';
import {
  PageType,
  ResumeStoreState,
} from './state';
import { Resume, ResumeCategory, ResumeCertificate } from '@/exportables/models/career/resume.model';

export default {
  [types.SET_CLICKED_TAG_NAME](state: ResumeStoreState, payload: string) {
    state.clickedTagName = payload;
  },
  [types.SET_IS_EDITABLE](state: ResumeStoreState, payload: boolean) {
    state.isEditable = payload;
  },
  [types.SET_IS_SHARABLE](state: ResumeStoreState, payload: boolean) {
    state.isSharable = payload;
  },
  [types.SET_MOUSE_OVERED_TAG_NAME](state: ResumeStoreState, payload: string) {
    state.mouseOveredTagName = payload;
  },
  [types.SET_PAGE_TYPE](state: ResumeStoreState, payload: PageType) {
    state.pageType = payload;
  },
  [types.SET_EDITING_PART_COUNT](state: ResumeStoreState, { category, delta }: { category: ResumeCategory, delta: number }) {
    const value = state.editingPartCount[category];
    state.editingPartCount[category] = Math.max(value + delta, 0);
  },
  [types.SET_RESUME](state: ResumeStoreState, payload: Partial<Resume>) {
    Object.assign(
      state.resume,
      payload,
    );
  },
  [types.SET_RESUME_CERTIFICATES](state: ResumeStoreState, payload: ResumeCertificate[]) {
    state.resumeCertificates = payload;
  },
};
