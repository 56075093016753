<template>
  <div :class="style.container">
    <div :class="style.wrapper">
      <div :class="['width-full', 'flex-between', style.box]">
        <div v-skeleton="isLoading" class="flex-start" :class="style.box">
          <div :class="style.inputWrap">
            <span :class="style.label">
              {{ I18n.t('job_profile.public.url') }}
            </span>

            <div class="input-group">
              <input
                type="text"
                class="form-control"
                :class="style.input"
                :value="url"
                readonly
              >

              <div
                v-tooltip="I18n.t('job_profile.public.url_copy')"
                class="input-group-append"
                @click="copy(url)"
              >
                <div class="input-group-text" :class="style['append-btn']">
                  <SvgIcon name="ic-content-copy" html-class="ic-20" />
                </div>
              </div>
            </div>
          </div>

          <button
            v-tooltip="I18n.t('job_profile.public.url_edit')"
            class="btn btn-md btn-outline-light"
            :class="style.btn"
            @click="openModal('PublicUrl')"
          >
            <SvgIcon name="ic-edit" html-class="ic-20" />
          </button>
        </div>

        <div v-if="!isLoading" :class="style.toggleWrap">
          <Toggle
            :class="style.toggle"
            :is-active="jobProfile.isOpened"
            :labels="[I18n.t('job_profile.public.open')]"
            @toggle="handleToggle"
          />
        </div>
      </div>

      <div :class="['flex-start', style.box]">
        <button
          v-tooltip="I18n.t('job_profile.public.open_options')"
          class="btn btn-md btn-outline-light"
          :class="style.btn"
          @click="openModal('PublicOptions')"
        >
          <SvgIcon name="ic-setting-filled" html-class="ic-20" />
        </button>

        <a
          href="/job_profiles/edit"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button
            v-tooltip="I18n.t('job_profile.edit')"
            class="btn btn-md btn-outline-light"
            :class="style.btn"
          >
            <SvgIcon name="ic-resume-new" html-class="ic-20" />
          </button>
        </a>
      </div>

      <div v-if="!isLoading && isPrivate" :class="style.private">
        <div :class="style.alert">
          <p v-for="idx in 2" :key="idx">
            {{ I18n.t(`job_profile.public.private_${idx}`) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  toRef,
  unref,
  useCssModule,
  watch,
} from '@vue/composition-api';
import { JobProfileShowState } from '../hooks/show/state';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { skeleton, tooltip } from '@/directives';
import { toggleOpen } from '@/exportables/apis/career/job-profile.api';
import { copy } from '@/helpers/clipboard';
import { useI18n } from '@/helpers/i18n';
import { useModal } from '@/helpers/modal';
import { setBodyOverflow } from '@/helpers/style';
import Toggle from '@/pages/career/resumes/components/Toggle.vue';
import { useStore } from '@/store/career';

export default defineComponent({
  name: 'JobProfileControlBar',
  components: {
    SvgIcon,
    Toggle,
  },
  directives: {
    skeleton,
    tooltip,
  },
  setup() {
    const isLoading = inject('isLoading');
    const state = inject<JobProfileShowState>('state')!;
    const I18n = useI18n();
    const modal = useModal();
    const store = useStore();
    const jobProfile = toRef(state, 'jobProfile');
    const isPrivate = computed(() => store.state.resume.isSharable && !unref(jobProfile).isOpened);
    const url = computed(() => `${window.location.origin}/pr/${state.jobProfile.resume.user.coverName}`);
    const style = useCssModule();

    const openModal = async (modalName: string) => {
      const { default: Component } = await import(`./modal/${modalName}.vue`);
      modal.show(Component, {}, undefined, { provide: { state } });
    };

    const handleToggle = async () => {
      const { isOpened } = await toggleOpen();
      state.jobProfile.isOpened = isOpened;
    };

    setBodyOverflow(unref(isPrivate));

    watch(
      isPrivate,
      (isPrivate) => setBodyOverflow(isPrivate),
    );

    return {
      I18n,
      copy,
      handleToggle,
      isLoading,
      isPrivate,
      jobProfile,
      openModal,
      style,
      url,
    };
  },
});
</script>

<style lang="scss" module>
@use "~/stylesheets/display";
@use "~/stylesheets/functions";
@use "~/stylesheets/variables";

.container {
  width: 100%;
  border-bottom: 1px solid variables.$color-border;
  position: relative;

  button,
  span {
    font-size: initial;
    font-weight: 500;
    white-space: nowrap;
  }
}

.wrapper {
  @extend .flex-between;
  max-width: functions.rem-calc(1320);
  padding: functions.rem-calc(12 40);
  margin: 0 auto;
}

.btn {
  width: functions.rem-calc(40);
  height: functions.rem-calc(40);
  border: 1px solid variables.$color-border;
  padding: 0;

  svg {
    fill: variables.$color-text--primary;
  }

  &:hover,
  &:active,
  &:focus {
    svg {
      fill: variables.$color-text--primary;
    }
  }
}

.box {
  gap: functions.rem-calc(8);
}

.inputWrap {
  @extend .flex-start;
}

.input {
  width: functions.rem-calc(300) !important;
  cursor: default !important;
}

.label {
  margin-right: functions.rem-calc(8);
}

.toggleWrap {
  position: relative;
}

.toggle {
  margin-right: functions.rem-calc(8);

  label {
    margin: functions.rem-calc(0 8);
  }
}

.private {
  width: 100%;
  height: 100vh;
  background-color: #ffffff80;
  position: absolute;
  bottom: -100vh;
  left: 0;
  z-index: 300;
}

.alert {
  width: 100%;
  height: functions.rem-calc(80);
  padding: functions.rem-calc(16);
  color: variables.$color-primary;
  background-color: variables.$blue-50;
  outline: 1px solid variables.$blue-75;
  font-size: functions.rem-calc(16);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  white-space: nowrap;

  p {
    min-width: functions.rem-calc(1320);
    padding: functions.rem-calc(0 40);
    margin: 0 auto;
    white-space: normal;
    z-index: 100;
  }
}

@media (variables.$xl-down) {
  .wrapper {
    max-width: functions.rem-calc(880);
  }

  .input {
    width: functions.rem-calc(250) !important;
  }

  .btn {
    &:first-child {
      margin: 0;
    }
  }

  .alert {
    p {
      min-width: unset;
      max-width: functions.rem-calc(880);
    }
  }
}

@media (variables.$md-down) {
  .wrapper {
    padding: functions.rem-calc(12 16);
    align-items: flex-end;
  }

  .inputWrap {
    flex-direction: column;
    align-items: flex-start;
  }

  .input {
    width: functions.rem-calc(200) !important;
  }

  .label,
  .btn {
    font-size: functions.rem-calc(14) !important;
  }

  .btn {
    align-self: flex-end;
  }

  .box {
    align-items: flex-end;
    gap: functions.rem-calc(4);
  }

  .toggleWrap {
    height: functions.rem-calc(40);
  }

  .toggle {
    height: 100%;
  }

  .alert {
    p {
      padding: functions.rem-calc(0 16);
    }
  }
}

@media (variables.$sm-down) {
  .box {
    margin: functions.rem-calc(4 0);

    &:first-child {
      flex-wrap: wrap;
    }

    &:last-child {
      height: functions.rem-calc(51);
    }
  }

  .btn {
    height: functions.rem-calc(32);
    font-size: functions.rem-calc(13);
  }

  .input {
    width: functions.rem-calc(150) !important;
  }

  .toggleWrap {
    height: functions.rem-calc(32);
  }

  .toggle {
    label {
      font-size: functions.rem-calc(13);
      margin-left: 0;
    }
  }

  .append-btn {
    width: functions.rem-calc(38);
    padding: functions.rem-calc(0 8);
  }

  .btn {
    width: functions.rem-calc(32);
    height: functions.rem-calc(32);
    margin-left: functions.rem-calc(4);
  }

  .alert {
    font-size: functions.rem-calc(14);
    padding: functions.rem-calc(8);

    p {
      padding: functions.rem-calc(0 8);
      margin: 0;
    }
  }
}

@media (max-width: 350px) {
  .input {
    width: functions.rem-calc(120) !important;
  }
}
</style>
