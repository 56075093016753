<template>
  <component :is="tag">
    <template v-for="splited in splitedKeypath">
      <slot v-if="slotList.includes(splited)" :name="splited">
        {{ splited }}
      </slot>
      <template v-else>
        {{ splited }}
      </template>
    </template>
  </component>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
} from '@vue/composition-api';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'SlottedI18n',
  props: {
    keypath: {
      type: String,
      default: '',
    },
    tag: {
      type: String,
      default: 'span',
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { slots }) {
    const I18n = useI18n();
    const splitedKeypath = computed(() => I18n.t(props.keypath, props.options).split(/[{}]/));
    const slotList = Object.keys(slots);

    return {
      splitedKeypath,
      slotList,
    };
  },
});
</script>
