<template>
  <button :class="[style.chip, isActive ? style.isActive : '']" @click="handleClick">
    <SvgIcon name="ic-check-14" />
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent, useCssModule } from '@vue/composition-api';
import SvgIcon from '../../../../components/shared/SvgIcon.vue';

export default defineComponent({
  name: 'JobProfileChip',
  components: { SvgIcon },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  setup(_, { emit }) {
    const style = useCssModule();
    const handleClick = () => emit('click');
    return {
      style,
      handleClick,
    };
  },
});
</script>

<style lang="scss" module>
@use "~stylesheets/variables";
@use "~stylesheets/functions";

.chip {
  display: inline-flex;
  padding: functions.rem-calc(6 12);
  align-items: center;
  gap: functions.rem-calc(4);
  border: 1px solid variables.$blue-grey-75;
  border-radius: functions.rem-calc(24);
  font-weight: 400;
  font-size: functions.rem-calc(14);
  color: variables.$color-text--secondary;
  fill: variables.$color-text--secondary;

  > svg {
    width: functions.rem-calc(14);
    height: functions.rem-calc(14);
  }

  &:hover {
    transition: 0.03s ease-out;
    background: rgba(0, 120, 255, 0.1);
    border: 1px solid rgba(0, 120, 255, 0.1);
    font-weight: 700;
    color: variables.$color-primary;
    fill: variables.$color-primary;
  }
}

.isActive {
  background: rgba(0, 120, 255, 0.1);
  border: 1px solid rgba(0, 120, 255, 0.1);
  font-weight: 500;
  color: variables.$color-primary;
  fill: variables.$color-primary;
}

@media (variables.$md-down) {
  .chip {
    padding: functions.rem-calc(4 10);
    font-size: functions.rem-calc(13);
  }
}
</style>
