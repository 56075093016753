import { reactive, ref } from '@vue/composition-api';
import { CompetitionTabContents } from '@/exportables/models/career/competition.model';

const isLoading = ref<boolean>(false);

const tabContents = reactive<Partial<CompetitionTabContents>>({
  description: undefined,
  jobPositions: undefined,
  event: undefined,
  schedules: undefined,
  leaderboards: undefined,
  faq: undefined,
  commentary: undefined,
});

const setState = (newTabContents: Partial<CompetitionTabContents>) => {
  Object.assign(tabContents, newTabContents);
};

export const useTab = () => {
  return {
    isLoading,
    tabContents,
    setState,
  };
};
