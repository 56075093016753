import { DefaultDate, DefaultNumber } from '../../types/general.d';
import { User } from '../user.model';
import { CustomForm as Form } from './job-application.model';
import { Tag } from './tag.model';

export class Resume {
  id: number = -1;
  activities: ResumeActivity[] = [];
  attachment: ResumeAttachment = ResumeAttachment.new();
  awards: ResumeAward[] = [];
  blog: string = '';
  certificates: ResumeCertificate[] = [];
  customForms?: CustomForm[];
  devEmploymentDuration: DefaultNumber = null;
  educations: ResumeEducation[] = [];
  email: string = '';
  employmentDuration: DefaultNumber = null;
  experiences: ResumeExperience[] = [];
  foreignLanguages: ResumeForeignLanguage[] = [];
  gitStat?: ResumeGitStat;
  hideSkillChecks: boolean = false;
  hideGitStats: boolean = false;
  introduction: string = '';
  isEmailVerification: boolean = false;
  isPhoneVerification: boolean = false;
  name: string = '';
  phone: string = '';
  primaryTags: Tag[] = [];
  projects: ResumeProject[] = [];
  printPolicy: boolean = false;
  publications: ResumePublication[] = [];
  ratingAverage?: number;
  registration: any = null;
  repository: string = '';
  reviewsCount?: number;
  salary: number = 0;
  score: number = 0;
  secondaryTags: Tag[] = [];
  status: string = '';
  technicalTags: Tag[] = [];
  timelineRange: number = 8;
  title: string = '';
  url: string = '';
  user: User = {
    id: -1,
    coverName: '',
    name: '',
  } as User;
  workedCompanyNames?: string[];

  constructor(resume: Partial<Resume> = {}) {
    const {
      activities,
      attachment,
      awards,
      educations,
      experiences,
      foreignLanguages,
      gitStat,
      primaryTags,
      projects,
      publications,
      secondaryTags,
      technicalTags,
      user,
    } = resume || {};

    Object.assign(this, resume);
    activities && (this.activities = activities.map((activity) => ResumeActivity.new(activity)));
    attachment && (this.attachment = ResumeAttachment.new(attachment));
    awards && (this.awards = awards.map((award) => ResumeAward.new(award)));
    educations && (this.educations = educations.map((education) => ResumeEducation.new(education)));
    experiences && (this.experiences = experiences.map((experience) => ResumeExperience.new(experience)));
    foreignLanguages && (this.foreignLanguages = foreignLanguages.map((foreignLanguage) => ResumeForeignLanguage.new(foreignLanguage)));
    gitStat && (this.gitStat = ResumeGitStat.new(gitStat));
    primaryTags && (this.primaryTags = primaryTags.map((tag) => Tag.new(tag)));
    projects && (this.projects = projects.map((project) => ResumeProject.new(project)));
    publications && (this.publications = publications.map((publication) => ResumePublication.new(publication)));
    secondaryTags && (this.secondaryTags = secondaryTags.map((tag) => Tag.new(tag)));
    technicalTags && (this.technicalTags = technicalTags.map((tag) => Tag.new(tag)));
    user && (this.user = User.new(user));
  }

  static new(resume?: Partial<Resume>) {
    return new Resume(resume);
  }
}

export class ResumeElement {
  id: number = -1;
  createdAt: DefaultDate = null;
  devEmploymentDuration: number = 0;
  email: string = '';
  employmentDuration: number = 0;
  errorMessages: string = '';
  isProfile: boolean = false;
  name: string = '';
  phone: string = '';
  title: string = '';
  updatedAt: DefaultDate = null;

  constructor(element = {} as Partial<ResumeElement>) {
    const { createdAt, updatedAt } = element;

    Object.assign(
      this,
      element,
      createdAt && { createdAt: new Date(createdAt) },
      updatedAt && { updatedAt: new Date(updatedAt) },
    );
  }

  static new(element = {} as Partial<ResumeElement>) {
    return new ResumeElement(element);
  }
}

export type BusinessJobProfileResumeEducation = Pick<ResumeEducation, 'name'>;
export type BusinessJobProfileResumeExperience = Omit<ResumeExperience, 'analyzedLink' | 'teamDescription' | 'company'>;

export class BusinessJobProfileResume {
  id: number = -1;
  devEmploymentDuration: DefaultNumber = null;
  educations: BusinessJobProfileResumeEducation[] = [];
  employmentDuration: DefaultNumber = null;
  experiences: BusinessJobProfileResumeExperience[] = [];
  jobCategories: Tag[] = [];
  name: string = '';
  primaryTags: Tag[] = [];
  salary: number = 0;
  workedCompanyNames: string[] = [];

  constructor(resume: Partial<BusinessJobProfileResume> = {}) {
    const {
      educations,
      experiences,
      jobCategories,
      primaryTags,
    } = resume;

    Object.assign(this, resume);
    educations && (this.educations = educations.map((education) => ResumeEducation.new(education) as BusinessJobProfileResumeEducation));
    experiences && (this.experiences = experiences.map((experience) => ResumeExperience.new(experience) as BusinessJobProfileResumeExperience));
    jobCategories && (this.jobCategories = jobCategories.map(Tag.new));
    primaryTags && (this.primaryTags = primaryTags.map(Tag.new));
  }

  static new(resume?: Partial<BusinessJobProfileResume>) {
    return new BusinessJobProfileResume(resume);
  }
}

export class ResumeActivity {
  id: number;
  title: string = '';
  description: string = '';
  link: string = '';
  startAt: DefaultDate = '';
  endAt?: DefaultDate = '';
  destroy: boolean = false;

  constructor(activity: Partial<ResumeActivity> = {}) {
    const { startAt, endAt } = activity;

    Object.assign(
      this,
      activity,
      startAt && { startAt: new Date(startAt) },
      endAt && { endAt: new Date(endAt) },
    );
  }

  static new(activity?: Partial<ResumeActivity>) {
    return new ResumeActivity(activity);
  }
}

export class ResumeAttachment {
  id: number;
  fileName: string = '';
  fileUrl: string = '';

  constructor(attachment = {} as Partial<ResumeAttachment>) {
    Object.assign(this, attachment);
  }

  static new(attachment = {} as Partial<ResumeAttachment>) {
    return new ResumeAttachment(attachment);
  }
}

export class ResumeAward {
  id: number;
  title: string = '';
  description: string = '';
  publisher: string = '';
  number: string = '';
  link: string = '';
  awardedAt: DefaultDate = '';
  endAt?: DefaultDate = '';
  destroy: boolean = false;

  constructor(award: Partial<ResumeAward> = {}) {
    const { awardedAt, endAt } = award;

    Object.assign(
      this,
      award,
      awardedAt && { awardedAt: new Date(awardedAt) },
      endAt && { endAt: new Date(endAt) },
    );
  }

  static new(award?: Partial<ResumeAward>) {
    return new ResumeAward(award);
  }
}

export class ResumeCompany {
  id: number = -1;
  name: string = '';
  companyUrl?: string = '';
  homeUrl?: string = '';

  constructor(company: Partial<ResumeCompany> = {}) {
    Object.assign(this, company);
  }

  static new(company?: Partial<ResumeCompany>) {
    return new ResumeCompany(company);
  }
}

export class ResumeEducation {
  id: number;
  creditScore: DefaultNumber | string = null;
  perfectCreditScore: DefaultNumber | string = null;
  degree?: string = '';
  description?: string = '';
  endAt?: DefaultDate = '';
  major: string = '';
  name: string = '';
  startAt: DefaultDate = '';
  destroy: boolean = false;

  constructor(education: Partial<ResumeEducation> = {}) {
    const { creditScore, perfectCreditScore, startAt, endAt } = education;
    const [parsedperfectCreditScore, parsedCreditScore] = [perfectCreditScore, creditScore].map((s) => {
      if (!s) return;
      const parsed = parseFloat(s.toString());
      return Number.isNaN(parsed) ? '' : parsed;
    });

    Object.assign(
      this,
      education,
      {
        perfectCreditScore: parsedperfectCreditScore,
        creditScore: parsedCreditScore,
      },
      endAt && { endAt: new Date(endAt) },
      startAt && { startAt: new Date(startAt) },
    );
  }

  static new(education?: Partial<ResumeEducation>) {
    return new ResumeEducation(education);
  }
}

export class ResumeExperience {
  id: number;
  name: string = '';
  description: string = '';
  link: string = '';
  analyzedLink: string = '';
  developmentUnrelated: boolean = false;
  duration?: number;
  role: string = '';
  teamDescription: string = '';
  startAt: DefaultDate = '';
  endAt: DefaultDate = '';
  company: ResumeCompany;
  companyId?: number | null;
  indexedCompany: ResumeIndexedCompany;
  indexedCompanyId?: number | null;
  parts: ResumePart[] = [];
  tags: Tag[] = [];
  destroy: boolean = false;

  constructor(experience = {} as Partial<ResumeExperience>) {
    const { company, indexedCompany, endAt, parts, startAt, tags } = experience;
    Object.assign(this, experience);
    endAt && (this.endAt = new Date(endAt));
    startAt && (this.startAt = new Date(startAt));
    parts && (this.parts = parts.map((part) => ResumePart.new(part)));
    tags && (this.tags = tags.map((tag) => Tag.new(tag)));
    this.indexedCompany = ResumeIndexedCompany.new(
      indexedCompany ? indexedCompany : { name: this.name },
    );
    this.company = ResumeCompany.new(company);
  }

  static new(experience?: Partial<ResumeExperience>) {
    return new ResumeExperience(experience);
  }
}

export type ForeignLanguageExam = {
  index?: number,
  name: string,
  degree: string,
};
export class ResumeForeignLanguage {
  id: number;
  name: string = 'english';
  exams: ForeignLanguageExam[] = [
    {
      index: -1,
      name: '',
      degree: '',
    },
  ];
  destroy: boolean = false;

  constructor(foreignLanguage: Partial<ResumeForeignLanguage> = {}) {
    Object.assign(this, foreignLanguage);
  }

  static new(foreignLanguage: Partial<ResumeForeignLanguage>) {
    return new ResumeForeignLanguage(foreignLanguage);
  }
}

export class ResumeGitStat {
  analyzedAt: DefaultDate = null;
  commitsCountByUser: number | null = null;
  inProgress: boolean = false;
  linesCountByUser: any[] = [];

  constructor(gitStat: Partial<ResumeGitStat> = {}) {
    Object.assign(this, gitStat);
  }

  static new(gitStat?: Partial<ResumeGitStat>) {
    return new ResumeGitStat(gitStat);
  }
}

export class ResumeIndexedCompany {
  id: number | null = null;
  name: string = '';
  ceoName: string = '';
  companyId: number | null = null;

  constructor(indexedCompany = {} as Partial<ResumeIndexedCompany>) {
    Object.assign(this, indexedCompany);
  }

  static new(indexedCompany = {} as Partial<ResumeIndexedCompany>) {
    return new ResumeIndexedCompany(indexedCompany);
  }
}

export class ResumePart {
  index?: number;
  title: string = '';
  description: string = '';
  startAt: DefaultDate = '';
  endAt: DefaultDate = '';

  constructor(part = {} as Partial<ResumePart>) {
    const { startAt, endAt } = part;
    Object.assign(this, part);
    endAt && (this.endAt = new Date(endAt));
    startAt && (this.startAt = new Date(startAt));
  }

  static new(part = {} as Partial<ResumePart>) {
    return new ResumePart(part);
  }
}

export class ResumeProject {
  id: number;
  name: string = '';
  description: string = '';
  analyzedLink: string = '';
  repository: string = '';
  roleDescription: string = '';
  teamDescription: string = '';
  productUrl: {
    websiteUrl: string,
    androidUrl: string,
    iosUrl: string,
  } = {
      websiteUrl: '',
      androidUrl: '',
      iosUrl: '',
    };
  startAt: DefaultDate | number = '';
  tags: Tag[] = [];
  destroy: boolean = false;

  constructor(project: Partial<ResumeProject> = {}) {
    const { tags } = project;

    Object.assign(
      this,
      project,
      tags && { tags: tags.map((tag) => Tag.new(tag)) },
    );
  }

  static new(project: Partial<ResumeProject>) {
    return new ResumeProject(project);
  }
}

export class ResumePublication {
  id: number;
  title: string = '';
  description: string = '';
  link: string = '';
  author: string = '';
  uniqueNumber: string = '';
  publisher: string = '';
  publishedAt: DefaultDate = '';
  destroy: boolean = false;

  constructor(publication = {} as Partial<ResumePublication>) {
    const { publishedAt } = publication;

    Object.assign(
      this,
      publication,
      publishedAt && { publishedAt: new Date(publishedAt) },
    );
  }

  static new(publication = {} as Partial<ResumePublication>) {
    return new ResumePublication(publication);
  }
}

export class ResumeTag {
  id: number = -1;
  name: string = '';

  constructor(tag = {} as Tag) {
    Object.assign(this, tag);
  }

  static new(tag = {} as Tag) {
    return new ResumeTag(tag);
  }
}

type CertificateExamType = 'PCCE' | 'PCCP';

export type ResumeCertificate = {
  id: number,
  examType: CertificateExamType,
  /**
   * 자격증 번호
   */
  code: string,
  language: string,
  level: number,
  startAt: DefaultDate,
  expireAt: DefaultDate,
};

export type CustomForm = {
  index: number,
  answer: string,
  answerText: string,
  form: Form,
};

export type ResumeCategory = 'activities' | 'awards' | 'educations' | 'experiences' | 'foreignLanguages' | 'projects' | 'publications';
