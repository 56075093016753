<template>
  <div ref="datepickerRef" :class="style.datepicker">
    <DatepickerButton
      :is-hovered="isHovered"
      :value="value"
      @initialize="() => emit('initialize')"
    />

    <label v-if="label" :for="name">{{ label }}</label>

    <Datepicker
      v-bind="options"
      :class="className"
      :calendar-class="{
        [style.alignRight]: align === 'right',
        [style.calendar]: true,
      }"
      :value="value"
      :disabled-dates="disabledDates"
      :name="name"
      :placeholder="placeholder"
      @selected="handleDate"
    />
    <slot></slot>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  ref,
  useCssModule,
  watch,
} from '@vue/composition-api';
// @ts-ignore
import Datepicker from 'vuejs-datepicker';
import resumeConstants from '../resume.constant';
import { inputValidationClass } from '../resume.util';
import DatepickerButton from './DatepickerButton.vue';
import formatConstants from '@/constant/format';
import { DefaultDate } from '@/exportables/types/general.d';
import { useHover } from '@/helpers/event';

const { LEFT_RIGHT_POSITION } = formatConstants;
const { minDate, maxDate, options } = resumeConstants.DATEPICKER;

const LEFT_RIGHT_POSITION_SET = new Set(LEFT_RIGHT_POSITION);

/**
 * @description
 * utils/datetime의 convertDate와는 구현 내용이 달라 따로 함수를 생성합니다.
 */
const convertDate = (date: DefaultDate) => {
  return (
    (date instanceof Date && date) ||
    (typeof date === 'string' && new Date(date)) ||
    null
  );
};

export default defineComponent({
  name: 'ResumeDatepicker',
  components: {
    Datepicker,
    DatepickerButton,
  },
  props: {
    className: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'yyyy-MM',
    },
    align: {
      type: String,
      default: 'left',
      validator: (value: string) => LEFT_RIGHT_POSITION_SET.has(value),
    },
    value: {
      type: [String, Date],
      default: '',
    },
    /** 선택 가능 시작 범위(greater than equal to, >=) */
    from: {
      type: [String, Date],
      default: () => minDate,
    },
    /** 선택 가능 종료 범위(less than equal to, <=) */
    to: {
      type: [String, Date],
      default: () => maxDate,
    },
  },
  emits: ['initialize', 'select'],
  setup(props, { emit }) {
    const datepickerRef = ref<HTMLElement>(null!);
    const inputRef = ref<HTMLInputElement>(null!);
    const disabledDates = computed(() => {
      const from = convertDate(props.to);
      const to = convertDate(props.from);
      return {
        from,
        to,
      };
    });
    const { isHovered, unwatch } = useHover(datepickerRef);
    const style = useCssModule();

    const handleBlur = () => {
      const selected = datepickerRef.value.querySelector(
        '.vdp-datepicker__calendar span.selected',
      ) as HTMLElement;
      if (!selected) return;

      selected.classList.contains('disabled')
        ? emit('select', null)
        : selected?.click();
    };

    const handleKeypress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();

        handleBlur();
      }
    };

    const handleDate = (value: Date) => {
      if (!value) return;
      emit('select', value);
    };

    watch(datepickerRef, (datepicker) => {
      const input = datepicker?.querySelector('input.date') as HTMLInputElement;
      input && (inputRef.value = input);
    });

    watch(inputRef, (input, nextInput) => {
      if (input !== nextInput) {
        input!.addEventListener('blur', handleBlur);
        input!.addEventListener('keypress', handleKeypress);
      }
    });

    onBeforeUnmount(() => {
      inputRef.value?.removeEventListener('blur', handleBlur);
      inputRef.value?.removeEventListener('keypress', handleKeypress);

      unwatch();
    });

    return {
      datepickerRef,
      disabledDates,
      emit,
      inputValidationClass,
      isHovered,
      handleDate,
      options,
      style,
    };
  },
});
</script>

<style lang="scss" module>
@import '~/stylesheets/functions';
@import '~/stylesheets/variables';

.datepicker {
  position: relative;
  margin-bottom: rem-calc(16);
}

.calendar {
  @media ($sm-down) {
    width: 100% !important;
  }
}

.alignRight {
  right: 0;
}
</style>
