import VueCompositionAPI, {
  computed,
  reactive,
} from '@vue/composition-api';
import Vue from 'vue';
import VueRouter, {
  RawLocation,
  Route,
  RouteConfig,
} from 'vue-router';
import NotFound from '@/containers/errors/NotFound.vue';
import { Query } from '@/exportables/types/general.d';
import { isBusinessPage } from '@/helpers/url';
import CompetitionIndex from '@/pages/career/competitions/layouts/Index.vue';
import CompetitionShow from '@/pages/career/competitions/layouts/Show.vue';
import JobApplicationForm from '@/pages/career/job-applications/layouts/Form.vue';
import JobPositionIndex from '@/pages/career/job-positions/layouts/Index.vue';
import JobProfileShow from '@/pages/career/job-profiles/layouts/Show.vue';
import PostsIndex from '@/pages/career/posts/layouts/PostsIndex.vue';
import TaggedPostsIndex from '@/pages/career/posts/layouts/TaggedPostsIndex.vue';
import RegistrationShow from '@/pages/career/registrations/layouts/Show.vue';
import ResumeShow from '@/pages/career/resumes/layouts/Show.vue';

Vue.use(VueCompositionAPI);
Vue.use(VueRouter);

export const careerRoutes: RouteConfig[] = [
  {
    path: '/competitions',
    component: CompetitionIndex,
    name: 'competition-index',
  },
  {
    path: '/competitions/:id',
    component: CompetitionShow,
    name: 'competition-show',
    props: (route: Route) => castParams(route),
  },
  {
    path: '/competitions/:id/:slug',
    component: CompetitionShow,
    name: 'competition-show-with-slug',
    props: (route: Route) => castParams(route),
  },
  {
    path: '/competitions/:competitionId/registrations/new',
    name: 'job-application-form',
    component: JobApplicationForm,
    props: (route: Route) => ({
      ...castParams(route),
      action: 'new',
      type: 'Competition',
    }),
  },
  {
    path: '/competitions/:competitionId/registrations/:id',
    name: 'registration-show',
    component: RegistrationShow,
    props: (route: Route) => ({
      id: parseInt(route?.params?.id),
    }),
  },
  {
    path: '/competitions/:competitionId/registrations/:registrationsId/edit',
    name: 'competition-show-registration-edit',
    component: JobApplicationForm,
    props: (route: Route) => ({
      ...castParams(route),
      action: 'edit',
      type: 'Competition',
    }),
  },
  {
    path: '/competitions/:competitionId/resume/:resumeId',
    component: ResumeShow,
    name: 'competition-resume-show',
    props: (route: Route) => ({
      id: parseInt(route?.params?.resumeId),
      isEditable: false,
    }),
  },
  {
    path: '/job',
    component: JobPositionIndex,
    name: 'job-position-index',
  },
  {
    path: '/job_applications/:id',
    component: () => import('@/pages/career/job-applications/layouts/Show.vue'),
    name: 'job-application-show',
    props: castParams,
  },
  {
    path: '/job_positions',
    redirect: { name: 'job-position-index' },
  },
  {
    path: '/job_positions/:jobPositionId/job_applications/new',
    component: JobApplicationForm,
    name: 'job-application-new',
    props: (route: Route) => ({
      ...castParams(route),
      action: 'new',
      type: 'JobPosition',
    }),
  },
  {
    path: '/job_profiles/edit',
    component: JobProfileShow,
    name: 'job-profile-edit',
    props: {
      isEditable: true,
    },
  },
  {
    path: '/job_profiles/my',
    name: 'job-profile-my',
    component: JobProfileShow,
  },
  {
    path: '/job_profiles/public_setting',
    component: JobProfileShow,
    name: 'job-profile-public-setting',
    props: {
      isEditable: false,
      isSharable: true,
    },
  },
  {
    path: '/job_profiles/:id',
    name: 'job-profile-show',
    component: JobProfileShow,
    props: castParams,
  },
  {
    path: '/pr/:coverName',
    component: JobProfileShow,
    name: 'job-profile-public',
    props: (route: Route) => ({
      ...castParams(route),
      isEditable: false,
      isSharable: true,
    }),
  },
  {
    path: '/resumes/:id',
    name: 'resume-show',
    component: ResumeShow,
    props: (route: Route) => ({
      ...castParams(route),
      isEditable: false,
    }),
  },
  {
    path: '/resumes/:id/edit',
    name: 'resume-edit',
    component: ResumeShow,
    props: (route: Route) => ({
      ...castParams(route),
      isEditable: true,
    }),
  },
  {
    path: '/resumes/:id/print',
    name: 'resume-print',
    component: ResumeShow,
    props: (route: Route) => ({
      ...castParams(route),
      isPrintPage: true,
    }),
  },
  {
    path: '/posts',
    name: 'posts-index',
    component: PostsIndex,
  },
  {
    path: '/posts/tag/:tag',
    name: 'tagged-posts-index',
    component: TaggedPostsIndex,
  },
];

export const businessCareerRoutes = [
  {
    path: '/bookmarks',
    namespace: 'business-bookmark-index',
    component: () => import('@/pages/career/business/bookmarks/layouts/Index'),
  },
  {
    path: '/competitions/:competitionId/resume/:resumeId',
    component: ResumeShow,
    name: 'competition-resume-show',
    props: (route: Route) => ({
      id: parseInt(route?.params?.resumeId),
      isEditable: false,
    }),
  },
  {
    path: '/job_applications/:id',
    component: () => import('@/pages/career/business/job-applications/layouts/Show.vue'),
    name: 'business-job-application-show',
    props: castParams,
  },
  {
    path: '/job_profile_offers',
    component: () => import('@/pages/career/business/job-offers/layouts/Index'),
    name: 'business-job-profile-offer-index',
  },
  {
    path: '/job_profiles',
    name: 'business-job-profile-index',
    component: () => import('@/pages/career/business/job-profiles/layouts/Index'),
  },
  {
    path: '/job_profiles/:id',
    name: 'job-profile-show',
    component: () => import('@/pages/career/business/job-profiles/layouts/Show.vue'),
    props: castParams,
  },
  {
    path: '/resumes/:id/print',
    name: 'resume-print',
    component: ResumeShow,
    props: (route: Route) => ({
      ...castParams(route),
      isPrintPage: true,
    }),
  },
];

type ErrorHandler = (err: Error) => void;
type Args = [RawLocation, Function?, ErrorHandler?];

const { push, replace } = VueRouter.prototype;

VueRouter.prototype.push = function (...args: Args) {
  return catchNavigationError(push, args, this);
};

VueRouter.prototype.replace = function (...args: Args) {
  return catchNavigationError(replace, args, this);
};

const catchNavigationError = (
  method: Function,
  args: Args,
  thisArg: VueRouter,
) => (
  Promise
    .resolve(method?.apply(thisArg, args))
    .catch((error: Error) => {
      if (error.name !== 'NavigationDuplicated') throw error;
    })
);

export const router = new VueRouter({
  mode: 'history',
  routes: [
    ...(isBusinessPage() ? businessCareerRoutes : careerRoutes),
    {
      path: '/404',
      name: 'not-found',
      component: NotFound,
    },
    {
      path: '/:pathMatch(.*)*',
      component: NotFound,
    },
  ],
});

export const route = reactive({ ...router.currentRoute });
const routeData = reactive<Pick<Route, 'params' | 'query'>>({ params: {}, query: {} });

router.afterEach((to) => {
  Object.assign(route, to);
  routeData.params = route.params;
  routeData.query = route.query;
});

const currentPage = computed(() => Number(routeData.query?.page || 1));

export const useRouter: () => VueRouter = () => router;
export const useRoute = (): Route => route;
export const usePage = () => currentPage;

function castParams(route: Route) {
  const { id, ...rest } = route.params;
  return {
    id: parseInt(id),
    ...rest,
  };
}

export const setPage = (page: string | number) => {
  const query = { ...route.query, page: (page || currentPage.value).toString() };
  router.replace({ query });
};

export const cleanQuery = (query: Query) => (
  Object.entries(query).reduce(
    (acc, [key, value]) => (
      Object.assign(
        acc,
        (Array.isArray(value) ? value.length : value)
          ? { [key]: value }
          : {},
      )
    ),
    {},
  )
);
