<template>
  <div class="search-select single">
    <div class="form-control">
      <ul class="select-list">
        <li class="input">
          <SearchInput :autofocus="autofocus" :placeholder="placeholder" />
        </li>
      </ul>
    </div>

    <SearchResult>
      <template #search-item="{ item }">
        <slot name="search-item" :item="item"></slot>
      </template>

      <template #no-item="{ item }">
        <slot name="no-item" :item="item"></slot>
      </template>

      <template #footer="{ item }">
        <slot name="footer" :item="item"></slot>
      </template>
    </SearchResult>
  </div>
</template>

<script>
import {
  defineComponent,
  provide,
} from '@vue/composition-api';
import SearchInput from './SearchInput.vue';
import SearchResult from './SearchResult.vue';

export default defineComponent({
  name: 'SingleSelect',
  components: {
    SearchInput,
    SearchResult,
  },
  props: {
    autofocus: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    search: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { search } = props;
    provide('search', search);
  },
});
</script>
