import { DefaultDate } from '../../types/general.d';
import { JobApplication } from './job-application.model';
import { JobPosition, JobPositionCompany } from './job-position.model';
import { JobProfile } from './job-profile.model';
import { Resume } from './resume.model';
import { Tag } from './tag.model';

export class JobOffer {
  id: number = -1;
  appliedAt: DefaultDate;
  isTopProgrammers: boolean = false;
  jobApplication?: JobOfferApplication;
  jobPosition: JobOfferPosition = {
    id: -1,
    address: '',
    career: '',
    company: JobPositionCompany.new(),
    jobCategories: [],
    status: 'hidden',
    signingBonus: 0,
    technicalTags: [],
    title: '',
  };
  jobProfile?: JobProfile;
  message: string;
  offeredAt: DefaultDate;
  offerRejectedAt: DefaultDate;
  offerRevokedAt: DefaultDate;
  rejectMessages?: {
    etc?: string,
    options: (keyof typeof rejectMessages)[],
  };
  revokeAt?: DefaultDate; // 만료 예정 시각
  status: Status;
  remainingDay: number;

  constructor(jobOffer: Partial<JobOffer>) {
    Object.assign(this, jobOffer);
  }

  static new(jobOffer: Partial<JobOffer> = {}) {
    return new JobOffer(jobOffer);
  }
}

export type JobOfferApplication = Pick<JobApplication,
| 'id'
| 'appliedAt'
| 'customFeeRate'
| 'canceledAt'
| 'hiredAt'
| 'inProgressAt'
| 'midwayRejectedAt'
| 'rejectedAt'
| 'revokedAt'
| 'previousStatus'
| 'status'>;

export type JobOfferPosition = Pick<JobPosition,
| 'id'
| 'address'
| 'career'
| 'company'
| 'signingBonus'
| 'status'
| 'technicalTags'
| 'title'
> & {
  jobCategories: Tag[],
};

export type JobOfferProfile = Pick<JobProfile, 'id' | 'blockedCompaniesAttributes' | 'isActive'> & {
  resume: Pick<Resume, 'name'>,
};

export const statuses = ['offered', 'applied', 'offer_rejected', 'offer_revoked'] as const;

export type Status = typeof statuses[number];

export const rejectMessages = {
  1: 'job_category',
  2: 'plan',
  3: 'negotiation',
  4: 'working_condition',
} as const;

export const RECENT_LIMIT_COUNT = 3;
