<script lang="tsx">
import {
  PropType,
  computed,
  defineComponent,
  inject,
  unref,
  useCssModule,
} from '@vue/composition-api';
import { Type } from '../resume.util';
import Modal from '@/components/shared/Modal2.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { tooltip } from '@/directives';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';
import { useModal } from '@/helpers/modal';
import { useStore } from '@/store/career';

export default defineComponent({
  name: 'ResumeHoverBtnWrpper',
  components: {
    Modal,
    SvgIcon,
  },
  directives: {
    tooltip,
  },
  props: {
    deleteItem: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    isDeletable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String as PropType<Type>,
      required: true,
    },
  },
  setup(props) {
    const changeEditState = inject<Function>('changeEditState')!;
    const I18n = useI18n();
    const { hide, show } = useModal();
    const style = useCssModule();
    const store = useStore();
    const isLoadable = computed(() => store.state.resume.isEditable);
    const isEditing = computed(() => store.getters['resume/isEditing']);

    const DeleteModal = () => (
      <Modal
        title={I18n.t('resume.messages.delete_item', {
          type: I18n.t(`resume.${props.type === 'attachment' ? 'attachment.title' : props.type}`),
        })}
        description={I18n.t('resume.messages.delete_item_confirm', {
          type: I18n.t(`resume.${props.type === 'attachment' ? 'attachment.title' : props.type}`),
        })}
        onHide={hide}
        scopedSlots={{
          footer: () => [
            <button class="btn btn-md btn-light" onClick={hide}>
              {I18n.t('actions.cancel')}
            </button>,
            <button class="btn btn-md btn-danger" onClick={handleDeleteItem}>
              {I18n.t('actions.remove')}
            </button>,
          ],
        }}
      />
    );

    const handleClickEditButton = () => {
      if (unref(isEditing)) {
        appendToast(I18n.t('resume.messages.save_first'));
        return;
      }

      changeEditState(true);
    };

    const handleClickDeleteButton = () => {
      if (unref(isEditing)) {
        appendToast(I18n.t('resume.messages.save_first'));
        return;
      }

      show(DeleteModal);
    };

    const handleDeleteItem = () => {
      props.deleteItem();
      hide();
    };

    return () => isLoadable.value && (
      <div class={style.container}>
        {props.isEditable ?
          <button
            v-tooltip={I18n.t('actions.edit')}
            class={[style.btn, style.edit]}
            onClick={handleClickEditButton}
          >
            <SvgIcon name="ic-title-edit" html-class="ic-16" />
          </button> :
          null
        }

        {props.isDeletable ?
          <button
            v-tooltip={I18n.t('actions.remove')}
            class={[style.btn, style.delete]}
            onClick={handleClickDeleteButton}
          >
            <SvgIcon name="ic-cancel" html-class="ic-16" />
          </button> :
          null
        }
      </div>
    );
  },
});
</script>

<style lang="scss" module>
@import "~/stylesheets/display";
@import "~/stylesheets/functions";
@import "~/stylesheets/variables";

.container {
  @extend .flex-start;
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
}

.btn {
  width: rem-calc(24);
  height: rem-calc(24);
  border: none;
  border-radius: $border-radius-sm;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.edit {
  background-color: #{$color-text--primary}10;

  svg {
    fill: $color-text--primary;
  }
}

.delete {
  background-color: $red-50;
  margin-left: rem-calc(8);

  svg {
    fill: $color-danger !important;
  }
}
</style>
