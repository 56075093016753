import {
  Ref,
  ref,
  unref,
  watch,
} from '@vue/composition-api';
import PhoneNumber from 'awesome-phonenumber';

export const usePhoneNumber = (
  phone: Ref<string | number>,
  countryCode: string | Ref<string> = 'KR',
) => {
  const code = typeof countryCode === 'string' ? () => countryCode : countryCode;
  const phoneNumber = ref(unref(phone));

  const isValid = (value: string) => new PhoneNumber(value, unref(countryCode)).isValid();

  watch(
    [phone, code],
    ([phone, code]) => (phoneNumber.value = new PhoneNumber(phone.toString(), unref(code).toString()).getNumber('national')),
  );

  return {
    isValid,
    phoneNumber,
  };
};
