import { Resume, ResumeCategory, ResumeCertificate } from '@/exportables/models/career/resume.model';
import constants from '@/pages/career/resumes/resume.constant';

const {
  ADMIN,
  BUSINESS,
  PRINT,
  PUBLIC,
  USER,
} = constants;

const state: ResumeStoreState = {
  editingPartCount: {
    activities: 0,
    awards: 0,
    educations: 0,
    experiences: 0,
    foreignLanguages: 0,
    projects: 0,
    publications: 0,
  },
  isEditable: false,
  isSharable: false,
  clickedTagName: '',
  mouseOveredTagName: '',
  pageType: 'user',
  resume: Resume.new(),
  resumeCertificates: [],
};

export default state;

export type ResumeStoreState = {
  editingPartCount: EditingPartCount,
  isEditable: boolean,
  isSharable: boolean,
  clickedTagName: string,
  mouseOveredTagName: string,
  pageType: PageType,
  resume: Resume,
  resumeCertificates: ResumeCertificate[],
};

const pageType = [ADMIN, BUSINESS, PRINT, PUBLIC, USER] as const;
export type PageType = typeof pageType[number];

export type EditingPartCount = {
  [key in ResumeCategory]: number;
};
