<template>
  <div class="form-group form-experience">
    <button
      type="button"
      class="btn btn-block btn-outline-light btn-filter dropdown-toggle"
      data-toggle="dropdown"
      aria-expanded="false"
    >
      {{ I18n.t('job_position.list.search_filter.min_career.title') }}
    </button>

    <div class="dropdown-menu dropdown-filter dropdown-menu-left">
      <div class="row justify-content-center">
        <div class="dropdown-item-wrapper">
          <div class="dropdown-item">
            <label class="min_career_label">
              <input v-model="minCareer" type="radio" value="" name="minCareer">
              {{ I18n.t('job_position.list.search_filter.total') }}
            </label>
          </div>
          <div class="dropdown-item">
            <label class="min_career_label">
              <input v-model="minCareer" type="radio" value="0" name="minCareer">
              {{ I18n.t('job_position.list.search_filter.min_career.newcomer') }}
            </label>
          </div>

          <div v-for="year in 9" :key="year" class="dropdown-item">
            <label class="min_career_label" @click.stop>
              <input v-model="minCareer" type="radio" :value="year" name="minCareer">
              {{ I18n.t('job_position.list.search_filter.min_career.label', { min_career: year }) }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  inject,
} from '@vue/composition-api';
import { FilterState } from '../../hooks/filter';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'JobPositionMinCareerFilter',
  setup() {
    const { minCareer } = inject<FilterState>('filter')!;
    const I18n = useI18n();

    return {
      I18n,
      minCareer,
    };
  },
});
</script>

<style lang="scss" scoped>
  @import "~stylesheets/variables";

.dropdown-menu {
  padding: 0.5rem 1.375rem;
  min-width: 100%;
  .dropdown-item {
    text-align: left;
  }
  .dropdown-item-wrapper {
    column-count: 2;
    @media ($sm-down) {
      column-count: 1;
    }
    .dropdown-item {
      padding: 0.125rem 1rem;
      @media ($lg-down) {
        padding: 0.125rem 0.5rem;
      }
    }
  }
}
</style>
