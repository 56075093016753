<template>
  <div
    class="btn-toolbar"
    :class="style.container"
    :style="position">
    <span v-tooltip:[tooltipPosition]="tooltipTitle">
      <slot name="body"></slot>
    </span>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  useCssModule,
} from '@vue/composition-api';
import constants from '@/constant/format';
import { tooltip } from '@/directives';
import { getPosition } from '@/helpers/style';

const POSITION_SET = new Set(constants.POSITION);
const ABSOLUTE_POSITION_SET = new Set(constants.ABSOLUTE_POSITION);

export default defineComponent({
  name: 'FloatingButton',
  directives: {
    tooltip,
  },
  props: {
    buttonPosition: {
      type: String,
      default: 'top right',
      validator: (value: string) => ABSOLUTE_POSITION_SET.has(value),
    },
    tooltipPosition: {
      type: String,
      default: 'left',
      validator: (value: string) => POSITION_SET.has(value),
    },
    tooltipTitle: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const position = computed(() => getPosition(props.buttonPosition, 40));
    const style = useCssModule();

    return {
      position,
      style,
    };
  },
});
</script>

<style lang="scss" module>
@import "~/stylesheets/functions";
@import "~/stylesheets/variables";

.container {
  margin: 0;
  position: fixed;
  z-index: 100;
  height: fit-content;

  @media ($xxl-down) {
    top: unset;
    bottom: rem-calc(40);
  }

  @media ($md-down) {
    display: none;
  }
}
</style>
