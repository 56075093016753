import { reactive } from '@vue/composition-api';
import { fetchCurrentUser } from '@/exportables/apis/user.api';
import { User } from '@/exportables/models/user.model';

const user = reactive<User>(User.new());

const initialize = async () => {
  Object.assign(user, await fetchCurrentUser());
};

export const useCurrentUser = () => {
  return {
    user,
    initialize,
  };
};
