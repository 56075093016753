<template>
  <section class="resume-card" :class="{ shrinked: !isEditable && isShrinked }">
    <div class="resume-card-header">
      <div class="resume-card-left">
        <slot name="title">
          <h4 class="resume-card-header-title">
            {{ title }}
          </h4>
        </slot>
      </div>

      <div class="resume-card-right">
        <template v-if="isEditable">
          <slot name="control">
            <button class="btn-light resume-card-btn-add" @click="handleAddItem">
              <SvgIcon name="ic-add" html-class="ic-24" />
              <span>
                {{ I18n.t('actions.add') }}
              </span>
            </button>
          </slot>
        </template>
      </div>
    </div>

    <div class="resume-card-body">
      <slot name="body"></slot>
    </div>

    <div class="resume-card-footer">
      <slot name="footer"></slot>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'ResumeCard',
  components: {
    SvgIcon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isShrinked: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['add-item'],
  setup(_, { emit }) {
    const I18n = useI18n();

    const handleAddItem = () => emit('add-item');

    return {
      I18n: { t: I18n.t },
      handleAddItem,
    };
  },
});
</script>
