<template>
  <div v-if="isLoading">
    <template v-if="result">
      <RegistrationCompleted
        v-if="type === 'Competition'"
        :resume="state.response.resume"
        :competition="result.competition"
        :registration="result.registration"
        :location="result.location"
        :user="user"
      />

      <ApplicationCompleted
        v-if="type === 'JobPosition'"
        :resume="state.response.resume"
        :company="result.company"
        :job-position="result.jobPosition"
        :location="result.location"
        :tag-manager="result.tagManager"
        :user="user"
      />
    </template>

    <div v-else class="content-application">
      <a :href="backPath" class="btn btn-svg btn-back">
        <SvgIcon name="ic-keyboard-arrow-left" html-class="ic-24" />
        <span>{{ I18n.t('job_application.form.go_back') }}</span>
      </a>

      <div class="container-sm">
        <FormHeader
          :title="applicationState.title"
          :current-step-index="currentStepIndex"
          :steps="steps"
        />

        <form id="application-form" @submit.prevent>
          <component
            v-bind="applicationState"
            :is="componentName"
            ref="component"
            :response="state.response"
            :preferences="preferences"
            :job-positions="state.jobPositions"
            :action="action"
            @update:updateRegistrationGroup="updateRegistrationGroup"
            @update:updateSelectedJobPositions="updateSelectedJobPositions"
            @update:updateSelectedPreference="updateSelectedPreference"
            @update:updateSelectedResume="updateSelectedResume"
            @update:updateCustomFormAnswers="updateCustomFormAnswers"
            @update:updateSubjectiveAnswer="updateSubjectiveAnswer"
          />
          <div class="control-wrap">
            <h6
              v-show="lastStepIndex === currentStepIndex && isNew"
              class="mb-4"
            >
              지원을 완료한 후에는 제출한 이력서를 수정할 수 없습니다.
              제출하시겠습니까?
            </h6>
            <button
              v-show="currentStepIndex !== 0"
              type="button"
              class="btn btn-default"
              @click="prev"
            >
              {{ I18n.t('terms.prev') }}
            </button>
            <button
              v-show="lastStepIndex > currentStepIndex"
              type="button"
              class="btn btn-primary btn-next"
              @click="next"
            >
              {{ I18n.t('terms.next') }}
            </button>
            <button
              v-show="lastStepIndex === currentStepIndex"
              type="button"
              class="btn btn-primary"
              @click="onComplete"
              data-hackle-vaule="career_competition_registrations_completed"
            >
              {{ I18n.t('terms.submit') }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  reactive,
  onMounted,
  ref,
  toRefs,
  PropType,
} from '@vue/composition-api';
import ApplicationCompleted from '../components/form/ApplicationCompleted.vue';
import ContactStep from '../components/form/ContactStep.vue';
import CustomFormStep from '../components/form/CustomFormStep.vue';
import FormHeader from '../components/form/Header.vue';
import JobPositionStep from '../components/form/JobPositionStep.vue';
import PreferenceStep from '../components/form/PreferenceStep.vue';
import PreviewStep from '../components/form/PreviewStep.vue';
import RegistrationCompleted from '../components/form/RegistrationCompleted.vue';
import SelectResumeStep from '../components/form/SelectResumeStep.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import {
  createJobApplication,
  createRegistration,
  updateRegistration,
  fetchRegistrationJobPositions,
} from '@/exportables/apis/career/job-application.api';
import { fetchJobPosition } from '@/exportables/apis/career/job-position.api';
import { fetchCompetitionsRegistrations, fetchRegistration } from '@/exportables/apis/career/registration.api';
import { fetchCurrentUser } from '@/exportables/apis/user.api';
import { Type, Action, ApplicationState, State } from '@/exportables/models/career/job-application.model';
import { JobPosition } from '@/exportables/models/career/job-position.model';
import { Resume } from '@/exportables/models/career/resume.model';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';
import { useModal } from '@/helpers/modal';
import { useRouter } from '@/hooks/useRouter';
import { computeSteps } from '@/pages/career/job-applications/job-application.util';
import camelCase from '@/plugins/camelCase';

export default defineComponent({
  name: 'JobApplicationForm',
  components: {
    ApplicationCompleted,
    ContactStep,
    CustomFormStep,
    FormHeader,
    JobPositionStep,
    PreviewStep,
    RegistrationCompleted,
    SelectResumeStep,
    SvgIcon,
    PreferenceStep,
  },
  props: {
    type: {
      type: String as PropType<Type>,
      default: null,
    },
    jobPositionId: {
      type: String,
      default: null,
    },
    competitionId: {
      type: String,
      default: null,
    },
    registrationsId: {
      type: String,
      default: null,
    },
    action: {
      type: String as PropType<Action>,
      default: null,
    },
  },
  setup(props) {
    const I18n = useI18n();
    const router = useRouter();
    const modal = useModal();

    const { type, jobPositionId, competitionId, registrationsId, action } = toRefs(props);

    const applicationState = reactive<ApplicationState>({
      id: 0,
      title: '',
      careerRange: '',
      minCareerRequired: false,
      resumeRequired: false,
      allowedDomains: [],
      restrictedDomains: [],
      multiAppliable: true,
      applicationLimit: 0,
      customFormGroupDescription: '',
      customForms: [],
      registrationGroups: [],
      registrationGroupId: null,
      hasJobPosition: false,
      isPreference: false,
      autoSelected: false,
      generalAuthenticate: false,
    });

    const user = ref(null);

    const steps = ref<Record<number, string>>({});
    const preferences = ref<number[]>([]);
    const isLoading = ref(false);
    const component = ref(null);
    const currentStepIndex = ref(0);
    const result = ref(null);

    const state: State = reactive({
      response: {
        resume: null,
        customFormAnswers: {},
        jobPositions: [],
        privacyPolicy: [],
        registrationGroupId: null,
      },
      jobPositions: [],
    });

    onMounted(async () => {
      user.value = await fetchCurrentUser();

      if (type.value === 'JobPosition') {
        const fetchedJobPositionData: JobPosition = (await fetchJobPosition(Number(jobPositionId.value))).jobPosition;
        Object.assign(applicationState, fetchedJobPositionData);
      } else if (type.value === 'Competition') {
        const fetchedData = await fetchCompetitionsRegistrations(Number(competitionId.value));
        const { competition: fetchedCompetitionData, authenticatedInfo: fetchedAuthenticatedInfo } = fetchedData;
        Object.assign(applicationState, fetchedCompetitionData);

        if (!applicationState.resumeRequired && applicationState.generalAuthenticate) {
          Object.assign(state.response, {
            resume: fetchedAuthenticatedInfo,
          });
        }

        applicationState.customForms = applicationState.customForms ? JSON.parse(fetchedCompetitionData.customForms) : [];
        applicationState.registrationGroups = applicationState.registrationGroups ? JSON.parse(fetchedCompetitionData.registrationGroups) : [];

        updateRegistrationGroup(applicationState.registrationGroupId);
      }

      steps.value = computeSteps(
        applicationState.resumeRequired,
        hasCustomForm.value,
        applicationState.hasJobPosition,
        applicationState.isPreference,
        props.action,
      );

      if (!isNew.value) {
        const fetchedRegistrationData = await fetchRegistration(Number(registrationsId.value));

        preferences.value = fetchedRegistrationData.registration.jobPositions!.map((jobPosition) => jobPosition.id);
        state.response.registrationGroupId = fetchedRegistrationData.registration.registrationGroup!.id || null;
        state.response.resume = fetchedRegistrationData.resume;
        await setJobPositions(state.response.registrationGroupId);

        if (applicationState.autoSelected) state.response.jobPositions = state.jobPositions.map((jobPosition) => jobPosition.id);
        else state.response.jobPositions = [...preferences.value];
      }

      isLoading.value = true;
    });

    const applyConfirmUrl = computed(() => [...(router as any).history.current.path.split('/').slice(0, -1), 'apply_confirm'].join('/'));
    const backPath = computed(() => (router as any).history.current.path.split('/').slice(0, 3).join('/'));
    const currentStep = computed(() => steps.value[currentStepIndex.value]);
    const componentName = computed(() => `${camelCase(currentStep.value, { camelCase: true })}Step`);
    const hasCustomForm = computed(
      () => applicationState.customForms && applicationState.customForms.length > 0,
    );
    const isNew = computed(() => action.value === 'new');
    const lastStepIndex = computed(() => Object.keys(steps.value).length - 1);

    const openModal = async (modalName: string, props: { [key: string]: string }) => {
      const { default: Component } = await import(`../components/modal/${modalName}.vue`);
      modal.show(Component, props);
    };

    const updateCustomFormAnswers = (answerId: number, value: string | string[]) => {
      if ((typeof (value) === 'object' && (value.length === 0 || value.filter((v) => v !== '').length === 0)) || value === '') {
        delete state.response.customFormAnswers[answerId];
      } else state.response.customFormAnswers[answerId] = value;
    };

    const updateSelectedJobPositions = (jobPositions: number[]) => {
      state.response.jobPositions = jobPositions;
      updateSelectedPreference([]);
    };

    const updateSelectedPreference = (selectedPreferences: number[]) => {
      preferences.value = selectedPreferences;
    };

    const updateSubjectiveAnswer = (answerId: number, keys: string | string[], value: { [key: string]: string }) => {
      if ((typeof (keys) === 'object' && keys.length === 0) && state.response.customFormAnswers.subjective) {
        delete state.response.customFormAnswers.subjective![answerId];
      } else {
        if (state.response.customFormAnswers.subjective === undefined) {
          state.response.customFormAnswers.subjective = {};
        }
        state.response.customFormAnswers.subjective[answerId] = value;
      }
    };

    const setJobPositions = async (registrationGroupId: number | null) => {
      if (!registrationGroupId) {
        state.jobPositions = [];
        return;
      }
      const data = await fetchRegistrationJobPositions(Number(registrationGroupId));
      state.jobPositions = data;
    };

    const updateRegistrationGroup = async (registrationGroupId: number | null) => {
      state.response.registrationGroupId = registrationGroupId;
      await setJobPositions(registrationGroupId);
      if (applicationState.autoSelected) updateSelectedJobPositions(state.jobPositions.map((jobPosition) => jobPosition.id));
      else updateSelectedJobPositions([]);
    };

    const updateSelectedResume = (resume: Resume) => {
      state.response.resume = resume;
    };

    const getMinCareer = (careerRangeStr: string) => careerRangeStr.split('...')[0];

    const notSuitableSelectedResume = () => {
      if (!applicationState.minCareerRequired || !applicationState.careerRange) return false;
      const minCareer = getMinCareer(applicationState.careerRange);
      return state.response.resume!.devEmploymentDuration! < Number(minCareer) * 12;
    };
    const scrollToError = async () => {
      await nextTick();

      const $target = document.querySelector('.error-feedback');
      if ($target) {
        const adjustPixel = 150;
        window.scrollTo(0, ($target as HTMLElement).offsetTop - adjustPixel);
      }
    };

    const isValidated = () => (component.value as any).isValidated();

    const scrollToTop = () => window.scrollTo(0, 0);
    const changeStepIndex = (value: number) => currentStepIndex.value += value;
    const changeComponent = (value: number) => {
      changeStepIndex(value);
      scrollToTop();
    };
    const next = () => {
      if (lastStepIndex.value <= currentStepIndex.value) return;
      if (isValidated()) changeComponent(1);
      else scrollToError();
    };
    const prev = () => {
      if (0 >= currentStepIndex.value) return;
      changeComponent(-1);
    };
    const submit = async () => {
      const data = {
        ...state.response,
        customForm: state.response.customFormAnswers,
      };
      if (type.value === 'JobPosition') {
        const applicationResult = await createJobApplication(applicationState.id, data);
        if (applicationResult) document.body.classList.add('job-applications-completed');
        result.value = applicationResult;
      }
      if (type.value === 'Competition') {
        if (applicationState.isPreference) data.jobPositions = preferences.value;
        if (isNew.value) {
          result.value = await createRegistration(applicationState.id, data);
        } else {
          try {
            result.value = await updateRegistration(Number(registrationsId.value), data);
          } catch (error: any) {
            appendToast(error.message);
          }
          return;
        }
      }

      $('#modal-dialog').modal('hide');

      scrollToTop();
    };
    const showRejectedModal = () => {
      openModal('ImpossibleAlert', { requiredMinCareer: getMinCareer(applicationState.careerRange) });
    };
    const initApplyModalHandler = () => {
      document.querySelector('button.btn-apply')!.addEventListener('click', () => {
        const privacyPolicies = document.querySelector('#privacy-policy-ids');
        if (privacyPolicies) {
          const privacyPolicyIds = JSON.parse((privacyPolicies as HTMLInputElement).value);
          for (const id of privacyPolicyIds) {
            const value = (document.querySelector(`input[name='privacy_policy[${id}]']:checked`) as HTMLInputElement)?.value;
            const $alertMessageElement = document.querySelector('#privacy-policy-modal-alert-message');
            if (value === undefined) {
              $alertMessageElement!.classList.remove('hidden');
              $alertMessageElement!.innerHTML = I18n.t('job_application.form.check_privacy_policy');
              return;
            }
            if (document.querySelector(`#privacy-checkbox-group-${id}[required]`) && value !== 'agreed') {
              $alertMessageElement!.classList.remove('hidden');
              $alertMessageElement!.innerHTML = I18n.t('job_application.form.need_agreement');
              return;
            }
          }
        }
        const privacyPolicy = ($('#agreement-form') as any).serializeJSON().privacy_policy;
        state.response.privacyPolicy = privacyPolicy;
        submit();
      });
    };
    const loadApplyModal = () => {
      $.get(`${applyConfirmUrl.value}.js`, {
        registrationGroupId: state.response.registrationGroupId,
      }).done(() => {
        initApplyModalHandler();
      });
    };
    const onComplete = () => {
      if (notSuitableSelectedResume()) {
        showRejectedModal();
        return;
      }
      if (isNew.value) loadApplyModal();
      else submit();
    };

    return {
      I18n,
      applicationState,
      backPath,
      changeStepIndex,
      component,
      componentName,
      currentStep,
      currentStepIndex,
      getMinCareer,
      initApplyModalHandler,
      isLoading,
      isNew,
      lastStepIndex,
      loadApplyModal,
      next,
      notSuitableSelectedResume,
      onComplete,
      preferences,
      prev,
      result,
      scrollToError,
      setJobPositions,
      showRejectedModal,
      state,
      steps,
      submit,
      updateCustomFormAnswers,
      updateRegistrationGroup,
      updateSelectedJobPositions,
      updateSelectedPreference,
      updateSelectedResume,
      updateSubjectiveAnswer,
      user,
    };
  },
});
</script>

<style lang="scss" scoped>
@use "~/stylesheets/functions";

.content-application {
  width: 100%;
  max-width: functions.rem-calc(1200);
  margin: 0 auto;
}

</style>
