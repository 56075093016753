import VueCompositionAPI, {
  computed,
  unref,
} from '@vue/composition-api';
import Vue from 'vue';
import VueRouter from 'vue-router';
import PrintOptionModal from '../components/modal/PrintOption.vue';
import { ResumeShowStates, getResume } from './state';
import { useModal } from '@/helpers/modal';
import { useRouter } from '@/router/career';

Vue.use(VueCompositionAPI);
Vue.use(VueRouter);

const modal = useModal();

export const usePrint = (state: ResumeShowStates) => {
  const router = useRouter();
  const resume = computed(() => getResume(state));

  const isPrintable = computed(() => {
    const { id, printPolicy } = unref(resume);
    return Boolean(id) && printPolicy;
  });

  const handleClickPrintButton = () => {
    const unrefedResume = unref(resume);
    const flag = Boolean(unrefedResume?.salary);
    const printPath = `/resumes/${unrefedResume.id}/print`;
    flag ? modal.show(PrintOptionModal, { printPath }, undefined, { provide: { state } }) : router.push(printPath);
  };

  return {
    handleClickPrintButton,
    isPrintable,
  };
};
