<template>
  <div
    :class="{
      [style.container]: true,
      [style.profileContainer]: isProfile,
    }"
  >
    <h2 v-skeleton="isLoading">
      <template v-if="isProfile">
        {{ title }}
      </template>

      <template v-else>
        <input
          :value="title"
          :class="style.input"
          type="text"
          @input="handleUpdateTitle"
        >
      </template>
    </h2>

    <div v-skeleton="isLoading" class="flex-start" :class="{ [style.profileButtons]: isProfile }">
      <div v-if="isProfile" :class="style.toggle">
        <Toggle
          :is-active="isMatching"
          :labels="[I18n.t('resume.topbar.toggle.active')]"
          @toggle="handleToggle"
        />

        <BPopover target="match-help" placement="top" triggers="focus hover" :custom-class="style.popover">
          <template #title>
            <h5 :class="style.title">
              {{ I18n.t('job_profile.position_match.title') }}
            </h5>
          </template>

          <span :class="style.desc" v-html="I18n.t('job_profile.position_match.content')"></span>
        </BPopover>

        <a id="match-help" tabindex="0">
          <SvgIcon name="ic-help-16" html-class="ic-16 ic-blue-grey-300" />
        </a>
      </div>

      <div class="flex-end">
        <a
          href="/job_profiles/public_setting"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button
            v-if="isProfile"
            v-tooltip:bottom="I18n.t('job_profile.public.title')"
            :class="style.btn"
          >
            <SvgIcon name="ic-public" html-class="ic-24 ic-blue-grey-900" />
          </button>
        </a>

        <button
          v-tooltip:bottom="I18n.t('buttons.preview')"
          :class="style.btn"
          @click="moveToPreview"
        >
          <SvgIcon name="ic-preview" html-class="ic-24 ic-blue-grey-900" />
        </button>

        <button
          v-tooltip:bottom="I18n.t('resume.modal.import.title')"
          :class="style.btn"
          @click="openModal('Import')"
        >
          <SvgIcon name="ic-import-form" html-class="ic-24 ic-blue-grey-900" />
        </button>

        <button
          v-if="!isProfile"
          v-tooltip:bottom="I18n.t('buttons.delete')"
          :class="style.btn"
          @click="openDeleteResumeModal"
        >
          <SvgIcon name="ic-delete" html-class="ic-24 ic-blue-grey-900" />
        </button>

        <template v-if="isProfile">
          <button
            class="dropdown-toggle"
            :class="style.btn"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <SvgIcon name="ic-more-vert" html-class="ic-24" />
          </button>

          {{ isMobileLayout }}
          <ul class="dropdown-menu dropdown-menu-right">
            <a
              v-if="isMobileLayout"
              href="/job_profiles/public_setting"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li
                class="cursor-pointer dropdown-item"
                :class="style.dropdown"
              >
                {{ I18n.t('job_profile.public.title') }}
              </li>
            </a>

            <li
              class="cursor-pointer dropdown-item"
              :class="style.dropdown"
              @click="moveToPreview"
            >
              {{ I18n.t('buttons.preview') }}
            </li>

            <li
              class="cursor-pointer dropdown-item"
              :class="style.dropdown"
              @click="openModal('Import')"
            >
              {{ I18n.t('resume.modal.import.title') }}
            </li>

            <li
              class="cursor-pointer dropdown-item"
              :class="style.dropdown"
              @click="openBlockedCompaniesModal"
            >
              {{ I18n.t('job_profile.blocked_company.title') }}
            </li>
          </ul>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="tsx">
import {
  computed,
  defineComponent,
  inject,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
  unref,
  useCssModule,
  watch,
} from '@vue/composition-api';
import { BPopover } from 'bootstrap-vue';
import { AppCustomEventBus } from '../../../../helpers/app-custom-event-bus';
import { handleUpdateForm } from '../hooks/form';
import { ResumeShowStates, isProfile as isP } from '../hooks/state';
import Toggle from './Toggle.vue';
import Modal from '@/components/shared/Modal2.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { skeleton, tooltip } from '@/directives';
import {
  initialize,
  toggleMatching,
} from '@/exportables/apis/career/job-profile.api';
import { deleteResume } from '@/exportables/apis/career/resume.api';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';
import { debounce } from '@/helpers/lazy';
import { useModal } from '@/helpers/modal';
import {
  getWindowWidth,
  useHorizontalResizer,
} from '@/helpers/style';
import BlockedCompaniesModal from '@/pages/career/job-profiles/components/modal/BlockedCompanies.vue';
import { JobProfileShowState } from '@/pages/career/job-profiles/hooks/show/state';

const heraWebappCareerEventBus = new AppCustomEventBus('hera-webapp-career-event-bus');

export default defineComponent({
  name: 'ResumeTopbar',
  components: {
    BPopover,
    SvgIcon,
    Toggle,
  },
  directives: {
    skeleton,
    tooltip,
  },
  setup() {
    const isLoading = inject('isLoading');
    const openModal = inject('openModal');
    const state = inject<ResumeShowStates>('state')!;
    const I18n = useI18n();
    const modal = useModal();
    const windowWidth = ref(getWindowWidth());
    const isMatching = ref(false);
    const isOpened = ref(false);
    const unsetResizer = useHorizontalResizer(windowWidth);
    const isProfile = computed(() => isP(state));
    const isMobileLayout = computed(() => {
      const unrefedWindowWidth = unref(windowWidth);
      return unrefedWindowWidth === 'sm-down' || unrefedWindowWidth === 'xs-down';
    });
    const title = ref(
      I18n.t(unref(isProfile) ? 'job_profile.title' : 'terms.resume'),
    );
    const isMatchPopoverOpen = ref(true);
    const style = useCssModule();

    const moveToPreview = () => {
      const unrefedIsProfile = unref(isProfile);
      const id = unrefedIsProfile ? state.jobProfile.id : state.resume.id;
      const path = unrefedIsProfile ? `/job_profiles/${id}` : `/resumes/${id}`;

      window.open(path, '_blank', 'noopener noreferrer');
    };

    const openBlockedCompaniesModal = () => modal.show(BlockedCompaniesModal);

    const openDeleteResumeModal = () => {
      const DeleteResumeModal = () => (
        <Modal
          title={I18n.t('resume.messages.delete_resume')}
          description={I18n.t('resume.messages.delete_resume_confirm')}
          onHide={modal.hide}
          scopedSlots={{
            footer: () => [
              <button class="btn btn-md btn-light" onClick={modal.hide}>
                {I18n.t('actions.cancel')}
              </button>,
              <button class="btn btn-md btn-danger" onClick={handleDelete}>
                {I18n.t('actions.remove')}
              </button>,
            ],
          }}
        />
      );

      modal.show(DeleteResumeModal);
    };

    const handleUpdateTitle = debounce(
      // 프로필이 아닌 경우에만
      async (event: InputEvent) => {
        title.value = event.target.value.trim() || I18n.t('resume.no_title');
        const unrefedTitle = unref(title);
        await handleUpdateForm(state, { title: unrefedTitle });
        (event.target as HTMLElement).blur();
      },
      500,
    );

    const handleDelete = async () => {
      const result = await deleteResume(state.resume.id);
      result && (window.location.href = '/resumes');
    };

    const handleToggle = async () => {
      const jobProileState = state as JobProfileShowState;
      const { active } = await toggleMatching(isMatching);
      if (active) {
        appendToast(I18n.t('resume.topbar.toggle.message.activated'));
      } else {
        appendToast(I18n.t('resume.topbar.toggle.message.deactivated'));
      }
      initialize(jobProileState);

      heraWebappCareerEventBus.dispatch('toggle-job-position-suggestion');
    };

    onMounted(() => {
      setTimeout(() => {
        isMatchPopoverOpen.value = false;
      }, 3000);
    });

    const handler = () => {
      const jobProileState = state as JobProfileShowState;

      initialize(jobProileState);
    };

    onMounted(() => {
      heraWebappCareerEventBus.addListener('toggle-job-position-suggestion', handler);
    });

    onUnmounted(() => {
      heraWebappCareerEventBus.removeListener('toggle-job-position-suggestion', handler);
    });

    onBeforeUnmount(unsetResizer);

    watch(
      state,
      ({
        resume,
        jobProfile,
      }) => unref(isProfile) ? (isMatching.value = jobProfile!.isActive) : (title.value = resume.title),
      { immediate: true },
    );

    return {
      I18n,
      handleDelete,
      handleToggle,
      handleUpdateTitle,
      isLoading,
      isMatching,
      isMatchPopoverOpen,
      isMobileLayout,
      isOpened,
      isProfile,
      moveToPreview,
      openBlockedCompaniesModal,
      openDeleteResumeModal,
      openModal,
      style,
      title,
    };
  },
});
</script>

<style lang="scss" module>
@use '~/stylesheets/variables';
@use '~/stylesheets/functions';
@use '~/stylesheets/display';

.container {
  @extend .flex-between;
  margin: functions.rem-calc(32 40 0);
  padding-bottom: functions.rem-calc(16);
  border-bottom: 1px solid variables.$color-border;

  h2 {
    @extend .flex-start;
    font-size: functions.rem-calc(32);
    font-weight: 700;
    line-height: functions.rem-calc(44);
    position: relative;
  }
}

.input {
  width: functions.rem-calc(400);
  font-weight: 700;
  border: none;
  border-radius: functions.rem-calc(4);
  outline: none;

  &:hover {
    background-color: variables.$blue-grey-25;
  }

  &:active,
  &:focus {
    background-color: variables.$blue-grey-10;
    border: 2px solid variables.$blue-500;
    margin: -2px;
  }
}

.toggle {
  @extend .flex-start;
  margin: functions.rem-calc(0 16);

  & > a {
    fill: variables.$color-text--primary;
    cursor: pointer;
    margin-left: functions.rem-calc(6);
  }

  label {
    margin: functions.rem-calc(0 8);
  }
}

.btn {
  @extend .flex-center;
  width: functions.rem-calc(40);
  height: functions.rem-calc(40);
  background-color: variables.$blue-grey-50;
  border-radius: functions.rem-calc(4);
  border: none;
  margin-left: functions.rem-calc(8);

  &:hover {
    background-color: variables.$blue-grey-75;
  }
}

.dropdown {
  &:not(&:last-child) {
    display: none;

    @media (variables.$sm-down) {
      display: block;
    }
  }
}

@media (variables.$lg-down) {
  .container {
    h2 {
      font-size: functions.rem-calc(28);
    }
  }
}

@media (variables.$md-down) {
  .container {
    margin: functions.rem-calc(24 0 0);
    padding: functions.rem-calc(16);
  }

  .input {
    width: 100%;
  }
}

@media (variables.$sm-down) {
  .container {
    h2 {
      font-size: functions.rem-calc(24);
      width: functions.rem-calc(150);
    }
  }

  .profileContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .profileButtons {
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .btn {
      display: none;

      &[data-toggle='dropdown'] {
        display: block;
      }
    }
  }

  .toggle {
    margin: 0;

    label {
      margin-left: 0;
    }
  }
}

.popover {
  width: functions.rem-calc(300);
  padding: functions.rem-calc(4);

  .title {
    font-size: functions.rem-calc(16);
    font-weight: 700;
    line-height: 1.4;
    color: variables.$blue-grey-700;
    margin-bottom: functions.rem-calc(-4);
  }

  .desc {
    font-size: functions.rem-calc(12);
    color: variables.$blue-grey-300;
    line-height: 1.5;
    word-break: keep-all;

    > p {
      margin: 0;

      &:first-of-type {
        margin-bottom: functions.rem-calc(4);
      }
    }
  }
}
</style>
