import { AxiosError } from 'axios';
import { useHttpClient } from '../httpClient';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';

export const JOB_APPLICATION_URL = '/job_applications';
export const REGISTRATION_URL = '/registrations';

const I18n = useI18n();
const httpClient = useHttpClient();

export const fetchJobApplication = async (id: number) => {
  try {
    const { data, status } = await httpClient.get(`${JOB_APPLICATION_URL}/${id}`);
    if (status !== 200) throw new Error(data?.message || I18n.t('error.internal'));
    return data;
  } catch (error) {
    error instanceof Error && appendToast(error.message);
    throw error;
  }
};

export async function fetchRegistrationJobPositions(registrationGroupId: number) {
  try {
    const { data, status } = await httpClient.get(`/registration_groups/${registrationGroupId}/job_positions`);
    if (status !== 200) throw new Error(data.message || I18n.t('error.internal'));
    return data;
  } catch (error) {
    error instanceof Error && appendToast(error.message);
    throw error;
  }
}

export const createJobApplication = async (id: number, payload: any) => {
  try {
    const { data, status } = await httpClient.post(JOB_APPLICATION_URL, {
      jobPositionId: id,
      ...payload,
    });
    if (status !== 200) throw new Error(data?.message || I18n.t('error.internal'));
    return data;
  } catch (error) {
    if (error instanceof Error) {
      const message = (error as AxiosError).response?.data?.message || error.message;
      appendToast(message);
    }
    throw error;
  }
};

export const createRegistration = async (id: number, payload: any) => {
  try {
    const { data, status } = await httpClient.post(REGISTRATION_URL, {
      competitionId: id,
      ...payload,
    });
    if (status !== 200)
      throw new Error(data?.message || I18n.t('error.internal'));
    return data;
  } catch (error) {
    if (error instanceof Error) {
      const message = (error as AxiosError).response?.data?.message || error.message;
      appendToast(message);
    }
    throw error;
  }
};

export const updateRegistration = async (id: number, payload: any) => {
  try {
    const { data, status } = await httpClient.patch(`${REGISTRATION_URL}/${id}/`, payload);
    if (status !== 200)
      throw new Error(data?.message || I18n.t('error.internal'));
    return data;
  } catch (error) {
    if (error instanceof Error) {
      const message = (error as AxiosError).response?.data?.message || error.message;
      appendToast(message);
    }
    throw error;
  }
};
