<template>
  <li class="list-contribution-item">
    <h5 v-if="part.title" class="part-title">
      {{ part.title }}
    </h5>

    <h6 v-if="part.startAt" class="part-period">
      {{ I18n.l('date.formats.year_month', part.startAt) }}
      {{ part.endAt && `~ ${I18n.l('date.formats.year_month', part.endAt)}` }}
    </h6>

    <MarkdownViewer
      class="markdown-viewer part-description"
      :value="part.description"
    />
  </li>
</template>

<script lang="ts">
import {
  PropType,
  defineComponent,
} from '@vue/composition-api';
import MarkdownViewer from '@/components/shared/MarkdownViewer.vue';
import { ResumePart } from '@/exportables/models/career/resume.model';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'ResumePartPreview',
  components: {
    MarkdownViewer,
  },
  props: {
    part: {
      type: Object as PropType<ResumePart>,
      required: true,
    },
  },
  setup() {
    const { l } = useI18n();

    return {
      I18n: { l },
    };
  },
});
</script>
