<template>
  <div class="list-filter-chips">
    <div
      v-if="jobCategories && jobCategories.length"
      id="job_category_id-filter-chips-group"
      class="filter-chips-group divider"
    >
      <li
        v-for="jobCategory in jobCategories"
        :key="jobCategory.id"
        class="filter-chip job_category"
      >
        <span>{{ jobCategory.name }}</span>
        <button class="btn-delete" @click="removeJobCategoryFilter(jobCategory.id)">
          <SvgIcon name="ic-cancel-plain-14" />
        </button>
      </li>
    </div>

    <div
      v-if="tags && tags.length"
      id="tag-filter-filter-chips-group"
      class="filter-chips-group divider"
    >
      <li
        v-for="tag in tags"
        :key="tag.id"
        class="filter-chip tag"
      >
        <span>{{ tag.name }}</span>
        <button class="btn-delete" @click="removeTagFilter(tag.id)">
          <SvgIcon name="ic-cancel-plain-14" />
        </button>
      </li>
    </div>

    <div
      v-if="companies && companies.length"
      id="company_id-filter-chips-group"
      class="filter-chips-group divider"
    >
      <li
        v-for="company in companies"
        :key="company.id"
        class="filter-chip company"
      >
        <span>{{ company.name }}</span>
        <button class="btn-delete" @click="removeCompanyFilter(company.id)">
          <SvgIcon name="ic-cancel-plain-14" />
        </button>
      </li>
    </div>

    <div
      v-if="minCareer"
      id="min_career-filter-chips-group"
      class="filter-chips-group divider"
    >
      <li class="filter-chip min_career">
        <span>{{ minCareerLabel }}</span>
        <button class="btn-delete" @click="removeMinCareerFilter">
          <SvgIcon name="ic-cancel-plain-14" />
        </button>
      </li>
    </div>

    <div
      v-if="minSalary"
      id="min_salary-filter-chips-group"
      class="filter-chips-group divider"
    >
      <li class="filter-chip min_salary">
        <span>
          {{ I18n.t('job_position.list.search_filter.min_salary.label', { min_salary: thousandSeparator(minSalary) }) }}
        </span>
        <button class="btn-delete" @click="removeMinSalaryFilter">
          <SvgIcon name="ic-cancel-plain-14" />
        </button>
      </li>
    </div>

    <div
      v-if="minEmployees"
      id="min_employees-filter-chips-group"
      class="filter-chips-group divider"
    >
      <li class="filter-chip min_employees">
        <span>
          {{ I18n.t('job_position.list.search_filter.min_employee.label', { min_employee: thousandSeparator(minEmployees) }) }}
        </span>
        <button class="btn-delete" @click="removeMinEmployeesFilter">
          <SvgIcon name="ic-cancel-plain-14" />
        </button>
      </li>
    </div>

    <div
      v-if="locations && locations.length"
      id="location-filter-chips-group"
      class="filter-chips-group divider"
    >
      <li v-for="location in locations" :key="location" class="filter-chip location">
        <span>{{ I18n.t(`company.location.${location}`) }}</span>
        <button class="btn-delete" @click="removeLocationFilter(location)">
          <SvgIcon name="ic-cancel-plain-14" />
        </button>
      </li>
    </div>

    <div v-if="jobThemes.length" class="filter-chips-group divider">
      <li
        v-for="jobTheme in jobThemes"
        :key="jobTheme.id"
        class="filter-chip job_theme"
      >
        <span>{{ jobTheme.name }}</span>
        <button class="btn-delete" @click="removeJobThemeFilter(jobTheme.id)">
          <SvgIcon name="ic-cancel-plain-14" />
        </button>
      </li>
    </div>

    <button
      v-if="hasFilters"
      class="filter-chip reset"
      type="button"
      @click="resetFilter"
    >
      {{ I18n.t('job_position.list.search_filter.reset_filter') }}
    </button>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  unref,
} from '@vue/composition-api';
import { FilterState } from '../../hooks/filter';
import { useStore } from '../../hooks/store';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { thousandSeparator } from '@/exportables/utils/number.util';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'JobPositionFilterChipList',
  components: {
    SvgIcon,
  },
  setup() {
    const filter = inject<FilterState>('filter')!;
    const {
      jobCategoryIds,
      jobThemeIds,
      minCareer,
    } = filter;
    const I18n = useI18n();
    const store = useStore();
    const jobCategories = computed(() => store.state.jobCategories.filter((jobCategory) => unref(jobCategoryIds)?.includes(jobCategory.id)));
    const jobThemes = computed(() => store.state.jobThemes.filter((theme) => unref(jobThemeIds)?.includes(theme.id)));
    const minCareerLabel = computed(() => (
      unref(minCareer) === '0'
        ? I18n.t('job_position.list.search_filter.min_career.newcomer')
        : I18n.t('job_position.list.search_filter.min_career.label', { min_career: unref(minCareer) })
    ));

    return {
      I18n,
      ...filter,
      jobCategories,
      jobThemes,
      minCareerLabel,
      thousandSeparator,
    };
  },
});
</script>

<style lang="scss" scoped>
.list-filter-chips{
  .filter-chip {
    display: inline-flex;
  }

  .filter-chips-group.divider ~ .filter-chips-group.divider:before {
    content: none;
  }
}
</style>
