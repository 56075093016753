import { Resume } from './resume.model';
import { Tag } from './tag.model';

export class JobProfile {
  id: number = -1;
  blockedCompaniesAttributes?: BlockedCompany[] = [];
  isActive: boolean = false; // 프로필 매칭용
  isOpened: boolean = false; // 공개 프로필 여부
  isTopProgrammers: boolean;
  jobCategories: Tag[] = []; // 백엔드에는 resume 모델의 job_position_category_ids 어트리뷰트 명으로 존재
  openSetting: OpenSetting = {
    activities: 'disabled',
    awards: 'disabled',
    blog: 'disabled',
    certificates: 'disabled',
    email: 'disabled',
    educations: 'disabled',
    experiences: 'disabled',
    foreignLanguages: 'disabled',
    introduction: 'disabled',
    phone: 'disabled',
    primaryTags: 'disabled',
    projects: 'disabled',
    publications: 'disabled',
    repository: 'disabled',
    resumeAttachment: 'disabled',
    salary: 'disabled',
    secondaryTags: 'disabled',
    testTokens: 'disabled',
  };
  resume: Resume = Resume.new();

  constructor(jobProfile: Partial<JobProfile> = {}) {
    const { jobCategories, resume } = jobProfile;

    Object.assign(
      this,
      jobProfile,
      jobCategories && (this.jobCategories = jobCategories.map((tag) => Tag.new(tag))),
      resume && { resume: Resume.new(resume) },
    );
  }

  static new(jobProfile?: Partial<JobProfile>) {
    return new JobProfile(jobProfile);
  }
}

export type BlockedCompany = {
  id: number,
  name: string,
  homeUrl?: string,
};

export type Open = 'enabled' | 'disabled';

export type OpenSetting = {
  activities: Open,
  awards: Open,
  certificates: Open,
  blog: Open,
  email: Open,
  educations: Open,
  experiences: Open,
  foreignLanguages: Open,
  introduction: Open,
  phone: Open,
  primaryTags: Open,
  projects: Open,
  publications: Open,
  repository: Open,
  resumeAttachment: Open,
  salary: Open,
  secondaryTags: Open,
  testTokens: Open,
};
