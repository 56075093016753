<template>
  <div class="resume-card-item form">
    <div class="form-group-wrap">
      <label class="form-group-title">
        {{ I18n.t('resume.experience') }} {{ index + 1 }}
      </label>

      <div class="form-row">
        <div class="col-9">
          <div class="form-group form-required">
            <label>
              {{ I18n.t('section.experience.name') }}
            </label>

            <SingleSelect
              :class="inputValidationClass($v.name)"
              :search="companySearch"
              :placeholder="I18n.t('section.experience.placeholder.name')"
              @blur="$v.name.$touch"
            >
              <template #search-item="{ item }">
                <div class="company-wrap">
                  <span class="company-icon">
                    <SvgIcon name="ic-domain" html-class="ic-18" />
                  </span>
                  <!-- <img :src="item.logoUrl || defaultImage" class="company-logo"> --><!-- TODO: indexed_companies와 companies가 연동이 많이 될 경우 수정 -->
                  <div class="company-info">
                    <p>
                      {{ item.name }}
                    </p>
                    <!-- <p>{{ item.homeUrl }}</p> -->
                  </div>
                </div>
              </template>

              <template #no-item="{ item }">
                <div class="company-wrap">
                  <span class="company-icon">
                    <SvgIcon name="ic-domain" html-class="ic-18" />
                  </span>
                  <!-- <img :src="defaultImage" class="company-logo"> -->
                  <span class="company-highlight"> '{{ item.name }}' </span>
                  {{ I18n.t('resume.messages.company.no_item') }}
                </div>
              </template>

              <template #footer="{ item }">
                <span class="company-highlight"> '{{ item.name }}' </span>
                <span style="margin-left: 4px">
                  {{ I18n.t('resume.messages.company.direct_input') }}
                </span>
              </template>
            </SingleSelect>
          </div>
        </div>

        <div class="col-3 flex-between">
          <div class="pipe-wrap">
            <div class="pipe"></div>
          </div>

          <div
            class="form-group non-dev-experience"
            @click="
              newExperience.developmentUnrelated =
                !newExperience.developmentUnrelated
            "
          >
            <input
              v-model="newExperience.developmentUnrelated"
              type="checkbox"
            >
            <label>
              {{ I18n.t('section.experience.non_dev_related.label') }}
            </label>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group form-required">
            <label>
              {{ I18n.t('section.experience.role') }}
            </label>

            <input
              v-model="newExperience.role"
              type="text"
              class="form-control"
              :class="inputValidationClass($v.role)"
              :placeholder="I18n.t('section.experience.placeholder.role')"
              autocomplete="off"
              @blur="$v.role.$touch"
            >
          </div>
        </div>

        <div class="col-date col-6">
          <Datepicker
            :value="newExperience.startAt"
            :to="newExperience.endAt || maxDate"
            :label="I18n.t('section.experience.start_at')"
            :class-name="inputValidationClass($v.startAt)"
            class="form-group form-required"
            @initialize="handleInitialize"
            @select="setStartAt"
          >
            <Toggle
              :is-active="isInEmployeed"
              :labels="[I18n.t('section.experience.in_employeed')]"
              @toggle="handleToggle"
            />
          </Datepicker>
        </div>

        <div class="col-date col-6">
          <Datepicker
            v-if="!isInEmployeed"
            :value="newExperience.endAt"
            :from="newExperience.startAt || minDate"
            :label="I18n.t('section.experience.end_at')"
            align="right"
            @initialize="() => setEndAt('')"
            @select="setEndAt"
          />
        </div>

        <div class="col-12">
          <div class="form-group">
            <label>
              {{ I18n.t('section.experience.stack') }}
            </label>

            <MultiSelect
              :search="tagSearch"
              :select="tagSelect"
              :placeholder="I18n.t('section.experience.placeholder.stack')"
            >
              <template #no-item>
                <div class="tag">
                  {{ I18n.t('resume.messages.tag.no_item') }}
                </div>
              </template>
            </MultiSelect>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label>
              {{ I18n.t('section.experience.link') }}
            </label>

            <input
              v-model="newExperience.link"
              class="form-control"
              :class="inputValidationClass($v.link)"
              type="text"
              placeholder="https://website.com"
              @blur="$v.link.$touch"
            >
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label>
              {{ I18n.t('section.experience.description') }}
            </label>

            <textarea
              v-model="newExperience.description"
              :placeholder="I18n.t('terms.input')"
              class="form-control"
              rows="3"
            ></textarea>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label>
              {{ I18n.t('section.part.title') }}
            </label>

            <PartForm
              v-for="(part, idx) in newExperience.parts"
              :key="idx"
              :index="idx"
              :info="part"
              @update-value="setPart"
            />
          </div>

          <button
            v-tooltip:top="I18n.t('section.part.add')"
            class="btn btn-sm form-btn-add-part"
            @click="handleAddPartItem"
          >
            <SvgIcon name="ic-add" html-class="ic-24" />
          </button>
        </div>
      </div>
    </div>

    <div class="form-btn-wrap-lg">
      <button class="btn btn-md btn-light" @click="handleCancelEdit">
        {{ I18n.t('actions.cancel') }}
      </button>
      <button class="btn btn-md btn-primary" @click="handleSubmit">
        {{ I18n.t('actions.save') }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import {
  PropType,
  defineComponent,
  inject,
  provide,
  reactive,
  ref,
  unref,
} from '@vue/composition-api';
import { useVuelidate } from '@vuelidate/core';
import { required, url } from '@vuelidate/validators';
import { handleUpdateForm, useForm, usePart } from '../../hooks/form';
import { ResumeShowStates, getResume } from '../../hooks/state';
import constants from '../../resume.constant';
import { inputValidationClass } from '../../resume.util';
import Datepicker from '../Datepicker.vue';
import PartForm from '../Part/Form.vue';
import Toggle from '../Toggle.vue';
import MultiSelect from '@/components/shared/MultiSelect.vue';
import SingleSelect from '@/components/shared/SingleSelect.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { tooltip } from '@/directives';
import {
  ResumeExperience,
  ResumePart,
} from '@/exportables/models/career/resume.model';
import { Tag } from '@/exportables/models/career/tag.model';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';
import { useSearch, useSelect } from '@/helpers/search';
import { cloneArray } from '@/helpers/utils';

type ExperienceKey = keyof ResumeExperience;

const { minDate, maxDate } = constants.DATEPICKER;

export default defineComponent({
  name: 'ResumeExperienceForm',
  components: {
    Datepicker,
    PartForm,
    MultiSelect,
    SingleSelect,
    SvgIcon,
    Toggle,
  },
  directives: {
    tooltip,
  },
  props: {
    isAdded: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
    experience: {
      type: Object as PropType<ResumeExperience>,
      required: true,
    },
  },
  setup(props) {
    const changeEditState = inject<Function>('changeEditState')!;
    const { newItem } = inject<ReturnType<typeof useForm>>('form')!;
    const state = inject<ResumeShowStates>('state')!;
    const rules = {
      name: { required },
      role: { required },
      startAt: { required },
      link: { url },
    };
    const newExperience = reactive<ResumeExperience>(
      new ResumeExperience(props.experience),
    );
    const tag = reactive<Tag>({
      id: -1,
      name: '',
    });
    const isInEmployeed = ref(newExperience.endAt ? false : true);
    const I18n = useI18n();
    const $v = useVuelidate(rules, newExperience);
    const part = usePart(newExperience.parts);
    const tagSelect = useSelect(newExperience.tags);
    const tagSearch = useSearch(tag, 'tag', {
      click: (t?: Tag) => {
        if (!t) return;
        tagSelect.handleSelect(t);
        Object.assign(tag, {
          id: -1,
          name: '',
        });
      },
    });
    const companySearch = useSearch(newExperience.indexedCompany, 'company', {
      footer: (t: Tag | undefined) => {
        companySearch.handleClickItem({
          id: null,
          ceoName: '',
          companyId: null,
          name: t?.name,
        });
      },
    });

    const setExperience =
      (key: ExperienceKey) => (newValue: ResumeExperience[ExperienceKey]) => {
        Object.assign(newExperience, { [key]: newValue });
      };

    const setStartAt = setExperience('startAt');
    const setEndAt = setExperience('endAt');

    const setPart = (part: ResumePart, index: number) => {
      const oldValue = newExperience.parts[index];
      newExperience.parts.splice(index, 1, {
        ...oldValue,
        ...part,
      });
    };

    const handleInitialize = () => {
      setStartAt('');
      setEndAt('');
    };

    const handleToggle = () => {
      Object.assign(isInEmployeed, { value: !isInEmployeed.value });
      unref(isInEmployeed) && setEndAt('');
    };

    const handleCancelEdit = () => {
      props.isAdded && Object.assign(newItem, { value: null });
      changeEditState(false);
    };

    const handleSubmit = () => {
      newExperience.name = newExperience.indexedCompany?.name;
      newExperience.indexedCompanyId = newExperience.indexedCompany?.id;
      newExperience.companyId = newExperience.indexedCompany?.companyId;

      const unrefed$v = unref($v);
      const resume = getResume(state);
      const experiences = cloneArray(resume.experiences);

      unrefed$v.$touch();

      if (unrefed$v.$error || unrefed$v.$invalid) {
        appendToast(I18n.t('resume.error.form'));
        return;
      }

      props.isAdded
        ? experiences.push(newExperience)
        : experiences.splice(props.index, 1, newExperience);

      handleUpdateForm(state, { experiences });
      changeEditState(false);

      Object.assign(newItem, { value: null });
    };

    provide('part', part);

    return {
      $v,
      I18n,
      companySearch,
      defaultImage: require('~/images/img-profile-default@2x.png'),
      handleAddPartItem: part.handleAddItem,
      handleCancelEdit,
      handleInitialize,
      handleSubmit,
      handleToggle,
      isInEmployeed,
      inputValidationClass,
      maxDate,
      minDate,
      newExperience,
      setEndAt,
      setPart,
      setStartAt,
      tagSearch,
      tagSelect,
    };
  },
});
</script>
