<template>
  <section :class="style.container">
    <h4>
      {{ I18n.t('job_position.featured_companies') }}
    </h4>

    <ul :class="style.list">
      <li v-for="company in list" :key="company.id">
        <FeaturedCompany :info="company" />
      </li>
    </ul>
  </section>
</template>

<script lang="ts">
import { PropType, defineComponent, useCssModule } from '@vue/composition-api';
import FeaturedCompany from './FeaturedCompany.vue';
import { FeaturedCompany as FeaturedCompanyType } from '@/exportables/models/career/company.model';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'FeaturedCompanies',
  components: {
    FeaturedCompany,
  },
  props: {
    list: {
      type: Array as PropType<FeaturedCompanyType[]>,
      default: () => [],
    },
  },
  setup() {
    const I18n = useI18n();
    const style = useCssModule();

    return {
      I18n,
      style,
    };
  },
});
</script>

<style lang="scss" module>
@use "~/stylesheets/functions";
@use "~/stylesheets/variables";

.container {
  h4 {
    margin-bottom: functions.rem-calc(16);
  }
}

.list {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: functions.rem-calc(16);
  list-style: none;
}

@media (variables.$md-down) {
  .list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: functions.rem-calc(8);
  }
}
</style>
