import { StoreOptions } from 'vuex';
import actions from './actions';
import mutations from './mutations';
import state, { JobPositionState } from './state';

const store: StoreOptions<JobPositionState> = {
  actions,
  mutations,
  state,
};

export default store;
