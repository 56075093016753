<template>
  <div
    role="progressbar"
    :class="style.container"
    aria-valuemin="0"
    :aria-valuemax="max"
    :aria-valuenow="value"
    :aria-valuetext="percentageText"
  >
    <svg :width="SVG_WIDTH" :height="SVG_HEIGHT" :class="style.svg">
      <circle
        :cx="CENTER_X"
        :cy="CENTER_Y"
        :r="RADIUS"
        :class="[style.bar, style.frame]"
        :stroke-width="STROKE_WIDTH"
        stroke-dasharray="180 360"
        pathLength="360"
      />
      <circle
        v-if="value"
        :cx="CENTER_X"
        :cy="CENTER_Y"
        :r="RADIUS"
        :class="[style.bar, style.progress]"
        :stroke-width="STROKE_WIDTH"
        :stroke-dasharray="progressDashArray"
        pathLength="360"
      />
    </svg>
    <span :class="style.percentage">{{ percentageText }}</span>
  </div>
</template>

<script>
import { computed, defineComponent, useCssModule } from '@vue/composition-api';

const STROKE_WIDTH = 8;
const RADIUS = 40;

const CENTER_X = RADIUS + STROKE_WIDTH / 2;
const CENTER_Y = STROKE_WIDTH / 2;

const SVG_WIDTH = CENTER_X * 2;
const SVG_HEIGHT = CENTER_X + CENTER_Y;

export default defineComponent({
  name: 'SemicircularProgressBar',
  props: {
    max: {
      type: Number,
      default: 100,
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const style = useCssModule();
    const progress = computed(() => (props.value / props.max));
    const progressDashArray = computed(() => `${180 * progress.value} 360`);
    const percentageText = computed(() => `${Math.round(progress.value * 100)}%`);

    return {
      STROKE_WIDTH,
      RADIUS,
      CENTER_X,
      CENTER_Y,
      SVG_WIDTH,
      SVG_HEIGHT,
      style,
      progressDashArray,
      progress,
      percentageText,
    };
  },
});
</script>

<style lang="scss" module>
@use "~stylesheets/variables";
@use "~stylesheets/functions";

.container {
  display: inline-block;
  position: relative;
}

.svg {
  transform: rotate(180deg);
}

.bar {
  fill: none;
  stroke-linecap: round;
}

.frame {
  stroke: variables.$blue-grey-50;
}

.progress {
  stroke: variables.$color-primary;
}

.percentage {
  position: absolute;
  bottom: 0;
  font-family: Hack;
  font-weight: 700;
  font-size: functions.rem-calc(20);
  color: variables.$color-text--primary;
  left: 50%;
  transform: translateX(-50%);
}
</style>
