<template>
  <div :class="style.container">
    <span>
      {{ I18n.t('business.recruit.job_profile.detail.top_programmers') }}
    </span>

    <SvgIcon name="ic-certificated" html-class="ic-22 ic-green-500" />
  </div>
</template>

<script lang="ts">
import { defineComponent, useCssModule } from '@vue/composition-api';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'TopProgrammersBadge',
  components: {
    SvgIcon,
  },
  setup() {
    const { t } = useI18n();
    const style = useCssModule();

    return {
      I18n: { t },
      style,
    };
  },
});
</script>

<style lang="scss" module>
@use '~/stylesheets/functions';
@use '~/stylesheets/variables';

.container {
  width: max-content;
  color: variables.$green-500;
  background: variables.$green-50;
  font-size: functions.rem-calc(14);
  font-weight: bold;
  border-radius: functions.rem-calc(4);
  padding: functions.rem-calc(2 8);
  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-left: functions.rem-calc(2);
    width: functions.rem-calc(22);
    height: functions.rem-calc(22);
  }
}

@media (variables.$md-down) {
  .container {
    span {
      font-size: functions.rem-calc(12);
    }

    svg {
      width: functions.rem-calc(12);
      height: functions.rem-calc(12);
    }
  }
}
</style>
