var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",class:[
    _vm.isMounted && ['show', _vm.style.mounted],
    _vm.overrideClass.container ],attrs:{"id":_vm.modalId}},[_c('div',{staticClass:"modal-dialog",class:_vm.overrideClass.dialog},[_c('div',{staticClass:"modal-content",class:_vm.overrideClass.content},[_c('div',{staticClass:"modal-header",class:_vm.overrideClass.header},[_vm._t("header",function(){return [_c('button',{staticClass:"close",attrs:{"data-dismiss":"modal","aria-label":"Close"},on:{"click":_vm.hide}},[_c('SvgIcon',{attrs:{"name":"ic-close","html-class":"ic-24"}})],1),_vm._v(" "),_c('h3',{staticClass:"modal-title"},[_vm._v("\n            "+_vm._s(_vm.title)+"\n          ")])]})],2),_vm._v(" "),_c('div',{staticClass:"modal-body",class:[
          _vm.style.body,
          _vm.overrideClass.body ]},[_vm._t("body",function(){return [_vm._v("\n          "+_vm._s(_vm.description)+"\n        ")]})],2),_vm._v(" "),_c('div',{staticClass:"modal-footer",class:[
          _vm.style.footer,
          _vm.overrideClass.footer,
          ( _obj = {}, _obj[_vm.style.edge] = _vm.footerEdge, _obj ) ],style:(_vm.overrideClass.footer)},[_vm._t("footer",function(){return [_c('button',{staticClass:"btn btn-light",attrs:{"data-dismiss":"modal","aria-label":"Close"},on:{"click":_vm.hide}},[_vm._v("\n            "+_vm._s(_vm.buttonName || _vm.I18n.t('actions.ok'))+"\n          ")])]})],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }