<template>
  <div class="container">
    <div class="competitions-filter">
      <h3>{{ I18n.t('competition.title.challenge') }}</h3>
    </div>
    <CompetitionList />
  </div>
</template>

<script lang="ts">
import {
  defineComponent, onMounted,
} from '@vue/composition-api';
import CompetitionList from '../components/CompetitionList.vue';
import { dispatchHackleViewEvent } from '@/exportables/services/useHackle';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'CompetitionIndex',
  components: {
    CompetitionList,
  },
  setup() {
    const I18n = useI18n();

    onMounted(() => {
      dispatchHackleViewEvent('career_competition_viewed');
    });

    return {
      I18n,
    };
  },
});
</script>
