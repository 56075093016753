import { useHttpClient } from './httpClient';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';

const CURRENT_BANNER_API_URL = '/banners/current/';
const BANNER_INDEX_API_URL = '/banners';

export const useBannerApiClient = () => {
  const httpClient = useHttpClient();
  const I18n = useI18n();

  const getCurrentBanner = async (params) => {
    try {
      const { data, status } = await httpClient.get(
        CURRENT_BANNER_API_URL, {
          params,
        },
      );
      if (status !== 200) throw new Error(data.message || I18n.t('error.internal'));
      return data;
    } catch (error) {
      appendToast(error.message);
    }
  };
  const getBanners = async (params) => {
    try {
      const { data, status } = await httpClient.get(BANNER_INDEX_API_URL, { params });
      if (status !== 200) throw new Error(data.message || I18n.t('error.internal'));
      return data;
    } catch (error) {
      appendToast(error.message);
    }
  };

  return {
    getBanners,
    getCurrentBanner,
  };
};
