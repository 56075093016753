<template>
  <Modal
    :title="I18n.t('resume.modal.import.title')"
    :button-name="I18n.t('terms.close')"
    @hide="closeModal"
  >
    <template #body>
      <template v-if="resumes.length">
        <h5 class="flex-start">
          {{ I18n.t(`resume.modal.import.label.${isProfile ? 'profile' : 'resume'}`) }}
        </h5>

        <ul :class="style.list">
          <li
            v-for="resume in resumes"
            :key="resume.id"
            :class="style.item"
          >
            <div class="thumb-resume rectangle" :class="style.rectangle">
              <SvgIcon name="ic-resume" html-class="ic-16" />
            </div>

            <div :class="style.info">
              <h5>
                {{ resume.title }}
                <span v-if="resume.isProfile" class="label label-primary" :class="style.btn">{{ I18n.t('job_profile.title') }}</span>
              </h5>

              <h6>
                {{ I18n.l('time.formats.default', resume.updatedAt) }} {{ I18n.t('resume.modal.import.updated') }}
              </h6>
            </div>

            <a
              v-tooltip:top="I18n.t('buttons.preview')"
              :href="`/resumes/${resume.id}`"
              target="_blank"
              rel="noopener"
            >
              <button class="btn">
                <SvgIcon name="ic-preview" html-class="ic-18" />
              </button>
            </a>

            <button
              class="btn btn-md radio-wrap"
              :class="style.select"
              @click="selectedResumeId = resume.id"
            >
              <input
                :value="resume.id"
                :checked="resume.id === selectedResumeId"
                type="radio"
              >
              <label>
                <span>
                  {{ I18n.t('actions.select') }}
                </span>
              </label>
            </button>
          </li>
        </ul>
      </template>

      <template v-else>
        <div class="flex-column flex-center">
          <p :class="style.noItem">
            {{ I18n.t('resume.messages.no_item') }}
          </p>
          <a href="/resumes/new">
            <button class="btn btn-md btn-primary">
              {{ I18n.t('resume.add') }}
            </button>
          </a>
        </div>
      </template>
    </template>

    <template #footer>
      <div class="flex-end">
        <button
          class="btn btn-md btn-light"
          data-dismiss="modal"
          aria-label="Close"
          @click="closeModal"
        >
          {{ I18n.t('actions.cancel') }}
        </button>
        <button
          class="btn btn-md btn-primary"
          :class="{ disabled: !selectedResumeId }"
          @click="handleSubmit"
        >
          {{ I18n.t('actions.save') }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  ref,
  unref,
  useCssModule,
} from '@vue/composition-api';
import {
  ResumeShowState,
  ResumeShowStates,
  getResume,
  initialize,
  isProfile as isP,
  setState,
} from '../../hooks/state';
import Modal from '@/components/shared/Modal2.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { tooltip } from '@/directives';
import {
  importResume,
  fetchResumes,
} from '@/exportables/apis/career/resume.api';
import { Resume } from '@/exportables/models/career/resume.model';
import { useI18n } from '@/helpers/i18n';

export default defineComponent({
  name: 'ResumeImportModal',
  components: {
    Modal,
    SvgIcon,
  },
  directives: {
    tooltip,
  },
  emits: ['hide'],
  setup(_, { emit }) {
    const state = inject<ResumeShowStates>('state')!;
    const resumes = ref<Resume[]>([]);
    const selectedResumeId = ref(0);
    const I18n = useI18n();
    const style = useCssModule();
    const resume = computed<Resume>({
      get: () => getResume(state),
      set: (value: Partial<Resume>) => setState(state, { resume: { ...resume.value, ...value } }),
    });
    const isProfile = computed(() => isP(state));
    const excludedItself = computed(
      () => unref(resumes).filter(
        (r) => r.id !== unref(resume).id,
      ),
    );

    const closeModal = () => emit('hide');

    const handleSubmit = async () => {
      const unrefedSelectedResumeId = unref(selectedResumeId);
      if (!unrefedSelectedResumeId) return;
      const { id } = unref(resume);

      await importResume(id, { importing_resume_id: unrefedSelectedResumeId });

      initialize(state, id);
      closeModal();
    };

    onMounted(async () => {
      const { resumes: importableResumes } = await fetchResumes();
      resumes.value = importableResumes.map(Resume.new);
    });

    return {
      I18n,
      closeModal,
      handleSubmit,
      isProfile,
      resumes: excludedItself,
      selectedResumeId,
      style,
    };
  },
});
</script>

<style lang="scss" module>
@import "~/stylesheets/variables";
@import "~/stylesheets/functions";
@import "~/stylesheets/display";

.list {
  @extend .flex-center, .flex-column;
  width: 100%;
  padding: 0;
  margin: 0;
  border-top: 1px solid $color-border;
  margin-top: rem-calc(12);
  padding-top: rem-calc(12);
}

.item {
  @extend .flex-between;
  width: 100%;
  margin-bottom: rem-calc(16);
}

.noItem {
  margin: rem-calc(40 0 18 0);
}

.info {
  width: calc(100% - #{rem-calc(210)});
  padding: rem-calc(0 24);

  h5,
  h6 {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  h6 {
    color: $color-text--secondary;
  }
}

.select {
  $p: &;

  @extend .flex-between;
  width: rem-calc(80);
  color: $color-primary;
  background-color: $blue-50;
  padding: rem-calc(8 10);
  margin-left: rem-calc(8);

  input[type="radio"] {
    &:checked {
      #{$p} label {
        &::before {
          background-color: $color-text--primary;
        }

        &::after {
          content: '';
          width: rem-calc(16);
          height: rem-calc(16);
          border-radius: 50%;
          background-color: white;
          position: absolute;
          left: 0.1rem;
        }
      }
    }
  }
}

@media ($sm-down) {
  .info {
    width: calc(100% - #{rem-calc(140)});
  }

  .rectangle {
    display: none;
  }

  .info {
    padding: 0;
  }
}
</style>
