<template>
  <div>
    <div class="home-logo">
      <a href="/">
        <slot name="homeIcon"><img src="~images/ic_hall_24_3F51B5.png" class="home-icon"></slot>
      </a>
    </div>

    <slot name="body" class="horizontal-center"></slot>

    <h3 v-if="title" class="title">
      {{ title }}
    </h3>
    <h4 v-if="titleSub" class="title-sub">
      {{ titleSub }}
    </h4>

    <a :href="nextUrl || 'javascript:history.go(-1);'" :class="nextButtonClassnames">
      {{ nextUrlLabel || '이전 페이지 가기' }}</a>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    titleSub: {
      type: String,
      required: false,
      default: null,
    },
    onlyShowError: {
      type: Boolean,
      required: false,
      default: true,
    },
    nextUrl: {
      type: String,
      required: false,
      default: null,
    },
    nextUrlLabel: {
      type: String,
      required: false,
      default: null,
    },
    nextButtonClassnames: {
      type: [String, Array],
      required: false,
      default: 'btn btn-light btn-lg',
    },
  },
  created() {
    if (this.onlyShowError) {
      document.querySelector('body > *').setAttribute('style', 'display: none');
      document.querySelector('.main').setAttribute('style', 'display: block !important');
    }
  },
};
</script>
