<template>
  <div id="tab_position" class="tab-content">
    <div class="container" style="margin-top: 32px">
      <Banner hackle-value="career_job_banner_clicked" />
    </div>

    <SearchFilter />

    <div
      v-if="featuredCompanies && featuredCompanies.length"
      class="container"
      :class="style.margin"
    >
      <FeaturedCompanies :list="featuredCompanies" />
    </div>

    <List />

    <div class="flex-center">
      <PaginationNavigator :limit="20" :total-pages="totalPages" />
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  provide,
  reactive,
  ref,
  toRef,
  unref,
  useCssModule,
  watch,
} from '@vue/composition-api';
import useSWRV from 'swrv';
import FeaturedCompanies from '../components/FeaturedCompanies.vue';
import PaginationNavigator from '../components/PaginationNavigator.vue';
import List from '../components/list/List.vue';
import SearchFilter from '../components/search-filter/SearchFilter.vue';
import { useStore } from '../hooks/store';
import Banner from '@/components/shared/Banner.vue';
import { JobPositionIndexState, fetchFeaturedCompanies, fetchJobPositions } from '@/exportables/apis/career/job-position.api';
import { fetchCurrentUser } from '@/exportables/apis/user.api';
import { Company, FeaturedCompany } from '@/exportables/models/career/company.model';
import { JobPosition, JobPositionFilterQuery } from '@/exportables/models/career/job-position.model';
import { Tag } from '@/exportables/models/career/tag.model';
import { User } from '@/exportables/models/user.model';
import { useHackle, dispatchHackleViewEvent } from '@/exportables/services/useHackle';
import { Query } from '@/exportables/types/general.d';
import { queryWrapper } from '@/helpers/url';
import { useFilter } from '@/pages/career/job-positions/hooks/filter';
import {
  usePage,
  useRoute,
  useRouter,
} from '@/router/career';

export default defineComponent({
  name: 'JobPositionIndex',
  components: {
    Banner,
    FeaturedCompanies,
    List,
    PaginationNavigator,
    SearchFilter,
  },
  setup() {
    const isInitialFetch = ref(true);
    const isLoading = ref(false);
    const isMobileFilterOpened = ref(false);
    const state = reactive<JobPositionIndexState>({
      companies: [],
      jobPositionFilter: null,
      jobPositionRecommendations: [],
      wantedJobPositions: [],
      jobPositions: [],
      tags: [],
      totalEntries: 0,
      totalPages: 1,
    });
    const currentUser = reactive<Partial<User>>({
      id: -1,
      isAdmin: false,
      isLoggedIn: false,
    });
    const totalPages = toRef(state, 'totalPages');
    const jobThemes = computed(() => store.state.jobThemes);
    const route = useRoute();
    const router = useRouter();
    const currentPage = usePage();
    const filter = useFilter();
    const store = useStore();
    const style = useCssModule();
    const { filterQuery, jobCategoryIds } = filter;
    const { data: featuredCompanies, mutate: mutateFeaturedCompanies } = useSWRV<FeaturedCompany[]>(
      '/v1/career/featured_companies',
      () => fetchFeaturedCompanies({ jobPositionCategoryIds: jobCategoryIds.value }),
      {
        revalidateOnFocus: false,
        shouldRetryOnError: false,
      },
    );

    const setJobPositions = async (
      query: Query,
    ) => {
      isLoading.value = true;

      const router = useRouter();
      const newQuery = queryWrapper(
        query,
        { keys: ['min_career', 'min_employees', 'min_salary', 'order', 'page'] },
        { keys: ['company_ids', 'job_category_ids', 'job_theme_ids', 'locations', 'tags'] },
      );

      const {
        companies,
        filterParams,
        jobPositionFilter,
        jobPositionRecommendations,
        wantedJobPositions,
        jobPositions,
        page,
        tags,
        totalEntries,
        totalPages,
      } = await fetchJobPositions(newQuery) || {};

      if (!page || !totalPages) return;

      Object.assign(
        state,
        {
          companies: companies.map(Company.new),
          jobPositionFilter,
          jobPositionRecommendations: jobPositionRecommendations.map(JobPosition.new),
          jobPositions: jobPositions.map(JobPosition.new),
          wantedJobPositions: wantedJobPositions.map(JobPosition.new),
          totalPages,
          totalEntries,
          tags: tags.map(Tag.new),
        },
      );

      if (unref(currentPage) !== Number(page)) {
        router.replace({ query: { ...router.currentRoute.query, page } });
      }

      if (unref(isInitialFetch)) {
        Object.assign(
          filterQuery,
          JobPositionFilterQuery.new(filterParams),
          {
            companies,
            tags,
          },
        );

        await nextTick();
      }

      isInitialFetch.value = false;
      isLoading.value = false;
    };

    store.dispatch('setJobCategories');
    store.dispatch('setJobThemes');

    setCurrentUser(currentUser);

    for (const key in state) provide(key, toRef(state, key as keyof JobPositionIndexState));

    provide('currentUser', currentUser);
    provide('filter', filter);
    provide('isLoading', isLoading);
    provide('isMobileFilterOpened', isMobileFilterOpened);

    watch(
      () => route.query,
      (query: Query) => setJobPositions(query),
      { immediate: true },
    );

    watch(
      () => filterQuery.jobCategoryIds,
      () => mutateFeaturedCompanies(),
    );

    watch(
      filterQuery,
      (filterQuery) => {
        if (unref(isInitialFetch)) return;

        const { tags } = filterQuery;
        const newQuery = queryWrapper(
          filterQuery,
          { keys: ['minCareer', 'minEmployees', 'minSalary', 'order'] },
          { keys: ['companyIds', 'jobCategoryIds', 'jobThemeIds', 'locations'] },
        );

        router.replace({
          query: Object.assign(
            {},
            { page: 1 },
            tags?.length && { tags: tags.map((tag) => tag.name) },
            newQuery,
          ),
        });
      },
      { deep: true },
    );

    onMounted(() => {
      dispatchHackleViewEvent('career_job_viewed');
    });

    return {
      featuredCompanies,
      filterQuery,
      jobThemes,
      style,
      totalPages,
    };
  },
});

const setCurrentUser = async (user: Partial<User>) => Object.assign(
  user,
  await fetchCurrentUser(),
);
</script>

<style lang="scss" module>
@use "~/stylesheets/functions";
@use "~/stylesheets/variables";

.margin {
  margin: functions.rem-calc(48) auto functions.rem-calc(16);
}

@media (variables.$md-down) {
  .margin {
    margin: functions.rem-calc(16) auto;
  }
}
</style>
