<template>
  <div
    v-if="currentItem"
    class="select-wrap"
    :class="style.container"
  >
    <button
      class="btn btn-md btn-block btn-outline-light dropdown-toggle"
      data-toggle="dropdown"
      aria-expanded="false"
    >
      <slot name="label" :item="currentItem">
        {{ currentItem.label }}
      </slot>
    </button>

    <ul
      class="dropdown-menu"
      :class="[`dropdown-menu-${dropdownPosition}`, style.list]"
      :style="{ maxHeight }"
      x-placement="bottom-start"
    >
      <li
        v-for="(item, index) in items"
        :key="`${item.value}-${index}`"
        class="dropdown-item"
        :class="{
          'dropdown-header': !item.id,
          [style.active]: currentItem === item,
        }"
        @click="select(index)"
      >
        <slot name="item" :item="item">
          <label>
            <input :value="item.value" type="hidden">
            {{ item.label }}
          </label>
        </slot>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import {
  PropType,
  defineComponent,
  ref,
  useCssModule,
} from '@vue/composition-api';
import { ArrayElement } from '@/exportables/types/array.d';

type Item<T> = {
  value: T,
  label: string,
};

export default defineComponent({
  name: 'DropdownMenu',
  props: {
    defaultIndex: {
      type: Number,
      required: false,
      default: 0,
    },
    items: {
      type: Array as PropType<Item<any>[]>,
      required: true,
      default: () => [],
    },
    maxHeight: {
      type: String,
      default: '',
      required: false,
    },
    dropdownPosition: {
      type: String,
      default: 'left',
    },
  },
  emits: ['onSelect'],
  setup(props, { emit }) {
    const style = useCssModule();
    const currentItem = ref<Item<ArrayElement<typeof props.items>['value']>>(
      props.items[props.defaultIndex],
    );

    const select = (index: number) => {
      const item = props.items[index];
      if (!item) return;
      currentItem.value = item;
      emit('onSelect', item);
    };

    select(props.defaultIndex);

    return {
      currentItem,
      select,
      style,
    };
  },
});
</script>

<style lang="scss" module>
@use "~/stylesheets/display";
@use "~/stylesheets/functions";
@use "~/stylesheets/variables";

.container {
  position: relative;
}

.list {
  overflow-y: scroll;
  padding: functions.rem-calc(8 0);

  &::-webkit-scrollbar {
    width: functions.rem-calc(4);
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: functions.rem-calc(6);
    background: variables.$blue-grey-200;
  }

  li, label {
    cursor: pointer;
  }

  li {
    color: variables.$color-text--primary;
    padding: functions.rem-calc(2 16);
  }

  li:hover {
    background-color: variables.$blue-50;
    color: variables.$color-primary;
  }

  .active {
    label {
      color: variables.$color-primary;
    }
  }
}
</style>
