<script lang="tsx">
import {
  PropType,
  defineComponent,
  useCssModule,
} from '@vue/composition-api';

type Color =
  | 'blue'
  | 'gray'
  | 'green'
  | 'purple'
  | 'red'
  | 'yellow';

export default defineComponent({
  name: 'StatusBadge',
  props: {
    color: {
      type: String as PropType<Color>,
      default: 'gray',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isStroked: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const style = useCssModule();

    return () => {
      const { color, isStroked, label } = props;

      return (
        label ?
          <span
            class={{
              [style.container]: true,
              [style[color]]: true,
              [style.stroked]: isStroked,
              [style.active]: props.isActive,
            }}
            onClick={() => emit('click')}
          >
            {label}
          </span> :
          null
      );
    };
  },
});
</script>

<style lang="scss" module>
@use "~/stylesheets/functions";
@use "~/stylesheets/variables";

.container {
  color: white;
  border: 1px solid transparent;
  font-size: functions.rem-calc(12);
  font-weight: 700;
  border-radius: functions.rem-calc(24);
  display: inline-block;
  padding: functions.rem-calc(0 10);
  transform: translateY(-1px);
  flex-shrink: 0;

  &.active {
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      color: #fff;
      background-color: variables.$color-primary;
      border-color: variables.$color-primary;
    }
  }

  &.blue {
    color: variables.$blue-500;
    background-color: variables.$blue-50;
    border-color: variables.$blue-50;
  }

  &.gray {
    &.stroked {
      color: variables.$color-text--body;
      background-color: variables.$blue-grey-10;
      border-color: variables.$blue-grey-75;
    }
  }

  &.green {
    color: variables.$color-success;
    background-color: variables.$green-50;
  }

  &.purple {
    color: white;
    background-color: variables.$purple-500;
  }

  &.red {
    color: white;
    background-color: variables.$red-500;
  }

  &.yellow {
    color: variables.$color-warning;
    background-color: variables.$orange-50;

    &.stroked {
      color: variables.$orange-500;
      background-color: variables.$orange-50;
      border-color: variables.$orange-50;
    }
  }
}
</style>
