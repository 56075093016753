<template>
  <Card v-if="jobPositions.length" :is-shrinked="true">
    <template #title>
      <span class="resume-card-header-title">{{ I18n.t('resume.job_fair_application') }}</span>
    </template>

    <template #body>
      <div class="resume-card-item">
        <ul class="list-contributions">
          <li
            v-for="(position, index) in jobPositions"
            :key="position.title"
            class="list-contribution-item"
          >
            <span v-if="isPreference" class="preference">{{ `${index + 1}${I18n.t('job_application.preference')}:` }}</span>
            <span class="company-name">{{ position.company.name }}</span>
            -
            <a :href="position.url" target="_blank" rel="noopener">{{ position.title }}</a>
          </li>
        </ul>
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
} from '@vue/composition-api';
import { useI18n } from '@/helpers/i18n';
import Card from '@/pages/career/resumes/components/Card.vue';

const I18n = useI18n();

type JobPosition = {
  id: number,
  title: string,
  url: string,
  company?: {
    name: string,
  },
};

export default defineComponent({
  name: 'JobFairApplications',
  components: {
    Card,
  },
  props: {
    jobPositions: {
      type: Array as PropType<JobPosition[]>,
      default: function () {
        return [];
      },
    },
    isPreference: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      I18n: { t: I18n.t },
    };
  },
});
</script>
