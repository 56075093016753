<template>
  <Modal
    :title="I18n.t('job_profile.blocked_company.title')"
    :button-name="I18n.t('terms.close')"
    @hide="closeModal"
  >
    <template #body>
      <h5 :class="style.label">
        {{ I18n.t('job_profile.blocked_company.label') }}
      </h5>

      <div class="flex-between">
        <div class="form-group form-required width-full">
          <label>
            {{ I18n.t('job_profile.blocked_company.company_name') }}
          </label>

          <SingleSelect :search="search" :placeholder="I18n.t('section.experience.placeholder.name')">
            <template #search-item="{ item }">
              <div class="company-wrap">
                <span class="company-icon">
                  <SvgIcon name="ic-domain" html-class="ic-18" />
                </span>
                <!-- <img :src="item.logoUrl || defaultImage" class="company-logo"> --><!-- TODO: indexed_companies와 companies가 연동이 많이 될 경우 수정 -->
                <div class="company-info">
                  <p>
                    {{ item.name }}
                  </p>
                  <!-- <p>{{ item.homeUrl }}</p> -->
                </div>
              </div>
            </template>

            <template #no-item="{ item }">
              <div class="company-wrap">
                <span class="company-icon">
                  <SvgIcon name="ic-domain" html-class="ic-18" />
                </span>
                <!-- <img :src="defaultImage" class="company-logo"> -->
                <span class="company-highlight">
                  '{{ item.name }}'
                </span>
                {{ I18n.t('resume.messages.company.no_item') }}
              </div>
            </template>

            <template #footer="{ item }">
              <span class="company-highlight">
                '{{ item.name }}'
              </span>
              <span style="margin-left: 4px;">
                {{ I18n.t('resume.messages.company.direct_input') }}
              </span>
            </template>
          </SingleSelect>
        </div>

        <button
          class="btn btn-md btn-primary"
          :class="style.btn"
          @click="handleAddCompany"
        >
          {{ I18n.t('job_profile.blocked_company.button') }}
        </button>
      </div>

      <div :class="style.tableWrap">
        <label>
          {{ I18n.t('job_profile.blocked_company.companies') }}
        </label>

        <table>
          <colgroup>
            <col width="30%">
            <col width="55%">
            <col width="15%">
          </colgroup>

          <thead>
            <tr>
              <td>
                {{ I18n.t('job_profile.blocked_company.company') }}
              </td>
              <td>
                {{ I18n.t('job_profile.blocked_company.url') }}
              </td>
              <td></td>
            </tr>
          </thead>

          <tbody>
            <tr v-for="company in blockedCompanies" :key="company.id">
              <td>
                {{ company.name }}
              </td>
              <td>
                {{ company.homeUrl }}
              </td>
              <td>
                <button
                  class="btn btn-sm btn-danger"
                  :class="style.deleteBtn"
                  @click="handleDeleteCompany(company.id)"
                >
                  {{ I18n.t('actions.remove') }}
                </button>
              </td>
            </tr>

            <tr v-if="blockedCompanies.length === 0">
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  reactive,
  useCssModule,
} from '@vue/composition-api';
import { useState } from '../../hooks/show/state';
import Modal from '@/components/shared/Modal2.vue';
import SingleSelect from '@/components/shared/SingleSelect.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import {
  deleteBlockedCompany,
  updateBlockedCompany,
} from '@/exportables/apis/career/job-profile.api';
import { useI18n } from '@/helpers/i18n';
import { InputState, useSearch } from '@/helpers/search';

export default defineComponent({
  name: 'JobProfileBlockedCompaniesModal',
  components: {
    Modal,
    SingleSelect,
    SvgIcon,
  },
  emits: ['hide'],
  setup(_, { emit }) {
    const { t } = useI18n();
    const { fetcher, state } = useState();
    const style = useCssModule();
    const company = reactive<InputState>({
      id: -1,
      name: '',
    });
    const search = useSearch(company, 'company');
    const blockedCompanies = computed(() => state.jobProfile.blockedCompaniesAttributes);

    const closeModal = () => emit('hide');

    const handleAddCompany = async () => {
      if (!company.name || !company.id) return;

      await updateBlockedCompany(company);
      fetcher.fetchJobProfile();

      Object.assign(
        company,
        {
          id: -1,
          name: '',
        },
        company?.companyId && {
          companyId: null,
        },
      );
    };

    const handleDeleteCompany = async (id: number) => {
      await deleteBlockedCompany(id);
      fetcher.fetchJobProfile();
    };

    return {
      I18n: { t },
      blockedCompanies,
      closeModal,
      defaultImage: require('~/images/img-profile-default@2x.png'),
      handleAddCompany,
      handleDeleteCompany,
      search,
      style,
    };
  },
});
</script>

<style lang="scss" module>
@import "~/stylesheets/functions";
@import "~/stylesheets/variables";

.label {
  border-bottom: 1px solid $color-border;
  padding-bottom: rem-calc(12);
  margin-bottom: rem-calc(12);
}

.btn {
  height: rem-calc(40);
  margin: rem-calc(24 0 16 16);
  flex-shrink: 0;
}

.deleteBtn {
  white-space: nowrap;
  float: right;
}

.tableWrap {
  width: 100%;
  margin-top: rem-calc(8);

  label {
    &::after {
      display: none;
    }
  }

  table {
    width: 100%;
    border: 1px solid $color-border;
    border-radius: $border-radius-xs;
    border-collapse: separate;
    border-spacing: 0;
  }

  thead {
    background-color: $blue-grey-20;

    td {
      font-size: rem-calc(13);
      vertical-align: middle;
    }
  }

  tbody {
    td {
      border-top: 1px solid $color-border;
      vertical-align: middle;
    }
  }

  tr {
    height: rem-calc(30);
  }

  td {
    font-size: rem-calc(14);
    padding: rem-calc(2 16);
    word-break: break-all;
  }
}

@media ($sm-down) {
  .btn {
    height: rem-calc(32);
    margin: rem-calc(24 0 16 8);
  }
}
</style>
