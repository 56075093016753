import { UserFeedTag } from '@/exportables/models/career/user-feed.model';

const isSameUserFeedTag = ({ id, name, count }: UserFeedTag) => (target: UserFeedTag) =>
  id === target.id && name === target.name && count === target.count;

export const isSameUserFeedTags = (
  a: UserFeedTag[],
  b: UserFeedTag[],
) => a.length === b.length && a.every(
  (userFeedTag) => b.find(isSameUserFeedTag(userFeedTag)),
);
