import { CompetitionRegistration } from '../../models/career/competition.model';
import { useHttpClient } from '../httpClient';
import { AuthenticatedInfo, Registration } from '@/exportables/models/career/registration.model';
import { Resume } from '@/exportables/models/career/resume.model';
import { Tokens } from '@/exportables/models/career/tokens.model';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';

const COMPETITION_URL = '/competitions';
const REGISTRATION_URL = '/registrations';

const I18n = useI18n();
const httpClient = useHttpClient();

export type RegistrationShowState = {
  registration: Registration,
  resume: Resume,
  tokens: Tokens,
};

export const initialize = async (
  state: RegistrationShowState,
  id: number,
) => {
  const registrationId = id ?? state.registration.id;

  setRegistration(state,
    await fetchRegistration(registrationId));
};

export const setRegistration = (
  state: RegistrationShowState,
  data: Partial<RegistrationShowState> = {},
) => {
  const {
    registration,
    resume,
    tokens,
  } = data;

  Object.assign(
    state,
    {
      registration: Registration.new(registration),
      resume: Resume.new(resume),
      tokens: Tokens.new(tokens),
    },
  );
};

export const fetchRegistration = async (id: number): Promise<RegistrationShowState> => {
  try {
    const { data, status } = await httpClient.get(`${REGISTRATION_URL}/${id}`);
    if (status !== 200) throw new Error(data?.message || I18n.t('error.internal'));
    return data;
  } catch (error) {
    error instanceof Error && appendToast(error.message);
    throw error;
  }
};

export const deleteRegistration = async (id: number) => {
  try {
    const { data, status } = await httpClient.delete(`${REGISTRATION_URL}/${id}`);
    if (status !== 204) throw new Error(data?.message || I18n.t('error.internal'));
    return data;
  } catch (error) {
    error instanceof Error && appendToast(error.message);
    throw error;
  }
};

export const fetchCompetitionsRegistrations = async (id: number) => {
  try {
    const { data, status } = await httpClient.get(`${COMPETITION_URL}/${id}/registrations/new`);
    if (status !== 200) throw new Error(data?.message || I18n.t('error.internal'));
    return data as {
      competition: CompetitionRegistration,
      authenticatedInfo: AuthenticatedInfo,
    };
  } catch (error) {
    error instanceof Error && appendToast(error.message);
    throw error;
  }
};
