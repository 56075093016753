import { Ref, unref } from '@vue/composition-api';
import { useHttpClient } from '../httpClient';
import { JobProfile, OpenSetting } from '@/exportables/models/career/job-profile.model';
import { Tokens } from '@/exportables/models/career/tokens.model';
import { appendToast } from '@/helpers/append';
import { useI18n } from '@/helpers/i18n';
import { JobProfileShowState } from '@/pages/career/job-profiles/hooks/show/state';

export const JOB_PROFILE_URL = '/job_profiles';
export const JOB_PROFILE_COMPANY_URL = '/job_profile_companies';

const I18n = useI18n();
const httpClient = useHttpClient();

export const initialize = async (
  state: JobProfileShowState,
  id?: number,
) => {
  const jobProfileId = id ?? state.jobProfile.id;

  setJobProfile(
    state,
    await fetchJobProfile(jobProfileId),
  );
};

export const setJobProfile = (
  state: JobProfileShowState,
  data: Partial<JobProfileShowState>,
) => {
  const jobProfile: JobProfile = JobProfile.new(data.jobProfile);

  Object.assign(
    state,
    {
      jobProfile,
      tokens: Tokens.new(data?.tokens),
    },
  );
};

export const fetchJobProfile = async (id: number | Ref<number> | string = 'my') => {
  try {
    const { data, status } = await httpClient.get(`${JOB_PROFILE_URL}/${unref(id)}`);
    if (status !== 200) throw new Error(data.message || I18n.t('error.internal'));
    return data;
  } catch (error) {
    error instanceof Error && appendToast(error.message);
  }
};

export const fetchPublicProfile = async (coverName: string) => {
  try {
    const { data, status } = await httpClient.get(`${JOB_PROFILE_URL}/public/${coverName}`);
    if (status !== 200) throw new Error(data.message || I18n.t('error.internal'));
    return data;
  } catch (error) {
    error instanceof Error && appendToast(error.message);
  }
};

export const hideJobProfile = async (id: number) => {
  try {
    const { data, status } = await httpClient.post(`${JOB_PROFILE_URL}/${id}/hide`);
    if (status !== 200) throw new Error(data.message || I18n.t('error.internal'));
    return data;
  } catch (error) {
    error instanceof Error && appendToast(error.message);
  }
};

export const updateOpenSetting = async (openSetting: OpenSetting) => {
  try {
    const { data, status } = await httpClient.patch(`${JOB_PROFILE_URL}/open_setting`, { openSetting });
    if (status !== 200) throw new Error(data.message || I18n.t('error.internal'));
    return data;
  } catch (error) {
    error instanceof Error && appendToast(error.message);
  }
};

export const toggleOpen = async () => {
  try {
    const { data, status } = await httpClient.post(`${JOB_PROFILE_URL}/toggle_open`);
    if (status !== 200) throw new Error(data.message || I18n.t('error.internal'));
    return data;
  } catch (error) {
    error instanceof Error && appendToast(error.message);
  }
};

export const toggleMatching = async (isActive: boolean | Ref<boolean>) => {
  const active = unref(isActive) ? 'terms.inactive' : 'terms.active';

  try {
    const { data, status } = await httpClient.post(`${JOB_PROFILE_URL}/toggle_active`);
    if (status !== 200) throw new Error(data.message || I18n.t('error.internal'));
    return data;
  } catch (error) {
    appendToast(I18n.t('job_profile.error.matching', { active: I18n.t(active) }));
    throw error;
  }
};

export const updateBlockedCompany = async (
  params: {
    id: number,
    companyId?: number,
    name: string,
  },
) => {
  try {
    const { status } = await httpClient.post('blocked_companies', params);
    if (status !== 200) throw new Error;
  } catch (error) {
    appendToast(I18n.t('job_profile.error.block', { action: I18n.t('actions.submit') }));
  }
};

export const deleteBlockedCompany = async (companyId: number) => {
  try {
    const { status } = await httpClient.delete(`blocked_companies/${companyId}`);
    if (status !== 204) throw new Error;
  } catch (error) {
    appendToast(I18n.t('job_profile.error.block', { action: I18n.t('actions.remove') }));
  }
};
