<template>
  <div class="container-tech-feed">
    <main class="main-content">
      <section class="recent-content">
        <div class="content-title">
          <ul class="nav nav-tabs flex-start w-100">
            <li v-if="hasUserFeedTags" class="nav-item">
              <h5
                :class="{ active: isFeed }"
                class="nav-link font-weight-700"
                @click="handleClickNavTab(FEED_TAB.feed)"
              >
                내 테크 피드
              </h5>
            </li>
            <li class="nav-item">
              <h5
                :class="{ active: !isFeed }"
                class="nav-link font-weight-700"
                @click="handleClickNavTab(FEED_TAB.all)"
              >
                모든 게시물
              </h5>
            </li>
          </ul>
        </div>
        <ul class="content-list">
          <Post
            v-for="post in posts"
            :key="post.id"
            :post="post"
          />
        </ul>
        <Pagination
          v-if="totalPage"
          :max="totalPage"
          :initial-page="currentPage"
          @update:initial-page="handleClickPage"
        />
      </section>
    </main>
    <UserFeedPreviewContainer />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
} from '@vue/composition-api';
import { FeedTab, FEED_TAB } from '../constants';
import UserFeedPreviewContainer from '../containers/UserFeedPreviewContainer.vue';
import Post from './Post.vue';
import Pagination from '@/components/shared/Pagination.vue';
import { Post as PostType } from '@/exportables/models/career/post.model';

export default defineComponent({
  name: 'CustomPosts',
  components: {
    Post,
    Pagination,
    UserFeedPreviewContainer,
  },
  props: {
    posts: {
      type: Array as PropType<PostType[]>,
      required: true,
    },
    isFeed: {
      type: Boolean,
      required: true,
    },
    hasUserFeedTags: {
      type: Boolean,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    totalPage: {
      type: Number,
      required: true,
    },
  },
  emits: ['clickNavTab', 'clickPage'],
  setup(_, { emit }) {
    const handleClickNavTab = (tab: FeedTab) => emit('clickNavTab', tab);
    const handleClickPage = (page: number) => emit('clickPage', page);

    return {
      FEED_TAB,
      handleClickNavTab,
      handleClickPage,
    };
  },
});
</script>
