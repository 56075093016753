<template>
  <Modal
    :title="I18n.t('resume.stacks.primary_tags')"
    @hide="closeModal"
  >
    <template #body>
      <div class="form-group">
        <h5 v-if="technicalTags.length" class="control-label">
          {{ I18n.t('resume.stacks.label') }}
        </h5>

        <div :class="style.wrapper">
          <Checkbox
            v-for="tag in technicalTags"
            :key="tag.id"
            :is-checked="isChecked(tag.id)"
            :is-disabled="primaryTags.length === 3"
            :label="tag.name"
            @check="handleCheck(tag)"
          />
        </div>
      </div>

      <div class="form-group" :class="style.input">
        <h6>
          {{ I18n.t(`resume.stacks.register${technicalTags.length ? '_else' : ''}`) }}
        </h6>

        <SingleSelect
          :placeholder="I18n.t('resume.stacks.placeholder.search')"
          :search="search"
          type="tags"
        >
          <template #no-item>
            <div class="tag">
              {{ I18n.t('resume.messages.tag.no_item') }}
            </div>
          </template>
        </SingleSelect>
      </div>
    </template>

    <template #footer>
      <div class="flex-end">
        <button
          class="btn btn-md btn-light"
          data-dismiss="modal"
          aria-label="Close"
          @click="closeModal"
        >
          {{ I18n.t('actions.cancel') }}
        </button>
        <button
          class="btn btn-md btn-primary"
          @click="handleSubmit"
        >
          {{ I18n.t('actions.save') }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts">
import {
  defineComponent,
  inject,
  reactive,
  toRefs,
  useCssModule,
} from '@vue/composition-api';
import { handleUpdateForm } from '../../hooks/form';
import { ResumeShowStates, getResume } from '../../hooks/state';
import Checkbox from '../Checkbox.vue';
import Modal from '@/components/shared/Modal2.vue';
import SingleSelect from '@/components/shared/SingleSelect.vue';
import { Tag } from '@/exportables/models/career/tag.model';
import { useI18n } from '@/helpers/i18n';
import { useSearch, useSelect } from '@/helpers/search';
import { cloneArray } from '@/helpers/utils';

export default defineComponent({
  name: 'ResumeStacksModal',
  components: {
    Checkbox,
    Modal,
    SingleSelect,
  },
  emits: ['hide'],
  setup(_, { emit }) {
    const state = inject<ResumeShowStates>('state')!;
    const { primaryTags, technicalTags } = getResume(state);
    const stacks = reactive<{ [key: string]: Tag[] }>({
      primaryTags: cloneArray(primaryTags),
      technicalTags: cloneArray(technicalTags),
    });
    const tag = reactive({
      id: -1,
      name: '',
    });
    const I18n = useI18n();
    const { handleSelect } = useSelect(stacks.technicalTags);
    const search = useSearch(tag, 'tag', {
      click(t: Tag) {
        handleSelect(t);
        Object.assign(tag, {
          id: -1,
          name: '',
        });
      },
    });
    const style = useCssModule();

    const closeModal = () => emit('hide');

    const isChecked = (id: number) => stacks.primaryTags.some((tag) => tag.id === id);

    const handleCheck = ({ id, name }: Tag) => {
      const index = stacks.primaryTags.findIndex((tag) => tag.id === id);

      if (index >= 0) {
        stacks.primaryTags.splice(index, 1);
      } else {
        if (stacks.primaryTags.length >= 3) return;
        stacks.primaryTags.push({ id, name });
      }
    };

    const handleSubmit = () => {
      const primaryTagIds = stacks.primaryTags.map((tag) => tag.id);

      handleUpdateForm(state, { primaryTagIds });
      closeModal();
    };

    return {
      ...toRefs(stacks),
      I18n: { t: I18n.t },
      closeModal,
      handleCheck,
      handleSubmit,
      isChecked,
      search,
      style,
    };
  },
});
</script>

<style lang="scss" module>
@use "~/stylesheets/variables";

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px;
}

.input {
  input {
    font-size: 15px;
  }
}

@media (variables.$sm-down) {
  .wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
