type EventType = string;
type EventCallback = (eventData?: EventData) => void;
type EventData = Record<string, unknown>;
type EventDetail = {
  appId: string,
  eventType: EventType,
  eventData?: EventData,
};

type EventCallbackMap = Map<EventCallback, WindowAttachedEventListener>;
type WindowAttachedEventListener = (event: Event) => void;

const EVENT_CHANNEL_NAME = 'hera-client-event-bus';

/**
 * 서로 다른 앱이 커스텀 이벤트를 발행하고 수신합니다.
 *
 * 동일한 앱에서 발행한 이벤트는 수신하지 않습니다.
 */
export class AppCustomEventBus {
  private appId: string;
  /**
   * 이벤트 이름을 키로, 이벤트 리스너 맵을 값으로 가지는 맵입니다.
   *
   * 이벤트 리스너를 맵 형태로 저장하는 이유는 다음과 같습니다:
   * - public API인 `addListener`와 `removeListener`는 `eventType`과 `eventCallback`을 인자로 받습니다.
   * - 하지만 내부적으로 window에 이벤트를 등록하거나 삭제할 때는 `eventCallback`이 아닌 `windowEventListener`를 사용합니다.
   * - 이때, 등록한 이벤트 리스너를 삭제하려면 `eventCallback`으로 생성한 `windowEventListener`의 참조값을 알아야 합니다.
   * - `eventCallback`과 `windowEventListener`를 맵 형태로 저장하여 `eventCallback`을 키로 `windowEventListener`를 찾을 수 있게 합니다.
   *
   * @example
   * ```ts
   * const eventListenerMaps = new Map<EventType, Set<EventCallbackMap>>();
   *
   * eventListenerMaps.set('test-event', new Set([
   *  new Map([
   *   [callback, windowEventListener],
   *  ]),
   * ]));
   * ```
   */
  private eventListenerMaps = new Map<EventType, Set<EventCallbackMap>>();

  constructor(appId: string) {
    this.appId = appId;
  }

  dispatch(eventType: EventType, eventData?: EventData) {
    window.dispatchEvent(
      new CustomEvent<EventDetail>(EVENT_CHANNEL_NAME, {
        detail: {
          appId: this.appId,
          eventType,
          eventData,
        },
      }),
    );
  }

  addListener(eventType: EventType, callback: EventCallback) {
    if (this.existedListener(eventType, callback)) return;

    const windowEventListener = this.createWindowEventListener(
      eventType,
      callback,
    );

    window.addEventListener(EVENT_CHANNEL_NAME, windowEventListener);
    this.addToEventListenerMaps(eventType, callback, windowEventListener);
  }

  removeListener(eventType: EventType, callback: EventCallback) {
    const listeners = this.eventListenerMaps.get(eventType);
    if (!listeners || !listeners.size) return;

    const matchedListener = this.getMatchedListener(eventType, callback);
    if (!matchedListener) return;

    const windowEventListener = matchedListener.get(callback);
    if (!windowEventListener) return;

    window.removeEventListener(EVENT_CHANNEL_NAME, windowEventListener);
    this.removeFromEventListenerMaps(eventType, matchedListener);
  }

  private existedListener(eventType: EventType, callback: EventCallback) {
    return !!this.getMatchedListener(eventType, callback);
  }

  private getMatchedListener(eventType: EventType, callback: EventCallback) {
    const listeners = this.eventListenerMaps.get(eventType);
    if (!listeners || !listeners.size) return;

    return Array.from(listeners).find((listener) => listener.get(callback));
  }

  private createWindowEventListener(
    eventType: EventType,
    callback: EventCallback,
  ): WindowAttachedEventListener {
    return (event: Event) => {
      const {
        appId,
        eventType: eventKey,
        eventData,
      } = (event as CustomEvent<EventDetail>).detail;

      if (appId === this.appId) return;

      if (eventKey === eventType) {
        callback(eventData);
      }
    };
  }

  private addToEventListenerMaps(
    eventType: EventType,
    callback: EventCallback,
    windowEventListener: WindowAttachedEventListener,
  ) {
    const currentListener = new Map();
    currentListener.set(callback, windowEventListener);

    const listeners = this.eventListenerMaps.get(eventType);
    this.eventListenerMaps.set(
      eventType,
      listeners
        ? new Set([...listeners, currentListener])
        : new Set([currentListener]),
    );
  }

  private removeFromEventListenerMaps(
    eventType: EventType,
    matchedListener: EventCallbackMap,
  ) {
    const listeners = this.eventListenerMaps.get(eventType);
    if (!listeners || !listeners.size) return;

    this.eventListenerMaps.set(
      eventType,
      new Set(
        [...listeners].filter((listener) => listener !== matchedListener),
      ),
    );
  }
}
