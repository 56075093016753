<template>
  <div>
    <template v-if="isNew">
      <div class="review-content">
        <div v-if="resume.id" class="review-content-item">
          <h6 class="section-title">
            {{ I18n.t('application.wizard_form.sections.confirm_resume_infomation') }}
          </h6>
          <div class="card card-resume-preview">
            <div class="card-body">
              <h6 class="title">
                {{ I18n.t('job_application.preview_step.selected_resume') }}
              </h6>
              <div class="media">
                <div class="thumb-resume">
                  <SvgIcon name="ic-resume" html-class="ic-24" />
                </div>
                <div class="media-body">
                  <h5 class="content">
                    {{ resume.title }}
                  </h5>
                  <h6>
                    {{ I18n.t('job_application.select_resume_step.updated_time', {
                      time: formatDate(resume.updatedAt, 'YYYY-MM-DD HH:mm:ss'),
                    }) }}
                  </h6>
                </div>
                <a :href="resumeUrl" class="btn btn-sm btn-preview" rel="noopener" target="_blank">미리보기</a>
              </div>
              <hr>
              <div class="form-group">
                <label class="col-form-label not-after-info">
                  {{ I18n.t('terms.name') }}
                </label>
                <h5 class="form-group-info read-only-info">
                  {{ resume.name }}
                </h5>
                <small class="form-text text-muted form-guide">
                  <SvgIcon name="ic-info-14" html-class="ic-14" />
                  {{ I18n.t('job_application.preview_step.must_check_name') }}
                </small>
              </div>
              <div class="form-group">
                <label class="col-form-label not-after-info">
                  {{ I18n.t('terms.phone') }}
                </label>
                <h5 class="form-group-info read-only-info">
                  {{ resume.phone }}
                </h5>
                <small class="form-text text-muted form-guide">
                  <SvgIcon name="ic-info-14" html-class="ic-14" />
                  {{ I18n.t('job_application.preview_step.must_check_phone') }}
                </small>
              </div>
              <div class="form-group">
                <label class="col-form-label not-after-info">
                  {{ I18n.t('terms.email') }}
                </label>
                <h5 class="form-group-info read-only-info">
                  {{ resume.email }}
                </h5>
                <small class="form-text text-muted form-guide">
                  <SvgIcon name="ic-info-14" html-class="ic-14" />
                  {{ I18n.t('job_application.preview_step.must_check_email') }}
                </small>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="review-content-item">
          <h6 class="section-title">
            {{ I18n.t('application.wizard_form.sections.contact') }}
          </h6>
          <ul :class="style.listContacts">
            <li v-if="resume.name">
              {{ I18n.t('terms.name') }} ― {{ resume.name }}
            </li>
            <li v-if="resume.email">
              {{ I18n.t('terms.email') }} ― {{ resume.email }}
            </li>
            <li v-if="resume.phone">
              {{ I18n.t('terms.phone') }} ― {{ resume.phone }}
            </li>
          </ul>
        </div>
      </div>
      <div v-if="isPresent(primaryCustomFormAnswers)" class="review-content">
        <div class="review-content-item">
          <h6 class="section-title">
            {{ I18n.t('application.title.inputted_custom_form') }}
          </h6>
          <div class="list-custom-form-answers">
            <div
              v-for="(_answer, customFormId) in primaryCustomFormAnswers"
              :key="customFormId"
              class="answer-item"
            >
              <h6 class="question-label">
                {{ customFormLabel(customFormId) }}
              </h6>

              <ul v-if="isAnswerType('orderedSelect', customFormId)">
                <li v-for="(answer, idx) in customFormAnswer(customFormId)" :key="idx">
                  {{ answer }} ({{ I18n.t('terms.optional') }} {{ idx + 1 }})
                </li>
              </ul>

              <ul v-else-if="isAnswerType('checkbox', customFormId)">
                <li v-for="(answer, idx) in customFormAnswer(customFormId)" :key="idx">
                  {{ customFormAnswer(customFormId)[idx] }}
                  <ul v-if="isPresent(subjectiveAnswers(customFormId)[idx])">
                    <li v-html="subjectiveAnswers(customFormId)[idx]"></li>
                  </ul>
                </li>
              </ul>

              <ul v-else-if="isAnswerType('radio', customFormId)">
                <li v-html="html_output(customFormAnswer(customFormId))"></li>

                <ul v-if="isPresent(subjectiveAnswers(customFormId))">
                  <li
                    v-for="(subjectiveAnswer, _idx) of subjectiveAnswers(customFormId)"
                    :key="_idx"
                    v-html="subjectiveAnswer"
                  ></li>
                </ul>
              </ul>

              <p
                v-else
                class="answer-paragraph"
                v-html="html_output(customFormAnswer(customFormId))"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div v-if="requiredRegistrationGroup || isPresent(response.jobPositions)" class="review-content">
      <div class="review-content-item">
        <h6 class="section-title">
          {{ I18n.t('job_application.preview_step.confirm_application_position') }}
        </h6>
        <div class="list-custom-form-answers">
          <template v-if="requiredRegistrationGroup">
            <h6 class="question-label">
              {{ I18n.t('job_application.preview_step.registration_group') }}
            </h6>
            <ul>
              <li>
                <h6>{{ registrationGroupTitle }}</h6>
              </li>
            </ul>
          </template>
          <template v-if="isPresent(response.jobPositions)">
            <div class="alert alert-light">
              <h6 class="question-label">
                {{ I18n.t('application.title.selected_job_position') }}
              </h6>
              <ul>
                <template v-if="isPresent(preferences)">
                  <li v-for="(jobPositionId, index) of preferences" :key="jobPositionId">
                    <h6>{{ `${index + 1}${I18n.t('job_application.preference')}: ${jobPositionTitle(jobPositionId)}` }}</h6>
                  </li>
                </template>
                <template v-else>
                  <li v-for="jobPositionId of response.jobPositions" :key="jobPositionId">
                    <h6>{{ jobPositionTitle(jobPositionId) }}</h6>
                  </li>
                </template>
              </ul>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType, useCssModule } from '@vue/composition-api';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { Action, CustomForm, Response } from '@/exportables/models/job-application.model';
import { RegistrationJobPosition } from '@/exportables/models/job-position.model';
import { RegistrationGroup as RegistrationGroupType } from '@/exportables/models/registration.model';
import { useI18n } from '@/helpers/i18n';
import { isPresent } from '@/helpers/utils';

export default defineComponent({
  name: 'JobApplicationPreviewStep',
  components: {
    SvgIcon,
  },
  props: {
    response: {
      type: Object as PropType<Response>,
      required: true,
    },
    customForms: {
      type: Array as PropType<CustomForm[]>,
      default: () => [],
    },
    jobPositions: {
      type: Array as PropType<RegistrationJobPosition[]>,
      default: () => [],
    },
    action: {
      type: String as PropType<Action>,
      default: 'new',
    },
    registrationGroups: {
      type: Array as PropType<RegistrationGroupType[]>,
      default: () => [],
    },
    preferences: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
  },
  emits: ['update:updateSelectedResume'],
  setup(props, { emit }) {
    const I18n = useI18n();
    const style = useCssModule();
    const registrationGroupId = ref(props.response.registrationGroupId);

    const customFormAnswers = computed(() => props.response.customFormAnswers);
    const isNew = computed(() => props.action === 'new');
    const primaryCustomFormAnswers = computed(() => {
      return Object.keys(customFormAnswers.value).
        filter((k) => k !== 'subjective').
        map((k) => Object.assign({}, { [k]: customFormAnswers.value[k] })).
        reduce((res, o) => Object.assign(res, o), {});
    });
    const resume = computed({
      get: () => props.response.resume,
      set: (resume) => {
        emit('update:updateSelectedResume', resume);
      },
    });
    const registrationGroupTitle = computed(() => {
      return props.registrationGroups.find(
        (registrationGroup) => registrationGroup.id === registrationGroupId.value,
      )?.title;
    });
    const requiredRegistrationGroup = computed(() => props.registrationGroups.length > 1);
    const resumeUrl = computed(() => {
      if (!resume.value!.id) return;
      return `/resumes/${resume.value!.id}`;
    });

    const customFormAnswer = (id: string) => {
      const answer = customFormAnswers.value[id];
      if (!isPresent(answer)) return I18n.t('job_application.preview_step.no_answer');

      const customForm = findElementById('customForms', id) as CustomForm;
      if (customForm.form_type === 'radio' || customForm.form_type === 'select_box') {
        return customForm.options[Number(answer as string)].label;
      } else if (customForm.form_type === 'checkbox') {
        const checkboxObj: { [key: string]: string } = {};
        (answer as string[]).forEach((ans: string) => checkboxObj[ans] = customForm.options[Number(ans)].label);
        return checkboxObj;
      } else if (customForm.form_type === 'ordered_select') {
        return (answer as string[]).map((ans: string) => customForm.options[Number(ans)].label);
      } else {
        return answer;
      }
    };
    const customFormLabel = (id: string) => {
      const customForm = findElementById('customForms', id) as CustomForm;
      return (customForm === undefined) ? '' : customForm.label;
    };

    const findElementById = (type: 'customForms' | 'jobPositions', id: string) =>
      (props[type] as (CustomForm | RegistrationJobPosition)[]).find((item) => Number(item.id) === Number(id))!;

    const isAnswerType = (type: 'checkbox' | 'radio' | 'orderedSelect', id: string) => {
      const customForm = findElementById('customForms', id) as CustomForm;
      if (type === 'checkbox') return customForm.form_type === 'checkbox';
      else if (type === 'radio') return customForm.form_type === 'radio';
      else if (type === 'orderedSelect') return customForm.form_type === 'ordered_select';
    };

    const jobPositionTitle = (id: string) => {
      const jobPosition = findElementById('jobPositions', id) as RegistrationJobPosition;
      return `${jobPosition.company.name} - ${jobPosition.title}`;
    };
    const subjectiveAnswers = (id: string) => {
      if (!isPresent(customFormAnswers.value['subjective'])) return '';
      return customFormAnswers.value['subjective']![id];
    };

    return {
      I18n,
      style,
      customFormAnswer,
      customFormLabel,
      findElementById,
      isAnswerType,
      isNew,
      jobPositionTitle,
      primaryCustomFormAnswers,
      registrationGroupId,
      registrationGroupTitle,
      requiredRegistrationGroup,
      resume,
      resumeUrl,
      subjectiveAnswers,
    };
  },
});
</script>

<style lang="scss" module>
@use "~/stylesheets/functions";

.listContacts {
  padding-left: functions.rem-calc(20);
}
</style>
