<template>
  <div
    class="btn btn-light btn-block btn-svg dropdown-toggle"
    :class="{
      [style.container]: true,
      'btn-share': !hideLabel,
    }"
    data-toggle="dropdown"
    aria-expanded="false"
  >
    <div>
      <SvgIcon
        name="ic-share"
        html-class="ic-24"
        :class="{
          [style.shareIcon]: hideLabel,
        }"
      />
      <span v-if="!hideLabel">{{ I18n.t('sharer.label') }}</span>
    </div>

    <div class="dropdown-menu dropdown-menu-right">
      <button
        v-for="item in shareItems"
        :key="item.key"
        class="dropdown-item"
        :class="style.dropdownItem"
        @click="item.handleClick"
      >
        <SvgIcon html-class="ic-24" :name="item.iconName" />
        {{ I18n.t(`sharer.${item.key}`) }}
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, useCssModule, watchEffect } from '@vue/composition-api';
import SvgIcon from '@/components/shared/SvgIcon.vue';
import { useI18n } from '@/helpers/i18n';
import { useShare } from '@/hooks/useShare';

export default defineComponent({
  name: 'ShareDropdown',
  components: {
    SvgIcon,
  },
  props: {
    url: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    imageUrl: {
      type: String,
      default: '',
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const style = useCssModule();
    const I18n = useI18n();
    const { setShareInfo, copyLink, share } = useShare();

    const shareItems = [
      {
        key: 'copy_url',
        iconName: 'ic-link',
        handleClick: copyLink,
      },
      {
        key: 'twitter',
        iconName: 'ic-twitter',
        handleClick: () => share('twitter'),
      },
      {
        key: 'fb',
        iconName: 'ic-facebook',
        handleClick: () => share('facebook'),
      },
      {
        key: 'kakao',
        iconName: 'ic-kakao',
        handleClick: () => share('kakao'),
      },
      {
        key: 'naver',
        iconName: 'ic-naver-blog',
        handleClick: () => share('naver'),
      },
    ];

    watchEffect(() => {
      setShareInfo(props);
    });

    return {
      I18n,
      style,
      shareItems,
    };
  },
});
</script>

<style lang="scss" module>
@use "~/stylesheets/variables";

.container {
  width: auto;

  .shareIcon {
    @media (variables.$md-down) {
      display: block;
    }
  }
}

.dropdownItem {
  display: flex;
  align-items: flex-start;

  svg {
    display: inline;
  }
}
</style>
