<template>
  <HStack
    :class="[
      style.alert,
      style[type],
      {
        [style.small]: small,
      },
      className,
    ]"
    :gap="small ? 1 : 2"
  >
    <div v-if="!noIcon" :class="style.svgWrapper">
      <SvgIcon :class="style.icon" :name="iconName" :html-class="small ? 'ic-16' : 'ic-20'" />
    </div>
    <VStack :class="style.content">
      <slot>
        {{ message }}
      </slot>
      <slot name="description"></slot>
    </VStack>
    <div v-if="closeBtn" :class="[style.close, style.svgWrapper]" @click="handleClose">
      <SvgIcon name="ic-close" html-class="ic-16" />
    </div>
  </HStack>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  useCssModule,
} from '@vue/composition-api';
import HStack from './HStack.vue';
import VStack from './VStack.vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';

export type AlertType = 'default' | 'primary' | 'success' | 'warning' | 'danger' | 'light';

export default defineComponent({
  components: {
    SvgIcon,
    HStack,
    VStack,
  },
  props: {
    className: {
      type: String,
      default: '',
    },
    type: {
      type: String as PropType<AlertType>,
      default: 'default',
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: 'ic-info',
    },
    closeBtn: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup(_, { emit }) {
    return {
      handleClose: () => emit('close'),
      style: useCssModule(),
    };
  },
});
</script>

<style lang="scss" module>
@use '~/stylesheets/variables';

.alert {
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  background-color: variables.$blue-grey-50;
  border-radius: 0.75rem;
}

.content {
  display: flex;
  width: 100%;
  gap: 0.5rem;
  line-height: 1.5rem;

  .icon {
    flex-shrink: 0;
  }
}

.close {
  flex-shrink: 0;
  cursor: pointer;
  fill: rgba(20, 20, 50, 0.12);
}

.primary {
  background-color: variables.$blue-50;
  color: variables.$color-primary;
  fill: variables.$color-primary;
}

.success {
  background-color: variables.$green-50;
  color: variables.$color-success;
  fill: variables.$color-success;
}

.warning {
  background-color: variables.$deep-orange-50;
  color: variables.$amber-900;
  fill: variables.$amber-900;
}

.danger {
  background-color: variables.$red-50;
  color: variables.$color-danger;
  fill: variables.$color-danger;
}

.light {
  background-color: variables.$blue-grey-50;
  color: variables.$color-text--body;
  fill: variables.$color-text--body;
}

.svgWrapper {
  display: flex;
  width: 1.4rem;
  height: 1.4rem;
  align-items: center;
  justify-content: center;
}

.content {
  font-size: 1rem;
}

.small {
  padding: 0.75rem;
  border-radius: 0.5rem;

  .content {
    font-size: 0.875rem;
  }
}
</style>
