<template>
  <div
    :data-title="tooltip"
    :class="['svg-wrapper', $style.wrapper]"
    data-toggle="tooltip"
    :data-placement="placement"
    data-html="true"
  >
    <SvgIcon :key="name" :name="name" :html-class="htmlClass" />
  </div>
</template>

<script>
import SvgIcon from './SvgIcon';

export default {
  name: 'SvgIconWithTooltip',
  components: {
    SvgIcon,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    htmlClass: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    placement: {
      type: String,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" module>
@use '~/stylesheets/variables';
@use 'app/javascript/src/styles/test';

.wrapper {
  display: flex;
  align-items: center;
  padding: test.rem_calc(8 4);

  @media (variables.$md-down) {
    padding: test.rem_calc(0 2 8);
  }
}
</style>
